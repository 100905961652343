.bl-modal-important {
    width: 760px !important;
    .ant-modal-content {
        border-radius: 12px;
        border: 1px solid var(--white-250, rgba(255, 255, 255, 0.5));
        background: rgba(255, 255, 255, 0.05);
        text-align: center;
        padding: 60px 40px;
        .ant-modal-close {
            top: 25px;
            right: 25px;
            svg {
                font-size: 28px;
                color: #fff;
            }
            @media screen and (max-width: 431px) {
                top: 0;
                right: 0;
            }
        }
        .bl-important {
            .title {
                color: var(--White, var(--White, #fff));
                text-align: center;
                font-family: "ALS Alumna";
                font-size: 48px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                @media screen and (max-width: 431px) {
                    font-size: 30px;
                }
            }
            .text {
                color: var(--White, var(--White, #fff));
                text-align: center;
                font-family: "ALS Hauss";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 32px;
                white-space: pre-line;
                .gold {
                    color: var(--White, var(--Color-6, #fef0b7));
                    font-family: "ALS Hauss";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                    @media screen and (max-width: 431px) {
                        font-size: 18px;
                        line-height: normal;
                    }
                }
                @media screen and (max-width: 431px) {
                    font-size: 18px;
                    line-height: normal;
                }
            }
        }
        @media screen and (max-width: 431px) {
            padding: 38px 8px;
        }
    }
}
