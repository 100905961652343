.bl-number-remove-info-modal {
    .ant-space {
        .ant-space-item {
            .ant-form {
                text-align: center;
            }
        }
    }
    .sub-title {
        font-family: ALS Hauss;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: var(--gray-1, #1f1f1f);
        text-align: center;
        span {
            color: var(--violet-2, #642878);
        }
    }
    .ant-btn {
        width: 100%;
        max-width: 300px;
        margin-top: 24px;
    }
    .bl-field-sim {
        .header {
            display: none;
        }
        @media screen and (max-width: 768px) {
            .ant-space-align-start {
                > .ant-space-item {
                    text-align: start;
                }
            }
            .content {
                width: 100%;
            }
        }
    }
}
