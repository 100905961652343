.bl-sl-bottom {
    position: absolute;
    top: 1338px;
    left: 0;
    right: 0;
    height: 2000px;
    padding-top: 101px;

    @media screen and (max-width: 992px) {
        top: 1012px;
        height: 2000px;
    }
    @media screen and (max-width: 768px) {
        top: 900px;
        height: 1900px;
    }
    @media screen and (min-width: 567px) and (max-width: 768px) {
        top: 1012px;
    }
    @media screen and (max-width: 370px) {
        top: 934px;
    }

    .inner {
        max-width: 1230px;
        margin: auto;
        overflow: hidden;
        .ant-click-animating-node,
        [ant-click-animating-without-extra-node="true"]:after {
            display: none !important;
        }
        .ant-space {
            width: 100%;
            .ant-space-item {
                width: 100%;
                .bz-title {
                    font-weight: 400;
                    font-size: 72px;
                    line-height: 72px;
                    text-transform: uppercase;

                    &.title2 {
                        .second {
                            left: 164px;
                        }
                        @media screen and (max-width: 992px) {
                            margin-top: 50px;
                            .first {
                                left: -20px;
                            }
                            .second {
                                left: 78px;
                            }
                        }
                        @media screen and (max-width: 320px) {
                            .second {
                                left: 58px;
                            }
                        }
                        @media screen and (max-width: 280px) {
                            .second {
                                left: 42px;
                            }
                        }
                    }

                    * {
                        position: relative;
                    }

                    .first {
                        color: #fef0b7 !important;
                        left: -100px;
                    }
                    .second {
                        left: 56px;
                    }
                    @media screen and (max-width: 1200px) {
                        font-size: 52px;
                    }

                    @media screen and (max-width: 992px) {
                        font-weight: 400;
                        font-size: 26px;
                        line-height: 26px;
                        .first {
                            left: -30px;
                        }
                        .second {
                            left: 44px;
                        }
                    }
                    @media screen and (max-width: 320px) {
                        font-size: 20px;
                    }
                }
                .snake {
                    position: relative;

                    @media screen and (max-width: 1240px) {
                        display: flex;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        align-content: center;
                        align-items: center;
                        gap: 26px;
                        margin-top: -4px;
                        svg {
                            font-size: 290px;
                        }
                    }
                    svg {
                        height: 901px;
                        font-size: 1197px;
                    }
                    .item {
                        position: absolute;
                        .bz-typography > * {
                            vertical-align: initial;
                        }
                        .num {
                            background: linear-gradient(
                                141.08deg,
                                #efce73 25.79%,
                                #fef0b7 63.66%,
                                #cea86f 97.07%
                            );
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                            text-fill-color: transparent;
                        }
                        .text {
                            display: flex;
                            flex-direction: column;
                            flex-wrap: nowrap;
                            font-size: 36px;
                            line-height: 62px;
                            text-transform: uppercase;
                            gap: 20px;
                        }
                        &.first {
                            top: 44px;
                            left: 140px;
                        }
                        &.second {
                            top: 360px;
                            left: 0;
                            right: 0;
                            margin: auto;
                        }
                        &.third {
                            top: 44px;
                            right: 96px;
                        }
                        @media screen and (max-width: 1240px) {
                            position: static;
                            height: 318px;
                            display: flex;
                            align-content: center;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            flex-wrap: nowrap;
                            svg {
                                height: 318px;
                                position: absolute;
                                width: 318px;
                                margin: auto;
                                left: 0;
                                right: 0;
                            }
                            .num {
                                font-weight: 400;
                                line-height: 100px;
                                margin-top: -63px;
                            }
                            .text {
                                font-weight: 500;
                                line-height: 26px;
                                gap: 10px;
                                @media screen and (max-width: 280px) {
                                    gap: 2px;
                                }
                            }
                            &.second {
                                .num {
                                    margin-top: -20px;
                                }
                            }
                        }
                        @media screen and (max-width: 320px) {
                            height: 288px;
                            svg {
                                height: 288px;
                                width: 288px;
                            }
                        }
                        @media screen and (max-width: 280px) {
                            height: 236px;
                            svg {
                                height: 236px;
                                width: 236px;
                            }
                        }
                    }
                }
                .subtitle {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    line-height: 62px;
                    text-transform: uppercase;
                    gap: 20px;
                    font-style: normal;
                    font-weight: 300;
                    margin-top: -50px;
                    margin-bottom: 30px;
                    b {
                        font-weight: 500;
                    }
                    @media screen and (max-width: 992px) {
                        line-height: 26px;
                        gap: 1px;
                        margin-top: -15px;
                        margin-bottom: 91px;
                        padding: 0 15px;
                    }
                }
                .actions {
                    .ant-col {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @media screen and (max-width: 992px) {
                            display: block;
                            text-align: center;
                        }
                        .ant-btn {
                            background: transparent;
                            border: 0;
                            display: grid;
                            flex-direction: column;
                            flex-wrap: nowrap;
                            align-content: center;
                            justify-content: center;
                            align-items: center;
                            justify-items: center;
                            gap: 10px;
                            font-weight: 300;
                            font-size: 36px;
                            line-height: 62px;
                            text-align: center;
                            text-transform: uppercase;
                            height: auto;
                            text-align: center;
                            svg {
                                height: 98px;
                                margin: auto;
                            }
                            &.b1 {
                                svg {
                                    font-size: 98px;
                                }
                            }
                            &.b2 {
                                svg {
                                    font-size: 131px;
                                }
                            }
                            &.b3 {
                                svg {
                                    font-size: 119px;
                                }
                            }
                            @media screen and (max-width: 1240px) {
                                font-size: 24px;
                            }
                            @media screen and (max-width: 992px) {
                                font-weight: 300;
                                font-size: 18px;
                                display: block;
                                margin: auto;
                                > * {
                                    display: block;
                                }
                            }
                            @media screen and (max-width: 280px) {
                                font-size: 16px;
                            }
                        }
                    }
                }
                .like {
                    .ant-btn {
                        background: transparent;
                        border: 0;
                        display: grid;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        align-content: center;
                        justify-content: center;
                        align-items: center;
                        justify-items: center;
                        gap: 10px;
                        font-weight: 300;
                        font-size: 36px;
                        line-height: 62px;
                        text-align: center;
                        text-transform: uppercase;
                        height: auto;
                        text-align: center;
                        margin: auto;
                        ::after {
                            display: none;
                        }
                        img {
                            width: 102px;
                            height: 102px;
                        }
                        @media screen and (max-width: 1240px) {
                            font-size: 24px;
                        }
                        @media screen and (max-width: 992px) {
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 62px;
                            margin-top: 100px;
                        }
                        @media screen and (max-width: 280px) {
                            font-size: 16px;
                        }
                    }
                }
                .bl-statistics {
                    .bl-scroller-wrapper {
                        margin: 24px auto;
                    }
                    .title-statistic {
                        background: var(
                            --Gold-Gradient-1,
                            linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%)
                        );
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        text-align: center;
                        font-family: ALS Alumna;
                        font-size: 72px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: uppercase;
                        margin-top: 60px;
                        margin-bottom: 50px;
                    }
                    .title-statistic-mobile {
                        .first {
                            left: -76px;
                            text-transform: uppercase;
                            background: var(
                                --Gold-Gradient-1,
                                linear-gradient(
                                    141deg,
                                    #efce73 25.79%,
                                    #fef0b7 63.66%,
                                    #cea86f 97.07%
                                )
                            );
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            left: -68px;
                            position: relative;
                        }
                        .second {
                            font-size: 26px;
                            line-height: normal;
                            text-transform: uppercase;
                            position: relative;
                            left: 35px;
                        }
                    }
                    .bl-group-statistics {
                        overflow: hidden;
                        .group-coll {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            grid-gap: 72px;
                            .group-count {
                                background: var(
                                    --Gold-Gradient-1,
                                    linear-gradient(
                                        141deg,
                                        #efce73 25.79%,
                                        #fef0b7 63.66%,
                                        #cea86f 97.07%
                                    )
                                );
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                display: flex;
                                align-items: center;
                                img {
                                    margin-right: 16px;
                                }
                                .ant-space {
                                    text-align: start;
                                }
                                .count {
                                    font-style: normal;
                                    font-weight: 550;
                                    line-height: 24px;
                                }
                                .text-count {
                                    font-style: normal;
                                    font-weight: 550;
                                    line-height: 24px;
                                }
                            }
                            @media screen and (max-width: 425px) {
                                gap: 24px;
                                flex-wrap: nowrap;
                                overflow: auto;
                                justify-content: flex-start;
                                padding: 0 16px;
                                padding-bottom: 8px;
                                margin-bottom: -8px;
                            }
                        }
                    }
                }
            }
        }
    }

    .bl-footer {
        @media screen and (max-width: 991px) {
            .bl-legal-info {
                align-items: flex-start;

                div:first-child {
                    text-align: left;
                }
            }
        }
    }
}
