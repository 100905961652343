@import "../../../../../../../styles/variables";

.bl-collection-collapse {
    &.locked {
        pointer-events: none;
    }
    .ant-collapse {
        background: transparent;
        border: 0;
        .ant-collapse-header {
            padding: 8px 8px;
            .ant-collapse-arrow {
                top: 20px;
                right: 121px;

                @media screen and (max-width: 319px) {
                    right: 114px;
                }
            }
            .ant-collapse-header-text {
                font-family: "ALS Alumna";
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 24px;
                color: #ffffff;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .tariff-plan {
                    display: flex;
                }
                .name {
                    font-family: "ALS Hauss";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 100%;
                    color: rgba(255, 255, 255, 0.5);
                    margin-top: 8px;
                }
                .phone {
                    font-size: 28px;
                    line-height: 100%;
                    font-family: "ALS Sansita", serif;
                    @-moz-document url-prefix() {
                        font-size: 20px;
                    }
                }
                .is-ios {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 26px;
                    line-height: 100%;
                    color: #fff !important;
                    font-family: "ALS Sansita", serif;
                    &.shimmer {
                        color: transparent !important;
                    }
                }
                .name-ios {
                    font-family: "ALS Hauss";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 140%;
                    color: rgba(255, 255, 255, 0.5);
                    margin-top: 8px;
                }
            }
        }
        .ant-collapse-item-active .ant-collapse-header {
            padding: 8px 8px 3px;
            .ant-collapse-arrow {
                transform: rotate(180deg);
                -webkit-transition: all 0.4s ease-in-out;
                -moz-transition: all 0.4s ease-in-out;
                -ms-transition: all 0.4s ease-in-out;
                -o-transition: all 0.4s ease-in-out;
                transition: all 0.4s ease-in-out;
            }
        }
        .ant-collapse-item {
            border: none;
            border-radius: 8px;
            &:not(:last-child) {
                margin-bottom: 0;
            }
            .ant-collapse-arrow {
                transform: rotate(0deg);
                -webkit-transition: all 0.4s ease-in-out;
                -moz-transition: all 0.4s ease-in-out;
                -ms-transition: all 0.4s ease-in-out;
                -o-transition: all 0.4s ease-in-out;
                transition: all 0.4s ease-in-out;
            }
            .ant-collapse-content {
                background: transparent;
                border: 0;
                > .ant-collapse-content-box {
                    padding-top: 0;
                    padding: 0;
                    font-family: "ALS Hauss";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 100%;
                    color: #ffffff;
                    ol > li,
                    .price {
                        font-family: "ALS Hauss";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 140%;
                        color: rgba(255, 255, 255, 0.5);
                    }
                    .price-ios,
                    .price-android {
                        font-family: "ALS Hauss";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 140%;
                        color: #ffffff;
                    }
                    .price-ios-rest,
                    .price-rest {
                        font-family: "ALS Hauss";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 140%;
                        color: #ffffff;
                        margin-top: 8px;
                    }
                    .icons-list {
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        line-height: 24px;
                        color: #ffffff;
                        img {
                            margin-right: 14px;
                        }
                    }
                    .info-tariff {
                        margin-top: 9px;
                        .ant-space {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .shimmer {
            pointer-events: none;
            color: transparent;
            background: linear-gradient(
                90deg,
                hsla(0, 0%, 75%, 0.35) 25%,
                hsla(0, 0%, 51%, 0.4) 37%,
                hsla(0, 0%, 75%, 0.35) 63%
            );
            background-size: 400% 100%;
            animation-name: skeleton-loading;
            animation-duration: 1.4s;
            animation-timing-function: ease;
            animation-iteration-count: infinite;

            &.text {
                border-radius: 4px;
            }
            &.btn {
                border-radius: 8px !important;
                border-color: transparent;
                span {
                    color: transparent;
                }
                &:hover {
                    background: linear-gradient(
                        90deg,
                        hsla(0, 0%, 75%, 0.35) 37%,
                        hsla(0, 0%, 75%, 0.35) 63%
                    );
                }
            }
        }
        @keyframes skeleton-loading {
            0% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0 50%;
            }
        }
        .lightning {
            cursor: pointer;
            position: relative;
            top: 1px;
            border-radius: 8px;
            font-size: 32px;
        }
    }

    .container {
        padding: 9px 5px 16px 0px;
        .group-icons {
            -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
            background-image: radial-gradient(
                    circle at 100% 100%,
                    #0000 12px,
                    #efce73 0,
                    #efce73 13px,
                    #0000 0
                ),
                linear-gradient(90deg, #efce73, #988967),
                radial-gradient(circle at 0 100%, #0000 12px, #988967 0, #988967 13px, #0000 0),
                linear-gradient(180deg, #988967, #605b4e),
                radial-gradient(circle at 0 0, #0000 12px, #605b4e 0, #605b4e 13px, #0000 0),
                linear-gradient(270deg, #605b4e, #efce73),
                radial-gradient(circle at 100% 0, #0000 12px, #efce73 0, #efce73 13px, #0000 0),
                linear-gradient(0deg, #efce73, #efce73);
            background-position: 0 0, top, 100% 0, 100%, 100% 100%, bottom, 0 100%, 0;
            background-repeat: no-repeat;
            background-size: 13px 13px, calc(100% - 26px) 1px, 13px 13px, 1px calc(100% - 26px);
            border-color: #0000;
            padding: 6px 8px 8px;
            .ant-space {
                .ant-space-item:not(:last-child) {
                    background-clip: content-box;
                    border-bottom: 1px solid #fff;
                    padding-bottom: 6px;
                    padding-top: 2px;
                }
            }
            @media screen and (max-width: 431px) {
                max-width: 300px;
            }
        }
        .colapse-conteiner--social-text {
            @media screen and (max-width: 370px) {
                font-size: 13px;
            }
        }
        .price-ios-rest,
        .price-rest {
            font-family: "ALS Hauss";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 140%;
            background: var(
                --gold-gradient-1,
                linear-gradient(171deg, #efce73 0%, #fef0b7 53.13%, #cea86f 100%)
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 14px;
        }
        .price-ios,
        .price-android {
            font-family: "ALS Hauss";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 140%;
            color: #ffffff;
            border-radius: 8px;
            background: rgba(82, 8, 106, 0.3);
            max-width: fit-content;
            padding: 4px 8px;
        }
        .bl-info-common-wrapper {
            .bg-value-int-gold {
                background: url(~@bezlimit/bezlimit-ui/dist/images/gold-opacity.png) center center
                        no-repeat,
                    url(~@bezlimit/bezlimit-ui/dist/images/gold-texture.png) center center no-repeat;
                background-size: cover;
                background-blend-mode: color, normal;
                border-radius: 16px;
                width: 86px;
                max-width: 125px;
                height: 33px;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                .info-packet-value {
                    color: #000;
                    font-family: "ALS Hauss";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%;
                    .inline {
                        color: #000;
                        font-size: 16px !important;
                        font-weight: 700 !important;
                    }
                }
            }
            .col-info-packet {
                .info-packet-value {
                    display: flex;
                    align-items: center;
                    .dark {
                        color: #000;
                        font-family: "ALS Hauss";
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 140%;
                    }
                    .gold {
                        font-family: "ALS Hauss";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%;
                    }
                    .suffix {
                        font-family: "ALS Hauss";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }

        @media screen and (max-width: 375px) {
            padding: 9px 0px 16px 0px;
        }
    }
}

.bl-collection-collapse {
    .colapse-conteiner {
        &--social-icons-col {
            width: 80px;
        }
        .name-tariff {
            color: #fff;
            font-family: "ALS Hauss";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
        }
        .ant-space-vertical {
            .ant-space-item {
                .social-icons-col {
                    img {
                        margin-right: 3px;
                    }
                }
            }
        }
    }
}
.colapse-conteiner {
    &--social-icons-row {
        display: flex;
        align-items: flex-end;
    }
    &--social-icons-col {
        width: 100px;
    }
    &--social-text {
        color: #fff !important;
        font-size: 14px;
    }
    @media screen and (max-width: 420px) {
        &--social-icons-col {
            width: 85px;
        }
    }
}
