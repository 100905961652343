@import '../../../../styles/variables';

.bonuses-rate-collapse {
    .ant-collapse-header {
        padding-left: 0 !important;
        .ant-collapse-expand-icon{
            color: $text-primary-color;
        }
    }
    .ant-collapse-content-box{
        padding-bottom: 0px !important;
        padding-top: 0px !important;
    }
}
.bonuses-rate-panel {
    p {
        display: flex;
        align-items: baseline;
        padding-left: 12px;
    }
    span{
        padding-left: 12px;
        color: $beige-color;
        @include tinyFont-normal;
    }
}

.bonuses-rate {
    .bonuses-rate-text {
        @include tinyFont-normal;
        white-space: nowrap;
    }
    .ant-space-item {
        position: relative;
        padding-left: 12px;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 4px;
            height: 26px;
            background: linear-gradient(141.08deg, #EFCE73 25.79%, #FEF0B7 63.66%, #CEA86F 97.07%);
            border-radius: 8px;
        }
    }
}