.bl-numbers-filters {
    .btn-skeleton {
        height: 48px;
        width: 100%;
        border-radius: 12px;
        background: linear-gradient(
            91.53deg,
            rgba(255, 255, 255, 0.35) -9.38%,
            rgba(255, 255, 255, 0) 99.81%
        );
    }
    .selected {
        width: 100%;
        &.ant-space {
            > .ant-space-item {
                &:first-child {
                    display: flex;
                    // justify-content: flex-end;
                    gap: 8px;
                    .ant-btn {
                        &.ant-btn-text {
                            &.reset {
                                display: none;
                                background: transparent !important;
                                font-weight: 400;
                                font-size: 17px;
                                line-height: 22px;
                                color: rgba(255, 255, 255, 0.5);
                                align-items: center;
                                flex-direction: row-reverse;
                                gap: 8px;
                                &:hover {
                                    color: #fff;
                                }
                                svg {
                                    color: #fff;
                                }
                                &.active {
                                    display: flex;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

  .btn-filter {
    width: 100%;
  }
}

.rename-modal,
.delete-success-modal,
.save-filter-modal {
  top: 33%;
}

.bl-numbers-filters {
  padding-top: 60px;

  .selected {
    width: 100%;

    &.ant-space {
      > .ant-space-item {
        &:last-child {
          gap: 8px;
        }
      }
        }
    }
    .btn-filter {
        width: 100%;
    }
    .group-button {
        .ant-btn {
            color: rgba(255, 255, 255, 0.5);
            font-family: "ALS Hauss";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 140%;
            background: transparent;
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            &:hover {
                color: #fff;
            }
            svg {
                margin-left: 8px;
            }
        }
    }
    .group-button-refresh {
        .btn-refresh {
            background: transparent;
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #ffffff;
            border-radius: 8px;
            min-height: 44px;
        }
        .btn-save-new {
            background: transparent;
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@media screen and (min-width: 1200px) {
    .bl-numbers-filters {
        .selected {
            .group-button {
                > .ant-row {
                    &:first-child {
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}
