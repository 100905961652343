.bl-radio-button-group {
    .ant-form-item-control-input-content {
        overflow: hidden;
        height: 32px;
        .ant-radio-group {
            overflow-x: auto;
            display: flex;
            flex-direction: row;
            padding-bottom: 17px;
            overflow-y: hidden;
            .ant-radio-button-wrapper {
                border-radius: 32px;
                width: 80px;
                margin-right: 5px;
                font-weight: 700;
                font-size: 17px;
                color: #000000;
                padding: 0 10px;
                text-align: center;
                background: url(~@bezlimit/bezlimit-ui/dist/images/gold-opacity.png) center center
                        no-repeat,
                    url(~@bezlimit/bezlimit-ui/dist/images/gold-texture.png) center center no-repeat;
                background-size: 100%;
                background-blend-mode: color, normal;
                transition: none;
                white-space: nowrap;
                &:first-child {
                    border-left: 0px solid #d9d9d900;
                }
                &:not(:first-child):before {
                    width: 0px;
                }
            }
            .ant-radio-button-wrapper-disabled {
                background: linear-gradient(0deg, #cfc8db, #cfc8db);
                color: #ffffff;
                white-space: nowrap;
            }
            .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
                border-color: #642878;
                background: #642878;
                color: #ffffff;
            }
        }
    }
}
.input-summ-space {
    display: flex;
    flex-wrap: wrap;
    text-align: start;
    position: relative;
    input {
        color: #1f1f1f !important;
        width: 184px;
        border: 1px solid #e5e5e5;
    }
    label {
        color: #828282;
    }
    .focused {
        .ant-space-horizontal {
            gap: 2px !important;
        }
    }
    .ant-col-14 {
        display: flex;
        padding: 18px 0;
    }

    .bz-input-block {
        border-radius: 8px;
        label {
            color: #828282 !important;
        }
        .ant-input-status-success {
            padding-bottom: 11px;
            padding-top: 21px;
            + .title {
                font-size: 12px;
                height: 12px;
                margin-top: 6px;
                .ant-space-horizontal {
                    gap: 2px !important;
                }
            }
        }
    }
    .ant-form-item-explain-error {
        text-align: start;
    }
    .ant-form-item {
        margin-bottom: 0px;
    }
    .ant-space-item > p {
        font-family: "ALS Hauss";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #828282;
        position: absolute;
        top: 17px;
        left: 200px;
        @media screen and (max-width: 350px) {
            position: relative;
            top: -10px;
            left: 2px;
        }
        &:nth-child(3) {
            width: 100% !important;
        }
    }
    .ant-space-item {
        &:nth-child(3) {
            width: 100% !important;
        }
    }
}
