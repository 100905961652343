.bl-legal-info {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    * {
        white-space: nowrap;
        p {
            display: inline;
        }
    }

    .copyright {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-end;
        flex-direction: column;
    }

    .ant-typography {
        color: #fff;
        margin: 0;
    }
    .legal-info {
        padding-bottom: 5px;
        border-bottom: 1px solid #848484;
        cursor: pointer;
    }
    .ant-space {
        @media screen and (max-width: 992px) {
            width: 100%;
            .ant-space-item {
                width: 100%;
            }
        }
    }
}

@media screen and (min-width: 576px) {
    .bl-legal-info {
        align-items: flex-end;

        div:first-child {
            text-align: right;
        }
    }
}
@media screen and (max-width: 991px) {
    .bl-legal-info {
        align-items: flex-start;

        div:first-child {
            text-align: right;
        }
    }
}
