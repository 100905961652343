.bl-reservation-list-header {
    .ant-form {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        gap: 20px;

        .ant-form-item {
            width: 100%;
            margin: 0;
            .ant-checkbox-group {
                display: flex;
                width: 100%;
                label {
                    height: 40px;
                    border-radius: 36px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 100%;
                    background: rgba(255, 255, 255, 0.1);
                    display: flex;
                    flex-wrap: nowrap;
                    flex-direction: row;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    padding: 0 16px;
                    white-space: nowrap;

                    .ant-checkbox-inner {
                        display: none;
                    }
                    &.ant-checkbox-wrapper-checked {
                        box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.25);

                        border: none;
                        background-image: url("../../../../../images/btn_long@1x.jpg");
                        background-image: -webkit-image-set(
                            url("../../../../../images/btn_long@1x.webp") 1x,
                            url("../../../../../images/btn_long@1x.jpg") 1x,
                            url("../../../../../images/btn_long@2x.webp") 2x,
                            url("../../../../../images/btn_long@2x.jpg") 2x
                        );
                        background-image: image-set(
                            url("../../../../../images/btn_long@1x.webp") 1x,
                            url("../../../../../images/btn_long@1x.jpg") 1x,
                            url("../../../../../images/btn_long@2x.webp") 2x,
                            url("../../../../../images/btn_long@2x.jpg") 2x
                        );
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        * {
                            color: black !important;
                        }
                    }
                    &.ant-checkbox-wrapper-disabled {
                        background: rgb(229 229 229 / 50%);
                        * {
                            color: #000;
                        }
                    }
                    &:last-child {
                        width: 16px;
                        padding: 0;
                        margin: 0;
                        opacity: 0;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 576px) {
        .filter-container {
            margin-left: -16px !important;
            margin-right: -16px !important;
            height: 40px;
            overflow: hidden;
            .ant-row {
                width: 100%;
                margin: 0 !important;
                padding-bottom: 12px;
                max-width: calc(100vw - 0px);
                overflow: auto;
                .ant-col {
                    padding-left: 16px !important;
                    padding-right: 16px !important;
                    .ant-space-item:last-child {
                        .ant-btn {
                            margin-right: 16px;
                        }
                    }
                }
            }
        }
    }
    .ant-space {
        display: flex;
        justify-content: flex-start;
        .ant-btn {
            height: 40px;
            border-radius: 36px;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            &.ant-btn-default {
                background: rgba(255, 255, 255, 0.1);
            }
            &.ant-btn-primary {
                box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.25);
            }
        }
    }
}

.toltip-superlink {
    transition: none;
    z-index: 1;
    position: relative;
    .ant-tooltip-arrow {
        transition: none;
        top: inherit;
        bottom: -21px;
        left: 0;
        right: -1px;
        margin: auto;
        transform: none;
        .ant-tooltip-arrow-content {
            transition: none;
            --antd-arrow-background-color: linear-gradient(
                to right bottom,
                rgba(94, 0, 94, 1),
                rgba(94, 0, 94, 1)
            );
            overflow: hidden;
            border: 1px solid rgba(255, 255, 255, 0.6);
            border-radius: 0;
            transform: translateY(-10px) rotate(45deg);
            &::before {
                top: -13px;
            }
        }
    }
    .ant-tooltip-inner {
        background: rgb(94 0 94);
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 8px;
        position: relative;
        transition: none;
        white-space: initial;
        &::after {
            content: "";
            transition: none;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -1px;
            height: 1px;
            background: rgb(94 0 94);
            margin: auto;
            width: 13px;
        }
    }
}
