.bl-extend-form {
  .ant-btn:not(.ant-btn-text) {
    width: 100%;
  }
  .copy {
    display: none;
  }
}

.bl-remove-booking-form {
  .cancel {
    .ant-btn {
      font-weight: 500;
      line-height: 120%;
    }
  }
}

@media screen and (max-width: 768px) {
  .bl-extend-form {
    .cancel {
      margin-top: 16px;
    }
  }
}