.bl-personal-manager-buttons {
  .ant-space, .ant-btn {
    width: 100%;
  }
  .copy {
    position: relative;
    overflow: hidden;
    span {
      display: flex;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      border-radius: 8px;
    }
  }
}

@media screen and (max-width: 768px) {
  .bl-personal-manager-buttons {
    .phone {
      font-weight: 400;
    }
  }
}