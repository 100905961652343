.bl-code-confirmation-replenish-bonuses {
    text-align: center;
    .text-helper.subtitle {
        font-weight: 500;
    }
    .phone {
        margin-bottom: 16px;
    }
    .field-code {
        margin-bottom: 8px;
    }
    .send-to-phone-message {
        line-height: 19px;
        margin-bottom: 8px;
    }
}

@media screen and (max-width: 768px) {
    .bl-code-confirmation-replenish-bonuses {
        h2 {
            font-weight: 700;
        }
        .subtitle.text-helper {
            font-weight: 400;
        }
        .phone {
            margin-bottom: -40px;
            margin-top: 10px;
        }
    }
}
