@import "../../../../styles/variables";

.bl-dealers-bonuses {
    .title {
        @include bigFont-medium;
    }
    .subtitle {
        @include standardFont-medium;
        color: $text-pale-color;
    }
    .col-title {
        @include tinyFont-medium;
        color: $text-pale-color;
        &.penultimate {
            display: flex;
            justify-content: right;
        }
        &.last {
            display: flex;
            justify-content: right;
        }
    }
}
