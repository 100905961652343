.bl-share-super-limk-modal {
    .ant-space {
        text-align: center;
        width: 100%;
        .title {
            font-weight: 700;
            line-height: 120%;
            text-align: center;
        }
        button,
        .copy-btn {
            background: transparent;
            border: 0;
            cursor: pointer;
            img {
                width: 40px;
                height: 40px;
            }
            .bz-typography {
                margin-top: 8px;
                font-weight: 400;
                line-height: 140%;
            }
        }
    }
}
