@import "../../../styles/variables";

.bl-dealers-page {
    max-width: 965px;
    margin: auto;

    .dealers-content {
        margin-top: 80px;
        margin-bottom: 40px;
    }

    .dealers-invite {
        margin-top: 40px;
        margin-bottom: 25px;
    }

    .dealers-search-btn {
        width: 100%;
    }

    .dealers-search-input {
        .ant-input-affix-wrapper {
            background: $bg-opacity-color;
            border: none;
            border-bottom-left-radius: 8px !important;
            border-top-left-radius: 8px !important;

            input.ant-input {
                &:placeholder-shown {
                    color: $text-pale-color;
                }
                color: $text-primary-color;
                @include title-inputFont-normal;
            }

            .ant-input-clear-icon {
                color: $text-pale-color;
            }
        }
        .ant-input-group-addon {
            border-bottom-right-radius: 8px !important;
            border-top-right-radius: 8px !important;
            background: $bg-opacity-color;
            border: none;
            .ant-input-search-button {
                color: $text-primary-color !important;
                background: none;
            }
        }
    }

    @media screen and (max-width: $max-tablet-width) {
        .dealers-content {
            margin-top: 40px;
            margin-bottom: 20px;
        }
    }
}

.bl-dealers-empty {
    max-width: 818px;
    margin: 80px auto 40px;
    text-align: center;

    .dealers-empty-image {
        margin-top: 8px;
    }

    .dealers-empty-title {
        margin-bottom: 8px;
        @include bigFont-medium;
    }

    .dealers-empty-description {
        @include standardFont-medium;
        color: $text-pale-color;
    }
}

.bl-dealer-info {
    margin-bottom: 16px;
    .dealer-info-collapse {
        .ant-collapse-header {
            padding: 0;
            .ant-collapse-expand-icon {
                color: $text-primary-color;
                svg {
                    transform: rotate(-90deg);
                }
            }
        }
        .ant-collapse-header-text {
            width: 100%;
        }
        .ant-collapse-content-box {
            margin-top: 32px;
            padding: 0 !important;
        }
    }

    .dealer-info-name {
        @include bigFont-medium;
    }

    .dealer-info-header-label {
        color: $text-pale-color;
        @include tinyFont-medium;
    }

    .dealer-info-label {
        color: $text-pale-color;
        @include standardFont-medium;
    }

    .dealer-info-activation {
        @include title-bigFont-normal;
    }
    .dealer-info-date {
        margin-top: 10px;
        @include standardFont-medium;
    }

    @media screen and (max-width: $max-tablet-width) {
        .dealer-info-name {
            @include standardFont-medium;
        }
        .dealer-info-label {
            @include tinyFont-medium;
        }
        .dealer-info-activation {
            @include title-tinyFont-normal;
        }
    }
}
