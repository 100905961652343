.bl-invite-a-user {
    height: 100%;
    .bz-block {
        height: 100%;
        .bz-block-title {
            line-height: 120%;
        }
        .bz-block-content {
            height: calc(100% - 66px);
            .ant-space {
                height: 100%;
                display: flex;
                justify-content: space-between;
            }
            .ant-btn {
                width: 100%;
            }
        }
        .subtitle {
            text-align: center;
        }
        .bz-block-content:not(:first-child) {
            margin-top: 8px;
        }
    }
}

.bl-invite-a-user-isPageDealers {
    width: 965px;
    height: 80px;
    & > .bz-block {
        border: 1px solid #ffce73;
        padding: 20px 16px;
        .ant-space {
            margin-top: 18px;
        }
        .text {
            font-weight: 500;
            width: 300px;
        }
        button {
            width: 550px !important;
        }
    }
    @media screen and (max-width: 960px) {
        width: 100%;
        & > .bz-block {
            button {
                width: 350px !important;
            }
        }
    }
    @media screen and (max-width: 780px) {
        height: 136px;
        & > .bz-block {
            .ant-space {
                margin-top: 0px !important;
            }
            .text {
                font-size: 14px;
                line-height: 140%;
                font-weight: 500;
                width: 100%;
            }
            button {
                margin-top: 15px !important;
                width: 100% !important;
            }
        }
    }
    @media screen and (max-width: 550px) {
        & > .bz-block {
            button {
                margin-top: 0px !important;
            }
        }
    }
    @media screen and (max-width: 320px) {
        height: 150px;
    }
}

@media screen and (max-width: 768px) {
    .bl-invite-a-user {
        .bz-block {
            .text {
                line-height: 19px;
            }
        }
    }
}
