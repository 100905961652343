.bl-favorite-numbers {
    text-align: center;
    * {
        transition: none !important;
    }
    @media screen and (min-width: 992px) {
        margin-top: -58px;
    }
    &.op4 {
        .numbers {
            opacity: 0.4 !important;
        }
    }
    .stron {
        font-weight: 500;
    }
    .btn-skeleton {
        height: 48px;
        min-width: 252px;
        border-radius: 12px;
        background: linear-gradient(
            91.53deg,
            rgba(255, 255, 255, 0.35) -9.38%,
            rgba(255, 255, 255, 0) 99.81%
        );
    }
    &.all-selected {
        .subtext {
            text-align: center;
            position: relative;
            left: -5px;
        }
        @media screen and (max-width: 768px) {
            .ant-space {
                &.first-space {
                    gap: 46px !important;
                }
            }
            .numbers {
                gap: 13px !important;
            }
        }
        @media screen and (max-width: 576px) {
            > .ant-space {
                width: 100%;
                > .ant-space-item {
                    width: 100%;
                    > .ant-space {
                        width: 100%;
                    }
                    .subtext {
                        text-align: left;
                        position: static;
                    }
                }
            }
            .subtext {
                margin-top: 4px;
            }
        }
    }
    .mode {
        min-width: 252px;
        background-size: 100% 100%;
        background-repeat: round;
        img {
            width: 20px;
            margin-right: 8px;
        }
    }
    .bz-title {
        background: linear-gradient(141.08deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    .subtext {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        text-align: left;
        color: rgba(255, 255, 255, 0.5);
    }
    .ant-space.numbers {
        align-content: center;
        justify-content: center;
        align-items: center;
        position: relative;
        .remove {
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: flex-end;
            text-align: center;
            position: absolute;
            cursor: pointer;
            top: 0;
            bottom: 0;
            margin: auto;
        }
        .ant-btn.default,
        .ant-btn.selected {
            background: rgba(255, 255, 255, 0.1);
            opacity: 0.8;
            border: 1px solid rgba(255, 255, 255, 0.3);
            backdrop-filter: blur(3px);
            border-radius: 8px;
            width: 46px;
            height: 64px;
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            .bz-typography {
                font-weight: 400;
                font-size: 58px;
                line-height: 100%;
                text-align: center;
                position: relative;
                top: 3px;
                left: 1px;
            }
            @media screen and (max-width: 768px) {
                width: 30px;
                height: 52px;
                .bz-typography {
                    font-size: 46px;
                }
            }
            @media screen and (max-width: 320px) {
                width: 24px;
                .bz-typography {
                    font-size: 36px;
                }
            }
        }
        .ant-btn.default {
            .bz-typography {
                background: linear-gradient(
                    141.08deg,
                    #efce73 25.79%,
                    #fef0b7 63.66%,
                    #cea86f 97.07%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                ont-style: normal;
            }
        }
        .ant-btn.selected {
            background: transparent;
            position: relative;
            border: 0;
            &.full {
                width: 64px;
                @media screen and (max-width: 768px) {
                    width: 52px;
                    .bz-typography {
                        font-size: 64px;
                    }
                }
            }
            .bz-typography {
                position: relative;
                font-style: normal;
                font-size: 72px;
                color: #1f1f1f;
                @media screen and (max-width: 768px) {
                    font-size: 52px;
                    left: 0px;
                }
                @media screen and (max-width: 320px) {
                    font-size: 42px;
                }
            }
            .bg {
                position: absolute;
                left: -6px;
                right: -6px;
                top: -6px;
                bottom: -6px;
                margin: auto;
                background-size: 100% 100% !important;
                &.index-1 {
                    // background: url("../../../../../images/fn-gold.png");

                    background-image: url("../../../../../images/fn_gold.png");
                    background-image: -webkit-image-set(
                        url("../../../../../images/fn_gold.webp") 1x,
                        url("../../../../../images/fn_gold.png") 1x,
                        url("../../../../../images/fn_gold.webp") 2x,
                        url("../../../../../images/fn_gold.png") 2x,
                        url("../../../../../images/fn_gold.webp") 3x,
                        url("../../../../../images/fn_gold.png") 3x
                    );
                    background-image: image-set(
                        url("../../../../../images/fn_gold.webp") 1x,
                        url("../../../../../images/fn_gold.png") 1x,
                        url("../../../../../images/fn_gold.webp") 2x,
                        url("../../../../../images/fn_gold.png") 2x,
                        url("../../../../../images/fn_gold.webp") 3x,
                        url("../../../../../images/fn_gold.png") 3x
                    );
                }
                &.index-2 {
                    // background: url("../../../../../images/fn-silver.png");

                    background-image: url("../../../../../images/fn_silver.png");
                    background-image: -webkit-image-set(
                        url("../../../../../images/fn_silver.webp") 1x,
                        url("../../../../../images/fn_silver.png") 1x,
                        url("../../../../../images/fn_silver.webp") 2x,
                        url("../../../../../images/fn_silver.png") 2x,
                        url("../../../../../images/fn_silver.webp") 3x,
                        url("../../../../../images/fn_silver.png") 3x
                    );
                    background-image: image-set(
                        url("../../../../../images/fn_silver.webp") 1x,
                        url("../../../../../images/fn_silver.png") 1x,
                        url("../../../../../images/fn_silver.webp") 2x,
                        url("../../../../../images/fn_silver.png") 2x,
                        url("../../../../../images/fn_silver.webp") 3x,
                        url("../../../../../images/fn_silver.png") 3x
                    );
                    left: -4px;
                    right: -4px;
                    top: -4px;
                    bottom: -4px;
                }
                &.index-3 {
                    // background: url("../../../../../images/fn-bronz.png");
                    background-image: url("../../../../../images/fn_bronze.png");
                    background-image: -webkit-image-set(
                        url("../../../../../images/fn_bronze.webp") 1x,
                        url("../../../../../images/fn_bronze.png") 1x,
                        url("../../../../../images/fn_bronze.webp") 2x,
                        url("../../../../../images/fn_bronze.png") 2x,
                        url("../../../../../images/fn_bronze.webp") 3x,
                        url("../../../../../images/fn_bronze.png") 3x
                    );
                    background-image: image-set(
                        url("../../../../../images/fn_bronze.webp") 1x,
                        url("../../../../../images/fn_bronze.png") 1x,
                        url("../../../../../images/fn_bronze.webp") 2x,
                        url("../../../../../images/fn_bronze.png") 2x,
                        url("../../../../../images/fn_bronze.webp") 3x,
                        url("../../../../../images/fn_bronze.png") 3x
                    );
                    left: -2px;
                    right: -2px;
                    top: -2px;
                    bottom: -2px;
                }
                @media screen and (max-width: 768px) {
                    left: -2px;
                    right: -2px;
                    top: -2px;
                    bottom: -2px;
                    &.index-2 {
                        left: -1px;
                        right: -1px;
                        top: -1px;
                        bottom: -1px;
                    }
                }
            }
            &.full {
                .bg {
                    &.index-1 {
                        // background: url("../../../../../images/fn-gold2.png");

                        background-image: url("../../../../../images/fn_gold2.png");
                        background-image: -webkit-image-set(
                            url("../../../../../images/fn_gold2.webp") 1x,
                            url("../../../../../images/fn_gold2.png") 1x,
                            url("../../../../../images/fn_gold2.webp") 2x,
                            url("../../../../../images/fn_gold2.png") 2x,
                            url("../../../../../images/fn_gold2.webp") 3x,
                            url("../../../../../images/fn_gold2.png") 3x
                        );
                        background-image: image-set(
                            url("../../../../../images/fn_gold2.webp") 1x,
                            url("../../../../../images/fn_gold2.png") 1x,
                            url("../../../../../images/fn_gold2.webp") 2x,
                            url("../../../../../images/fn_gold2.png") 2x,
                            url("../../../../../images/fn_gold2.webp") 3x,
                            url("../../../../../images/fn_gold2.png") 3x
                        );
                        top: -5px;
                        bottom: -5px;
                        @media screen and (max-width: 768px) {
                            right: -5px;
                            left: -5px;
                        }
                    }
                    &.index-2 {
                        // background: url("../../../../../images/fn-silver2.png");

                        background-image: url("../../../../../images/fn_silver2.png");
                        background-image: -webkit-image-set(
                            url("../../../../../images/fn_silver2.webp") 1x,
                            url("../../../../../images/fn_silver2.png") 1x,
                            url("../../../../../images/fn_silver2.webp") 2x,
                            url("../../../../../images/fn_silver2.png") 2x,
                            url("../../../../../images/fn_silver2.webp") 3x,
                            url("../../../../../images/fn_silver2.png") 3x
                        );
                        background-image: image-set(
                            url("../../../../../images/fn_silver2.webp") 1x,
                            url("../../../../../images/fn_silver2.png") 1x,
                            url("../../../../../images/fn_silver2.webp") 2x,
                            url("../../../../../images/fn_silver2.png") 2x,
                            url("../../../../../images/fn_silver2.webp") 3x,
                            url("../../../../../images/fn_silver2.png") 3x
                        );
                        top: -3px;
                        bottom: -3px;
                        right: -3px;
                        left: -3px;
                    }
                }
            }
        }
    }
}
