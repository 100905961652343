.bl-email-code-confirmation {
  text-align: center;
  .text-helper.subtitle {
    font-weight: 500;
  }
  .field-code {
    margin-bottom: 8px;
  }
  .send-to-email-message {
    line-height: 19px;
    margin-bottom: 8px
  }
}

@media screen and (max-width: 768px) {
  .bl-code-confirmation {
    h2 {
      font-weight: 700;
    }
    .subtitle.text-helper {
      font-weight: 400;
    }
  }
}