.page-history {
    .bl-operations-history {
        .bl-accrual-history {
            .total-sum-payout {
                text-align: start;
                .sum-bold {
                    font-weight: 500;
                }
            }
        }
    }
}

@media screen and (min-width: 767px) {
    .page-history {
        .bl-operations-history {
            .colapse-payouts-group {
                .accruals-size-colapse-mobile {
                    display: none;
                }
                .sum-mobile-colapse-subtitle {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .page-history {
        .bl-operations-history {
            .bl-accrual-history {
                .colapse-payouts-group {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                    padding: 0 0px 24px 0;
                    margin-bottom: 20px;
                    .accruals-size-colapse-mobile {
                        font-size: 14px !important;
                        line-height: 100%;
                        margin-top: 4px;
                    }
                    .total-sum-payout {
                        text-align: start;
                        .sum-charges-colapse {
                            font-weight: 700;
                            font-size: 17px !important;
                            line-height: 100%;
                        }
                        .sum-mobile-payout-colapse {
                            font-size: 17px !important;
                            line-height: 100%;
                            .sum-bold {
                                font-size: 17px !important;
                                margin-top: 4px;
                            }
                        }
                        .ant-space-align-center {
                            align-items: normal;
                        }
                    }
                    .ant-collapse-arrow {
                        top: -61px;
                        font-size: 10px;
                    }
                    .sum-mobile-payout-colapse {
                        .sum-mobile-colapse-subtitle {
                            font-weight: 500;
                        }
                        .sum-bold {
                            text-align: start;
                        }
                    }

                    .accruals-date-colapse-mobile {
                        font-weight: 500;
                        .accruals-size-colapse-mobile {
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}
