.bl-numbers-filter-form {
  .field-search {
    &.ant-form-item-has-error{ 
      * {
        border: 0 !important;
      }
    }
    .ant-input-wrapper {
      border: 1px solid #E5E5E5 !important;
      border-radius: 8px;
      padding: 0 15px;
      .ant-input-affix-wrapper {
        border: 0 !important;
      }
    }
    .ant-input-search {
      * {
        color: #000!important;
      }
      >.ant-input-group {
        > .ant-input-group-addon {
          &:last-child {
            .ant-btn {
              .anticon-search {
                &:before {
                  background: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.7605 11.2633C11.6488 10.3768 12.1984 9.15131 12.1984 7.79746C12.1984 5.09261 10.0046 2.8999 7.29844 2.8999C4.59224 2.8999 2.39844 5.09261 2.39844 7.79746C2.39844 10.5023 4.59224 12.695 7.29844 12.695C8.65012 12.695 9.87396 12.148 10.7605 11.2633ZM10.7605 11.2633L13.9606 14.463' stroke='%23642878' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                  width: 16px;
                  height: 16px;
                  font-size: 16px;
                }
              }
            }
          }
        }
      } 
    }
    .ant-btn:not(.ant-btn-text) {
      min-width: auto;
    }
    .ant-input-suffix {
      .anticon-close-circle {
        &:before {
          background: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5312 5.02946C12.8236 4.73609 12.8228 4.26121 12.5295 3.9688C12.2361 3.67639 11.7612 3.67717 11.4688 3.97054L12.5312 5.02946ZM3.47054 11.9688C3.17717 12.2612 3.17639 12.7361 3.4688 13.0295C3.76122 13.3228 4.23609 13.3236 4.52946 13.0312L3.47054 11.9688ZM4.52946 3.9688C4.23609 3.67639 3.76122 3.67717 3.4688 3.97054C3.17639 4.26391 3.17717 4.73878 3.47054 5.0312L4.52946 3.9688ZM11.4688 13.0295C11.7612 13.3228 12.2361 13.3236 12.5295 13.0312C12.8228 12.7388 12.8236 12.2639 12.5312 11.9705L11.4688 13.0295ZM11.4688 3.97054L7.4819 7.97054L8.5443 9.02946L12.5312 5.02946L11.4688 3.97054ZM7.48363 7.9688L3.47054 11.9688L4.52946 13.0312L8.54256 9.0312L7.48363 7.9688ZM3.47054 5.0312L7.48363 9.0312L8.54256 7.9688L4.52946 3.9688L3.47054 5.0312ZM7.4819 9.02946L11.4688 13.0295L12.5312 11.9705L8.5443 7.97054L7.4819 9.02946Z' fill='%23642878'/%3E%3C/svg%3E%0A");
          width: 16px;
          height: 16px;
          font-size: 16px;
        }
      }
    }
  }
}