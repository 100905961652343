.bl-personal-manager {
    height: 100%;
    .bz-block {
        height: 100%;
        padding-top: 36px;
        .bz-block-content {
            height: 100%;
            > .ant-space {
                > .ant-space-item:last-child {
                    & {
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }
                }
            }
        }
        .ant-space {
            width: 100%;
            height: 100%;
            justify-content: flex-start;
        }
    }
}

@media screen and (max-width: 991px) {
    .bl-personal-manager {
        .bz-block {
            padding-top: 41px;
            .bz-block-content {
                > .ant-space {
                    > .ant-space-item:last-child {
                        & {
                            position: static;
                            margin: inherit;
                        }
                    }
                }
            }
        }
    }
}
