.bl-activation-list-items-item {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    padding: 16px;
    &.bl-super_link {
        border: 1px solid #ffce73;
        background: rgb(57 7 50 / 50%);
    }
    .left {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: flex-start;
        @media screen and (max-width: 768px) {
            align-items: flex-start;
            .phone {
                margin-top: 4px;
            }
        }
        @media screen and (max-width: 320px) {
            .phone {
                margin-top: 0px;
            }
        }
    }
    .ant-col-xs-12 {
        @media screen and (max-width: 320px) {
            flex: 0 0 100%;
            max-width: 100%;
            .bl-activation-list-items-item-status .ant-space {
                align-items: flex-start;
                .ant-space {
                    align-items: center;
                }
            }
        }
    }
    .bl-numbers-list-info-phone {
        .ant-space {
            .ant-space-item {
                &:first-child {
                    display: none;
                }
                .phone {
                    font-weight: 400;
                    font-size: 32px;
                    line-height: 100%;
                    background: linear-gradient(
                        141.08deg,
                        #efce73 25.79%,
                        #fef0b7 63.66%,
                        #cea86f 97.07%
                    );
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-repeat: no-repeat;
                    background-origin: content-box;
                    padding: 1px;
                }
            }
        }
    }
    .desc {
        .ant-space,
        ol {
            display: flex;
            align-items: center;
        }
        .bl-reservation-list-info-description {
            display: flex;
            align-items: center;
            > .ant-breadcrumb {
                > ol {
                    > li {
                        margin-right: 16px;
                        .tariff-name {
                            margin-left: 0px;
                        }
                        .bz-typography {
                            color: #fff !important;
                            font-weight: 500 !important;
                            font-size: 16px !important;
                            line-height: 120% !important;
                        }
                        > span {
                            &:last-child {
                                display: none;
                            }
                        }
                    }
                }
                .ant-breadcrumb-separator {
                    margin: 0 8px;
                }
            }
        }
    }
    .nps-number,
    .nps-number-true {
        background: var(
            --Linear,
            linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: "ALS Hauss";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        @media screen and (max-width: 430px) {
            margin-left: 0;
        }
    }
    .nps-number-true {
        margin-left: 14px;
    }
    .btn-remove-sim,
    .btn-nps-notif {
        width: 100%;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        padding: 4px 10px;
        @media screen and (max-width: 768px) {
            max-width: 100%;
        }
    }
}

@media screen and (max-width: 992px) {
    .bl-activation-list-items-item {
        .desc {
            .bl-reservation-list-info-description {
                > .ant-breadcrumb {
                    > ol {
                        > li {
                            display: block;
                            margin-right: 0;
                            .bz-typography {
                                font-size: 14px !important;
                                line-height: 19px !important;
                                color: rgba(255, 255, 255, 0.5) !important;
                            }
                        }
                    }
                    li {
                        display: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .bl-activation-list-items-item {
        padding: 12px;
        .bl-numbers-list-info-phone {
            .ant-space {
                .ant-space-item {
                    .phone {
                        font-size: 24px;
                    }
                }
            }
        }
    }
}
