.bl-code-confirmation-formPhone {
    .bl-duration {
        margin-top: 20px;
        margin-bottom: 35px;
        .text-helper {
            color: white !important;
        }
    }
    button {
        width: 259px;
    }
    .ant-btn-text {
        height: 48px !important;
    }
    .bl-duration {
        margin: 30px 0px 15px !important;
    }
}
.bl-code-confirmation-button-submit {
    margin-top: 10px;
}
.field-email-errorTypography {
    color: #ed0101 !important;
    font-size: 12px !important;
}
@media screen and (max-width: 768px) {
    .bl-new-password .bl-title {
        margin-top: 30px !important;
    }
    .bl-reset-password .bl-title {
        margin-top: 30px !important;
    }
    .bl-code-confirmation-formPhone {
        .bz-input-group-numbers-item > .error-text {
            margin: 0px 0 0px !important;
        }
    }
}
