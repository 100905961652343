.bl-success-output {
    span {
        text-align: center !important;
        vertical-align: bottom !important;
        color: #642878;
    }
}
.bl-replenishment-balanse-bonuses-output {
    .field-phone {
        width: 430px;
        height: 242px;
        background: url("../../../images/background-for-bonuses.jpg") no-repeat center center;
        background-size: cover;
        .ant-form-item-row {
            height: 100%;
        }
        .ant-form-item-control-input {
            position: absolute;
            left: 2.79%;
            right: 2.79%;
            top: 26.03%;
            bottom: 54.13%;
            margin-right: 4px;
        }
        .ant-input-affix-wrapper {
            background: rgba(255, 255, 255, 0.2) !important;
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: 8px;
        }
        label,
        input {
            color: #ffffff;
        }
        .ant-form-item-explain {
            position: absolute;
            top: 115px;
            left: 21px;
        }
    }
    .input-summ-bonuses {
        .ant-form-item-explain {
            text-align: left;
        }
    }
}
@media screen and (max-width: 768px) {
    .bl-replenishment-balanse-bonuses-output {
        .field-phone {
            .ant-form-item-control-input {
                margin-right: 0;
            }
        }
    }
}
@media screen and (max-width: 568px) {
    .bl-replenishment-balanse-bonuses-output {
        .field-phone {
            width: auto;
            height: 242px;
            background: #642878;
            border-radius: 20px;
        }
    }
}
