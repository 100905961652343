.bl-delivery-form {
    margin-top: 32px;
    .field-address-container {
        position: relative;
        .icon {
            width: 16px;
            position: absolute;
            right: 25px;
            top: 16px;
            margin: auto;
            cursor: pointer;
            @media screen and (max-width: 768px) {
                right: 25px;
            }
        }
    }
    &.isMobile {
        .field-addresses-parent-container {
            .field-addresses-container {
                .ant-dropdown {
                    width: 100%;
                    min-width: 100% !important;

                    .ant-dropdown-menu {
                        padding: 23px 8px 23px 10px;
                    }
                }
            }
        }
    }
    .field-addresses-parent-container {
        position: relative;
        .field-addresses {
            background: rgba(255, 255, 255, 0.2);
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: 8px;
            display: flex;
            height: 48px;
            flex-direction: row;
            align-content: center;
            justify-content: space-between;
            align-items: center;
            padding: 0 16px;
            color: rgba(255, 255, 255, 0.5);
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 16px;
            position: relative;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            &::after {
                content: "";
                position: absolute;
                right: 16px;
                top: 0;
                bottom: 0;
                margin: auto;
                background: url("../../../images/aarrow.png") no-repeat 0 0;
                background-size: 16px 16px;
                width: 16px;
                height: 16px;
            }
            &.ant-dropdown-open {
                &::after {
                    transform: rotate(90deg);
                }
            }
        }
        .field-addresses-container {
            display: none;
            position: absolute;
            left: 8px;
            right: 8px;
            margin: auto;
            top: 50px;
            height: 198px;
            overflow: hidden;
            background-color: #fff;
            border-radius: 8px;
            border: 1px solid #e5e5e5;
            z-index: 1000;
            ul {
                height: 100%;
            }
            .trash {
                width: 20px;
                cursor: pointer;
            }
            .item {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: space-between;
                align-items: center;
                .item-text {
                    cursor: pointer;
                }
            }
            > div {
                position: static !important;
                * {
                    transition: none !important;
                    transform: none !important;
                    opacity: 1 !important;
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    cursor: default;
                }
            }
            &.opened {
                display: block;
            }
            .ant-dropdown {
                overflow-y: scroll;
                height: 100%;
                width: calc(100% + 20px);
                height: 200px !important;
                top: 0 !important;
                left: 0 !important;

                @media screen and (max-width: 992px) {
                    width: 100%;
                    min-width: 100% !important;
                }

                .ant-dropdown-menu {
                    background-color: #fff;
                    border-radius: 0;
                    text-align: left;
                    padding: 23px 12px;
                    border: 0;
                    transition: none !important;
                    .ant-dropdown-menu-item {
                        text-align: left;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 19px;
                        color: #1f1f1f;
                        padding: 0 6px;
                        @media screen and (max-width: 1024px) {
                            padding: 0 2px;
                        }
                        height: auto;
                        margin-bottom: 17px;
                        transition: none !important;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .ant-input-affix-wrapper {
        background: transparent;
    }
    .ant-space {
        width: 100%;
    }
    .ant-picker {
        width: 100%;
    }
    input {
        color: #fff;
    }
    .ant-input-number {
        width: 100%;
        background: transparent;
        color: #fff;
        border-radius: 8px;
        border-color: rgba(255, 255, 255, 0.5);
        height: 48px;
        display: flex;
        align-items: center;
        .ant-input-number-handler-wrap {
            display: none;
        }
    }
    .btn-delivery-form {
        text-align: center;
        .ant-btn {
            max-width: 340px;
            width: 100%;
        }
    }
    .text-area-delivery-form {
        .ant-input {
            min-height: 120px;
            color: #fff;
            padding-top: 25px;
        }
        .ant-form-item-label {
            padding-top: 0;
        }
        .focused {
            .ant-form-item-label {
                margin-top: 4px;
                padding: 0;
            }
        }
        .ant-form-item {
            .ant-form-item-row {
                .ant-form-item-label {
                    transition: all 0.2s linear;
                }
            }
        }
    }
    .bz-input-block,
    .field-address-wrapper {
        .title {
            transition: all 0.2s linear;
        }
    }
    .ant-picker {
        input {
            margin-top: 10px;
        }
    }
    .date-picker-delivery {
        .ant-picker-focused {
            background-color: initial;
            border-color: #cfc8db;
        }
        .ant-picker-input {
            &::before {
                content: "Дата отправки";
                position: absolute;
                top: -3px;
                left: 0px;
                font-family: "ALS Hauss", sans-serif;
                font-size: 11px;
                color: #828282;
            }
        }
    }
    .focused {
        .date-picker-delivery {
            .ant-picker-input {
                input {
                    margin-top: 10px;
                }

                &::before {
                    top: -3px;
                    font-size: 11px;
                }
            }
            .ant-form-item-label {
                padding-top: 0;
                margin-top: -5px;
                transition: 0.2s;
                label {
                    font-size: 12px;
                    transition: 0.2s;
                }
            }
        }
    }

    .ant-form-item-label {
        padding-top: 7px;
        position: absolute;
        left: 16px;
        label {
            font-size: 14px;
            color: #828282;
        }
    }
    .ant-input-number-focused {
        box-shadow: none;
    }
    .sub-text {
        margin-top: -8px;
        margin-bottom: 16px;
    }
    .ant-input:focus {
        background-color: transparent;
    }
    .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
        color: #fff;
    }
    .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
        background-color: transparent;
    }
    .ant-form-item-has-success {
        .ant-form-item-row {
            .ant-form-item-label {
                top: -4px !important;
                margin-top: 5px;
                label {
                    font-size: 12px !important;
                    height: 12px;
                    margin-top: 6px;
                }
            }
        }
    }
    .ant-form-item-has-success {
        .ant-input {
            padding-bottom: 11px;
            padding-top: 25px;
        }
        .title {
            font-size: 12px;
            height: 12px;
            margin-top: 6px;
        }
    }
    .ant-form-item-has-error
        :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
        background: transparent;
    }
    .ant-picker-clear {
        right: 20px;
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .bl-delivery-sim {
        .bl-title {
            margin-bottom: 38px;
        }
        .bz-tabs {
            .ant-tabs-tab {
                margin: 0;
                margin-left: 0 !important;
                .ant-tabs-tab-btn {
                    font-size: 17px;
                }
            }
        }
        .bl-new-delivery {
            padding: 0;
            margin-top: 40px;
        }
        .bl-delivery-form {
            .ant-row {
                row-gap: 0px !important;
            }
            .sub-text {
                margin-top: -8px;
                margin-bottom: 8px;
            }
        }
    }
}

.address-action {
    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 120%;
        text-align: center;
        color: #1f1f1f;
        margin-bottom: 16px;
        @media screen and (max-width: 768px) {
            font-weight: 700;
            font-size: 17px;
            text-align: left;
        }
    }
    .sub-title {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 22px;
        text-align: center;
        color: #1f1f1f;
        margin-bottom: 40px;
        @media screen and (max-width: 768px) {
            font-size: 14px;
            line-height: 140%;
            text-align: left;
            margin-bottom: 16px;
            br {
                display: inline;
            }
        }
    }
    .actions {
        .ant-btn {
            display: block;
            width: 100%;
            font-weight: 700;
            font-size: 17px;
            line-height: 120%;
            text-align: center;
            font-style: normal;
            @media screen and (max-width: 768px) {
                font-size: 14px;
                line-height: 140%;
                color: #1f1f1f;
            }
            &.ant-btn-default {
                font-weight: 500;
                color: #1f1f1f;
                @media screen and (max-width: 768px) {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 18px;
                    color: #1f1f1f;
                }
            }
            &:last-child {
                margin-top: 5px;
                @media screen and (max-width: 768px) {
                    margin: 0;
                }
            }
        }
    }
}
