@import "../../../../../../styles/variables";

.bl-numbers-list-actions {
    .icon-wrapper {
        width: 32px;
        height: 32px;
        border-radius: 8px;
    }
    .lightning {
        cursor: pointer;
        position: relative;
        margin-top: 0;
        top: 0 !important;
    }
    .bz-typography-disable {
        .lightning {
            display: none !important;
        }
    }
    .wantBuy {
        width: 115px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #e0ca88;
        border-radius: 8px;
        background-color: #412145;

        font-size: 12px;
        line-height: 100%;
        font-weight: 700;
        white-space: nowrap;
        cursor: pointer;
        &:hover {
            opacity: 0.85;
        }
    }
    .btn-turbo {
        background-image: url("../../../../../../images/bg-btn-turbo.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 8px;
    }
}

.kanban {
    .bl-numbers-list-actions {
        &.is-paid {
            .ant-space {
                justify-content: space-between;
                width: 100%;
                min-height: 32px;
            }
            .wantBuy {
                width: 102px;
                height: 32px;
                right: 0;
                bottom: 0;
            }
        }
    }
}

@media screen and (max-width: 1060px) {
    .kanban {
        .bl-numbers-list-actions {
            &.is-paid {
                .wantBuy {
                    width: 90px;
                }
            }
        }
    }
}

@media screen and (max-width: 990px) {
    .kanban {
        .bl-numbers-list-actions {
            &.is-paid {
                .wantBuy {
                    width: 102px;
                }
            }
        }
    }
}

@media screen and (max-width: 820px) {
    .kanban {
        .bl-numbers-list-actions {
            &.is-paid {
                .wantBuy {
                    width: 90px;
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .bl-numbers-list-actions {
        .wantBuy {
            cursor: pointer;
            &:hover {
                opacity: 0.85;
            }
        }
    }
    .kanban {
        .bl-numbers-list-actions {
            &.is-paid {
                .ant-space {
                    width: auto;
                    min-height: auto;
                }
                .wantBuy {
                    width: 102px;
                    position: static;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .bl-numbers-list-actions {
        .wantBuy {
            width: 102px;
            height: 32px;
        }
    }
}
