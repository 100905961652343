.field-search {
  &.empty {
    display: none;
  }
  .ant-input {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }

  input::-webkit-input-placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
  input:-moz-placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
  input::-moz-placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
  input:-ms-input-placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
  input::-ms-input-placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }

  .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child {
    .ant-btn {
      height: auto;
      width: auto;
      .anticon-search {
        position: relative;
        &::before {
          content: '';
          background: url("../../../images/search.png") no-repeat;
          display: block;
          width: 17px;
          height: 17px;
        }
        svg {
          display: none;
        }
      }
    }
  }
  .ant-input-suffix {
    margin: 0 22px 0 0;
    .anticon-close-circle {
      &::before {
        content: '';
        background: url("../../../images/close.png") no-repeat;
        display: block;
        width: 17px;
        height: 17px;
      }
      svg {
        display: none;
      }
    }
  }
  .ant-form-item-explain {
    display: none;
  }
  .ant-input-search {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    gap: 240px;
    width: 607px;
    height: 56px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    *{
      background: transparent !important;
      border: 0!important;
      color: #fff!important;
    }
  }
  .ant-input-affix-wrapper {
    padding: 4px 0!important;
  }
}

@media screen and (max-width: 768px) {
  .field-search {
    .ant-input-search {
      width: 100%;
    }
  }
}