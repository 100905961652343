.bl-numbers-filters-selected {
    flex-wrap: wrap;
    display: flex;
    grid-gap: 8px;
    flex-direction: column;
    .items {
        flex-wrap: wrap;
        display: flex;
        grid-gap: 8px;
    }
    .ant-btn {
        height: 28px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 16px;
        padding: 6px 12px;
        color: #ffffff;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 8px;
        &.not-click-able {
            cursor: auto;
        }
        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
}
