.page-history {
    .bl-operations-history {
        .bl-back {
            margin-top: 0px;
        }
        .btn-show {
            text-align: center;
            margin-top: 35px;
            .btn-show-more {
                background: rgba(255, 255, 255, 0.1);
                border-radius: 8px;
                width: 252px;
                &.hidden {
                    display: none;
                }
            }
            .btn-show-list{
                width: 252px;
            }
            
        }
        .bl-title {
            text-align: center;
            margin-bottom: 54px;
            padding: 0px 50px;
        }
        .tabs-container {
            .ant-tabs-tab {
                &:hover {
                    .ant-tabs-tab-btn {
                        color: #fef0b7;
                    }
                }
            }
        }
        .ant-tabs-content-holder {
            .bz-block {
                .ant-divider {
                    background: rgba(255, 255, 255, 0.5);
                    margin: 10px 0 24px 0;
                }
                .total-sum-amount {
                    text-align: end;
                }
            }
        }
        .bl-accrual-history {
            .total-sum-amount {
                .total-sum {
                    background: linear-gradient(
                        141.08deg,
                        #efce73 25.79%,
                        #fef0b7 63.66%,
                        #cea86f 97.07%
                    );
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-repeat: no-repeat;
                    background-origin: content-box;
                    padding: 1px;
                    white-space: nowrap;
                }
            }
        }
        .text-content-null {
            font-family: "ALS Alumna";
            font-size: 32px;
            text-align: center;
            margin: 109px 0 800px 0;
        }
    }
}
.dropdown-operations-history {
    .ant-picker-panels {
        position: relative;
        > .ant-picker-panel {
            &:first-child {
                .ant-picker-header-super-prev-btn,
                .ant-picker-header-prev-btn {
                    position: relative;
                    z-index: 1;
                }
            }
            &:last-child {
                position: absolute;
                .ant-picker-body {
                    display: none;
                }
                .ant-picker-header-view {
                    opacity: 0;
                }
            }
        }
    }
}


@media screen and (max-width: 576px) {
    .page-history {
        .bl-operations-history {
            .bl-title {
                margin-bottom: 32px;
            }
            .ant-tabs-nav {
                margin-bottom: 40px;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .page-history {
        .bl-operations-history {
            .ui-back {
                .ant-btn{
                    svg{
                        top: -9px;
                    }
                }
            }
            .text-content-null {
                margin: 40px;
            }
            .ant-collapse {
                .ant-collapse-item{
                    position: relative;
                    .ant-collapse-header {
                        position: static !important;
                        .ant-collapse-arrow {
                            right: 0px;
                        }
                    }
                }
                
            }
        }
    }
}
