.bl-numbers-list-info-phone {
    a,
    * {
        color: #fff !important;
    }
    .phone {
        font-weight: 400;
        line-height: 100%;
        font-family: "ALS Sansita", serif;
    }
    svg {
        font-size: 16px;
    }
}
