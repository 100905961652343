.ant-tabs-nav-operations {
    display: none;
}
.bl-plans-desktop {
    .left-right-button {
        display: flex;
        width: 104px;
        margin: 0 auto;
    }
    #left-button,
    #right-button {
        cursor: pointer;
        border-radius: 50%;
        margin: 0px auto;
        svg {
            width: 17px;
            height: 17px;
        }
        button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 42px;
            height: 42px;
            background: #ffffff00;
            border-radius: 50%;
            top: -1px;
            left: -1px;
            border: 1px solid rgba(254, 240, 183, 0.5) !important;
            &:hover {
                background: url(~@bezlimit/bezlimit-ui/dist/images/gold-opacity.png) center center
                        no-repeat,
                    url(~@bezlimit/bezlimit-ui/dist/images/gold-texture.png) center center no-repeat;
                background-size: auto 100%;
                background-blend-mode: color, normal;
                svg {
                    color: #50238f;
                }
            }
            &:disabled {
                background: #767676;
            }
        }
    }
    #left-button {
        button {
            left: -2px;
        }
    }
    .modal-info-tariff-wrapper-description-desktop {
        transition: left 1s ease-in-out;
    }
    .ant-tabs-content-holder {
        max-width: 100%;
        margin: auto;
        .modal-info-tariff-wrapper-description-desktop {
            position: relative;
            width: max-content;
            margin-top: 20px;
            padding-left: 178px;
            .modal-info-tariff-wrapper-description-item {
                &:last-child .card-desktop {
                    margin-right: 0px;
                }
            }
        }
    }
}
.bl-plans-mobile {
    .tariffs-cards {
        .slick-active {
            .title-value {
                path {
                    fill: #1f1f1f;
                }
            }
        }
    }
}
.bl-plans {
    .bl-title {
        margin-bottom: 30px;
    }
    .ant-tabs-ink-bar {
        width: 133px;
    }
    .ant-carousel {
        width: 100%;
        max-width: 576px;
        .slick-list {
            .slick-slide {
                > div {
                    > div {
                        padding: 0 8px;
                    }
                }
                &:not(.slick-active) {
                    .card {
                        background: transparent;
                        border: 1px solid rgba(255, 255, 255, 0.5);

                        .subscription_fee {
                            .title,
                            .suffix {
                                color: #fff;
                            }
                        }

                        .packets-tariff {
                            .title,
                            .suffix {
                                color: #fff;
                            }
                        }

                        .suffix {
                            opacity: 0.5;
                        }
                    }
                }
                .slick-active {
                    border: 1px solid #fef0b7;
                }
            }
        }
    }
}

@media screen and (min-width: 576px) {
    .bl-plans {
        .ant-tabs-ink-bar {
            width: 89px;
        }
        .ant-tabs-nav-list {
            .ant-tabs-tab {
                margin: 0px 32px 0 0;
                &:first-child {
                    margin: 0px 0px 0 0;
                }
            }
        }

        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
        .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
            white-space: normal;

            .ant-tabs-tab {
                display: inline-block;
                white-space: nowrap;
            }
        }
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
        .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
            display: block;
        }
        .ant-tabs-nav-list {
            text-align: center;
        }
    }
}
@media screen and (max-width: 576px) {
    .bl-plans {
        .left-right-button {
            display: none !important;
        }
    }
}
