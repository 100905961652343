.bl-numbers-filtre-modal {
  text-align: center;
  .ant-space, .ant-checkbox-group {
    width: 100%;
  }
  .ant-checkbox-group {
    text-align: left;
  }
  h2 {
    font-weight: 400;
    line-height: 120%;;
  }
  .ant-btn {
    &[type="submit"] {
      height: 67px;
      border-radius: 0 0 8px 8px;
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
      position: absolute;
      bottom: -67px;
      left: 0;
      right: 0;
      margin: auto;
      max-width: 252px;
    }
    &.ant-btn-text {
      display: flex;
      margin: auto;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;
      align-content: space-between;
      font-weight: 400;
      font-size: 17px;
      line-height: 22px;
      color: #642878;
      span {
        margin-right: 19px;
      }
    }
  }
}

.bl-filter-modal {
  min-width: 1142px;
  padding: 40px 0 112px !important;
  .ant-modal-close {
    position: fixed;
    top: 40px;
    right: 48px;
    svg {
      color: #fff;
      font-size: 28px;
    }
  }
  .ant-modal-content .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    border: 0 !important;
  }
  .ant-modal-content {
    padding: 52px 60px 49px;
  }
}


@media screen and (max-width: 1440px) {
  .bl-filter-modal {
    .ant-modal-close {
      right: 16px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .bl-filter-modal {
    .ant-modal-close {
      right: 0;
    }
  }
}

@media screen and (max-width: 1240px) {
  .bl-filter-modal {
    .ant-modal-close {
      right: 0;
      top: 0;
    }
  }
}

@media screen and (max-width: 1200px) {
  .bl-filter-modal {
    min-width: 768px;
    .ant-modal-close {
      right: 48px;
      top: 40px;
    }
  }
}

@media screen and (max-width: 992px) {
  .bl-filter-modal {
    .ant-modal-close {
      right: 16px;
    }
  }
}

@media screen and (max-width: 920px) {
  .bl-filter-modal {
    .ant-modal-close {
      right: 0;
      top: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .bl-filter-modal {
    min-width: auto;
    padding: 72px 0 !important;
    .ant-modal-close {
      display: block;
      position: absolute;
      top: -64px;
      right: -14px;
      svg {
        color: #000;
        font-size: 14px;
      }
    }
    .ant-modal-content {
      padding: 40px 16px;
    }
  }
  .bl-numbers-filtre-modal {
    h2 {
      line-height: 100%;
    }
    .ant-btn {
      &[type="submit"] {
        height: 48px;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        border-radius: 8px;
        position: fixed;
        bottom: 16px;
        min-width: calc(100% - 32px);
        transition: none;
      }
    }
  }
  
}