.bl-filter-sets {
    display: flex;
    flex-direction: column;
    .ant-divider {
        background: #fff;
        margin: 12px 0;
    }
    .bl-filter-tooltip {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .bl-dropdown-filter {
            text-align: end;
            cursor: pointer;
        }
    }
    .field-radio {
        margin-bottom: 0;
        .ant-radio-group {
          width: 100%;
          min-width: 305px;
        }
        .ant-radio-wrapper {
            width: 100%;
            .ant-radio {
                width: 20px;
            }
            span {
                width: 100%;
            }
            .dropdown-filter {
                text-align: end;
            }
        }
    }
    .titlt-filter-sets-mobile {
        display: flex;
        font-weight: 500;
        align-items: center;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 768px) {
    .bl-numbers-filter-mobile-form {
        .bl-filter-sets {
            .ant-radio-wrapper {
                .bz-typography {
                    color: #1f1f1f;
                    font-weight: 400;
                }
            }
            .field-radio {
                .ant-form-item-control-input-content {
                    height: 100%;
                    .ant-radio-group {
                        display: block;
                        .ant-radio {
                            display: block;
                            margin-right: 25px;
                            top: 0px;
                            .ant-radio-inner {
                                width: 36px;
                                border-radius: 10px;
                                height: 20px;
                                background: #e5e5e5;
                                &::after {
                                  transform: scale(1);
                                  left: -17px;
                                  box-shadow: 0px 2px 4px rgb(0 0 0 / 16%);
                                  width: 16px;
                                  height: 16px;
                                  background: #ffffff;
                                  opacity: 1;
                                }
                            }
                        }
                        .ant-radio-wrapper {
                            border: none;
                            .ant-row {
                                .ant-col-23 {
                                    .bz-typography {
                                        text-align: start;
                                        color: #1f1f1f;
                                        font-weight: 400;
                                    }
                                }
                            }
                        }
                        .ant-radio-wrapper-checked {
                            background: #fff;
                            .bz-typography {
                                color: #1f1f1f;
                                font-weight: 400;
                            }
                            .ant-radio-inner {
                                background: url(~@bezlimit/bezlimit-ui/dist/images/gold-opacity.png)
                                        center center no-repeat,
                                    url(~@bezlimit/bezlimit-ui/dist/images/gold-texture.png) 100%
                                        center no-repeat;
                                background-size: 100%;
                              background-blend-mode: color, normal;

                              &::after {
                                right: 1px;
                                left: initial;
                              }
                            }
                        }
                    }
                }
            }

          .ant-divider {
            background: rgba(207, 200, 219, 0.5);
          }
        }
    }
}
