@import "../../../../styles/variables";

.bl-profile-info {
    margin-top: 56px;
    @include standardFont-medium;

    .profile-avatar-wrapper {
        text-align: center;
        margin-top: 24px;
        margin-bottom: 24px;

        .ant-image-mask-info {
            color: rgba(240, 248, 255, 0);
            position: relative;
            width: 120px;
            span {
                top: -1px;
                left: -10px;
                position: relative;
                color: $icon-hover-color;
                &:after {
                    color: $icon-hover-color;
                    position: absolute;
                    top: 9px;
                    left: 21px;
                    content: "Просмотр";
                    font-size: 18px;
                }
            }
        }

        .ant-image {
            height: 100%;
            width: 100%;
            .ant-image-img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }
    }

    .avatar-upload-wrapper {
        position: absolute;
        top: 155px;
        left: Calc(50% + 40px);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
    }

    .avatar-upload-input {
        position: absolute;
        top: 0;
        visibility: hidden;
        width: 0;
        height: 0;
    }

    .avatar-upload-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background: $bg-violet;
        border-radius: 50%;

        svg {
            path {
                fill: $text-primary-color;
            }
        }

        &:hover {
            cursor: pointer;
            background: rgb(97, 79, 104);
            svg {
                path {
                    fill: #b5a9b9;
                }
            }
        }
    }

    .profile-fio {
        @include title-tinyFont-normal;
    }

    .profile-label {
        color: $text-pale-color;
        &.personal {
            color: #fff;
        }
    }

    .profile-edit-wrapper {
        display: flex;
        align-items: center;
    }

    .profile-edit {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        background: $bg-violet-opacity-color;
        border-radius: 50%;
        margin-left: 8px;

        &:hover {
            cursor: pointer;
            background: $bg-opacity-color2;
            svg {
                path {
                    fill: $text-hover-color;
                }
            }
        }
    }
    .profile-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 28px;
        margin-right: 8px;

        svg {
            fill: none;
        }
    }
    .btn-notif {
        height: 21px;
        font-family: "ALS Hauss";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        margin-top: 8px;
        border-radius: 6px;
        display: flex;
        align-items: center;
    }

    @media screen and (max-width: $max-tablet-width) {
        .avatar-upload-wrapper {
            top: 110px;
        }
    }
}
