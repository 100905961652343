.page-history {
    .bl-operations-history {
        .bl-accrual-history {
            .bl-title-payouts {
                .sub-title-accrued {
                    text-align: start;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .page-history {
        .bl-operations-history {
            .bl-title-payouts,
            .subtitle-payments {
                display: none;
            }
            .ant-divider-horizontal {
                display: none;
            }
        }
    }
}
