.bl-hint-mobile {
    margin-top: -70px;
    .title-loyalty {
        color: var(--White, #fff);
        text-align: center;
        font-family: "ALS Hauss";
        font-size: 24px;
        font-style: normal;
        font-weight: 550;
        line-height: 28px;
        margin: 20px 0;
    }
    .btn-close-back {
        background: transparent;
        svg {
            width: 32px;
        }
    }
    .ant-tabs {
        .ant-tabs-nav {
            .ant-tabs-nav-operations {
                display: none;
            }
            .ant-tabs-nav-wrap {
                align-items: center;
                justify-content: center;
                .ant-tabs-nav-list {
                    .bronze-loyalty,
                    .silver-loyalty,
                    .gold-loyalty,
                    .top-loyalty {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                    }
                    .tabs-icons {
                        .tabs-name-active {
                            display: none;
                        }
                        .tabs-name-silver {
                            background: var(
                                --Linear,
                                linear-gradient(
                                    115deg,
                                    #e9e9e9 -3.01%,
                                    #919191 51.72%,
                                    #cfcfcf 106.45%
                                )
                            );
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            text-align: center;
                            font-family: "ALS Hauss";
                            font-size: 17px;
                            font-style: normal;
                            font-weight: 550;
                            line-height: 28px;
                        }
                        .tabs-name-bronze {
                            background: var(
                                --Linear,
                                linear-gradient(134deg, #e2b379 18.77%, #b17c5c 79.63%)
                            );
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            text-align: center;
                            font-family: "ALS Hauss";
                            font-size: 17px;
                            font-style: normal;
                            font-weight: 550;
                            line-height: 28px;
                        }
                        .tabs-name-gold {
                            background: var(
                                --Linear,
                                linear-gradient(134deg, #e2b379 18.77%, #b17c5c 79.63%)
                            );
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            text-align: center;
                            font-family: "ALS Hauss";
                            font-size: 17px;
                            font-style: normal;
                            font-weight: 550;
                            line-height: 28px;
                        }
                        .tabs-name-top {
                            background: var(
                                --Linear,
                                linear-gradient(
                                    296deg,
                                    #ceecf2 10.86%,
                                    #5c658c 39.52%,
                                    #dcfcfe 79.94%
                                )
                            );
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            text-align: center;
                            font-family: "ALS Hauss";
                            font-size: 17px;
                            font-style: normal;
                            font-weight: 550;
                            line-height: 28px;
                        }
                    }
                    .ant-tabs-tab-active {
                        .bronze-loyalty {
                            border-radius: 32px;
                            border: 0.7px solid var(--Linear, #efce73);
                            background: var(
                                --linear-2-paints,
                                linear-gradient(
                                    109deg,
                                    #b18537 9.53%,
                                    #ccaa69 61.34%,
                                    #977141 97.7%
                                ),
                                linear-gradient(90deg, #b88b3c 0%, #9d7646 101.92%)
                            );
                            width: 140px;
                            height: 146px;
                            img {
                                zoom: 1.4;
                            }

                            .tabs-name-bronze {
                                display: none;
                            }
                            .tabs-name-active {
                                display: block;
                            }
                        }
                        .silver-loyalty {
                            border-radius: 32px;
                            border: 1px solid #fff;
                            background: var(
                                --linear-2-paints,
                                linear-gradient(
                                    109deg,
                                    #949494 29.6%,
                                    #acabab 58.57%,
                                    #828282 92.77%
                                )
                            );
                            width: 140px;
                            height: 146px;
                            img {
                                zoom: 1.4;
                            }
                            .tabs-name-silver {
                                display: none;
                            }
                            .tabs-name-active {
                                display: block;
                            }
                        }
                        .gold-loyalty {
                            border-radius: 32px;
                            border: 1px solid var(--Linear, #efce73);

                            background: var(
                                --Linear,
                                linear-gradient(
                                    109deg,
                                    #d3b06c 9.59%,
                                    #decc8c 61.18%,
                                    #c8a258 84.63%
                                )
                            );
                            width: 140px;
                            height: 146px;
                            img {
                                zoom: 1.4;
                            }
                            .tabs-name-gold {
                                display: none;
                            }
                            .tabs-name-active {
                                display: block;
                            }
                        }
                        .top-loyalty {
                            border-radius: 32px;
                            background: var(
                                --Linear,
                                linear-gradient(
                                    109deg,
                                    #2f354b 19.13%,
                                    #50596f 61.18%,
                                    #2c3044 84.63%
                                )
                            );
                            width: 140px;
                            height: 146px;
                            border: 1px solid var(--linear-2-paints, #949494);
                            img {
                                zoom: 1.4;
                            }
                            .tabs-name-top {
                                display: none;
                            }
                            .tabs-name-active {
                                display: block;
                            }
                        }
                    }
                }
                @media screen and (max-width: 431px) {
                    overflow: inherit;
                }
            }
            &::before {
                border-bottom: none;
            }
            .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left:before {
                opacity: 0;
            }
        }
        .ant-tabs-content-holder {
            .ant-tabs-content {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                .ant-tabs-tabpane {
                    .conteiner-descrription {
                        width: 440px;
                        .loyalty-description {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-wrap: nowrap;
                            flex-direction: column;
                            p {
                                margin-left: 0 !important;
                                text-align: center;
                                color: var(--White, #fff);
                                font-family: "ALS Hauss";
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 28px;
                                margin-bottom: 12px;
                                @media screen and (max-width: 431px) {
                                    font-size: 16px;
                                }
                            }
                            .personally,
                            .dealer,
                            .personally_nps,
                            .dealer_nps,
                            .bonus_1,
                            .price {
                                border-radius: 12px;
                                border: 1px solid #949494;
                                background: var(
                                    --Linear,
                                    linear-gradient(
                                        139deg,
                                        rgba(255, 255, 255, 0.05) 14.69%,
                                        rgba(102, 102, 102, 0.05) 94.61%
                                    )
                                );
                                padding: 24px 24px 24px 42px;
                                max-width: 340px;
                                width: 340px;
                                margin-bottom: 12px;
                                h3 {
                                    margin-bottom: 0 !important;
                                }
                                h2 {
                                    color: var(--White, #fff);
                                    font-family: "ALS Hauss";
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 550;
                                    line-height: 26px;
                                    &::after {
                                        content: "";
                                        position: absolute;
                                        width: 6px;
                                        height: 6px;
                                        left: 70px;
                                        background: #fff;
                                        border-radius: 50%;
                                        margin-top: 9px;
                                        @media screen and (max-width: 431px) {
                                            left: 55px;
                                        }
                                        @media screen and (max-width: 420px) {
                                            left: 45px;
                                        }
                                        @media screen and (max-width: 400px) {
                                            left: 35px;
                                        }
                                        @media screen and (max-width: 375px) {
                                            left: 28px;
                                        }
                                    }
                                }
                                p {
                                    color: var(--White, #fff);
                                    font-family: "ALS Hauss";
                                    font-size: 15px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 26px;
                                    text-align: start;
                                    margin-bottom: 0;
                                }
                                @media screen and (max-width: 370px) {
                                    max-width: 320px;
                                }
                            }
                            .bonus_1 {
                                padding: 12px;
                            }
                            .price {
                                padding: 24px;
                                p {
                                    img {
                                        margin-right: 8px;
                                    }
                                    span {
                                        strong {
                                            margin-right: 24px;
                                        }
                                    }
                                }
                                @media screen and (max-width: 370px) {
                                    padding: 24px 16px;
                                }
                            }
                        }
                        .ScrollbarsCustom-Track {
                            right: 24px !important;
                            top: 40px !important;
                            width: 6px !important;
                            height: calc(100% - 240px) !important;
                            background: none !important;
                            .ScrollbarsCustom-Thumb {
                                border-radius: 20px !important;
                                background: rgba(217, 217, 217, 0.45) !important;
                            }
                            @media screen and (max-width: 431px) {
                                height: calc(100% - 300px) !important;
                            }
                        }
                        .details-btn {
                            color: var(--White, #fff);
                            font-family: "ALS Hauss";
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 120%;
                            margin-top: 12px;
                            cursor: pointer;
                            text-align: center;
                        }
                        @media screen and (max-width: 431px) {
                            width: 410px;
                        }
                        @media screen and (max-width: 370px) {
                            width: 385px;
                        }
                    }
                }
            }
        }
        .ant-tabs-ink-bar {
            display: none;
        }
    }
    .close-button {
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-btn {
            max-width: 343px;
            width: 100%;
            margin-top: 48px;
            @media screen and (max-width: 430px) {
                max-width: 310px;
            }
        }
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        text-shadow: none;
    }
}
