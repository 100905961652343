.bl-my-dealers {
    width: auto;
    .ant-btn {
        margin-top: 25px;
        width: 100%;
    }
    .bl-my-dealers-text {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        span {
            margin-right: 10px;
        }
    }
}
@media screen and (max-width: 768px) {
    .bl-my-dealers {
        width: auto;
    }
}
