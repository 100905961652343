@import '../../../../styles/variables';

.bl-summary-title {
    .total-sum-amount {
        text-align: end;
        .ant-divider-vertical {
            background: rgba(255, 255, 255, 0.5);
            margin: 0px 20px;
            height: 60px;
        }
    }
    .total-sum {
        text-align: end;
        white-space: nowrap;
    }

    @media screen and (max-width: 1199px) {
        .total-sum {
            font-size: 32px !important;
        }
    }
    
    @media screen and (max-width: $max-phone-width) {
        .total-sum {
            font-size: 32px !important;
            text-align: center;
        }
        .total-sum-amount {
            .ant-divider-vertical {
                margin: 0px 25px;
            }
        }
    }
    @media screen and (max-width: 576px) {
        .total-sum-amount {
            text-align: center;
        }
    }
}
