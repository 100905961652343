.bl-numbers-list-actions {
    .in-process {
        position: relative;
        top: -1px;
    }
    .dealer-id-mobile {
        font-weight: 500;
        line-height: 100%;
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .bl-numbers-list-actions {
        .dealer-id-mobile {
            display: block;
        }
    }
}
