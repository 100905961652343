@import "../../../styles/variables";

.bl-restore-access {
    text-align: center;
    p {
        color: $text-secondary-color;
        @include standardFont-normal;
    }

    .restore-access-title {
        @include bigFont-medium;
    }

    .restore-access-name {
        color: $primary-color;
        @include standardFont-medium;
    }
    .restore-access-btn {
        margin-top: 24px;
        width: 100%;
    }
    .restore-access-cansel-btn {
        color: $text-secondary-color;
        width: 100%;
    }

    .ant-space-item {
        width: 100%;
    }
}
@media screen and (max-width: 768px) {
    .bl-restore-access__success-loginPassword {
        width: 210px;
        margin: 0px auto;
    }
}
