.bl-numbers-list-actions-internet {
    font-weight: 500;
    line-height: 19px;
    white-space: nowrap;
    cursor: pointer;
}
@media screen and (max-width: 768px) {
  .bl-numbers-list-actions-internet {
    font-weight: 700;
    line-height: 19px;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;
    gap: 10px;
    width: 70px;
    height: 32px;
    border-radius: 8px;
    border: 1px solid #e0ca88;
  }
}

@media screen and (max-width: 320px) {
  .bl-numbers-list-actions-internet {
    width: auto;
  }
}