@import "../../../../styles/variables";

.bl-widget-advertisement {
    padding: 0;
    border: 1px solid #ffce73;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    border-radius: 12px;
    @media screen and (max-width: 820px) {
        padding: 0;
        min-height: 206px;
        background-position: center center;
    }
    .bz-block-content {
        height: 204px;
        .vidContain {
            .bl-widget-advertisement-is-ios {
                position: absolute;
                width: 100%;
                height: 100%;
            }
            video {
                width: 100%;
                height: 100%;
                position: absolute;
                object-fit: cover;
                z-index: 0;
            }
        }
        .caption {
            padding: 16px;
            min-height: 204px;
        }
        .img-files {
            position: absolute;
            z-index: 100;
            right: 30px;
            bottom: 0px;
        }
        .img-files_mob {
            position: absolute;
            z-index: 100;
            right: 0px;
            bottom: 0px;
            @media screen and (max-width: 390px) {
                width: 142px;
            }
            @media screen and (max-width: 370px) {
                width: 122px;
                height: 122px;
            }
        }
    }
    .title {
        color: var(--white, #fff);
        font-family: ALS Hauss;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        text-align: start;
        @media screen and (max-width: 385px) {
            font-size: 22px;
        }
    }
    .description {
        padding-bottom: 4px;
        color: var(--white-250, rgba(255, 255, 255, 0.5));
        font-family: ALS Hauss;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-align: start;
        @media screen and (max-width: 820px) {
            font-family: ALS Hauss;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: rgba(255, 255, 255, 0.7);
            padding-bottom: 0px;
        }
    }
    .col-btn-linc {
        text-align: start;
        .ant-btn {
            margin-top: 10px;
            max-width: 224px;
            width: 224px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                margin-right: 10px;
            }
            @media screen and (max-width: 820px) {
                width: 185px;
                height: 48px;
                margin-top: 0;
            }
        }
    }
}
