.bl-bonuses-page {
    .bl-back {
        margin-top: 0;
    }
    .bl-mybonuses {
        .bz-block {
            min-height: 214px;
        }
    }
    .ui-back {
        svg {
            top: 0;
        }
    }
}
