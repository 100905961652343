.bl-widget-delivery-list {
    .img-status {
        margin-right: -15px;
        img {
            width: 10px;
        }
    }
    .status-name:first-letter {
        text-transform: capitalize;
    }
    .delivery-list-id {
        display: flex;
        align-items: center;
    }
    .track-number {
        align-items: center;
        a {
            .link {
                color: #fff;
                font-family: "ALS Hauss";
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                transition: 1s linear;

                @media screen and (min-width: 768px) {
                    &:hover {
                        background: linear-gradient(
                            141.08deg,
                            #efce73 25.79%,
                            #fef0b7 63.66%,
                            #cea86f 97.07%
                        );
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-repeat: no-repeat;
                        background-origin: content-box;
                        background-clip: text;
                    }
                }
                @media screen and (max-width: 531px) {
                    color: #bf69c0;
                }
                @media screen and (max-width: 370px) {
                    font-size: 16px;
                }
            }
        }
        img {
            cursor: pointer;
        }
        .ant-btn {
            align-content: center;
            align-items: center;
            background: transparent;
            display: flex;
            width: auto;
            position: relative;
            background-image: url("../../../../../images/copy.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            border-radius: 0;
            width: 24px;
            height: 24px;
            span {
                bottom: 0;
                left: 0;
                margin: auto;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 100;
            }
            svg {
                color: #fff;
                &:hover {
                    color: #efce73;
                }
            }
            @media screen and (min-width: 768px) {
                &:hover {
                    background-image: url("../../../../../images/copy_gold.png");
                }
            }
        }
        .toltip-track_number {
            background-image: url("../../../../../images/bg_tooltip_track_number.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            max-width: 435px;
            left: -140px !important;
            .ant-tooltip-inner {
                padding: 10px 14px;
                background-color: transparent;
                height: 57px;
                white-space: pre;
                color: var(--White, #fff);
                font-family: "ALS Hauss";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .ant-tooltip-arrow {
                display: none;
            }
        }
        .bl-track_number {
            .track-container {
                position: absolute;
                z-index: 5;
                top: -80px;
                right: -16px;
                opacity: 1;
                -webkit-transition: all 0.4s ease-in-out;
                -moz-transition: all 0.4s ease-in-out;
                -ms-transition: all 0.4s ease-in-out;
                -o-transition: all 0.4s ease-in-out;
                transition: all 0.4s ease-in-out;
                @media screen and (max-width: 431px) {
                    right: 50px;
                }
                @media screen and (max-width: 420px) {
                    right: 35px;
                }
                @media screen and (max-width: 390px) {
                    right: 20px;
                }
                @media screen and (max-width: 375px) {
                    right: 10px;
                }
                @media screen and (max-width: 370px) {
                    right: 0px;
                }
                &.hidden {
                    opacity: 0;
                    z-index: -1;
                }
                .bz-typography {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    padding: 8px;
                    white-space: pre;
                    font-size: 14px;
                    line-height: 17.92px;
                    font-weight: 400;
                    padding-bottom: 22px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .bl-save-track_number {
            .save-track-container {
                position: absolute;
                z-index: 5;
                top: -67px;
                right: -16px;
                opacity: 1;
                -webkit-transition: all 0.4s ease-in-out;
                -moz-transition: all 0.4s ease-in-out;
                -ms-transition: all 0.4s ease-in-out;
                -o-transition: all 0.4s ease-in-out;
                transition: all 0.4s ease-in-out;
                @media screen and (max-width: 767px) {
                    right: -7px;
                }
                &.hidden {
                    opacity: 0;
                    z-index: -1;
                }
                .bz-typography {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    padding: 8px;
                    white-space: pre;
                    font-size: 14px;
                    line-height: 17.92px;
                    font-weight: 400;
                    padding-bottom: 22px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .toltip-save-track_number {
            background-image: url("../../../../../images/bg_toltip_save_track_number.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            max-width: 233px;
            left: -17px !important;
            .ant-tooltip-inner {
                padding: 10px 14px;
                background-color: transparent;
                height: 56px;
                white-space: pre;
                color: var(--White, #fff);
                font-family: "ALS Hauss";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .ant-tooltip-arrow {
                display: none;
            }
        }
    }
    .ant-collapse {
        width: 100%;
        position: relative;

        .ant-collapse-header {
            position: static !important;
            .ant-collapse-arrow {
                top: 10px !important;
            }
        }
        .ant-collapse-arrow {
            color: #fff;
            font-size: 18px !important;
            top: -11px !important;
            svg {
                transform: rotate(270deg);
            }
        }
        .ant-collapse-item-active {
            .ant-collapse-arrow {
                svg {
                    transform: rotate(90deg) !important;
                }
            }
        }
        .ant-collapse-item {
            .ant-collapse-content-box {
                border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
            }
        }
        .btn-repeat-delivery {
            margin-top: 0;
        }
        @media screen and (max-width: 768px) {
            border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
            &:last-child {
                border-bottom: none;
            }
            .ant-collapse-item {
                .ant-collapse-content-box {
                    border-bottom: none;
                }
            }
        }
    }
}
@media screen and (min-width: 768px) {
    .bl-widget-delivery-list {
        .ant-divider {
            display: none;
        }
        .delivery-list-sub-title {
            display: none;
        }
        .delivery-list-id {
            .mobile-img {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .bl-widget-delivery-list {
        border-color: transparent;
        border: 0;
        .ant-row {
            row-gap: 24px;
            border-color: transparent;
            border: 0;
        }
        .ant-divider {
            background: rgba(255, 255, 255, 0.5);
            margin: 0;
        }
        .ant-divider:last-child {
            display: none;
        }
        .delivery-list-id {
            .lattice {
                display: none;
            }
        }
        .delivery-list-sub-title {
            margin-bottom: 8px;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: rgba(255, 255, 255, 0.5);
        }
        .status-name,
        .date-created-at,
        .id-list,
        .track-number {
            font-weight: 500;
            line-height: 100%;
            color: #ffffff;
        }
        .delivery-list-id {
            .mobile-img {
                img {
                    width: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .bl-widget-delivery-list {
        .img-status {
            margin-right: 0;
        }
    }
}
