.bl-numbers-list-info {
    .icon-wrapper {
        width: 32px;
        height: 32px;
        border-radius: 8px;
    }
    .icons-container {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4px;
        .lightning {
            cursor: pointer;
            position: static;
            width: 0;
            opacity: 0;
            transition: none;
        }
    }

    > .ant-space {
        > .ant-space-item {
            &:first-child {
                > .bz-typography {
                    > .ant-space {
                        > .ant-space-item {
                            .phone {
                                width: 138px;
                            }
                        }
                    }
                }
            }
        }
    }
    .ant-collapse {
        .ant-collapse-item {
            .ant-collapse-header {
                .ant-collapse-arrow {
                    top: 30%;
                    svg {
                        transition: all 0.4s ease-in-out 0s;
                        color: rgb(255, 255, 255);
                        width: 24px;
                        height: 24px;
                        transform: rotate(90deg);
                    }
                    @media screen and (max-width: 768px) {
                        right: 119px;
                        top: 11px;
                    }
                }
            }
        }
        .ant-collapse-item-active {
            .ant-collapse-header {
                .ant-collapse-arrow {
                    svg {
                        transform: rotate(-90deg) !important;
                    }
                }
            }
        }
    }
    .list-icons {
        img {
            margin-right: 4px;
        }
    }
}

.is-can-super-booking {
    .bl-numbers-list-info {
        .ant-collapse {
            .ant-collapse-item {
                .ant-collapse-header {
                    .ant-collapse-arrow {
                        @media screen and (max-width: 410px) {
                            right: 94%;
                            top: 44px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .bl-numbers-list-info {
        > .ant-space {
            > .ant-space-item {
                &:first-child {
                    > .bz-typography {
                        width: auto;
                    }
                }
            }
        }
        .list-icons {
            img {
                margin-right: 3px;
            }
        }
    }
}
