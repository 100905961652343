@import "../../../../../styles/variables";

.payouts-history {
    margin-bottom: 16px;
    .mobile-payouts-sum {
        display: flex;
        justify-content: right;
    }
}
@media screen and (min-width: $max-phone-width) {
    .payouts-history {
        .mobile-text-payout {
            display: none;
        }
    }
}

@media screen and (max-width: $max-tablet-width) {
    .payouts-history {
        .payouts-sum {
            font-size: 17px !important;
            font-weight: 700;
        }
    }
}

@media screen and (max-width: $max-phone-width) {
    .payouts-history {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        margin-bottom: 32px;
        .col-mobile-payouts-sum {
            top: -20px;
            .mobile-payouts-sum {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                text-align: flex-end;
                .payouts-sum {
                    font-size: 17px !important;
                    font-weight: 700;
                }
                .mobile-text-payout {
                    margin-top: 2px;
                }
            }
        }
        .date-format-mobile-payouts {
            margin-top: 4px;
            font-weight: 500;
        }
        .date-format-mobile {
            display: flex;
            justify-content: center;
            margin-bottom: 24px;
            .date-format-mobile-text {
                background: rgba(255, 255, 255, 0.16);
                border-radius: 16px;
                font-weight: 500;
                line-height: 100%;
                color: rgba(255, 255, 255, 0.5);
                min-width: 127px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 10px;
            }
        }
    }
}
