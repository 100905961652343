.bl-reset-password-form {
  text-align: center;
  max-width: 430px;
  .ant-btn {
    width: 252px;
  }
  .ant-space.ant-space-vertical {
    min-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .bl-reset-password-form {
    .ant-btn {
      width: 100%;
    }
    .ant-space.ant-space-vertical {
      .ant-space-item {
        &:first-child{
          .ant-form-item {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
