@font-face {
    font-family: "ALS Hauss";
    font-style: normal;
    font-weight: normal;
    src: url("./fonts/ALS_Hauss/ALS_Hauss_Regular_1.002.otf") format("opentype");
}

@font-face {
    font-family: "ALS Hauss";
    font-style: normal;
    font-weight: 500;
    src: url("./fonts/ALS_Hauss/ALS_Hauss_Medium_1.002.otf") format("opentype");
}

@font-face {
    font-family: "ALS Hauss";
    font-style: normal;
    font-weight: 700;
    src: url("./fonts/ALS_Hauss/ALS_Hauss_Bold_1.002.otf") format("opentype");
}

@font-face {
    font-family: "ALS Alumna";
    font-style: normal;
    font-weight: normal;
    src: url("./fonts/ALSAlumnaRegular/ALSAlumnaRegular.ttf") format("opentype");
}

@font-face {
    font-family: "ALS Roboto";
    font-style: normal;
    font-weight: normal;
    src: url("./fonts/ALS_Roboto/Roboto-Regular.ttf") format("opentype");
}

@font-face {
    font-family: "ALS NotoSans";
    font-style: normal;
    font-weight: normal;
    src: url("./fonts/ALS_NatoSans/NotoSans-Regular.ttf") format("opentype");
}

@font-face {
    font-family: "ALS Belbak";
    font-style: normal;
    font-weight: normal;
    src: url("./fonts/ALS_Bakbak/BakbakOne-Regular.ttf") format("opentype");
}
