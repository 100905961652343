.bl-lottery-ng-prize {
    display: flex;
    width: 1041px;
    padding: 6px;
    flex-direction: column;
    align-items: center;
    gap: 19px;
    margin: auto;
    border-radius: 14px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    height: 192px;
    background-image: radial-gradient(
            circle at 100% 100%,
            transparent 13px,
            #ffffff 13px,
            #ffffff 14px,
            transparent 14px
        ),
        linear-gradient(to right, #ffffff, #fffbeb),
        radial-gradient(
            circle at 0% 100%,
            transparent 13px,
            #fffbeb 13px,
            #fffbeb 14px,
            transparent 14px
        ),
        linear-gradient(to bottom, #fffbeb, #fef0b7),
        radial-gradient(
            circle at 0% 0%,
            transparent 13px,
            #fef0b7 13px,
            #fef0b7 14px,
            transparent 14px
        ),
        linear-gradient(to left, #fef0b7, #fef4cc),
        radial-gradient(
            circle at 100% 0%,
            transparent 13px,
            #fef4cc 13px,
            #fef4cc 14px,
            transparent 14px
        ),
        linear-gradient(to top, #fef4cc, #ffffff);
    background-size: 14px 14px, calc(100% - 28px) 1px, 14px 14px, 1px calc(100% - 28px);
    background-position: top left, top center, top right, center right, bottom right, bottom center,
        bottom left, center left;
    background-repeat: no-repeat;

    @media screen and (max-width: 1200px) {
        width: 589px;
        height: 158px;
    }

    @media screen and (max-width: 992px) {
        max-width: 343px;
        height: 88px;
        border-radius: 12px;
        width: 100%;
        padding: 4px;
        background-image: radial-gradient(
                circle at 100% 100%,
                transparent 11px,
                #ffffff 11px,
                #ffffff 12px,
                transparent 12px
            ),
            linear-gradient(to right, #ffffff, #fffbeb),
            radial-gradient(
                circle at 0% 100%,
                transparent 11px,
                #fffbeb 11px,
                #fffbeb 12px,
                transparent 12px
            ),
            linear-gradient(to bottom, #fffbeb, #fef0b7),
            radial-gradient(
                circle at 0% 0%,
                transparent 11px,
                #fef0b7 11px,
                #fef0b7 12px,
                transparent 12px
            ),
            linear-gradient(to left, #fef0b7, #fef4cc),
            radial-gradient(
                circle at 100% 0%,
                transparent 11px,
                #fef4cc 11px,
                #fef4cc 12px,
                transparent 12px
            ),
            linear-gradient(to top, #fef4cc, #ffffff);
        background-size: 12px 12px, calc(100% - 24px) 1px, 12px 12px, 1px calc(100% - 24px);
        background-position: top left, top center, top right, center right, bottom right,
            bottom center, bottom left, center left;
        background-repeat: no-repeat;
    }

    &__title {
        display: inline-flex;
        padding: 6px 24px 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        overflow: hidden;
        background: rgba(255, 255, 255, 0.02);
        backdrop-filter: blur(21px);
        position: absolute;
        top: -37px;
        z-index: 2;
        background-image: radial-gradient(
                circle at 100% 100%,
                transparent 9px,
                #ffeaaf 9px,
                #ffeaaf 10px,
                transparent 10px
            ),
            linear-gradient(to right, #ffeaaf, #8f738b),
            radial-gradient(
                circle at 0% 100%,
                transparent 9px,
                #8f738b 9px,
                #8f738b 10px,
                transparent 10px
            ),
            linear-gradient(to bottom, #8f738b, #603f71),
            radial-gradient(
                circle at 0% 0%,
                transparent 9px,
                #603f71 9px,
                #603f71 10px,
                transparent 10px
            ),
            linear-gradient(to left, #603f71, #ac9886),
            radial-gradient(
                circle at 100% 0%,
                transparent 9px,
                #ac9886 9px,
                #ac9886 10px,
                transparent 10px
            ),
            linear-gradient(to top, #ac9886, #ffeaaf);
        background-size: 10px 10px, calc(100% - 20px) 1px, 10px 10px, 1px calc(100% - 20px);
        background-position: top left, top center, top right, center right, bottom right,
            bottom center, bottom left, center left;
        background-repeat: no-repeat;

        @media screen and (max-width: 992px) {
            padding: 4px 12px 6px 12px;
            border-radius: 8px;
            top: -22px;
            background-image: radial-gradient(
                    circle at 100% 100%,
                    transparent 7px,
                    #ffeaaf 7px,
                    #ffeaaf 8px,
                    transparent 8px
                ),
                linear-gradient(to right, #ffeaaf, #8f738b),
                radial-gradient(
                    circle at 0% 100%,
                    transparent 7px,
                    #8f738b 7px,
                    #8f738b 8px,
                    transparent 8px
                ),
                linear-gradient(to bottom, #8f738b, #603f71),
                radial-gradient(
                    circle at 0% 0%,
                    transparent 7px,
                    #603f71 7px,
                    #603f71 8px,
                    transparent 8px
                ),
                linear-gradient(to left, #603f71, #ac9886),
                radial-gradient(
                    circle at 100% 0%,
                    transparent 7px,
                    #ac9886 7px,
                    #ac9886 8px,
                    transparent 8px
                ),
                linear-gradient(to top, #ac9886, #ffeaaf);
            background-size: 8px 8px, calc(100% - 16px) 1px, 8px 8px, 1px calc(100% - 16px);
            background-position: top left, top center, top right, center right, bottom right,
                bottom center, bottom left, center left;
            background-repeat: no-repeat;
        }

        &__text {
            text-align: center;
            filter: drop-shadow(2px 1px 0px #3d074f);
            font-family: "ALS Hauss";
            font-size: 42px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            background: linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media screen and (max-width: 992px) {
                font-size: 22px;
            }
        }
    }
    &__video {
        overflow: hidden;
        height: 100%;
        width: 100%;
        -webkit-box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(50px);
        z-index: 1;
        border-radius: 10px;
        background-image: radial-gradient(
                circle at 100% 100%,
                transparent 11px,
                #fce9b1 11px,
                #fce9b1 12px,
                transparent 12px
            ),
            linear-gradient(to right, #fce9b1, #95826c),
            radial-gradient(
                circle at 0% 100%,
                transparent 11px,
                #95826c 11px,
                #95826c 12px,
                transparent 12px
            ),
            linear-gradient(to bottom, #95826c, #412d34),
            radial-gradient(
                circle at 0% 0%,
                transparent 11px,
                #412d34 11px,
                #412d34 12px,
                transparent 12px
            ),
            linear-gradient(to left, #412d34, #aa9881),
            radial-gradient(
                circle at 100% 0%,
                transparent 11px,
                #aa9881 11px,
                #aa9881 12px,
                transparent 12px
            ),
            linear-gradient(to top, #aa9881, #fce9b1);
        background-size: 12px 12px, calc(100% - 24px) 1px, 12px 12px, 1px calc(100% - 24px);
        background-position: top left, top center, top right, center right, bottom right,
            bottom center, bottom left, center left;
        background-repeat: no-repeat;

        @media screen and (max-width: 992px) {
            background-image: radial-gradient(
                    circle at 100% 100%,
                    transparent 8px,
                    #fce9b1 8px,
                    #fce9b1 9px,
                    transparent 9px
                ),
                linear-gradient(to right, #fce9b1, #95826c),
                radial-gradient(
                    circle at 0% 100%,
                    transparent 8px,
                    #95826c 8px,
                    #95826c 9px,
                    transparent 9px
                ),
                linear-gradient(to bottom, #95826c, #412d34),
                radial-gradient(
                    circle at 0% 0%,
                    transparent 8px,
                    #412d34 8px,
                    #412d34 9px,
                    transparent 9px
                ),
                linear-gradient(to left, #412d34, #aa9881),
                radial-gradient(
                    circle at 100% 0%,
                    transparent 8px,
                    #aa9881 8px,
                    #aa9881 9px,
                    transparent 9px
                ),
                linear-gradient(to top, #aa9881, #fce9b1);
            background-size: 9px 9px, calc(100% - 18px) 1px, 9px 9px, 1px calc(100% - 18px);
            background-position: top left, top center, top right, center right, bottom right,
                bottom center, bottom left, center left;
            background-repeat: no-repeat;
        }

        video {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
    }
}
