.bl-mb-accumulated {
  .sum {
    background: linear-gradient(141.08deg, #EFCE73 25.79%, #FEF0B7 63.66%, #CEA86F 97.07%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-repeat: no-repeat;
    background-origin: content-box;
    padding: 1px;
    line-height: 100%;
  }
  .ant-space {
    text-align: center;
  }
}