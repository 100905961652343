@import "../../../../../styles/variables";

.bl-connection-banner {
    margin-bottom: 96px;
    .not-available-connection {
        background: linear-gradient(141.08deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-repeat: no-repeat;
        background-origin: content-box;
        padding: 1px;
    }
    .bz-title {
        color: #fff;
        text-align: center;
        font-family: "ALS Alumna";
        font-size: 72px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        @media screen and (max-width: 431px) {
            font-size: 40px;
        }
    }
    .col-info-packet-subtitle {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .info-packet-value {
            .gold-price {
                font-family: "ALS Alumna";
                font-size: 62px;
                font-style: normal;
                font-weight: 400;
                line-height: 60px;
                .info-packet-value-gray {
                    font-family: "ALS Hauss";
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 60px;
                    margin-left: 23px;
                }
            }
        }
    }
    .bl-tariff-connection-info-Common {
        display: flex;
        align-items: center;
        text-align: center;
        padding-left: 30px;
    }
    .bl-info-common {
        width: 100%;

        .bl-info-common-row {
            justify-content: flex-start;
            .col-info-common-rest {
                display: flex;
                justify-content: flex-start;
                text-align: center;
                @media screen and (max-width: 431px) {
                    justify-content: center;
                }
            }
            .info-packet-value {
                display: flex;
                justify-content: center;
                align-items: center;
                .gold {
                    font-family: "ALS Hauss";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%;
                }
                .inline {
                    font-family: "ALS Hauss";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
        .bl-info-common-wrapper {
            .bg-value-int-gold {
                background: url(~@bezlimit/bezlimit-ui/dist/images/gold-opacity.png) center center
                        no-repeat,
                    url(~@bezlimit/bezlimit-ui/dist/images/gold-texture.png) center center no-repeat;
                background-size: cover;
                background-blend-mode: color, normal;
                background-blend-mode: color, normal;
                border-radius: 16px;
                width: 106px;
                max-width: 125px;
                height: 47px;
                display: flex;
                align-items: center;
                justify-content: center;
                .info-packet-value {
                    background: linear-gradient(141.08deg, #000 25.79%, #000 63.66%, #000 97.07%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%;
                    font-family: "ALS Hauss";
                    .inline {
                        font-family: "ALS Hauss";
                        font-size: 16px !important;
                        font-style: normal;
                        font-weight: 700;
                    }
                }
            }
        }
    }
    .connected-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .circle {
        @include title-tinyFont-normal;
        height: 282px;
        width: 282px;
        @include flexCenter;
        margin: auto;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        p {
            font-size: 24px;
            max-width: 145px;
            margin-bottom: 0px;
        }
        @media screen and (max-width: 576px) {
            margin-top: 26px;
        }
        &:before {
            content: "";
            position: absolute;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            background: url("../../../../../images/lions.png") no-repeat center center;
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: all 0.8s ease-in-out;
            -moz-transition: all 0.8s ease-in-out;
            -ms-transition: all 0.8s ease-in-out;
            -o-transition: all 0.8s ease-in-out;
            transition: all 0.8s ease-in-out;
        }

        &:hover:before {
            -moz-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }

        &:hover {
            p {
                color: #bf69c0;
            }
        }

        &:active {
            p {
                color: $beige-color;
            }
        }
    }
    .ui-back {
        display: block !important;
        position: absolute !important;
    }
    @media screen and (max-width: $max-tablet-width) {
        margin-bottom: 45px;
    }
    .info-packet-subtitle {
        text-align: center;
        font-family: ALS Hauss;
        font-size: 17px;
        line-height: 120%;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 8px;
    }
    .info-packet-value {
        text-align: center;
        font-family: ALS Alumna, sans-serif;
        font-size: 79px;
        line-height: 60px;
        font-weight: 400;
        margin-bottom: 0;
        .info-packet-value-gray {
            font-family: "ALS Hauss";
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 60px;
            -webkit-text-fill-color: transparent;
            background: linear-gradient(141.08deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%);
            -webkit-background-clip: text;
            background-origin: content-box;
            background-repeat: no-repeat;
        }
    }
    .bl-tariff-connection-info {
        display: flex;
        align-items: center;
        justify-content: center;
        > .ant-row {
            align-items: center;
        }
    }
    @media screen and (max-width: 767px) {
        .info-packet-subtitle {
            font-weight: 500;
        }
        .info-packet-value {
            font-size: 32px;
            line-height: 32px;
        }
        .info-packet-value-gray {
            display: none;
        }
    }
}
.page-header-banner {
    background: url("../../../../../images/bg_header_col_car@1x2.png");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    height: 1220px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    @media screen and (max-width: 431px) {
        height: 1320px;
    }
}
