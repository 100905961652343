@import "../../../styles/variables";

.bl-dealers-bonuses-page {
    max-width: 1184px;
    margin: auto;

    .bz-block {
        border-radius: 12px;
    }
}

.bl-dealers-bonuses-content {
    margin-top: 60px;
    margin-bottom: 40px;

    .col-title {
        @include tinyFont-medium;
        color: $text-pale-color;
        &.last {
            display: flex;
            justify-content: right;
        }
        &.penultimate {
            display: flex;
            justify-content: right;
        }
    }

    .divider-title {
        background: rgba(255, 255, 255, 0.5);
        margin: 10px 0 8px 0;
    }
    .btn-show {
        text-align: center;
    }
    .btn-show-more {
        width: $btn-standard-width;
    }

    .search-input {
        .ant-input-affix-wrapper {
            background: $bg-opacity-color;
            border: none;
            border-bottom-left-radius: 8px !important;
            border-top-left-radius: 8px !important;

            input.ant-input {
                &:placeholder-shown {
                    color: $text-pale-color;
                }
                color: $text-primary-color;
                @include title-inputFont-normal;
            }

            .ant-input-clear-icon {
                color: $text-pale-color;
            }
        }
        .ant-input-group-addon {
            border-bottom-right-radius: 8px !important;
            border-top-right-radius: 8px !important;
            background: $bg-opacity-color;
            border: none;
            .ant-input-search-button {
                color: $text-primary-color !important;
                background: none;
            }
        }
    }

    @media screen and (max-width: $max-phone-width) {
        .btn-show-more {
            width: 100%;
        }
    }
}
@media screen and (max-width: 375px) {
    .bl-dealers-bonuses-page {
        .page-header{
            font-size: 38px;
      }
    }
  }
