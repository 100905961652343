.bl-book-beservation-form {
    text-align: center;
    position: relative;
    @media screen and (max-width: 768px) {
        margin-top: -20px;
    }
    .super-container {
        * {
            color: #fff;
        }
    }
    .opacity-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: -110px;
        margin: auto;
        z-index: 10;
        background: transparent;
        max-width: 610px;
        @media screen and (max-width: 768px) {
            bottom: -18px;
            height: calc(100% + 74px);
            max-width: 100%;
        }
    }
    .tariffname {
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }

    .ant-collapse-expand-icon {
        svg {
            transform: rotate(90deg);
        }
    }

    .ant-form-item-explain-connected {
        .ant-form-item-explain-error:not(:first-child) {
            display: none;
        }
    }
    .ant-form {
        > .ant-space {
            width: 100%;
        }
    }
    .ant-btn {
        width: 252px;
    }
    .subheader {
        text-align: left;
    }
    .fields-container {
        > .ant-space-item {
            &:last-child {
                margin-top: -3px;
            }
        }
    }
    .sim-type {
        .ant-form-item {
            margin: 0;
        }
        label {
            .bz-typography {
                font-size: 20px !important;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                color: #fff !important;
            }
        }
        .desc {
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            margin-bottom: 32px;
            @media screen and (max-width: 768px) {
                display: none;
            }
        }
    }
    .fields-container {
        > .ant-space-item {
            margin-bottom: -52px;
            @media screen and (max-width: 768px) {
                margin-bottom: -45px;
            }
        }
    }
    .device-popup-container {
        .ant-select-dropdown {
            padding: 0;
            text-align: left;
            background: transparent;
            backdrop-filter: blur(6px);
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: 8px;
            .rc-virtual-list-holder-inner {
                > div {
                    &:first-child {
                        padding-top: 16px;
                    }

                    &:last-child {
                        padding-bottom: 12px;
                    }
                }
            }
            .ant-select-item {
                background: transparent !important;
                &:last-child {
                    .ant-divider {
                        display: none;
                    }
                }
            }
            .ant-select-item {
                padding-inline: 16px;
            }
        }
        .wrapper-container {
            .ant-divider {
                margin: 6px 0;
                background: rgba(255, 255, 255, 0.5);
            }
            .wrapper {
                position: relative;
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
                align-content: center;
                align-items: center;
                gap: 5px;
                flex-direction: row;
                justify-content: space-between;
                .ch {
                    background: url("../../../images/device-radio.png") no-repeat;
                    border: 0;
                    box-sizing: border-box;
                    height: 21px;
                    width: 21px;
                    background-size: contain;
                }
                .bz-typography {
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                }
                &:hover {
                    .bz-typography:not(.ch) {
                        opacity: 0.7;
                    }
                }
            }
        }
        .ant-select-item.ant-select-item-option-selected .wrapper .ch {
            background: url("../../../images/device-radio2.png") no-repeat;
            background-size: contain;
        }
    }
    .field-device {
        .ant-select-selection-item {
            .bz-typography {
                color: #fff !important;
                font-size: 17px !important;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
            }
        }
    }
    .esim-container {
        @media screen and (max-width: 768px) {
            width: 100%;
            max-width: 608px;
        }
        .ant-space-item:first-child {
            @media screen and (max-width: 768px) {
                display: none;
            }
        }
        .ant-select-selector {
            .ant-divider {
                display: none;
            }
        }
        .ant-form-item {
            margin: 0;
        }
        .device-container {
            .ant-select-selection-item {
                font-size: 17px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                color: #fff;
            }
            .anticon {
                color: rgba(255, 255, 255, 0.5);
            }
            .device-desc {
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                text-align: left;
                @media screen and (max-width: 768px) {
                    margin-bottom: 6px;
                }
            }
            .ant-select-arrow {
                .anticon {
                    display: block;
                    background: url("../../../images/device-search.png") no-repeat 100% 100%;
                    width: 16px;
                    height: 16px;
                    background-size: cover;
                    left: -6px;
                    top: -1px;
                    position: relative;
                }
                &.ant-select-arrow-loading {
                    .anticon {
                        background: transparent;
                        width: 100%;
                        height: 100%;
                    }
                    svg {
                        display: block;
                    }
                }
                svg {
                    display: none;
                }
            }
            .ant-select-focused,
            .ant-select:hover {
                .ant-select-arrow {
                    display: none;
                }
            }
            .ant-select-clear {
                background: transparent;
                .anticon {
                    display: block;
                    background: url("../../../images/cansel.png") no-repeat 100% 100%;
                    width: 16px;
                    height: 16px;
                    background-size: cover;
                    left: -7px;
                    top: -2px;
                    position: relative;
                }
                svg {
                    display: none;
                }
            }
        }
        .ant-input {
            color: #fff !important;
            font-size: 17px !important;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .desc {
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }
        .ant-collapse {
            max-width: 624px;
            text-align: left;
            border: 1px solid #efce73 !important;
            border-radius: 8px;
            .ant-collapse-item {
                width: 100%;
                .ant-collapse-header {
                    width: 624px;
                    height: 48px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-inline: 16px;
                    @media screen and (max-width: 768px) {
                        width: 100%;
                    }
                    .ant-collapse-header-text {
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 120%;
                        color: #fff;
                    }
                    .anticon.ant-collapse-arrow {
                        color: #fff;
                    }
                }
            }
            .ant-collapse-content-box {
                .bz-typography {
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                }
                .bz-typography:not(:last-child) {
                    margin-bottom: 24px;
                }
            }
        }
    }
}

@-webkit-keyframes autofill {
    0%,
    100% {
        color: #666;
        background: transparent;
    }
}

input:-webkit-autofill {
    -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

@media screen and (max-width: 768px) {
    .bl-book-beservation-form {
        .ant-btn {
            width: 100%;
        }
        .fields-container {
            > .ant-space-item {
                &:last-child {
                    margin-top: 6px;
                }
            }
        }
    }
}
