.page-history {
    .bl-operations-history {
        .bl-accrual-history {
            .ant-picker {
                width: 100%;
            }
            .header-bl-operations-histoty {
                margin-bottom: 32px;
                
            }
        }
        .header-bl-operations-histoty {
            .format-accumulated-sum {
                background: transparent;
                border: none;
                width: 130px;
                text-align: end;
                padding: 0;
            }
            .format-accumulated-process-sum {
                width: 120px;
                background: transparent;
                border: none;
                text-align: end;
                padding: 0;
            }
            .total-sum-amount {
                .ant-divider-vertical {
                    background: rgba(255, 255, 255, 0.5);
                    margin: 0px 20px;
                    height: 60px;
                }
            }
            .title-and-datapicker {
                .payouts-field-range-picker-container,
                .payments-field-range-picker-container {
                    min-width: 323px;

                    .field-range-picker {
                        position: relative;
                    }
                    &.focused {
                        .ant-form-item-label {
                            padding: 0;
                        }
                        .ant-picker-input {
                            margin-top: 15px;
                        }
                        .ant-picker-range-separator {
                            display: block;
                        }
                    }
                    .ant-form-item-label {
                        position: absolute;
                        left: 16px;
                        padding-top: 7px;
                        label {
                            font-size: 12px;
                            line-height: 16px;
                            color: rgba(255, 255, 255, 0.5);
                        }
                    }
                    .ant-picker-suffix {
                        position: absolute;
                        right: 16px;
                    }
                    .ant-picker {
                        width: 100%;
                        .ant-picker-input {
                            max-width: 80px;
                        }
                    }
                    .ant-picker-range-separator {
                        position: relative;
                        top: 6px;
                        left: -2px;
                        display: none;
                    }
                }
                .data-picker-operations-history {
                    input {
                        pointer-events: none;
                    }
                }
            }
        }
    }
}



@media screen and (max-width: 991px) {
    .total-sum {
        font-size: 40px !important;
    }
}

@media screen and (max-width: 767px) {
    .page-history {
        .bl-operations-history {
            .title-and-datapicker {
                text-align: center;
                margin-bottom: 32px;
                .title {
                    display: none;
                }
                .payouts-field-range-picker-container,
                .payments-field-range-picker-container {
                    min-width: 100% !important;
                }
                .ant-space {
                    width: 100%;
                }
            }
            .total-sum-amount {
                text-align: center !important;
            }
            .header-bl-operations-histoty {
                .title-mobile {
                    text-align: center;
                    margin-bottom: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .page-history {
        .bl-operations-history {
            .bl-accrual-history {
                .total-sum {
                    font-size: 32px !important;
                    text-align: center;
                }
            }
            .header-bl-operations-histoty {
                .total-sum-amount {
                    .ant-divider-vertical {
                        margin: 0px 25px;
                    }
                }
                .format-accumulated-sum {
                    width: 100px;
                    text-align: center;
                }
                .format-accumulated-process-sum {
                    width: 85px;
                    text-align: center;
                }
            }
        }
    }
}
