.bl-numbers-in-booking {
    .bl-numbers-in-booking-duration {
        .ant-space {
            .ant-space-item {
                &:nth-child(odd) {
                    min-width: 28px;
                    text-align: center;
                }
            }
        }
    }

    &:not(.kanban) {
        .bl-numbers-list-actions {
            > .ant-space {
                > .ant-space-item {
                    &:nth-child(1) {
                        display: flex;
                        gap: 8px;
                    }
                    &:nth-child(2) {
                        min-width: 155px;
                    }
                }
            }
        }
    }

    .process-text {
        background: linear-gradient(141.08deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-repeat: no-repeat;
        background-origin: content-box;
        padding: 1px;
        min-width: 150px;
    }

    &.kanban {
        .absolute {
            position: absolute;
            right: 22px;
            white-space: nowrap;
            left: 24px;
            margin: auto;
            bottom: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .ant-table-tbody {
            .ant-table-cell {
                &:last-child {
                    .bl-numbers-list-actions {
                        text-align: right;
                    }
                }
            }
        }

        .bl-numbers-list-actions {
            padding: 0;
            text-align: right;

            > .ant-space {
                gap: 8px !important;
                justify-content: flex-end;
                margin-right: -2px;
            }
        }

        .ant-table {
            &:not(.ant-table-empty) {
                tbody {
                    tr {
                        display: flex;
                        gap: 8px;
                        padding-bottom: 43px;

                        td {
                            position: static;

                            &:first-child {
                                position: static;
                                padding: 0;
                                width: auto;
                                @media screen and (max-width: 430px) {
                                    position: absolute;
                                }
                            }

                            &:nth-child(2) {
                                margin: 0;
                                width: 100% !important;
                            }

                            .bl-numbers-list-info {
                                > .ant-space {
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ant-table-thead {
        .title {
            font-weight: 700;
            line-height: 120%;
        }
    }

    svg {
        font-size: 24px;
    }

    thead {
        display: none !important;
    }
}

@media screen and (max-width: 1200px) {
    .bl-numbers-in-booking {
        &.kanban {
            .ant-table {
                &:not(.ant-table-empty) {
                    tbody {
                        tr {
                            width: 50%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .bl-numbers-in-booking {
        &.kanban {
            .bl-numbers-list-actions {
                > .ant-space {
                    margin-right: 0;
                }
            }

            .in-process {
                line-height: 140%;
            }

            .absolute {
                right: 17px;
                left: 17px;
                bottom: 17px;
            }

            .ant-table {
                &:not(.ant-table-empty) {
                    tbody {
                        tr {
                            padding-bottom: 45px;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
