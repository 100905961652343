.svgAssetHidden {
    visibility: hidden;
    position: absolute;
    width: 0px;
    height: 0px;
    overflow: hidden;
}

.bl-delivery-sim {
    .load-more {
        text-align: center;
        .ant-btn {
            width: 100%;
        }
    }
}

.bl-my-deliveries {
    margin-top: 70px;
    > .bz-block-content {
        > div {
            &:last-child {
                .ant-divider {
                    display: none;
                }
            }
        }
    }
    .spase-list-description {
        width: 100%;
    }
    .ant-collapse {
        .ant-collapse-arrow {
            color: #fff;
            font-size: 18px !important;
            top: -11px !important;
            svg {
                transform: rotate(270deg);
            }
        }
        .ant-collapse-item-active {
            .ant-collapse-arrow {
                svg {
                    transform: rotate(90deg) !important;
                }
            }
        }
    }
    .ant-collapse {
        position: relative;
        .ant-collapse-header {
            position: static !important;
            .ant-collapse-arrow {
                top: 10px !important;
            }
        }
    }
    .ant-collapse {
        border-color: transparent;
        border: 0;
        border-radius: 0;
    }
}

@media screen and (max-width: 768px) {
    .bl-my-deliveries {
        .title-colapse-deliveries {
            display: none;
        }

        .spase-list-description {
            border-bottom: none;
        }
        .divider-title-deliviries {
            display: none;
        }
        .ant-collapse {
            margin-bottom: 24px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);

            .ant-collapse-header {
                .ant-collapse-arrow {
                    right: 0px;
                    svg {
                        width: 10px;
                    }
                }
            }
        }
        .ant-collapse:last-child {
            border-bottom: none;
        }
    }
}
