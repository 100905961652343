@import "../../../../styles/variables";

.bl-widget-new-year {
    padding: 0;
    border: 1px solid #ffce73;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    border-radius: 12px;
    cursor: pointer;
    @media screen and (max-width: 576px) {
        height: 206px;
    }
    .bz-block-content {
        height: 204px;
        @media screen and (max-width: 576px) {
            height: 188px;
        }
        .vidContain {
            .bl-widget-advertisement-is-ios {
                position: absolute;
                width: 100%;
                height: 100%;
            }
            video {
                width: 100%;
                height: 100%;
                position: absolute;
                object-fit: cover;
                z-index: 0;
            }
        }
        .advertising-text {
            position: relative;
            bottom: -192px;
            right: 8px;
            text-align: end;
            font-size: 11px;
            z-index: 1;
            @media screen and (max-width: 576px) {
                font-size: 10px;
            }
        }
    }
}
