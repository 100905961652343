.bl-footer.ant-layout-footer {
    background: transparent;
    bottom: 0;
    width: 100%;
    padding: 0 16px 91px;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #fff;
    margin-top: 120px;
    @media screen and (max-width: 280px) {
        .ant-space-item {
            max-width: 248px;
        }
    }
    .col-info {
        @media screen and (min-width: 768px) {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            bottom: 5px;
        }
    }
}

@media screen and (min-width: 576px) {
    .bl-footer.ant-layout-footer {
        padding: 0 60px 91px;

        > .ant-row {
            width: 100%;
        }
    }
}
