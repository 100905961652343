.bl-remove-booking-form {
  .ant-btn:not(.ant-btn-text) {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .bl-remove-booking-form {
    .cancel {
      margin-top: 16px;
    }
  }
}