#portal-copy {
    text-align: center;
    position: fixed;
    bottom: 16px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
}

@media screen and (max-width: 768px) {
    #portal-copy {
        .ant-space {
            display: flex;
            flex-direction: column;
            gap: 8px !important;
            padding: 0 16px;
            .ant-space-item {
                width: 100%;
            }
            .ant-btn {
                width: 100%;
                min-width: 100% !important;
                font-size: 14px;
                :hover {
                    color: #fff !important;
                }
            }
        }
    }
}
