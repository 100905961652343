.bl-numbers-filters-filter {
  h2 {
    font-weight: 700;
    font-size: 17px;
    line-height: 120%;
  }
  .items {
    flex-wrap: wrap;
    .ant-btn {
      height: 28px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 16px;
      padding: 6px 12px;
      color: #FFFFFF;
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      &:disabled {
        opacity: .5;
      }
    }
  }
}