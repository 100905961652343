.ant-collapse-content-box-main {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    width: 844px;
    padding: 16px 0px;
    margin: 13px auto 0;

    .social-icons-col {
        width: 111px;
        .social-icon {
            margin-right: 4px;
            width: 16px;
            max-width: 20px !important;
            max-height: 20px !important;
        }
    }
    .social-text {
        font-size: 16px;
    }
    @media screen and (max-width: 1240px) {
        width: 765px;
    }
    @media screen and (max-width: 890px) {
        width: auto;
        height: auto;
        .social-icons-col {
            width: 80px;
            .social-icon {
                margin-right: 3px;
            }
        }
        .social-text {
            font-size: 14px;
        }
    }
    @media screen and (max-width: 359px) {
        .social-icons-row {
            flex-direction: column;
        }
        .social-text {
            margin-top: 9px;
        }
    }
}

.ant-collapse-content-box-plan {
    align-content: space-between;
    width: 627px;
    .social-icons-col {
        justify-content: flex-start !important;
    }
    .social-text {
        font-size: 14px;
        font-weight: 500;
    }
    @media screen and (max-width: 890px) {
        height: auto;
        width: auto;

        .social-icons-col {
            justify-content: flex-start !important;
            width: 80px;
            .social-icon {
                margin-right: 3px;
            }
        }
        .social-text {
            text-align: start;
            margin-top: 0px;
        }
    }
    @media screen and (max-width: 450px) {
        .social-text {
            font-size: 14px;
        }
    }
    @media screen and (max-width: 360px) {
        .social-text {
            text-align: start;
            margin-top: 0px;
        }
    }
}
.ant-collapse-content-box-plans {
    height: auto;
    .social-text {
        font-size: 17px;
        font-weight: 500;
    }
    .social-icons-col {
        justify-content: flex-start !important;
        width: 82px;
        .social-icon {
            margin-right: 3px;
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1000px) {
        .social-icons-row {
            flex-direction: column;
        }
        .social-text {
            text-align: start;
            margin-top: 0px;
        }
    }
    @media screen and (max-width: 766px) {
        .social-icons-row {
            padding-bottom: 14px;
        }
        .social-icons-col {
            width: 80px;
            .social-icon {
                margin-right: 3px;
            }
        }
    }
    @media screen and (max-width: 586px) {
        .social-icons-row {
            &:last-child {
                img {
                    filter: initial;
                }
            }
        }
    }
    @media screen and (max-width: 450px) {
        .social-text {
            font-size: 14px;
        }
    }
    @media screen and (max-width: 387px) {
        padding-bottom: 0px;
        .social-icons-row {
            flex-wrap: nowrap;
            align-items: center;
            align-content: flex-start;
        }
        .social-text {
            text-align: start;
            margin-top: 0px;
        }
    }
    @media screen and (max-width: 359px) {
        .social-icons-row {
            flex-direction: row;
            align-items: center;
        }
    }
    width: auto;
    height: auto;
    .social-icons-col {
        text-align: start;
    }
    @media screen and (min-width: 768px) and (max-width: 1000px) {
        .social-icons-row {
            flex-direction: row;
        }
    }
    @media screen and (max-width: 766px) {
        .social-icons-row {
            padding-bottom: 10px;
        }
        .social-icons-col {
            width: 80px;
            .social-icon {
                margin-right: 3px;
                height: auto !important;
            }
        }
    }
    @media screen and (max-width: 450px) {
        .social-icons-row {
            padding-bottom: 9px;
        }
    }
    @media screen and (max-width: 375px) {
        .social-icons-col {
            width: 70px;
            .social-icon {
                width: 14px;
            }
        }
    }
    @media screen and (max-width: 340px) {
        .social-icons-row {
            flex-direction: column;
            align-items: flex-start !important;
            padding-bottom: 6px;
        }
    }
}
