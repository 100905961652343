.bl-database-of-numbers {
  .bz-block {
    background: url('../../../../images/lion.png') no-repeat;
    background-position: bottom right -3px;
    background-size: 175px;
    border-color: #EFCE73;
    .text-helper {
      font-weight: 500;
    }
    .ant-btn {
      width: 100%;
    }
  }
}


@media screen and (max-width: 991px) {
  .bl-database-of-numbers{
    .bz-block {
      background: url('../../../../images/lion2.png') no-repeat;
      background-position: bottom left;
      background-size: 124px;
      padding-left: 135px;
      min-height: 146px;
      .bz-block-title {
        font-size: 24px;
        font-weight: 500;
      }
      .bz-block-content:not(:first-child) {
        margin-top: 8px;
      }
      .text-helper{
        line-height: 19px;
      }
      .ant-btn {
        height: 32px;
        max-width: 165px;
      }
    }
    .ant-space{
      gap: 11px !important;
    }
  }
}

@media screen and (max-width: 320px) {
  .bl-database-of-numbers{
    .bz-block {
      padding-left: 50px;
    }
  }
}