.bl-nps-reservation-list {
    margin-top: 5px;
    @media screen and (max-width: 768px) {
        margin-top: 16px;
    }
    .nps-dropdown {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 11px;
        &-container {
            display: flex;
            flex-direction: row;
            gap: 12px;
            align-items: center;
            @media screen and (max-width: 768px) {
                width: 100%;
                align-items: center;
                justify-content: center;
            }
            form {
                position: relative;
                min-width: 368px;
                @media screen and (max-width: 768px) {
                    min-width: auto;
                    max-width: 271px;
                    width: 271px;
                }
                svg {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    right: 24px;
                    &.is-open {
                        transform: rotate(-180deg);
                    }
                    @media screen and (max-width: 768px) {
                        right: 12px;
                    }
                }
            }
            .ant-form-item {
                margin: 0;
            }
            .ant-select {
                min-width: 252px;
                width: 100%;
                position: relative;
                background: transparent;
                &::before {
                    content: "";
                    background: linear-gradient(
                        45deg,
                        #fef0b7,
                        #eaccb5,
                        #fff,
                        #cd9ab1,
                        #851ca8,
                        #851ca8,
                        #851ca8,
                        #ff00c8,
                        #fef0b7
                    );
                    position: absolute;
                    top: -2px;
                    left: -2px;
                    background-size: 400%;
                    z-index: -1;
                    filter: blur(5px);
                    width: calc(100% + 4px);
                    height: calc(100% + 4px);
                    animation: glowing 20s linear infinite;
                    opacity: 1;
                    transition: opacity 0.3s ease-in-out;
                    border-radius: 12px;
                    -webkit-backface-visibility: hidden;
                    -moz-backface-visibility: hidden;
                    -webkit-transform: translate3d(0, 0, 0);
                    -moz-transform: translate3d(0, 0, 0);
                    transform: translate3d(0, 0, 0);
                }
                &::after {
                    z-index: -1;
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    border-radius: 12px;
                    backdrop-filter: blur(12px);
                    background: transparentb;
                    background: rgb(40 19 49 / 90%);
                }
                @media screen and (max-width: 768px) {
                    min-width: 100%;
                }

                &-selection-placeholder {
                    font-family: "ALS Hauss";
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    display: flex;
                    align-items: center;
                    opacity: 0.15;
                }
                &-selector {
                    border-radius: 8px;
                    border: 1px solid var(--Linear, #fef0b7) !important;
                    max-height: 52px;
                    height: 52px;
                    border: 0 !important;

                    input {
                        font-family: "ALS Hauss";
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 120%;
                        background: var(
                            --Linear,
                            linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%)
                        ) !important;
                        background-clip: text !important;
                        -webkit-background-clip: text !important;
                        -webkit-text-fill-color: transparent !important;
                    }
                }
            }
        }
        &-text {
            color: var(--white-250, rgba(255, 255, 255, 0.5));
            font-family: "ALS Hauss";
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            text-align: center;
        }
    }
    .ant-btn {
        margin: auto;
        position: relative;
        display: flex;
        margin: auto;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        @media screen and (max-width: 768px) {
            max-width: 343px;
        }
        &-loading {
            width: 626px;
        }
        img {
            margin-top: 2px;
            @media screen and (max-width: 768px) {
                height: 26px;
            }
        }
        .inner {
            font-family: "ALS Hauss";
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            background: var(
                --Linear,
                linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%)
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            &::before {
                content: "";
                background: linear-gradient(
                    45deg,
                    #fef0b7,
                    #eaccb5,
                    #fff,
                    #cd9ab1,
                    #851ca8,
                    #851ca8,
                    #851ca8,
                    transparent,
                    #fef0b7
                );
                position: absolute;
                top: -2px;
                left: -2px;
                background-size: 400%;
                z-index: -1;
                filter: blur(5px);
                width: calc(100% + 4px);
                height: calc(100% + 4px);
                animation: glowing 20s linear infinite;
                opacity: 1;
                transition: opacity 0.3s ease-in-out;
                border-radius: 12px;
                -webkit-backface-visibility: hidden;
                -moz-backface-visibility: hidden;
                -webkit-transform: translate3d(0, 0, 0);
                -moz-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }
            &::after {
                z-index: -1;
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                border-radius: 12px;
                backdrop-filter: blur(12px);
                background: transparentb;
                background: rgb(40 19 49 / 90%);
            }
            .title {
                text-align: left;
                font-family: "ALS Hauss";
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            .text {
                text-align: left;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-top: -4px;
            }
        }
    }

    @keyframes glowing {
        0% {
            background-position: 0 0;
        }
        50% {
            background-position: 400% 0;
        }
        100% {
            background-position: 0 0;
        }
    }
}

.bl-nps-phone-container {
    padding: 0;
    margin: 0;
    background: transparent;
    .rc-virtual-list-holder-inner {
        gap: 2px;
    }
    .ant-select {
        width: 100%;

        &-item {
            border-radius: 8px;
            background: var(--Violet-4, #cfc8db);
            backdrop-filter: blur(7.5px);
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            text-align: center;
            &-option-content {
                color: var(--Violet-1, #4c1c5c);
                font-family: "ALS Alumna";
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
            }
            &-option-active {
                border-radius: 8px;
                background: var(--Violet-2, #642878);
                .ant-select-item-option-content {
                    background: var(
                        --Gold-Gradient-1,
                        linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%)
                    );
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            &-empty {
                border-radius: 8px;
                background: var(--Violet-4, #cfc8db);
                backdrop-filter: blur(7.5px);
                height: 42px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                text-align: center;
                color: var(--Violet-1, #4c1c5c);
                font-family: "ALS Hauss";
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
            }
        }
    }
}
