@import "../../../styles/variables";

.bl-bonuses-page {
    max-width: 1184px;
    margin: auto;
    .bl-loyalty {
        .hint-icon {
            .bl-hint_header {
                position: absolute;
                top: 9px;
                right: 12px;
                z-index: 20;
                .ant-btn {
                    width: 24px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    background: transparent;
                    svg {
                        width: 24px !important;
                        height: 24px !important;
                    }
                }
            }
        }
        .btn-hint-mobile {
            position: absolute;
            top: 9px;
            right: 12px;
            left: auto;
            .ant-btn {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .bz-block {
        border-radius: 12px;
    }

    .bonuses-content {
        margin-top: 60px;
        margin-bottom: 40px;
    }

    .bonuses-output-number {
        .bl-replenish-number-bonuses {
            margin-bottom: 0px;
        }
    }

    @media screen and (max-width: $max-tablet-width) {
        .bonuses-content {
            margin-top: 40px;
            margin-bottom: 20px;
        }
    }
}
