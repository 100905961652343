.bl-reservation-list-info {
    .process-text {
        font-size: 24px !important;
        background: linear-gradient(141.08deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-repeat: no-repeat;
        background-origin: content-box;
        padding: 1px;
    }

    .actions,
    .extend {
        display: flex;
        justify-content: flex-end;
        @media screen and (max-width: 320px) {
            justify-content: flex-start;
        }
    }
    .icons {
        display: flex;
        gap: 12px;
        justify-content: flex-end;
        flex-wrap: wrap;
        align-content: flex-start;
        align-items: center;
        svg {
            font-size: 24px;
        }
    }
    .icons-mobile {
        display: none;
    }
    .phone {
        .ant-btn {
            background: none;
            margin-left: 21px;
            .bz-typography {
                font-weight: 400;
                line-height: 19px;
            }
        }
    }
    .duration {
        display: flex;
        align-items: center;
        // justify-content: center;
        &.super_link,
        &.kanban {
            justify-content: center;
            flex-direction: column-reverse;
            align-items: start;
            .nps-number {
                margin-left: 5px;
            }
        }
        .nps-number {
            margin-left: 14px;
            background: var(
                --Linear,
                linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%)
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: "ALS Hauss";
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
        }
    }
    .desc {
        margin-top: -7px;
        .bl-reservation-list-info-description {
            > .ant-breadcrumb {
                > ol {
                    > li {
                        display: block;
                        .bz-typography {
                            color: #fff !important;
                            font-weight: 500 !important;
                            font-size: 17px !important;
                            line-height: 120% !important;
                        }
                        > span {
                            &:last-child {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .bl-reservation-list-info {
        .phone {
            .ant-btn {
                display: none;
            }
        }
        .icons-mobile {
            display: flex;
            gap: 12px;
            justify-content: flex-end;
            svg {
                font-size: 24px;
            }
        }
        .desc {
            margin-top: 0;
        }
    }
}

@media screen and (max-width: 768px) {
    .bl-reservation-list-info {
        .process-text {
            font-size: 18px !important;
        }
        .duration {
            .duration-container {
                margin: auto;
            }
            .bz-typography {
                font-size: 18px !important;
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .bl-reservation-list-info {
        .icons-mobile {
            flex: 0 0 100%;
            max-width: 100%;
            justify-content: flex-start;
        }
        .desc {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}
