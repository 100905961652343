.bl-number-list-info-modal-phone {
  text-align: center;
  .ant-breadcrumb {
    justify-content: center;
    align-items: center;
    .bz-typography {
      font-weight: 700;
    }
  }
  svg {
    font-size: 5px;
  }
}

@media screen and (max-width: 768px) {
  .bl-number-list-info-modal-phone {
    .ant-breadcrumb {
      ol {
        justify-content: center;
        align-items: center;
        li {
          white-space: nowrap;
        }
      }
      .bz-typography {
        font-weight: 500;
        line-height: 19px;
      }
    }
    .phone {
      line-height: 100%;
    }
  }
}