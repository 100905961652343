@import "../../../../styles/variables";

.bl-dealer-bonuses {
    margin-bottom: 16px;
    .dealers-bonuses-text {
        @include tinyFont-medium;
    }
    .dealers-bonuses-subtext {
        @include tinyFont-medium;
        color: $text-pale-color;
    }
    .dealers-bonuses-progress-line {
        max-width: 260px;
        .ant-progress-inner {
            background-color: rgba(255, 255, 255, 0.2);
        }
        .ant-progress-bg {
            background-color: white;
            background-image: url("../../../../images/violet-line@1x.png");
            background-image: -webkit-image-set(
                url("../../../../images/violet-line@1x.webp") 1x,
                url("../../../../images/violet-line@1x.png") 1x,
                url("../../../../images/violet-line@2x.webp") 2x,
                url("../../../../images/violet-line@1x.png") 2x
            );
            background-image: image-set(
                url("../../../../images/violet-line@1x.webp") 1x,
                url("../../../../images/violet-line@1x.png") 1x,
                url("../../../../images/violet-line@2x.webp") 2x,
                url("../../../../images/violet-line@1x.png") 2x
            );
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }
    .mobile-charged-sum-wrapper {
        display: flex;
        justify-content: right;
    }
    .mobile-bonuses-sum {
        text-align: end;
    }
    .mobile-bonuses-sum-sum,
    .mobile-bonuses-charged-sum {
        white-space: nowrap;
        @include bigFont-normal;
    }
    .mobile-charged-sum-text {
        margin-top: 0;
    }

    @media screen and (min-width: $max-phone-width) {
        .mobile-bonuses-sum-text,
        .mobile-charged-sum-text {
            display: none;
        }
    }

    @media screen and (max-width: $max-tablet-width) {
        .mobile-bonuses-sum-sum,
        .mobile-bonuses-charged-sum {
            @include standardFont-bold;
        }
    }

    @media screen and (max-width: $max-phone-width) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        padding-bottom: 24px;
        margin-bottom: 24px;

        .col-mobile-status {
            justify-content: flex-end;
            display: flex;
            align-items: flex-end;
            &.left {
                justify-content: flex-start;
                align-items: flex-start;
            }
        }
        .col-no-process-bar {
            margin-bottom: 16px;
        }
        .col-mobile-progress {
            .dealers-bonuses-progress-line {
                min-width: 100%;
            }
        }
        .mobile-bonuses-sum {
            text-align: start;
        }
        .mobile-charged-sum-sum {
            line-height: 100%;
            margin-bottom: 4px;
        }
        .mobile-bonuses-sum-sum {
            line-height: 100%;
            margin-bottom: 4px;
            &.right {
                text-align: right;
            }
        }
        .mobile-bonuses-sum-text {
            @include tinyFont-medium;
            line-height: 100%;
            &.right {
                text-align: right;
            }
        }
        .mobile-charged-sum-text {
            @include tinyFont-normal;
            line-height: 100%;
            margin-top: -6px;
        }
        .mobile-bonuses-charged-sum {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        .col-mobile-progress ~ .ant-col > .mobile-bonuses-charged-sum {
            align-items: flex-end !important;
        }
        .mobile-charged-sum-wrapper {
            text-align: end;
        }
    }
}
