.bl-grid {
  .ant-segmented {
    background: transparent;
  }
  *{
    text-overflow: initial;
  }
  .ant-segmented-item {
    background: transparent!important;
    border: 1px solid transparent!important;
    svg {
      opacity: .5;
    }
  }
  .ant-segmented-item-selected {
    border: 1px solid #FFFFFF!important;
    border-radius: 8px!important;
    background: transparent!important;
    svg {
      opacity: 1;
    }
  }
  .list {
    fill: #FFFFFF;
  }
  .ant-segmented-item-label {
    width: 38px;
    height: 38px;
    padding: 0;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-segmented-thumb {
    background: rgba(255,255,255,.5);
    border-radius: 8px;
    width: 38px;
    height: 38px;
  }
  .ant-segmented-item-icon {
    height: 17px;
  }
  svg {
    font-size: 17px;
  }
}