.bl-numbers-search-form {
    text-align: center;
    @media screen and (min-width: 992px) {
        margin-top: -58px;
    }
    .btn-skeleton {
        height: 48px;
        min-width: 252px;
        border-radius: 12px;
        background: linear-gradient(
            91.53deg,
            rgba(255, 255, 255, 0.35) -9.38%,
            rgba(255, 255, 255, 0) 99.81%
        );
        @media screen and (max-width: 768px) {
            min-width: 100%;
        }
    }
    .ant-btn {
        &[type="submit"] {
            min-width: 252px;
            background-size: 100% 100%;
            background-repeat: round;
        }
        &.mode {
            min-width: 252px;
            background-size: 100% 100%;
            background-repeat: round;
            padding-top: 6px;
            img {
                position: relative;
                top: -2px;
                width: 20px;
                margin-right: 8px;
            }
        }
    }
    .actions {
        width: 100%;
        max-width: 530px;
        .ant-space-item {
            width: 100%;
            .ant-btn {
                width: 100%;
            }
        }
    }
    .field-phone_pattern {
        margin: 0;
        input {
            background: rgba(255, 255, 255, 0.2);
            border: 1px solid rgba(255, 255, 255, 0.3);
            backdrop-filter: blur(6px);
            border-radius: 8px;
            width: 46px;
            height: 64px;
            font-family: "ALS Alumna";
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0;
            color: #ffeeb4;
            padding-top: 5px;
            &:nth-child(3) {
                margin-right: 21px;
            }
        }
    }
    .bz-input-group-numbers {
        gap: 15px;
    }
    .helper-text {
        text-align: left;
        font-weight: 400;
        line-height: 140%;
    }
}

@media screen and (max-width: 768px) {
    .bl-numbers-search-form {
        .bz-input-group-numbers {
            gap: 8px;
            justify-content: space-between;
        }
        .smart-container {
            max-width: 553px;
        }
    }
}

@media screen and (max-width: 576px) {
    .bl-numbers-search-form {
        .ant-space {
            width: 100%;
        }
        .ant-btn {
            &[type="submit"] {
                min-width: 100%;
            }
        }
        .field-phone_pattern {
            input {
                width: 30px;
                height: 52px;
                padding-top: 3px;
            }
        }
        .bz-input-group-numbers {
            gap: 4px;
        }
    }
}

@media screen and (max-width: 425px) {
    .bl-numbers-search-form {
        .field-phone_pattern {
            input {
                width: 30px;
                &:nth-child(3) {
                    margin-right: 7px;
                }
            }
        }
    }
}

@media screen and (max-width: 374px) {
    .bl-numbers-search-form {
        .field-phone_pattern {
            input {
                width: 28px;
            }
        }
        .bz-input-group-numbers {
            gap: 3px;
        }
    }
}

@media screen and (max-width: 350px) {
    .bl-numbers-search-form {
        .field-phone_pattern {
            input {
                width: 26px;
                font-size: 26px;
            }
            input::-webkit-input-placeholder {
                font-size: 26px;
            }
            input:-moz-placeholder {
                font-size: 26px;
            }
            input::-moz-placeholder {
                font-size: 26px;
            }
            input:-ms-input-placeholder {
                font-size: 26px;
            }
            input::-ms-input-placeholder {
                font-size: 26px;
            }
        }
        .bz-input-group-numbers {
            gap: 2px;
        }
    }
}

@media screen and (max-width: 320px) {
    .bl-numbers-search-form {
        .field-phone_pattern {
            input {
                width: 24px;
                font-size: 24px;
                &:nth-child(3) {
                    margin-right: 3px;
                }
            }
        }
    }
}

@media screen and (max-width: 290px) {
    .bl-numbers-search-form {
        .field-phone_pattern {
            input {
                width: 22px;
                font-size: 22px;
            }
        }
    }
}
