.bl-clear-my-data {
    .bl-logo {
        margin-bottom: 80px;
    }
    .bl-title {
        margin-bottom: 12px;
    }
    .subtitle {
        font-family: "ALS Hauss";
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 22px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 16px;
        @media screen and (max-width: 768px) {
            font-family: "ALS Hauss";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            color: #ffffff;
            white-space: pre;
            margin-bottom: 0;
        }
    }
    form {
        max-width: 624px;
        margin: auto;
        .ant-form-item-has-error
            :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
            .ant-input {
                border-color: #ed0101 !important;
            }
        }
        textarea {
            min-height: 78px;
        }
        .ant-input {
            color: #fff !important;
            &.ant-input-status-error {
                border-color: #ed0101 !important;
            }
        }
        .ant-checkbox-group {
            margin-bottom: 80px;
            @media screen and (max-width: 768px) {
                margin-bottom: 0;
            }
            .bz-typography {
                color: #fff;
                font-family: "ALS Hauss";
                font-style: normal;
                font-weight: 400;
                font-size: 14px !important;
                line-height: 140%;
            }
        }
        .actions {
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 0;
            .ant-form-item {
                margin: 0;
            }
            .ant-btn {
                width: 340px;
                margin: auto;
                border-radius: 8px;
                font-family: "ALS Hauss";
                font-style: normal;
                font-weight: 700;
                font-size: 17px;
                line-height: 100%;
                background-size: 100% 100%;
                &:disabled {
                    color: rgba(207, 200, 219, 0.5);
                    background: rgba(255, 255, 255, 0.1);
                    border: 1px solid rgba(207, 200, 219, 0.5);
                    line-height: normal;
                }

                &.cancel {
                    background: transparent !important;
                    width: auto;
                    border: 0;
                    color: #fff;
                }
            }
        }
    }
}
