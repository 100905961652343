.bl-sim-modal {
  .subtitle {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .bl-sim-modal {
    .subtitle {
      text-align: left;
    }
  }
}