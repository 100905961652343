.bl-personal-manager-info {
  .title, .text-helper, .phone {
    font-weight: 500;
  }
  .title {
    margin-top: 26px;
  }
  img {
    width: 136px;
  }
  .ant-space {
    align-items: flex-start;
  }
}

@media screen and (max-width: 768px) {
  .bl-personal-manager-info {
    .phone {
      font-weight: 400;
    }
    .title {
      margin-top: 14px;
    }
    img {
      width: 100px;
    }
  }
}