.bl-output-number-bonuses {
    width: 374px;
    height: 108px;
    .icon-vector > svg {
        margin-left: 115px;
    }
}
@media only screen and (max-width: 1184px) {
    .bl-output-number-bonuses {
        width: auto;
        .icon-vector > svg {
            margin-left: 115px;
        }
    }
}
@media only screen and (max-width: 770px) {
    .bl-output-number-bonuses {
        .icon-vector > svg {
            margin-left: 123px;
        }
    }
}
