.bl-invite-a-user-form {
    .ant-btn:not(.ant-btn-text) {
        width: 100%;
    }
    .clip {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .bl-invite-a-user-form {
        .cancel {
            margin-top: 16px;
        }
    }
}
