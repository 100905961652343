@import "../../../../../../styles/variables";

.modal-tariff-container-wrapper {
    .modal-tariff-container {
        .modal-title-content,
        p {
            color: $text-gray-color !important;
            text-align: left;
        }
        .moda-value-content {
            text-align: left;
        }
    }
    .modal-carousel-container {
        .info-tariff-modal-title {
            @include title-bigFont-normal;
            line-height: 100.5%;
            margin-bottom: 30px;
            text-align: center;
            color: $text-secondary-color;
        }
        .button-activation {
            text-align: center;
        }
        .ant-modal-title {
            @include title-bigFont-normal;
            font-size: 44px;
        }
        .carousel-modal-content {
            text-align: initial;
        }
        .slick-arrow {
            color: $text-primary-color;
            @include title-tinyFont-normal;
            min-width: 162px;
            width: min-content;
            height: 57px;
            line-height: 120%;
            &::before {
                display: none;
            }
        }
        .slick-arrow.slick-prev {
            left: calc(100% / 2 - 600px);
        }
        .slick-arrow.slick-next {
            right: calc(100% / 2 - 600px);
        }

        .ant-modal-close-x {
            font-size: 28px;
            color: $text-primary-color;
        }

        p,
        ul {
            @include tinyFont-normal;
            color: $text-secondary-color !important;
        }
        .ul-li-dot {
            list-style: disc;
        }

        .ul-star {
            list-style-image: url("../../../../../../images/star.svg");
        }

        .ant-modal-close {
            position: fixed;
            top: 30px;
            right: 30px;
        }
    }
}

@media screen and (max-width: 576px) {
    .modal-tariff-container {
        .ant-modal-close {
            top: 5px !important;
            right: 5px !important;
        }
    }
}
