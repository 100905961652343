.bl-payments-number-form {
    .recommended-sum {
        text-align: start;
        margin-bottom: 20px;
        span {
            font-family: "ALS Alumna";
            font-size: 24px;
            font-weight: 400;
            border-bottom: 1px solid;
            color: #642878;
            margin-left: 5px;
            margin-right: 5px;
            cursor: pointer;
            &:hover {
                font-weight: 700;
                font-size: 30px;
            }
        }
    }
    .input-summ-bonuses {
        .focused {
            .ant-space-horizontal {
                gap: 2px !important;
            }
        }
        .ant-col-14 {
            display: flex;
            padding: 18px 0;
        }

        .bz-input-block {
            .ant-input-status-success {
                padding-bottom: 11px;
                padding-top: 21px;
                + .title {
                    font-size: 12px;
                    height: 12px;
                    margin-top: 6px;

                    .ant-space-horizontal {
                        gap: 2px !important;
                    }
                }
            }
        }
    }

    .field-replenich-bonuses {
        text-align: start;
    }
    .field-replenich {
        .ant-form-item-explain {
            display: none;
        }
    }

    .text-declination {
        display: flex;
    }
}

@media screen and (max-width: 768px) {
    .bl-payments-number-form {
        .recommended-sum {
            line-height: 22px;
            span:hover {
                font-size: 24px;
                font-weight: 400;
            }
            span{
                margin-left: 0;
            }
        }
    }
}
@media screen and (max-width: 574px) {
    .bl-payments-number-form {
        .input-summ-bonuses {
            .ant-col-14 {
                padding: 18px 10px;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .bl-payments-number-form {
        .input-summ-bonuses {
            .ant-space-horizontal {
                gap: 30px !important;
            }
            .ant-col-14 {
                padding: 18px 10px;
            }
        }
    }
}
@media screen and (max-width: 460px) {
    .bl-payments-number-form {
        .input-summ-bonuses {
            .ant-space-horizontal {
                gap: 20px !important;
            }
        }
    }
}
@media screen and (max-width: 425px) {
    .bl-payments-number-form {
        .input-summ-bonuses {
            .ant-space-horizontal {
                gap: 15px !important;
            }
        }
    }
}
@media screen and (max-width: 400px) {
    .bl-payments-number-form {
        .input-summ-bonuses {
            .ant-space-horizontal {
                gap: 25px !important;
            }
        }
    }
}
@media screen and (max-width: 340px) {
    .bl-payments-number-form {
        .input-summ-bonuses {
            .ant-space-horizontal {
                gap: 10px !important;
            }
        }
    }
}
