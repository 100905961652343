.bl-field-passport-upload {
    .header {
        .title {
            font-weight: 400;
            line-height: 22px;
        }
        .subtitle {
            font-weight: 400;
            line-height: 19px;
        }
    }
    .subheader {
        font-weight: 700;
        line-height: 120%;
        margin-bottom: -9px;
    }
}

@media screen and (max-width: 768px) {
    .bl-field-passport-upload {
        .header {
            .title {
                line-height: 120%;
            }
            .subtitle {
                line-height: 140%;
            }
        }
        .content .ant-space-horizontal > .ant-space-item {
            width: 50% !important;
        }
    }
}

@media screen and (max-width: 405px) {
    .bl-field-passport-upload {
        .content > .ant-space {
            flex-direction: row;
        }
    }
}
