.bl-activation-list-items-item-status {
  text-align: right;
  .ant-space {
    align-items: flex-end;
    justify-content: center;
  }
  .status-text {
    align-items: center;
    font-weight: 500;
    line-height: 120%;
    svg {
      position: relative;
      top: 2px;
      font-size: 20px;
    }
  }
  .status-date {
    font-weight: 400;
    line-height: 16px;
  }
}