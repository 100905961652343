.list-container {
  &.grouped {
    .bl-numbers-list {
      &.fake {
        .ant-table-tbody {
          display: none;
        }
        .ant-table-thead {
          >tr {
            >th {
              border-bottom: 1px solid #fff!important;
            }
          }
        }
      }
    }
  }
}