.modal-removement-number-list {
    .removement-modal--description {
        line-height: 19px;
        color: #000000;
    }
    .ant-modal-content {
        .bz-modal-header {
            .bz-modal-title {
                font-family: "ALS Hauss";
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 120%;
                text-align: center;
                color: #000000;
            }
        }
    }
    .ant-modal-content {
        .ant-modal-footer {
            .removement-modal--cancel-btn {
                font-family: "ALS Hauss";
                font-style: normal;
                font-weight: 500;
                font-size: 17px;
                line-height: 120%;
                text-align: center;
                color: #000000;
            }
            .removement-modal--delite-btn {
                font-weight: 500;
                font-size: 17px;
            }
        }
    }
}
