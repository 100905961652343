.bl-operaion-history-form{
    .title-and-datapicker {
        .payouts-field-range-picker-container,
        .payments-field-range-picker-container {
            min-width: 323px;

            .field-range-picker {
                position: relative;
            }
            &.focused {
                .ant-form-item-label {
                    padding: 0;
                }
                .ant-picker-input {
                    margin-top: 15px;
                }
                .ant-picker-range-separator {
                    display: block;
                }
            }
            .ant-form-item-label {
                position: absolute;
                left: 16px;
                padding-top: 7px;
                label {
                    font-size: 12px;
                    line-height: 16px;
                    color: rgba(255, 255, 255, 0.5);
                }
            }
            .ant-picker-suffix {
                position: absolute;
                right: 16px;
            }
            .ant-picker {
                width: 100%;
                .ant-picker-input {
                    max-width: 80px;
                }
            }
            .ant-picker-range-separator {
                position: relative;
                top: 6px;
                left: -2px;
                display: none;
            }
        }
        .data-picker-operations-history {
            input {
                pointer-events: none;
            }
        }
    }
    text-align: center;
    width: 100%;
    min-width: 332px;
    // .ant-btn {
    //     width: 252px;
    // }
    .ant-space.ant-space-vertical {
        min-width: 100%;
    }
    // .bl-login-form-title {
    //     width: 206px;
    //     margin: 0 auto;
    //     margin-bottom: 32px;
    //     font-family: "ALS Hauss";
    //     font-style: normal;
    //     font-weight: 500;
    //     font-size: 16px;
    //     color: rgba(255, 255, 255, 0.5);
    //     .ant-col {
    //         cursor: pointer;
    //     }
    // }
}

@media screen and (max-width: 768px) {
    .bl-operaion-history-form{
        min-width: 0;
    }
    
}
