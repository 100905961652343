.body-subtitle-bonuses-success {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 425px) {
  .body-subtitle-bonuses-success{
    .bz-typography{
      font-size: 16px !important;
    }
  }
}
