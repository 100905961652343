.bl-numbers-list-info-phone {
    .phone {
        font-weight: 400;
        line-height: 90%;
        font-family: "ALS Sansita", serif;
    }

    svg {
        font-size: 16px;
        position: relative;
        top: 2px;
    }

    .dealer-id {
        font-weight: 500;
        line-height: 100%;
    }
}

.kanban {
    .bl-numbers-list-info-phone {
        svg {
            top: -1px;
        }
    }
}

.bl-numbers-in-booking {
    .bl-numbers-list-info-phone {
        .phone {
            opacity: 0.5;
        }
    }
}
