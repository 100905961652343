.field-fake-select {
  .ant-select  {
    width: 100%;
  }
  &.disabled {
    label {
      background: #ccc;
      opacity: .5;
    }
  }
}
.hidden-fake-input {
  position: absolute;
  z-index: -2;
  width: 0;
  height: 0;
  opacity: 0;
}
.dropdown-fake-select {
  min-width: fit-content !important;
  width: auto !important;
  text-align: left;
  z-index: 3;
  position: relative!important;
  top: 51px!important;
}