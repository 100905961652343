.bl-news-readall-modal {
    width: 343px !important;
    top: -90px !important;
    right: -390px;
    .ant-modal-content {
        border: 1px solid rgba(255, 255, 255, 0.5);
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(12px);
        padding: 40px 16px 16px 24px;
        .ant-modal-close {
            svg {
                color: #fff;
            }
        }
        .bl-read-all-modal {
            .text {
                font-family: "ALS Hauss";
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                margin-bottom: 24px;
                white-space: pre-line;
            }
            .ant-space {
                width: 100%;
                .ant-btn {
                    width: 100%;
                }
                .ant-btn-default {
                    border: 1px solid rgba(255, 255, 255, 0.5);
                }
            }
        }
    }
    @media screen and (max-width: 431px) {
        top: -260px !important;
        right: 0;
    }
    @media screen and (max-width: 390px) {
        top: -220px !important;
    }
    @media screen and (max-width: 375px) {
        top: -130px !important;
    }
    @media screen and (max-width: 370px) {
        top: -160px !important;
    }
}
