.bl-sl-top {
    background: url("../../../../../images/colored1.png") no-repeat bottom center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1338px;
    background-size: cover;
    padding-top: 200px;

    @media screen and (max-width: 992px) {
        padding-top: 256px;
        height: 1000px;
    }

    .inner {
        max-width: 1184px;
        padding: 0 16px;
        max-width: 1184px;
        margin: auto;
        .ant-space {
            width: 100%;
            .ant-space-item {
                width: 100%;
                .bz-title {
                    font-weight: 400;
                    font-size: 72px;
                    line-height: 72px;
                    text-transform: uppercase;

                    * {
                        position: relative;
                    }

                    .first {
                        color: #fef0b7 !important;
                    }
                    .second {
                        left: -224px;
                    }
                    .third {
                        right: -84px;
                    }
                    @media screen and (max-width: 1200px) {
                        font-size: 52px;
                        margin-top: 13px;
                    }

                    @media screen and (max-width: 992px) {
                        font-weight: 400;
                        font-size: 26px;
                        line-height: 26px;
                        .first {
                            left: -30px;
                        }
                        .second {
                            left: -83px;
                        }
                        .third {
                            right: -12px;
                        }
                    }
                    @media screen and (max-width: 320px) {
                        font-size: 20px;
                        .first {
                            left: -34px;
                        }
                        .second {
                            left: -78px;
                        }
                        .third {
                            right: -5px;
                        }
                    }
                    @media screen and (max-width: 280px) {
                        .second {
                            left: -54px;
                        }
                    }
                }
                .qr {
                    display: flex;
                    justify-content: center;
                    img {
                        padding: 67px;
                        background: #ffffff;
                        border-radius: 35px;
                        max-width: 384px;
                        @media screen and (max-width: 992px) {
                            max-width: 308px;
                            padding: 59px;
                        }
                        @media screen and (max-width: 320px) {
                            max-width: 280px;
                        }
                        @media screen and (max-width: 280px) {
                            max-width: 240px;
                            padding: 40px;
                        }
                    }
                }
                .actions {
                    max-width: 940px;
                    margin: auto;
                    .ant-col {
                        display: flex;
                        justify-content: center;
                        .ant-btn {
                            background: rgba(255, 255, 255, 0.05);
                            border: 1px solid #ffffff;
                            backdrop-filter: blur(12.5px);
                            border-radius: 8px;
                            width: 100%;
                            max-width: 300px;
                            display: flex;
                            align-content: center;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: center;
                            align-items: center;
                            gap: 12px;
                            font-weight: 700;
                            font-size: 17px;
                            line-height: 120%;
                            @media screen and (max-width: 280px) {
                                font-size: 13px;
                            }
                        }
                        &:nth-child(4) {
                            path {
                                stroke: rgb(255, 255, 255);
                            }
                        }
                        @media screen and (min-width: 992px) {
                            &:nth-child(1),
                            &:nth-child(3) {
                                justify-content: end;
                            }
                            &:nth-child(2),
                            &:nth-child(4) {
                                justify-content: start;
                            }
                        }
                    }
                }
            }
        }
    }
}
