.bl-filter-button {
  .ant-btn {
    &.btn-filter {
      padding: 0;
      background: transparent;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      gap: 8px;
      svg {
        font-size: 20px;
      }
      .bz-typography {
        line-height: 100%;
        font-weight: 500;
      }
      .ant-badge {
        margin-left: 4px;
        .ant-badge-count {
          box-shadow: none;
          background: rgba(255, 255, 255, 0.2);
          width: 24px;
          height: 24px;
          line-height: 24px;
          border-radius: 16px;
          padding: 0;
          .ant-scroll-number-only{
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .current {
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 340px) {
  .bl-filter-button {
    .text {
      display: none;
    }
  }
}