.bl-replenishment-bonuses-form {
    .input-summ-bonuses {
        .focused {
            .ant-space-horizontal {
                gap: 2px !important;
            }
        }
        .ant-col-14 {
            display: flex;
            padding: 18px 0;
        }

        .bz-input-block {
            .ant-input-status-success {
                padding-bottom: 11px;
                padding-top: 21px;
                + .title {
                    font-size: 12px;
                    height: 12px;
                    margin-top: 6px;

                    .ant-space-horizontal {
                        gap: 2px !important;
                    }
                }
            }
        }
    }

    .field-replenich-bonuses {
        text-align: start;
    }
    .field-replenich {
        .ant-form-item-explain {
            display: none;
        }
    }
    .ant-radio-button-wrapper:not(:first-child):before {
        width: 0;
        height: 0;
        padding: 0;
    }

    .ant-radio-button-wrapper:focus-within {
        box-shadow: none;
    }
    .bl-radio-button-group {
        .ant-form-item-control-input-content {
            overflow: hidden;
            height: 32px;
            .ant-radio-group {
                overflow-x: auto;
                display: flex;
                flex-direction: row;
                padding-bottom: 17px;
                overflow-y: hidden;
                .ant-radio-button-wrapper {
                    border-radius: 32px;
                    width: 80px;
                    margin-right: 5px;
                    font-weight: 700;
                    font-size: 17px;
                    color: #000000;
                    padding: 0 10px;
                    text-align: center;
                    background: url(~@bezlimit/bezlimit-ui/dist/images/gold-opacity.png) center
                            center no-repeat,
                        url(~@bezlimit/bezlimit-ui/dist/images/gold-texture.png) center center
                            no-repeat;
                    background-size: 100%;
                    background-blend-mode: color, normal;
                    transition: none;
                    white-space: nowrap;
                }
                .ant-radio-button-wrapper-disabled {
                    background: linear-gradient(0deg, #cfc8db, #cfc8db);
                    color: #ffffff;
                    white-space: nowrap;
                }
                .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
                    border-color: #642878;
                    background: #642878;
                    color: #ffffff;
                }
            }
        }
    }
    .text-declination {
        display: flex;
    }
}
@media screen and (max-width: 574px) {
    .bl-replenishment-bonuses-form {
        .input-summ-bonuses {
            .ant-col-14 {
                padding: 18px 10px;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .bl-replenishment-bonuses-form {
        .input-summ-bonuses {
            .ant-space-horizontal {
                gap: 30px !important;
            }
            .ant-col-14 {
                padding: 18px 10px;
            }
        }
    }
}
@media screen and (max-width: 460px) {
    .bl-replenishment-bonuses-form {
        .input-summ-bonuses {
            .ant-space-horizontal {
                gap: 20px !important;
            }
        }
    }
}
@media screen and (max-width: 425px) {
    .bl-replenishment-bonuses-form {
        .input-summ-bonuses {
            .ant-space-horizontal {
                gap: 15px !important;
            }
        }
    }
}
@media screen and (max-width: 400px) {
    .bl-replenishment-bonuses-form {
        .input-summ-bonuses {
            .ant-space-horizontal {
                gap: 25px !important;
            }
        }
    }
}
@media screen and (max-width: 340px) {
    .bl-replenishment-bonuses-form {
        .input-summ-bonuses {
            .ant-space-horizontal {
                gap: 10px !important;
            }
        }
    }
}
