.bl-edit-password-modal {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .bl-edit-password-modal {
    text-align: left;
    h2 {
      font-weight: 700;
    }
  }
}