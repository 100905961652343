.bl-new-delivery {
    margin-top: 70px;
    padding: 0 200px;
    .title-text,
    .sub-title-text {
        text-align: center;
    }
    .ant-form-item-has-error {
        label {
            font-size: 12px;
            height: 12px;
            margin-top: 6px;
        }
    }
    .ant-form-item-has-error {
    }
}
