.bl-login {
  text-align: center;
  padding: 0!important;
  .ant-space.ant-space-vertical {
    >:nth-child(2){
      width: 100%;
    }
  }
  .bz-block {
    min-width: 608px;
    padding: 56px 89px 40px;
    margin-top: 42px;
  }
}

@media screen and (max-width: 768px) {
  .bl-login {
    .bz-block {
      min-width: 100%;
      margin: 0 0 49px;
      padding: 40px 16px;
    }
  }
}
@media screen and (max-width: 320px) {
  .bl-login {
    .bz-block {
      margin: 0 0 29px;
    }
  }
}
