.bl-number-filter-mode {
    text-align: center;
    .ant-btn {
        min-width: 364px !important;
        background-size: 100% 100%;
        background-repeat: round;
        &.ant-btn-default {
            border: 1px solid #e5e5e5;
            border-radius: 12px;
            color: rgb(0, 0, 0);
        }
    }
    &.modal-loading {
        .ant-space {
            width: 100%;
            * {
                width: 100%;
            }
            .ant-skeleton-button {
                width: 100%;
                background: linear-gradient(
                    90.64deg,
                    rgba(255, 255, 255, 0.2) -9.46%,
                    rgba(255, 255, 255, 0.02) 96.47%
                );
                border-radius: 43px;
            }
            @media screen and (max-width: 576px) {
                margin-top: 12px;
            }
        }
    }
}

.modalpage {
    .ant-modal-content {
        background: #3d1b3e;
        border: 1px solid rgba(255, 255, 255, 0.25);
    }
    .ant-modal-close {
        display: none;
    }
    .ant-skeleton-button.close {
        width: 20px;
        height: 20px;
        background: linear-gradient(
            100.66deg,
            rgba(217, 217, 217, 0.2) -3.7%,
            rgba(217, 217, 217, 0) 117.36%,
            rgba(217, 217, 217, 0.05) 117.36%
        );
        border-radius: 4px;
        min-width: 20px;
        position: absolute;
        right: 24px;
        top: 24px;
    }
}

@media screen and (max-width: 768px) {
    .bl-number-filter-mode {
        margin-top: 16px;
        .ant-btn {
            min-width: 100% !important;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 140%;
            border-radius: 43px;
            &.ant-btn-default {
                color: #1f1f1f;
                border-radius: 43px;
            }
        }
    }
}
