.sidebar {
    z-index: 4;
    height: 100%;
    min-width: 280px;
    width: 100%;
    background: url("./sidebar_new.png") no-repeat left top;
    background-size: 100% 100%;
    color: black;
    transition: left 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 30px 0;
    font-family: ALS Alumna, sans-serif;
    max-height: 100vh;
    overflow-y: auto;
    &.sidebar.sidebar-close {
        left: calc(0px - max(350px, 100%));
    }
    &.sidebar.sidebar-open {
        left: 0;
    }
    .sidebar-close {
        color: var(--color-main);
        cursor: pointer;
        min-height: 23px;
        max-height: 23px;
        color: #642878;
    }
    .sidebar-logo {
        min-height: 61px;
        max-height: 61px;
    }
    .ant-menu {
        background: transparent;
    }
}

.ant-layout-sider-children {
    position: relative;
}

.ant-layout-sider-collapsed.ant-layout-sider {
  // left: -350px;
}

.ant-layout-sider-collapsed {
    .sidebar {
        &:before {
            display: none;
        }
    }
}
.sidebar-drop {
    z-index: 3;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(60px);
}
