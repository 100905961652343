.bl-reservation-list {
    .reservation-shimmer {
        .list-reservation,
        .kanban-reservation {
            .ant-skeleton {
                width: 100%;
                .ant-skeleton-button {
                    width: 100%;
                    height: 38px;
                }
                .btn-skeleton-text {
                    height: 18px;
                }
                .btn-skeleton-process {
                    height: 23px;
                }
            }
        }
    }
}
