@import "../../../../../../styles/variables";

.bl-tariff-connection-info {
    animation: fade-in 0.4s ease;
    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    & > .ant-row {
        justify-content: center;
    }
    &-Common {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: center;
    }
    &-Social {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .social-icons-row {
        align-items: center;
        background-clip: content-box;
        border-bottom: 1px solid #fff;
        margin-bottom: 6px;
        padding-bottom: 8px;
        padding-top: 0;
    }
    .coll-info-common {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: center;
    }
    .bl-tariff-connection-info-Social {
        .social-text {
            margin-bottom: 0px;
            text-align: left;
            font-family: ALS Hauss;
            font-size: 17px;
            line-height: 120%;
            font-weight: 500;
        }
    }
    .ant-col {
        display: flex;
        justify-content: center;
        text-align: center;
        @media screen and (max-width: 431px) {
            align-items: center;
        }
    }
}
@media screen and (max-width: 767px) {
    .bl-tariff-connection-info {
        .bl-tariff-connection-info-Social {
            .social-icons-col {
                display: flex !important;
                justify-content: flex-end !important;
            }
            .social-icons {
                width: 80px;
                .social-icon {
                    margin-right: 4px;
                    width: auto;
                    height: 14px;
                }
                .social-icon.bz {
                    height: 18px;
                }
            }
            .social-text {
                margin-bottom: 0px;
                font-family: ALS Hauss;
                font-size: 14px;
                line-height: 19px;
                font-weight: 500;
                text-align: left;
            }
        }
        .bl-tariff-connection-info-Common {
            justify-content: center;
        }
    }
}

@media screen and (max-width: $max-phone-width) {
    .bl-tariff-connection-info {
        &-Common {
            justify-content: center;
        }
    }
}
