.bl-nps-reservation {
}

.bl-nps-reservation-drawer {
    .ant-modal-content {
        border-radius: 12px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(10px);
        .empty-result {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 18px;
            img {
                height: 182px;
            }
            .empty-title {
                color: var(--White, #fff);
                text-align: center;
                font-family: "ALS Hauss";
                font-size: 30px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                white-space: pre;
            }
            .empty-text {
                color: var(--White, #fff);
                text-align: center;
                font-family: "ALS Hauss";
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                white-space: pre;
            }
            .empty-actions {
                text-align: center;
                .ant-btn {
                    width: 343px;
                    height: 48px;
                    background-size: 100% 100%;
                    color: var(--Gray-1, #000);
                    text-align: center;
                    leading-trim: both;
                    text-edge: cap;
                    font-family: "ALS Hauss";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    &-default {
                        color: #fff;
                        text-align: center;
                        font-family: "ALS Hauss";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                        background: transparent;
                    }
                }
            }
        }
        .ant-modal-body {
            padding-top: 45px;
            text-align: center;
            .inner-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 24px;
                .bottom {
                    width: 100%;
                    .text {
                        white-space: pre;
                        color: var(--White, #fff);
                        text-align: center;
                        font-family: "ALS Hauss";
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 28px;
                    }
                    form {
                        margin-top: 24px;
                        .ant-form-item {
                            margin-bottom: 32px;
                        }
                        .actions {
                            display: flex;
                            flex-direction: column;
                            gap: 0;
                            justify-content: center;
                            align-items: center;
                            .ant-btn {
                                max-width: 322px;
                                width: 100%;
                                height: 48px;
                                color: var(--Gray-1, #000);
                                text-align: center;
                                leading-trim: both;
                                text-edge: cap;
                                font-family: "ALS Hauss";
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 100%;
                                background-size: 100% 100%;
                                border-radius: 8px;
                                &-default {
                                    color: #fff;
                                    text-align: center;
                                    font-family: "ALS Hauss";
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: 100%;
                                    background: transparent;
                                }
                            }
                        }
                        label {
                            color: rgba(255, 255, 255, 0.4);
                            leading-trim: both;
                            text-edge: cap;
                            font-family: "ALS Hauss";
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: row;
                            .ant-checkbox {
                                top: 1px;
                                &-checked {
                                    .ant-checkbox-inner:after {
                                        background: linear-gradient(
                                            141.08deg,
                                            #efce73 25.79%,
                                            #fef0b7 63.66%,
                                            #cea86f 97.07%
                                        );
                                        width: 11px;
                                        height: 11px;
                                        border-radius: 50%;
                                        top: 0;
                                        bottom: 0;
                                        right: 0;
                                        left: 0;
                                        margin: auto;
                                        transform: none;
                                        box-shadow: none;
                                        border: 0;
                                        transition: none;
                                    }
                                }
                                &-inner {
                                    width: 21px;
                                    height: 21px;
                                    border-radius: 50%;
                                    background: transparent;
                                    border: 1px solid #e5e5e5 !important;
                                    transition: none;
                                }
                            }
                        }
                    }
                }
                .top {
                    .text {
                        color: var(--White, #fff);
                        text-align: center;
                        font-family: "ALS Hauss";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 28px;
                        &.text2 {
                            font-size: 17px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 28px;
                        }
                    }
                    .title {
                        background: var(
                            --Linear,
                            linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%)
                        );
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-family: "ALS Hauss";
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 550;
                        line-height: 28px;
                    }
                }
            }
        }
        .ant-modal-close {
            top: 4px;
        }
        .bz-modal-header {
            position: absolute;
            right: 50px;
            top: 10px;
            .bz-modal-title {
                .ant-btn {
                    padding: 0;
                    background: transparent;
                    position: relative;
                    height: 32px;
                    width: 32px;
                    svg {
                        width: 32px;
                        height: 32px;
                    }
                    .hint {
                        border-radius: 50%;
                        animation: pulse 3s infinite;
                        position: absolute;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        margin: auto;
                        width: 32px;
                        height: 32px;
                        @keyframes pulse {
                            0% {
                                -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
                                box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
                            }
                            70% {
                                -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
                                box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
                            }
                            100% {
                                -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
                                box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
                            }
                        }
                    }
                }
            }
        }
    }
    .ant-drawer-mask {
        background: transparent;
    }
    .ant-drawer-header {
        padding: 55px 46px;
        border: 0;
        &-title {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-start;
            align-items: center;
            gap: 25px;
            .ant-drawer-close {
                padding: 0;
                margin: 0;
            }
            .ant-drawer-title {
                width: 32px;
                flex: none;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .ant-btn {
                    padding: 0;
                    background: transparent;
                    position: relative;
                    height: 32px;
                    width: 32px;
                    svg {
                        width: 32px;
                        height: 32px;
                    }
                    .hint {
                        border-radius: 50%;
                        animation: pulse 3s infinite;
                        position: absolute;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        margin: auto;
                        width: 32px;
                        height: 32px;
                        @keyframes pulse {
                            0% {
                                -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
                                box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
                            }
                            70% {
                                -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
                                box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
                            }
                            100% {
                                -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
                                box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
                            }
                        }
                    }
                }
            }
        }
    }
    .ant-drawer-content {
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(50px);
        .ant-drawer-body {
            display: flex;
            flex-direction: column;
            gap: 24px;
            margin-bottom: 142px;
            justify-content: center;
            .empty-result {
                display: flex;
                flex-direction: column;
                gap: 32px;
                img {
                    height: 182px;
                    margin: auto;
                }
                .empty-title {
                    color: var(--White, #fff) !important;
                    text-align: center;
                    leading-trim: both;
                    text-edge: cap;
                    font-family: "ALS Hauss";
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    white-space: pre;
                }
                .empty-text {
                    color: var(--White, #fff);
                    text-align: center;
                    font-family: "ALS Hauss";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-top: -16px;
                    white-space: pre;
                }
                .empty-actions {
                    text-align: center;
                    .ant-btn {
                        width: 343px;
                        height: 48px;
                        background-size: 100% 100%;
                        color: var(--Gray-1, #000);
                        text-align: center;
                        leading-trim: both;
                        text-edge: cap;
                        font-family: "ALS Hauss";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        &-default {
                            color: #fff;
                            text-align: center;
                            font-family: "ALS Hauss";
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 100%;
                            background: transparent;
                        }
                    }
                }
            }
            .top {
                display: flex;
                flex-direction: column;
                gap: 16px;
            }
            .bottom {
                display: flex;
                flex-direction: column;
                gap: 32px;
            }
            .text {
                color: var(--White, #fff);
                text-align: center;
                font-family: "ALS Hauss";
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                b {
                    font-weight: 500;
                    line-height: normal;
                    display: contents;
                }
            }
            .title {
                text-align: center;
                font-family: "ALS Hauss";
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                background: var(
                    --Linear,
                    linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%)
                );
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            form {
                .ant-form-item {
                    margin-bottom: 32px;
                }
                .actions {
                    display: flex;
                    flex-direction: column;
                    gap: 0;
                    justify-content: center;
                    align-items: center;
                    .ant-btn {
                        width: 343px;
                        height: 48px;
                        color: var(--Gray-1, #000);
                        text-align: center;
                        leading-trim: both;
                        text-edge: cap;
                        font-family: "ALS Hauss";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        background-size: 100% 100%;
                        &-default {
                            color: #fff;
                            text-align: center;
                            font-family: "ALS Hauss";
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 100%;
                            background: transparent;
                        }
                    }
                }
                label {
                    color: var(--65, rgba(255, 255, 255, 0.65));
                    leading-trim: both;
                    text-edge: cap;
                    font-family: "ALS Hauss";
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: row;
                    .ant-checkbox {
                        top: 1px;
                        &-checked {
                            .ant-checkbox-inner:after {
                                background: linear-gradient(
                                    141.08deg,
                                    #efce73 25.79%,
                                    #fef0b7 63.66%,
                                    #cea86f 97.07%
                                );
                                width: 11px;
                                height: 11px;
                                border-radius: 50%;
                                top: 0;
                                bottom: 0;
                                right: 0;
                                left: 0;
                                margin: auto;
                                transform: none;
                                box-shadow: none;
                                border: 0;
                                transition: none;
                            }
                        }
                        &-inner {
                            width: 21px;
                            height: 21px;
                            border-radius: 50%;
                            background: transparent;
                            border: 1px solid #e5e5e5 !important;
                            transition: none;
                        }
                    }
                }
            }
        }
    }
}
