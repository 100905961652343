.bl-block4 {
    border-radius: 42px 42px 0px 0px;
    box-shadow: 0px 0px 1px 0px #21045c;
    padding: 42px 42px 37px 42px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
    backdrop-filter: blur(21px);
    position: relative;
    @media screen and (max-width: 768px) {
        backdrop-filter: blur(0px);
        background: transparent;
        border-radius: 0;
        padding: 0 16px;
        box-shadow: none;
        max-width: 375px;
        margin: -70px auto 0;
    }
    @media screen and (max-width: 430px) {
        max-width: 100%;
    }
    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: url("../../../../../images/npsbg4.png") no-repeat;
        background-size: 100% auto;
        top: 0;
        left: 0;
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
    .title {
        font-family: "ALS Hauss";
        font-size: 28px;
        font-style: normal;
        font-weight: 550;
        line-height: normal;
        background: var(
            --Linear,
            linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 6px;
    }
    .text {
        color: var(--White, #fff);
        font-family: "ALS Hauss";
        font-size: 23px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @media screen and (max-width: 768px) {
            font-family: "ALS Hauss";
            font-size: 15px;
            font-style: normal;
            font-weight: 550;
            line-height: 20px;
            background: var(
                --Linear,
                linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%)
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
    .subtext {
        color: var(--White, #fff);
        font-family: "ALS Hauss";
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        opacity: 0.5;
        margin-top: 10px;
        @media screen and (max-width: 768px) {
            font-size: 14px;
            line-height: 20px;
        }
    }
}
