.acnivation-shimmer {
    border: 1px solid hsla(0, 0%, 100%, 0.5);
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    .ant-skeleton {
        width: 100%;
        .ant-skeleton-button {
            width: 100%;
            height: 38px;
        }
        .btn-skeleton-text {
            height: 18px;
        }
        .btn-skeleton-process {
            height: 23px;
        }
    }
}
