.page-support{
  .ui-back{
    svg{
      top: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .page-support {
      .ui-back{
        .ant-btn{
          padding-left: 0;
          svg{
            top: -9px;
          }
        }
    }
  }
}
