.bl-payment-number-bonuses {
  .subtitle{
    span{
      color: #9876A4;
      font-size: 16px;
    }
  }
  .bz-block {
      background: url("../../../images/background-for-transfer-funds.png") no-repeat left top;
      background-size: 100% 100%;
      border-radius: 8px;
      width: 430px;
      margin-top: 8px;
      padding: 16px;
      .bz-block-content {
          text-align: start;
          .bz-typography {
              text-align: start;
          }
      }
  }
  .ant-space-item {
      width: 100%;
      text-align: center;
  }
  .format-accumulated-sum {
      background: transparent;
      border: none;
  }
}