.bl-write-boss-modal {
    .subtitle {
        text-align: center;
    }
}

.bl-write-boss-modal-form {
    .ant-modal-content {
        .ant-form-item-control {
            min-width: 100%;
        }
        .ant-modal-close {
            display: none;
        }
        .close-btn {
            color: #000;
            width: 18px;
            height: 18px;
            min-width: 18px;
            padding: 0;
            position: absolute;
            right: 18px;
            top: 16px;
        }
        @media screen and (max-width: 450px) {
            padding: 40px 16px 30px;
        }
    }
    .bl-write-boss-modal .subtitle {
        font-weight: 400;
    }
}

@media screen and (min-width: 1200px) {
    .bl-write-boss-modal-form {
        width: 992px !important;
        text-align: center;
        h2 {
            text-align: center;
        }
        .ant-space {
            width: 100%;
            .ant-space-item {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .bl-write-boss-modal {
        .subtitle {
            text-align: left;
            font-weight: 500;
        }
    }
}
