.bl-reservation-list-info-extend {
    .btn-reservation {
        .bz-typography {
            font-weight: 700;
            line-height: 120%;
        }
        border: 1px solid #ffffff;
        border-radius: 8px;
        background: transparent;
        min-width: 200px;
        transition: none;
        margin-left: 16px;
        &:disabled {
            opacity: 0.5;
        }
    }
    .btn-replenishment {
        min-width: 200px;
        transition: none;
        margin-left: 16px;
    }
}

@media screen and (max-width: 768px) {
    .bl-reservation-list-info-extend {
        .ant-btn {
            .bz-typography {
                line-height: 140%;
            }
        }
        .btn-replenishment {
            min-width: auto;
        }
        .btn-reservation {
            min-width: auto;
        }
    }
}
@media screen and (max-width: 913px) {
    .bl-reservation-list-info-extend {
        .btn-replenishment {
            min-width: 150px;
        }
        .btn-reservation {
            min-width: auto;
        }
    }
}
