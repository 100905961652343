@import "../../../styles/variables";

.bl-support-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 965px;
    margin: auto;

    .buttons {
        .ant-btn {
            width: 277px;
        }
    }

    .support-description {
        text-align: center;
        margin-top: 26px;
        margin-bottom: 40px;
        white-space: pre-line;
        @include tinyFont-normal;
        font-size: 17px;
    }

    .support-button {
        width: $btn-standard-width;
        margin-bottom: 120px;
    }

    .support-list-item {
        margin: auto;
        width: 311px;
        height: 250px;
        transition: 0.5s;
        cursor: pointer;
        &:hover {
            background: url(~@bezlimit/bezlimit-ui/dist/images/gold-opacity.png) center center
                    no-repeat,
                url(~@bezlimit/bezlimit-ui/dist/images/gold-texture.png) center center no-repeat;
            background-size: cover;
            background-blend-mode: color, normal;
            color: black;
            border-color: transparent;
            -webkit-transform: scale(1.1);
            -ms-transform: scale(1.1);
            transform: scale(1.1);
            .support-list-item-more {
                color: $text-secondary-color;
            }
            ul,
            P {
                color: $text-secondary-color;
            }
        }

        .bz-block-content {
            height: 100%;
        }
    }

    .support-list-item-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .support-list-item-title {
        @include title-standardFont-normal;
    }

    .support-list-item-more {
        color: $text-pale-color;
        padding-bottom: 16px;
        @include standardFont-medium;
    }

    @media screen and (max-width: $max-tablet-width) {
        .support-description {
            margin-top: 56px;
            margin-bottom: 48px;
        }

        .support-button {
            width: 100%;
            margin-bottom: 72px;
        }

        .support-list-item {
            width: 280px;
            height: 180px;
        }

        .support-list-item-title {
            @include title-tinyFont-normal;
        }

        .support-list-item-more {
            padding-bottom: 8px;
        }
    }

    @media screen and (max-width: $max-phone-width) {
        .support-list-item {
            width: 100%;
            height: 140px;

            &:hover {
                background: url(~@bezlimit/bezlimit-ui/dist/images/gold-opacity.png) center center
                        no-repeat,
                    url(~@bezlimit/bezlimit-ui/dist/images/gold-texture.png) center center no-repeat;
                background-size: cover;
                background-blend-mode: color, normal;
                transform: none;
                -webkit-transform: none;
                -ms-transform: none;
            }
        }

        .support-list-item-description {
            @include tinyFont-normal;
        }
    }

    @media screen and (max-width: $max-small-phone-width) {
        .support-list-item-title {
            @include title-inputFont-normal;
        }

        .support-list-item-more {
            padding-bottom: 8px;
            @include smallFont-normal;
        }
    }
}
@media screen and (max-width: 320px) {
    .bl-support-page{
        .page-header{
            font-size: 38px;
        }
    }
}
