.page-history {
    .bl-operations-history {
        .bl-list-payments {
            margin-bottom: 16px;
            .bl-nps {
                .nps-description {
                    background: var(
                        --Linear,
                        linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%)
                    );
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-family: "ALS Hauss";
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;

                    img {
                        margin-right: 4px;
                    }
                    @media screen and (max-width: 536px) {
                        border-top: 1px solid transparent;
                        border-image: linear-gradient(0.25turn, #642878, #cea86f, #642878);
                        border-image-slice: 1;
                        width: 100%;
                        max-width: 125px;
                        padding-top: 1px;
                        margin-top: 1px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 767px) {
    .page-history {
        .bl-operations-history {
            .bl-list-payments {
                .mobile-text-payments {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .page-history {
        .bl-operations-history {
            .bl-list-payments {
                border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                margin-bottom: 32px;
                .total-sum-sum-ikon {
                    display: none;
                }
                .list-total-sum {
                    font-size: 17px !important;
                    line-height: 100%;
                    margin-bottom: 4px;
                    font-weight: 700;
                    svg {
                        margin-left: 4px;
                    }
                }
                .col-mobile-payauts-sum {
                    text-align: end;
                }
                .date-format-mobile-payments {
                    margin-top: 4px;
                    font-weight: 500;
                }
                .col-mobile-status-payments {
                    display: flex;
                    align-items: center;
                    .group-status-icons {
                        display: flex;
                        gap: 0;
                        img {
                            width: 12px;
                            height: 12px;
                            margin-right: 4px;
                            margin-top: 4px;
                        }
                    }
                }
                .col-status-name {
                    text-align: end;
                    .bl-nps {
                        .loyalty-name {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                        }
                    }
                }
            }
            .bl-accrual-history {
                .date-format-mobile {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 24px;
                    .date-format-mobile-text {
                        background: rgba(255, 255, 255, 0.16);
                        border-radius: 16px;
                        font-weight: 500;
                        line-height: 100%;
                        color: rgba(255, 255, 255, 0.5);
                        min-width: 127px;
                        height: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 10px;
                    }
                }
            }
        }
    }
}
