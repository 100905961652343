.bl-smart-field {
    max-width: 616px;
    .inputs {
        width: 100%;
        .ant-space-item {
            width: 25%;
            -webkit-transition: all 0.4s ease-in-out;
            -moz-transition: all 0.4s ease-in-out;
            -ms-transition: all 0.4s ease-in-out;
            -o-transition: all 0.4s ease-in-out;
            transition: all 0.4s ease-in-out;
            .ant-input-focused,
            .ant-input:focus {
                box-shadow: none;
            }
            .ant-input {
                width: 100%;
                border: 1px solid rgba(255, 255, 255, 0.5);
                border-radius: 8px;
                background: transparent;
                height: 56px;
                color: #fff;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                max-height: 56px;
                .ant-input-handler-wrap {
                    display: none;
                }
            }
            .input_0 {
                z-index: 1;
            }
            .input_1 {
                z-index: 2;
            }
            &:first-child {
                width: 50%;
            }
        }
    }
}
