.bl-lottery-ng-video {
    margin: 0 auto 42px;
    max-width: 1056px;

    @media screen and (max-width: 992px) {
        margin-bottom: 26px;
    }

    &__title {
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        filter: drop-shadow(2px 1px 0px #3d074f);
        -webkit-text-stroke-width: 0.5;
        -webkit-text-stroke-color: #000;
        font-family: "ALS Alumna";
        font-size: 72px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        background-image: linear-gradient(
            to right,
            #fff7d8,
            #fef0b7,
            #efce73,
            #f0c78a,
            #efce73,
            #fef0b7,
            #fff7d8
        );
        background-size: 200% auto;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: shine 4s linear infinite;
        margin-bottom: 62px;

        @media screen and (max-width: 992px) {
            font-size: 36px;
            line-height: normal;
            margin-bottom: 36px;
            white-space: pre;
        }

        @keyframes shine {
            100% {
                background-position: 200% center;
            }
        }
    }

    &__container {
        position: relative;

        &__title {
            display: inline-flex;
            padding: 6px 24px 8px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            overflow: hidden;
            background: rgba(255, 255, 255, 0.02);
            backdrop-filter: blur(21px);
            position: absolute;
            top: -37px;
            left: 0;
            right: 0;
            max-width: 440px;
            margin: auto;
            max-height: 73px;
            background-image: radial-gradient(
                    circle at 100% 100%,
                    transparent 9px,
                    #ffe9af 9px,
                    #ffe9af 10px,
                    transparent 10px
                ),
                linear-gradient(to right, #ffe9af, #8d8178),
                radial-gradient(
                    circle at 0% 100%,
                    transparent 9px,
                    #8d8178 9px,
                    #8d8178 10px,
                    transparent 10px
                ),
                linear-gradient(to bottom, #8d8178, #493f5450),
                radial-gradient(
                    circle at 0% 0%,
                    transparent 9px,
                    #493f5450 9px,
                    #493f5450 10px,
                    transparent 10px
                ),
                linear-gradient(to left, #493f5450, #bba181),
                radial-gradient(
                    circle at 100% 0%,
                    transparent 9px,
                    #bba181 9px,
                    #bba181 10px,
                    transparent 10px
                ),
                linear-gradient(to top, #bba181, #ffe9af);
            background-size: 10px 10px, calc(100% - 20px) 1px, 10px 10px, 1px calc(100% - 20px);
            background-position: top left, top center, top right, center right, bottom right,
                bottom center, bottom left, center left;
            background-repeat: no-repeat;

            @media screen and (max-width: 992px) {
                padding: 4px 12px 6px 12px;
                border-radius: 8px;
                top: -22px;
                max-width: 232px;
                background-image: radial-gradient(
                        circle at 100% 100%,
                        transparent 7px,
                        #ffe9af 7px,
                        #ffe9af 8px,
                        transparent 8px
                    ),
                    linear-gradient(to right, #ffe9af, #8d8178),
                    radial-gradient(
                        circle at 0% 100%,
                        transparent 7px,
                        #8d8178 7px,
                        #8d8178 8px,
                        transparent 8px
                    ),
                    linear-gradient(to bottom, #8d8178, #493f5450),
                    radial-gradient(
                        circle at 0% 0%,
                        transparent 7px,
                        #493f5450 7px,
                        #493f5450 8px,
                        transparent 8px
                    ),
                    linear-gradient(to left, #493f5450, #bba181),
                    radial-gradient(
                        circle at 100% 0%,
                        transparent 7px,
                        #bba181 7px,
                        #bba181 8px,
                        transparent 8px
                    ),
                    linear-gradient(to top, #bba181, #ffe9af);
                background-size: 8px 8px, calc(100% - 16px) 1px, 8px 8px, 1px calc(100% - 16px);
                background-position: top left, top center, top right, center right, bottom right,
                    bottom center, bottom left, center left;
                background-repeat: no-repeat;
            }

            &__text {
                text-align: center;
                filter: drop-shadow(2px 1px 0px #3d074f);
                font-family: "ALS Hauss";
                font-size: 42px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
                background: linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @media screen and (max-width: 992px) {
                    font-size: 22px;
                }
            }
        }

        &__video {
            width: 100%;
            text-align: center;

            iframe {
                width: 100%;
                height: 528px;
                border-radius: 12px;
                border: 1.5px solid var(--linear-2-paints, #efce73);

                @media screen and (max-width: 1200px) {
                    width: 589px;
                    height: 330px;
                }

                @media screen and (max-width: 992px) {
                    width: 343px;
                    height: 175px;
                }

                @media screen and (max-width: 375px) {
                    max-width: 100%;
                }
            }
        }
    }
}
