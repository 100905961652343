.bl-numbers-filters-mobile-modal {
    text-align: center;
    min-width: auto;
    .title {
        font-weight: 700;
        line-height: 120%;
    }
}
.bl-filter-modal-mobile {
    padding: 72px 0 !important;
    .ant-modal-close {
        display: block;
        position: absolute;
        top: -64px;
        right: -14px;
        svg {
            color: #fff;
            font-size: 14px;
        }
    }
    @media screen and (max-width: 450px) {
        padding: 0px 0 !important;
        .ant-modal-close {
            top: 0px;
            right: 0px;
            svg {
                color: #000000;
            }
        }
    }
}
