.notifications-page-wrapper-titlePageHeader {
  margin-bottom: 35px;
}

.page-notifications {
  .bl-content {
    padding-bottom: 70px;
  }
}

.notifications-page-wrapper {
  Button {
    margin-top: 15px !important;
    display: flex;
    justify-content: center;
    margin: auto;
    align-items: center;
  }
}

.notifications-page-wrapper-notifications-description-headerTop {
  display: flex;
  width: 1030px;
  justify-content: space-between;
  margin: auto;
  margin-top: 15px;
  font-family: "ALS Hauss";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;

  div {
    width: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    * {
      display: flex;
      justify-content: center;
      width: 25px;
      height: 25px;
      color: #696868;
      justify-content: center;
      align-items: center;
    }

    .switchIndexNotifications {
      padding: 6px;
      width: 35px;
      height: 35px;
      border: 1px solid #ffffff;
      border-radius: 8px;

      * {
        color: #f1f1f1;
      }
    }

    :hover {
      cursor: pointer;
    }
  }
}

.notifications-page-wrapper-notifications-description-more {
  a {
    color: white;
    border-bottom: 1px solid white;
  }
}

.notifications-page-wrapper-notifications-descriptionСubeFilter {
  width: 1030px;
  height: auto;
  background-color: rgba(0, 255, 255, 0);
  margin: auto;
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .notifications-page-wrapper-notifications-description-item {
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 24px 16px;
      background-color: rgba(179, 255, 0, 0);
      width: 330px;
      height: auto;
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 8px;
      color: #ffffff;
      margin-bottom: 20px;
    margin-right: 12px;
    gap: 10px;

      .red-circle {
          position: absolute;
          top: 15px;
          right: 15px;
          background: red;
          border-radius: 50%;
          width: 10px;
          height: 10px;
      }

      .notifications-page-wrapper-notifications-description-title {
          color: white;
          font-size: 36px;
          font-family: ALS Alumna;
          font-weight: 400;
          line-height: 36px;
          word-wrap: break-word;
      }

    .notifications-page-wrapper-notifications-description-more {
      color: white;
      font-size: 17px;
      font-family: ALS Hauss;
      font-weight: 700;
      line-height: 20.4px;
      word-wrap: break-word;
    }

      .notifications-page-wrapper-notifications-description-moreDetailed {
          align-self: flex-end;
          color: rgba(255, 255, 255, 0.5);
          font-size: 12px;
          font-family: ALS Hauss;
          font-weight: 400;
          word-wrap: break-word;
      }
  }

    .red-circle-border {
        border-color: #ffdb98;
    }
}

.notifications-page-wrapper-notifications-descriptionLineFilter {
    width: 1030px;
    height: auto;
    background-color: rgba(0, 255, 255, 0);
    margin: auto;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;

    .notifications-page-wrapper-notifications-description-item {
        position: relative;
        transition: 0.5s;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        padding: 24px 16px 16px;
        background-color: rgba(179, 255, 0, 0);
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 8px;
        color: #ffffff;
        margin-bottom: 20px;

        .red-circle {
            position: absolute;
            top: 15px;
            right: 15px;
            background: red;
            border-radius: 50%;
            width: 10px;
            height: 10px;
        }

        .ant-space-vertical {
            gap: 16px;
            align-items: center;

            .ant-space-item:nth-child(2) {
                width: 90%;
                text-align: center;
            }
        }

        .notifications-page-wrapper-notifications-description-title {
            font-family: "ALS Alumna";
            font-style: normal;
            font-weight: 400;
            font-size: 33px;
            line-height: 100%;
        }

        .notifications-page-wrapper-notifications-description-more {
            font-family: "ALS Hauss";
            font-style: normal;
            font-weight: 700;
            font-size: 17px;
        }

        .notifications-page-wrapper-notifications-description-moreDetailed {
            font-family: "ALS Hauss";
            font-style: normal;
            color: rgba(255, 255, 255, 0.5);
            align-self: stretch;
            text-align: right;
        }

        .red-circle-border {
            border-color: #ffdb98;
        }
    }

    .red-circle-border {
        border-color: #ffdb98;
    }
}

.notifications-page-wrapper-notifications-description-headerTopNotifyNotifications {
    display: flex;
    justify-content: center;
    font-size: 25px;
    text-align: center;
    font-family: ALS Alumna, sans-serif;
    padding: 0px 15px;
    margin-top: -61px;
}

@media screen and (max-width: 1055px) {
  .notifications-page-wrapper-notifications-description-headerTopNotifyNotifications {
    margin-top: 20px;
  }
  .notifications-page-wrapper-notifications-description-headerTop {
    margin-top: 0px;
    width: 700px;

    div {
      display: none;
    }
  }
  .notifications-page-wrapper-titlePageHeader {
      p {
          margin-bottom: 0px !important;
      }

      margin-bottom: 25px;
      margin-top: 0px;
  }
  .notifications-page-wrapper .notifications-page-wrapper-notifications-descriptionСubeFilter {
    width: 700px;

    .notifications-page-wrapper-notifications-description-item {
      margin-bottom: 25px;
    }
  }
}

@media screen and (max-width: 990px) {
  .page .bl-content .back-to-profile {
    margin-bottom: 30px !important;
  }
  .notifications-page-wrapper {
    .notifications-page-wrapper-notifications-description-headerTop {
        font-size: 17px;
        width: 330px;
    }

    .notifications-page-wrapper-notifications-descriptionСubeFilter {
      margin-top: 25px;
      width: 330px;
      height: auto;

      .notifications-page-wrapper-notifications-description-item {
        margin-right: 0px;
        margin-bottom: 20px;
      }
    }
  }
}
