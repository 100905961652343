.bl-extend-modal {
  .subtitle {
    text-align: center;
    font-weight: 400;
    line-height: 22px;
    span {
      font-weight: 500;
      margin-left: 5px;
    }
  }
}

@media screen and (max-width: 768px) {
  .bl-extend-modal {
    .subtitle {
      text-align: left;
    }
  }
}