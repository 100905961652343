.field-range-picker-container {
  .field-range-picker {
    position: relative;
  }
  &.focused {
    .ant-form-item-label {
        padding: 0;
    }
    .ant-picker-input {
        margin-top: 15px;
    }
    .ant-picker-range-separator {
      display: block;
    }
  }
  .ant-form-item-label {
      position: absolute;
      left: 16px;
      padding-top: 7px;
      top: 0!important;
      label {
          font-size: 12px;
          line-height: 16px;
          color: rgba(255, 255, 255, 0.5);
      }
  }
  .ant-picker-suffix {
    position: absolute;
    right: 16px;
  }
  .ant-picker {
    width: 100%;
    .ant-picker-input {
      max-width: 80px;
    }
  }
  .ant-picker-range-separator {
    position: relative;
    top: 6px;
    left: -2px;
    display: none;
  }
  input{
    pointer-events: none
}
}

.dropdown-date-picker-field {
  .ant-picker-panels {
      position: relative;
      > .ant-picker-panel {
          &:first-child {
              .ant-picker-header-super-prev-btn,
              .ant-picker-header-prev-btn {
                  position: relative;
                  z-index: 1;
              }
          }
          &:last-child {
              position: absolute;
              .ant-picker-body {
                  display: none;
              }
              .ant-picker-header-view {
                  opacity: 0;
              }
          }
      }
  }
}

@media screen and (max-width: 768px) {
  .field-range-picker-container {
    .ant-form-item-label {
        left: 13px;
    }
    .ant-picker {
      padding: 12px;
    }
  }
}