.bl-ten-for-bezlimit {
    text-align: center;
    .conteiner-input {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .bl-ten-for-bezlimit {
        text-align: left;
        h2 {
            font-weight: 700;
        }
    }
}
