.bl-accrual-history{
  .header-bl-operations-histoty{
    .title-mobile{
      text-align: center;
    }
  }
}

@media screen and (max-width: 767px){
  .bl-accrual-history{
    .header-bl-operations-histoty{
      .title-desctop{
        display: none;
      }
    }
  }
}