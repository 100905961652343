.bl-sider-profile {
  .bl-menu {
    .ant-menu-inline .ant-menu-item::after {
      display: none;
    }

    .ant-menu-inline {
      width: auto;
      border: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      grid-gap: 24px;
      margin: 20px 0;

      .ant-menu-item {
        width: auto;
        padding: 0 !important;
        margin: 0;
        height: auto;
        line-height: normal;
      }

      .ant-menu-item:not(:last-child) {
        margin: 0;
      }

      .ant-menu-item-selected,
      .ant-menu-item:active {
        background: transparent !important;
        color: #5f2875;
      }

      .ant-menu-item-selected,
      .ant-menu-item:hover {
        color: #5f2875;
      }

      .ant-menu-title-content {
        color: var(--color-main) !important;
        font-size: 20px;
        text-decoration: none !important;
        padding-bottom: 8px;
        border-bottom: 1px solid #cccccc;
        transition: opacity 0.3s;
      }

      .ant-menu-title-content:hover,
      .ant-menu-item-selected {
        opacity: 0.7;
      }

      @media only screen and (max-width: 425px) {
        margin: 0;
      }
    }
  }
}
