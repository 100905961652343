.bl-numbers-list-booking {
  >.ant-space {
    width: 100%;
    >.ant-space-item {
      >.ant-space {
        width: 100%;
      }
    }
  }
  .title {
    font-weight: 700;
    line-height: 120%;
  }
}