.bl-slider-superlink {
    img {
        width: 100%;
        border-radius: 15px;
    }
    .actions {
        position: relative;
        z-index: 100;
        margin-top: 89px;
        .ant-btn {
            width: 100%;
            background: rgba(255, 255, 255, 0.05);
            border: 1px solid #ffffff;
            backdrop-filter: blur(12.5px);
            border-radius: 8px;
            font-weight: 700;
            font-size: 17px;
            line-height: 120%;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            gap: 10px;
        }
        @media screen and (max-width: 768px) {
            margin-top: 64px;
        }
    }
    .slick-list {
        z-index: 100;
    }
    .slick-arrow.slick-prev {
        position: fixed;
        top: 0;
        height: 100%;
        width: 50%;
        left: 0;
        z-index: 1;
        cursor: url("../../../../../../images/arrow_service_left.png") 4 1, auto !important;
    }
    .slick-arrow.slick-next {
        position: fixed;
        top: 0;
        height: 100%;
        width: 50%;
        right: 0;
        z-index: 1;
        cursor: url("../../../../../../images/arrow_service_right.png") 130 1, auto !important;
    }
    .slick-dots {
        position: absolute;
        bottom: -50px;
        z-index: 100;
        li {
            margin: 0 10px !important;
            width: 18px !important;
            height: 18px !important;
            button {
                width: 18px !important;
                height: 18px !important;
                transition: none !important;
            }
            @media screen and (max-width: 768px) {
                width: 12px !important;
                height: 12px !important;
                button {
                    width: 12px !important;
                    height: 12px !important;
                }
            }
        }
        @media screen and (max-width: 768px) {
            bottom: -43px;
        }
    }
    .ant-carousel .slick-dots li.slick-active {
        button {
            background: url("../../../../../../images/dots_activ.svg") no-repeat center center;
            background-size: cover;
        }
    }
    .ant-carousel .slick-dots li button {
        background: url("../../../../../../images/dots_default.svg") no-repeat center center;
        opacity: 1;
        background-size: cover;
    }
}

.bl-slider-superlink-modal {
    .ant-modal-content {
        padding: 40px 0;
        background: transparent;
        box-shadow: none;
        .ant-modal-close {
            position: fixed;
            right: 13px;
            svg {
                color: #fff;
            }
            @media screen and (max-width: 844px) {
                right: 0px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .ant-modal-body {
            max-width: 300px;
            margin: auto;
        }
    }
}
