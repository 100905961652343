.bl-news-widget {
    margin-bottom: -48px;
    .ant-carousel {
        .slick-dots {
            margin-bottom: 14px;
            bottom: 50px;
            li {
                width: 15px;
                button {
                    border-radius: 50%;
                    height: 10px;
                    width: 10px;
                }
            }
        }
    }
    .ant-space {
        width: 100%;
    }
    .bz-block {
        min-height: 378px;
    }
    .carousel-content {
        width: 100%;
        .ant-carousel {
            width: 100%;
        }
    }
    .slick-slider {
        .ant-modal-header {
            color: #fff;
            background: transparent;
            font-family: "ALS Hauss";
            font-style: normal;
            font-weight: 700;
            font-size: 17px;
            line-height: 120%;
        }
    }
    .ant-btn {
        width: 90%;
        background: transparent;
        border: 1px solid #ffffff;
        border-radius: 8px;
        top: -100px;
        left: 16px;
        max-width: 340px;
    }
    .static-tag {
        width: 77px;
        border: 1px solid #ffce73;
        border-radius: 18px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(141.08deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%);
        font-weight: 700;
        font-size: 12px;
        color: #1f1f1f;
    }
    .published-date {
        color: rgba(255, 255, 255, 0.5);
        font-weight: 500;
        font-size: 17px;
        text-align: end;
    }
    .preview-text {
        line-height: 19px;
        color: #fff;
    }
    .preview-image {
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: cover !important;
        .bl-modal-content {
            .title {
                margin-top: 38px;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .bl-news-widget {
        .bz-block {
            min-height: 330px;
        }
        .ant-btn {
            margin-left: 20px;
            max-width: 851px !important;
            left: 20px;
        }
    }
}
@media screen and (max-width: 800px) {
    .bl-news-widget {
        .ant-btn {
            margin-left: 10px;
        }
    }
}
@media screen and (max-width: 500px) {
    .bl-news-widget {
        .ant-btn {
            margin-left: 0px;
            left: 18px;
        }
    }
}
