.page-history {
    .bl-operations-history {
        .colapse-payments-group {
            .total-sum-colapse {
                font-weight: 500;
            }
            .sum-charges-colapse {
                font-weight: 500;
            }
        }
        .ant-collapse {
            background: transparent;
            border: 0;
            .ant-collapse-arrow {
                color: #fff;
                font-size: 18px;
                top: -11px;
                cursor: pointer;
                svg {
                    transform: rotate(270deg);
                    position: relative;
                    top: -20px;
                }
            }
            .ant-collapse-item-active {
                .ant-collapse-arrow {
                    svg {
                        transform: rotate(90deg) !important;
                        position: relative;
                        top: -20px;
                    }
                }
            }
            .ant-collapse-item-disabled {
                .ant-collapse-arrow {
                    display: none;
                }
            }
        }
    }
}

@media screen and (min-width: 767px) {
    .page-history {
        .bl-operations-history {
            .total-sum-colapse {
                .total-sum-colapse-subtitle {
                    display: none;
                }
            }
            .sum-charges-colapse {
                .sum-charges-colapse-subtitle {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .page-history {
        .bl-operations-history {
            .total-sum-colapse {
                .total-sum-colapse-subtitle {
                    font-weight: 400;
                    margin: 24px 0 8px 0;
                }
            }
            .sum-charges-colapse {
                .sum-charges-colapse-subtitle {
                    font-weight: 400;
                    margin: 24px 0 8px 0;
                }
            }
            .ant-collapse {
                .ant-collapse-arrow {
                    top: -80px;
                    font-size: 10px;
                }
            }
            .bl-accrual-history {
                .colapse-payments-group {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                    padding: 0 0px 24px 0;
                    margin-bottom: 20px;
                    .accruals-size-colapse {
                        font-size: 14px !important;
                        line-height: 100%;
                    }
                    .total-sum-colapse,
                    .sum-charges-colapse {
                        font-weight: 700;
                        font-size: 17px !important;
                        line-height: 100%;
                    }
                    .ant-collapse-arrow {
                        top: -105px;
                    }
                }
            }
        }
    }
}
