.bl-empty-result {
  text-align: center;
  padding: 32px 16px 8px;
  .ant-space,
  .ant-btn {
    width: 100%;
  }
  .ant-space {
    .ant-space-item {
      &:nth-child(3) {
        margin-top: -32px;
      }
    }
  }
  .title {
    font-weight: 500;
    line-height: 120%;
  }
  .text {
    font-weight: 500;
    line-height: 120%;
  }
}

@media screen and (max-width: 768px) {
  .bl-empty-result {
    padding: 24px 16px 16px;
    .title {
      font-weight: 700;
    }
    .text {
      line-height: 19px;
    }
  }
}