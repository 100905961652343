.bl-reservation-list {
    .ant-pagination {
        display: none;
    }

    .bl-free {
        .ant-btn {
            margin-top: -4px;
        }
    }
    .loading-more {
        text-align: center;
        .ant-btn {
            min-width: 341px;
        }
    }

    .bl-super_link {
        .duration {
            margin-top: 4px;
        }
    }

    .ant-table-thead {
        > tr {
            > th {
                border: 0;
                text-align: right;
            }
        }
    }
    .bl-numbers-list-info-phone {
        .phone {
            font-size: 32px !important;
        }
    }
    &:not(.kanban) {
        .ant-table-tbody {
            > tr {
                > td {
                    padding: 0 0 16px 0;
                    > div {
                        padding: 16px;
                        border: 1px solid rgba(255, 255, 255, 0.5);
                        border-radius: 8px;
                        &.empty {
                            border: 0;
                            background: transparent !important;
                        }
                        &.bl-super_link {
                            border: 1px solid #ffce73;
                            background: rgb(57 7 50 / 50%);
                        }
                    }
                }
            }
        }
    }

    &.kanban {
        .extend {
            flex: 0 0 100%;
            max-width: 100%;
            .bl-reservation-list-info-extend {
                width: 100%;
                .ant-btn {
                    min-width: 100%;
                }
                .btn-replenishment,
                .btn-reservation {
                    margin-top: 12px;
                    margin-left: 0px;
                }
                .btn-extend {
                    margin-top: 0;
                }
            }
        }
        .desc {
            display: block !important;
            .tariff-name {
                margin-left: 0px;
            }
        }
        .sim {
            display: block;
            .ant-btn {
                width: 100%;
                background: transparent;
                border: 1px solid #ffffff;
                border-radius: 8px;
                .bz-typography {
                    font-weight: 700;
                    line-height: 120%;
                }
            }
        }
        .ant-table {
            &:not(.ant-table-empty) {
                thead {
                    border: 0;
                    margin: 0;
                    > tr {
                        > th {
                            width: 100%;
                            display: block;
                        }
                    }
                }
            }
        }
        .ant-table-tbody {
            @media screen and (max-width: 992px) {
                column-count: 2;
            }
            @media screen and (max-width: 768px) {
                column-count: 1;
            }
            tr {
                margin: 0;
                display: grid;
                grid-template-rows: 1fr auto;
                break-inside: avoid;
            }
        }
        .ant-table-empty {
            .ant-table-tbody {
                display: table !important;
                width: 100%;
                padding: 16px 0 24px;
            }
        }
        .ant-table-tbody {
            > tr {
                > td {
                    padding: 0;
                }
            }
        }
        .bl-reservation-list-info {
            .phone {
                font-size: 24px !important;
                flex: 0 0 60%;
                max-width: 60%;
                &.super_link {
                    flex: 0 0 58.33333333%;
                    max-width: 58.33333333%;
                }

                .phone {
                    max-width: 100%;
                }
                .ant-space {
                    width: 100%;
                }
                .ant-btn {
                    display: none;
                }
            }
            .actions {
                flex: 0 0 40%;
                max-width: 40%;
                @media screen and (max-width: 320px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
            .description {
                display: none;
            }
        }
        .book-yet {
            margin-top: 24px;
        }
    }
    .book-yet {
        text-align: center;
        margin-top: 16px;
        .ant-btn {
            min-width: 341px;
        }
    }
}

.page-book {
    .kanban .ant-table:not(.ant-table-empty) tbody tr td {
        height: 100%;
        .bl-reservation-list-info.bl-super_link {
            position: relative;
            padding: 16px;
            margin: -16px;
            height: calc(100% + 32px);
            @media screen and (max-width: 768px) {
                border: 1px solid #ffce73;
                background: rgb(57 7 50 / 50%);
                border-radius: 8px;
                padding: 10px 8px;
                border-radius: 8px;
                margin: -12px -8px;
                height: calc(100% + 24px);
            }
            @media screen and (min-width: 768px) {
                &::before {
                    content: "";
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    right: 0;
                    bottom: 0px;
                    margin: auto;
                    border: 1px solid #ffce73;
                    background: rgb(57 7 50 / 50%);
                    border-radius: 8px;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .bl-reservation-list {
        .bl-reservation-list-info {
            .phone {
                .ant-btn {
                    display: none;
                }
            }
        }
        &.kanban {
            .ant-table:not(.ant-table-empty) {
                tbody {
                    tr {
                        width: 50%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .bl-reservation-list {
        &.kanban {
            .ant-table:not(.ant-table-empty) {
                tbody {
                    tr {
                        width: 100%;
                    }
                }
            }
            .bl-reservation-list-info-actions {
                .ant-space {
                    .ant-space-item {
                        &:first-child {
                            display: block;
                        }
                    }
                }
            }
            .book-yet {
                margin-top: 16px;
            }
        }
        .book-yet {
            margin-top: 16px;
            .ant-btn {
                min-width: 100%;
            }
        }
    }
}

@media screen and (max-width: 300px) {
    .bl-reservation-list {
        &.kanban {
            .bl-reservation-list-info-actions {
                .ant-space {
                    gap: 8px !important;
                }
            }
        }
    }
}
