.bl-lottery-ng-timer {
    margin: 38px auto 42px;
    max-width: 718px;
    position: relative;

    @media screen and (max-width: 1200px) {
        max-width: 589px;
    }

    @media screen and (max-width: 992px) {
        margin-bottom: 20px;
        max-width: 343px;
    }
    @media screen and (max-width: 431px) {
        margin: 32px auto 10px;
    }

    &__title {
        display: inline-flex;
        padding: 6px 24px 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        overflow: hidden;
        background: rgba(255, 255, 255, 0.02);
        backdrop-filter: blur(21px);
        position: absolute;
        top: -37px;
        left: 0;
        right: 0;
        max-width: 606px;
        margin: auto;
        max-height: 73px;
        z-index: 2;
        background-image: radial-gradient(
                circle at 100% 100%,
                transparent 9px,
                #ffe9af 9px,
                #ffe9af 10px,
                transparent 10px
            ),
            linear-gradient(to right, #ffe9af, #987386),
            radial-gradient(
                circle at 0% 100%,
                transparent 9px,
                #987386 9px,
                #987386 10px,
                transparent 10px
            ),
            linear-gradient(to bottom, #987386, #7e2980),
            radial-gradient(
                circle at 0% 0%,
                transparent 9px,
                #7e2980 9px,
                #7e2980 10px,
                transparent 10px
            ),
            linear-gradient(to left, #7e2980, #dba25f),
            radial-gradient(
                circle at 100% 0%,
                transparent 9px,
                #dba25f 9px,
                #dba25f 10px,
                transparent 10px
            ),
            linear-gradient(to top, #dba25f, #ffe9af);
        background-size: 10px 10px, calc(100% - 20px) 1px, 10px 10px, 1px calc(100% - 20px);
        background-position: top left, top center, top right, center right, bottom right,
            bottom center, bottom left, center left;
        background-repeat: no-repeat;

        @media screen and (max-width: 1200px) {
            max-width: 560px;
        }

        @media screen and (max-width: 992px) {
            padding: 4px 12px 6px 12px;
            border-radius: 8px;
            top: -22px;
            max-width: 276px;
            background-image: radial-gradient(
                    circle at 100% 100%,
                    transparent 7px,
                    #ffe9af 7px,
                    #ffe9af 8px,
                    transparent 8px
                ),
                linear-gradient(to right, #ffe9af, #987386),
                radial-gradient(
                    circle at 0% 100%,
                    transparent 7px,
                    #987386 7px,
                    #987386 8px,
                    transparent 8px
                ),
                linear-gradient(to bottom, #987386, #7e2980),
                radial-gradient(
                    circle at 0% 0%,
                    transparent 7px,
                    #7e2980 7px,
                    #7e2980 8px,
                    transparent 8px
                ),
                linear-gradient(to left, #7e2980, #dba25f),
                radial-gradient(
                    circle at 100% 0%,
                    transparent 7px,
                    #dba25f 7px,
                    #dba25f 8px,
                    transparent 8px
                ),
                linear-gradient(to top, #dba25f, #ffe9af);
            background-size: 8px 8px, calc(100% - 16px) 1px, 8px 8px, 1px calc(100% - 16px);
            background-position: top left, top center, top right, center right, bottom right,
                bottom center, bottom left, center left;
            background-repeat: no-repeat;
        }

        &__text {
            text-align: center;
            filter: drop-shadow(2px 1px 0px #3d074f);
            font-family: "ALS Hauss";
            font-size: 42px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            background: linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media screen and (max-width: 1200px) {
                font-size: 38px;
            }

            @media screen and (max-width: 992px) {
                font-size: 18px;
            }
        }
    }

    &__container {
        position: relative;
        min-height: 218px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 14px;
        background-image: radial-gradient(
                circle at 100% 100%,
                transparent 13px,
                #efce73 13px,
                #efce73 14px,
                transparent 14px
            ),
            linear-gradient(to right, #efce73, #f7df96),
            radial-gradient(
                circle at 0% 100%,
                transparent 13px,
                #f7df96 13px,
                #f7df96 14px,
                transparent 14px
            ),
            linear-gradient(to bottom, #f7df96, #cea86f),
            radial-gradient(
                circle at 0% 0%,
                transparent 13px,
                #cea86f 13px,
                #cea86f 14px,
                transparent 14px
            ),
            linear-gradient(to left, #cea86f, #fbe8a6),
            radial-gradient(
                circle at 100% 0%,
                transparent 13px,
                #fbe8a6 13px,
                #fbe8a6 14px,
                transparent 14px
            ),
            linear-gradient(to top, #fbe8a6, #efce73);
        background-size: 14px 14px, calc(100% - 28px) 1px, 14px 14px, 1px calc(100% - 28px);
        background-position: top left, top center, top right, center right, bottom right,
            bottom center, bottom left, center left;
        background-repeat: no-repeat;
        overflow: hidden;

        &::before {
            content: "";
            position: absolute;
            width: 102%;
            height: 100%;
            background: url("../../../../../images/timerbg2.jpg") no-repeat;
            background-size: 100% 100%;
            z-index: -1;
        }

        @media screen and (max-width: 1200px) {
            height: 177px;
            min-height: auto;
        }

        @media screen and (max-width: 992px) {
            height: 117px;
            border-radius: 12px;
            background-image: radial-gradient(
                    circle at 100% 100%,
                    transparent 11px,
                    #efce73 11px,
                    #efce73 12px,
                    transparent 12px
                ),
                linear-gradient(to right, #efce73, #f7df96),
                radial-gradient(
                    circle at 0% 100%,
                    transparent 11px,
                    #f7df96 11px,
                    #f7df96 12px,
                    transparent 12px
                ),
                linear-gradient(to bottom, #f7df96, #cea86f),
                radial-gradient(
                    circle at 0% 0%,
                    transparent 11px,
                    #cea86f 11px,
                    #cea86f 12px,
                    transparent 12px
                ),
                linear-gradient(to left, #cea86f, #fbe8a6),
                radial-gradient(
                    circle at 100% 0%,
                    transparent 11px,
                    #fbe8a6 11px,
                    #fbe8a6 12px,
                    transparent 12px
                ),
                linear-gradient(to top, #fbe8a6, #efce73);
            background-size: 12px 12px, calc(100% - 24px) 1px, 12px 12px, 1px calc(100% - 24px);
            background-position: top left, top center, top right, center right, bottom right,
                bottom center, bottom left, center left;
            background-repeat: no-repeat;
        }
    }
}
