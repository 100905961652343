.bl-write-boss-form {
    .ant-btn:not(.ant-btn-text) {
        width: 100%;
    }
    .button-container {
        .ant-form-item {
            margin: 0;
            text-align: center;
            .ant-btn {
                max-width: 255px;
            }
        }
    }
    textarea {
        resize: none;
        min-height: 176px;
        padding: 16px;
        &::placeholder {
            color: #828282 !important;
        }
        &::-webkit-input-placeholder {
            color: #828282 !important;
        }
        &:-moz-placeholder {
            /* Firefox 18- */
            color: #828282 !important;
        }
        &::-moz-placeholder {
            /* Firefox 19+ */
            color: #828282 !important;
        }
        &:-ms-input-placeholder {
            color: #828282 !important;
        }
        &::-ms-input-placeholder {
            color: #828282 !important;
        }
    }
    .ant-form-item {
        margin-bottom: 16px;
    }
    .ant-input-suffix {
        display: flex;
        position: relative;
        left: 10px;
        .ant-input-clear-icon-has-suffix {
            margin-right: 10px;
            transform: scale(1.5);
        }
    }
}

@media screen and (max-width: 768px) {
    .bl-write-boss-form {
        .button-container {
            .ant-form-item {
                .ant-btn {
                    max-width: 100%;
                }
            }
        }
        .ant-form-item {
            margin-bottom: 8px;
        }
    }
}
