.bl-numbers-in-booting-duration-text {
  font-weight: 400;
  line-height: 100%;
  position: relative;
  top: 1px;
  &.time {
    width: 43px;
    text-align: center;
    white-space: nowrap;
  }

  @media screen and (max-width: 1200px) {
    font-size: 20px !important;
    &.time {
      width: 36px;
    }
  }

  @media screen and (max-width: 992px) {
    font-size: 17px !important;
    &.time {
      width: auto;
      text-align: center;
    }
  }
}