.bl-accruals-filter-form {
  width: 100%;
  text-align: center;
  .ant-btn {
    &[type="submit"] {
      min-width: 100%;
    }
  }
  
  .field-search {
    .ant-input-search {
      width: auto;
      height: 48px;
      padding: 0 16px;
      gap: 0;
    }
    input {
      font-family: 'ALS Alumna';
      font-size: 18px;
      line-height: 18px;
      position: relative;
      top: 2px;
    }
    input::-webkit-input-placeholder {
      font-size: 18px;
    }
    input:-moz-placeholder {
      font-size: 18px;
    }
    input::-moz-placeholder {
      font-size: 18px;
    }
    input:-ms-input-placeholder {
      font-size: 18px;
    }
    input::-ms-input-placeholder {
      font-size: 18px;
    }
    input::placeholder {
      font-size: 18px;
    }
    .ant-input-affix-wrapper {
      .ant-input-clear-icon {
        z-index: 1;
        position: relative;
        left: -10px;
        top: 0px;
      }
    }
    .ant-input-suffix {
      margin: 1px 0 0;
    }
    .bz-input-block {
      display: flex;
    }
  }
  .ant-row {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .bl-accruals-filter-form {
    .ant-space {
      width: 100%;
    }
    .ant-btn {
      &[type="submit"] {
        min-width: 100%;
      }
    }
    .field-number-search {
      .ant-input-affix-wrapper {
        padding: 0 12px!important;
      }
    }
  }
}