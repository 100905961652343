.bl-in-booking-list {
    .row {
        .ant-col {
            display: flex;
            align-items: center;
        }
    }
    .icons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        svg {
            font-size: 24px;
        }
    }
    .duration {
        display: flex;
        align-items: center;
        padding-right: 15px !important;
        justify-content: flex-end;
        .ant-space {
            gap: 8px !important;
        }
        .process-text {
            font-family: "ALS Alumna";
            font-style: normal;
            font-weight: 400;
            font-size: 20px !important;
            line-height: 100%;
            color: #efce73;
        }
    }
    .name {
        font-weight: 500;
        line-height: 120%;
    }
    .pay {
        &.desktop {
            display: flex;
        }
        &.mobile {
            display: none;
        }
        align-items: center;
        justify-content: flex-end;
        .ant-btn {
            height: 32px;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            min-width: 150px;
        }
    }
    .phone-container {
        .name {
            display: none;
        }
    }
    .ant-divider {
        display: none;
    }
    .nps-icons-phone {
        background: var(
            --Linear,
            linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: "ALS Hauss";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        display: flex;
        align-items: center;
        @media screen and (max-width: 767px) {
            position: absolute;
            top: -15px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .bl-in-booking-list {
        .duration {
            .process-text {
                font-size: 15px !important;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .bl-in-booking-list {
        .name-container {
            display: none !important;
        }
        .phone {
            font-size: 22px !important;
        }
        .icons {
            justify-content: flex-start;
            gap: 8px;
        }
        .ant-divider {
            display: block;
            background: rgba(255, 255, 255, 0.5);
            width: calc(100% - 16px);
            min-width: auto;
            margin: auto;
        }
        .duration {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 8px !important;
            .ant-space {
                gap: 4px !important;
            }
            * {
                font-size: 17px !important;
            }
            .bl-numbers-in-booking-duration {
                position: relative;
                right: -2px;
                display: flex;
                .ant-space-item {
                    &:nth-child(odd) {
                        min-width: 28px;
                        text-align: center;
                    }
                }
            }
        }
        .phone-container {
            .name {
                display: block;
                font-weight: 400;
                line-height: 140%;
            }
        }
        .pay {
            &.desktop {
                display: none;
            }
            &.mobile {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .ant-btn {
                    height: 30px;
                    font-weight: 500;
                    font-size: 12px;
                    border-radius: 39px;
                    width: auto;
                }
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .bl-in-booking-list {
        .pay {
            &.mobile {
                .ant-btn {
                    width: 125px;
                    min-width: auto;
                }
            }
        }
    }
}

@media screen and (max-width: 320px) {
    .bl-in-booking-list {
        .phone-container {
            flex: 0 0 100%;
            max-width: 100%;
        }
        .pay {
            &.desktop {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .ant-btn {
                    height: 30px;
                    font-weight: 500;
                    font-size: 12px;
                    border-radius: 39px;
                    width: 100%;
                }
            }
            &.mobile {
                display: none;
            }
        }
    }
}
