.bl-group-statistics-shimmer {
    overflow: hidden;
    .group-coll {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        max-width: 930px;
        margin: auto;
        grid-gap: 72px;
        .group-count {
            .block-1 {
                width: 68px;
                height: 68px;
                border-radius: 8px;
                background: linear-gradient(
                    137deg,
                    rgba(207, 200, 219, 0.62) 4.18%,
                    rgba(255, 255, 255, 0.1) 56.48%
                );
            }
            .block-2 {
                width: 107px;
                height: 66px;
                border-radius: 8px;
                background: linear-gradient(
                    137deg,
                    rgba(207, 200, 219, 0.62) 4.18%,
                    rgba(255, 255, 255, 0.1) 56.48%
                );
            }
        }
        @media screen and (max-width: 425px) {
            gap: 24px;
            flex-wrap: nowrap;
            overflow: auto;
            justify-content: flex-start;
            padding: 0 15px;
            padding-bottom: 8px;
            margin-bottom: -8px;
        }
    }
}
