@import "../../../../styles/variables";

.tariffs-cards {
    .left-right-button {
        svg {
            color: #fef0b7;
        }
    }
    .card {
        align-items: center;
        background-image: radial-gradient(
                circle at 100% 100%,
                transparent 16px,
                #efce73 16px,
                #efce73 18px,
                transparent 18px
            ),
            linear-gradient(to right, #efce73, #988967),
            radial-gradient(
                circle at 0% 100%,
                transparent 16px,
                #988967 16px,
                #988967 18px,
                transparent 18px
            ),
            linear-gradient(to bottom, #988967, #605b4e),
            radial-gradient(
                circle at 0% 0%,
                transparent 16px,
                #605b4e 16px,
                #605b4e 18px,
                transparent 18px
            ),
            linear-gradient(to left, #605b4e, #efce73),
            radial-gradient(
                circle at 100% 0%,
                transparent 16px,
                #efce73 16px,
                #efce73 18px,
                transparent 18px
            ),
            linear-gradient(to top, #efce73, #efce73);
        background-size: 18px 18px, calc(100% - 36px) 2px, 18px 18px, 2px calc(100% - 36px);
        background-position: top left, top center, top right, center right, bottom right,
            bottom center, bottom left, center left;
        background-repeat: no-repeat;
        border-radius: 16px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        min-width: 166px;
        padding: 19px 16px;
        text-align: center;
        backdrop-filter: blur(6px);
        cursor: pointer;
        border-radius: 8px;
        padding: 19px;
        text-align: center;
        min-width: 166px;

        .header {
            margin-bottom: 29px;
        }
        .ant-col {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .ant-space-item {
            @include title-tinyFont-normal;
            font-family: ALS Hauss, sans-serif !important;
        }
        .suffix {
            @include smallFont-normal;
            color: $text-pale-color;
        }
        .subscription_fee {
            margin-bottom: 17px;

            .title {
                @include title-bigFont-normal;
            }
        }

        .ant-typography p {
            margin-bottom: 0;
        }
        .packets-tariff {
            display: flex;
            align-items: center;
            flex-direction: column;
        }
        .social-rest {
            background: linear-gradient(141.08deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            font-family: "ALS Hauss";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            margin-bottom: 16px;
        }
        .bg-value-int-gold {
            background: url(~@bezlimit/bezlimit-ui/dist/images/gold-opacity.png) center center
                    no-repeat,
                url(~@bezlimit/bezlimit-ui/dist/images/gold-texture.png) center center no-repeat;
            background-size: cover;
            background-blend-mode: color, normal;
            border-radius: 16px;
            width: 79px;
            max-width: 125px;
            height: 71px;
            display: flex;
            align-items: center;
            justify-content: center;
            .title-value {
                color: #000;
                font-family: "ALS Hauss";
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
                .inline {
                    color: #000;
                    font-size: 16px !important;
                    font-weight: 700 !important;
                }
            }
        }
        .col-info-packet {
            .title-value {
                display: flex;
                align-items: center;
                .suffix {
                    font-family: "ALS Hauss";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    color: #000;
                }
            }
        }
    }
    flex-wrap: nowrap;
    margin-top: 0px;
    margin-bottom: 110px;
    padding-left: 0px;
    padding-right: 0px;
    .card-mobile {
        .gold {
            .suffix {
                background: linear-gradient(141.08deg, #fff 25.79%, #fff 63.66%, #fff 97.07%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }
        }
        .title {
            .suffix {
                vertical-align: middle;
                margin-left: 9px;
                font-family: "ALS Hauss";
                font-style: normal;
                font-weight: 400;
                font-size: 24px !important;
                line-height: 120%;
                opacity: 1 !important;
            }
        }
        .title-value {
            font-family: "ALS Hauss";
            font-weight: 500;
            font-size: 24px;
            line-height: 120%;
            white-space: normal;
            margin-bottom: 0;
            path {
                fill: #fff;
            }
            img {
                display: inline-block;
            }

            .suffix {
                font-family: "ALS Hauss";
                font-style: normal;
                font-weight: 400;
                font-size: 20px !important;
                line-height: 120%;
            }
        }
        .social-icons-col {
            display: flex;
            justify-content: flex-start;
            text-align: left;
        }
        .social-icons {
            img {
                display: inline-block;
            }
        }
        .social-icon {
            margin-right: 4px;
            width: 16px;
            height: auto;
        }
        .social-text {
            margin-bottom: 0px;
            font-family: ALS Hauss;
            font-size: 14px;
            line-height: 19px;
            font-weight: 500;
            text-align: left;
            color: white;
            @media screen and (max-width: 390px) {
                font-size: 12px;
            }
            @media screen and (max-width: 360px) {
                font-size: 11px;
            }
        }
        .social-rest {
            font-family: ALS Hauss;
            font-size: 17px;
            line-height: 120%;
            font-weight: 500;
            line-height: 100%;
            text-align: center;
            color: white;
            margin-bottom: 10px;
            margin-top: 10px;
        }
        .stats {
            width: 100%;
            flex-direction: unset;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            position: relative;
            > * {
                position: relative;
            }
        }
    }
    .card-desktop {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        *::before,
        *::after {
            content: "";
            pointer-events: none;
        }
        cursor: pointer;
        width: 340px;
        height: 100%;
        margin-right: 30px;
        border-radius: 16px;
        border-color: #efce73;
        .title {
            background: linear-gradient(141.08deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            font-family: "ALS Alumna";
            font-weight: 400;
            font-size: 48px;
            line-height: 100%;
            .suffix {
                background: linear-gradient(141.08deg, #fff 25.79%, #fff 63.66%, #fff 97.07%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }
        }
        .suffix {
            vertical-align: baseline;
            margin-left: 9px;
        }
        .social-text {
            font-family: "ALS Hauss";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
        }
        .social-rest {
            font-family: "ALS Hauss";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 100%;
            margin-bottom: 10px;
        }
        .title-value {
            font-family: "ALS Hauss";
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 140%;
            margin-bottom: 10px;
            path {
                fill: #efce73;
            }
            .suffix {
                margin-left: 0px;
                display: block !important;
            }
        }
        .social-rest,
        .title-value {
            background: linear-gradient(141.08deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            .suffix {
                background: linear-gradient(141.08deg, #fff 25.79%, #fff 63.66%, #fff 97.07%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }
        }
        .bl-plan-mobile {
            justify-content: center;
            .bg-value-int-gold {
                background: url(~@bezlimit/bezlimit-ui/dist/images/gold-opacity.png) center center
                        no-repeat,
                    url(~@bezlimit/bezlimit-ui/dist/images/gold-texture.png) center center no-repeat;
                background-size: cover;
                background-blend-mode: color, normal;
                background-blend-mode: color, normal;
                background-size: auto 100%;
                border-radius: 16px;
                max-width: 79px;
                height: 71px;
                position: relative;
                top: -5px;
                .title-value {
                    background: linear-gradient(141.08deg, #000 25.79%, #000 63.66%, #000 97.07%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                    .suffix {
                        color: #000;
                    }
                    .inline {
                        font-family: "ALS Hauss";
                        font-size: 16px !important;
                        font-style: normal;
                        font-weight: 700;
                        background: linear-gradient(
                            141.08deg,
                            #000 25.79%,
                            #000 63.66%,
                            #000 97.07%
                        );
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                    }
                }
            }
        }
    }
    .card {
        text-align: center;
        .title {
            background: linear-gradient(141.08deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            font-family: "ALS Alumna";
            font-weight: 400;
            font-size: 62px;
            line-height: 100%;
            .suffix {
                background: linear-gradient(141.08deg, #fff 25.79%, #fff 63.66%, #fff 97.07%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }
        }
        .suffix {
            font-family: "ALS Hauss";
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 15px;
            color: #ffffff;
            &.inline {
                display: contents;
                font-family: "ALS Hauss";
                font-style: normal;
                font-weight: 400;
                font-size: 12px !important;
                line-height: 15px;
                color: #ffffff;
            }
        }
        .title-value {
            svg {
                width: 35px;
                height: 16px;
            }
        }
    }
}
.page-ytader-sub-pages svg {
    color: $beige-color;
}
.bl-plan-mobile-row-socialIconsServices-wrapper {
    flex-direction: column;
    .social-icons-row {
        align-items: center;
        background-clip: content-box;
        border-bottom: 1px solid #fff;
        margin-bottom: 6px;
        padding-bottom: 8px;
        padding-top: 0;
    }
    .subscription-fee-590-margin {
        margin-top: 70px;
    }
}
@media screen and (max-width: 450px) {
    .tariffs-cards {
        .card-mobile {
            .title-value {
                white-space: pre-line;
                span {
                    display: block !important;
                }
            }
        }
    }
}
@media screen and (max-width: 387px) {
    .tariffs-cards {
        .card-mobile {
            .bl-plan-mobile {
                row-gap: 0px;
                .ant-collapse-content-box-plans {
                    margin-top: 0px;
                }
            }
        }
    }
}
@media screen and (max-width: 360px) {
    .tariffs-cards {
        .card-mobile {
            .title {
                font-size: 36px;
            }
            .title-value {
                white-space: pre-line;
            }
            .suffix.inline {
                font-size: 16px !important;
                line-height: 100%;
            }
        }
    }
}
.plans-connection {
    padding-top: 170px;
    background: linear-gradient(rgba(0, 0, 0, 0.121), rgba(0, 0, 0, 0.174)),
        url("../../../../images/image 51.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.page-tariffs {
    padding-top: 195px;
    background: url("../../../../images/guy-with-phone2.jpg");

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .bl-content {
        max-width: 100%;
    }
}

@media screen and (max-width: 776px) {
    .page-tariffs {
        .bl-content {
            padding: 0px;
        }
        padding-top: 185px;
    }
    .plans-connection {
        padding-top: 200px;
        .bl-content {
            padding: 0px;
        }
    }
}
@media screen and (max-width: 576px) {
    .page.page-tariffs {
        background: url("../../../../images/guy-with-phone2_mob.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        .ant-tabs-nav {
            margin: 0 0 16px !important;
        }
    }
}
