.page-book {
    .bl-back {
        margin-top: 0;
    }
    .ant-tabs-nav {
        margin: 0 0 64px;
    }
    .tabs-container {
        .ant-tabs-tab {
            &:hover {
                .ant-tabs-tab-btn {
                    color: #fef0b7;
                }
            }
        }
    }
    .ant-table-thead > tr > th {
        padding: 16px 0;
    }
}

@media screen and (max-width: 768px) {
    .page-book {
        .ant-tabs-nav {
            margin: 0 0 40px;
        }
        .ui-back {
            .ant-btn {
                svg {
                    top: -9px;
                }
            }
        }
    }
}
