.bl-login-form {
    text-align: center;
    max-width: 430px;
    .global-error {
        color: #ed0101;
        font-size: 12px;
        margin-top: -14px;
    }
    .ant-btn {
        width: 252px;
    }
    .ant-space.ant-space-vertical {
        min-width: 100%;
    }
    .bl-login-form-title {
        width: 206px;
        margin: 0 auto;
        margin-bottom: 32px;
        font-family: "ALS Hauss";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.5);
        .ant-col {
            cursor: pointer;
        }
    }
    .bl-login-form-title-active {
        text-decoration-line: underline;
        color: white;
    }
    .bl-login-form-register {
        cursor: pointer;
        text-decoration: underline;
        margin-top: 20px !important;
        margin-bottom: 20px;
    }
    .ant-form-item-explain-error {
        margin-bottom: 5px;
    }
}
.bl-register-store-form {
    width: 608px !important;
    .bl-register-store-form-butCheckbox-isPatronymic {
        padding-left: 0px !important;
        padding-right: 20px !important;
        padding-top: 12px;
    }
    .ant-form-item-explain-error {
        margin-top: 4px;
        margin-bottom: 5px;
    }
}
.bl-reset-password {
    .bl-register-store-form-button {
        margin-top: 39px;
        margin-bottom: 150px;
        .ant-btn {
            width: 296px;
        }
    }
}
@media screen and (max-width: 768px) {
    .bl-login-form {
        .ant-btn {
            width: 100%;
        }
    }
    .bl-register-store-form {
        width: auto !important;
        .bl-register-store-form-butCheckbox-isPatronymic {
            padding-right: 0px !important;
            padding-top: 0px;
        }
    }
    .bl-reset-password {
        .bl-register-store-form-button {
            margin-bottom: 50px;
        }
    }
}
