@import "../../../../styles/variables";

.bl-info {
    display: flex;
    justify-content: flex-end;

    > .ant-row {
        align-items: center;
        @media screen and (max-width: 280px) {
            .ant-space {
                > .ant-space-item:first-child {
                    width: 0;
                }
            }
        }
    }

    @media screen and (max-width: 480px) {
        > .ant-row > .ant-col > .ant-row > .ant-space {
            gap: 0px !important;

            .ant-col {
                padding: 0px 3px !important;
            }
        }
    }
}

.bl-news,
.bl-notifications {
    &__red-circle {
        text-align: center;
        font-size: 12px;
        position: absolute;
        top: 1px;
        right: 9px;
        background: red;
        border: 1px solid #fdfdfd00;
        border-radius: 9px;
        width: 22px;
        height: 15px;
        @media screen and (max-width: 768px) {
            font-size: 11px;
            top: -7px;
            right: 1px;
        }
    }
    &__icon {
        &:hover {
            path {
                stroke: $icon-hover-color;
                fill: rgba(255, 255, 255, 0);
            }
        }
    }
}
.bl-news__move-shake {
    animation: tilt-shaking 0.35s infinite;
    @keyframes tilt-shaking {
        0% {
            transform: rotate(0deg);
        }
        25% {
            transform: rotate(5deg);
        }
        50% {
            transform: rotate(0eg);
        }
        75% {
            transform: rotate(-5deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
}
.none-loyalty {
    display: none;
}

.bl-hint_header {
    button {
        border: 0px !important;
    }
    svg {
        width: 32px !important;
        height: 32px !important;
    }
    &__icon {
        &:hover {
            path {
                stroke: #efce73d5;
                fill: rgba(255, 255, 255, 0);
            }
        }
    }
    @media screen and (max-width: 768px) {
        position: absolute;
        top: 36px;
        left: 86px;
        svg {
            width: 23px !important;
            height: 23px !important;
        }
    }
    @media screen and (max-width: 375px) {
        top: 38px;
    }
    @media screen and (max-width: 320px) {
        left: 76px;
    }
    @media screen and (max-width: 280px) {
        left: 61px;
    }
}
.bl-hint_header__move-shake {
    border-radius: 50%;
    animation: pulse 3s infinite;
    @keyframes pulse {
        0% {
            -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
            box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
        }
        70% {
            -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
            box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
        }
        100% {
            -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
            box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        }
    }
}

.bl-hint_header {
    @media screen and (max-width: 768px) {
        .btn-default.ant-btn {
            width: 23px;
            height: 23px;
        }
    }

    @media screen and (max-width: 375px) {
        .btn-default.ant-btn {
            width: 20px;
            height: 20px;
        }
    }
}
