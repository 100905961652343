.bl-widget-delivery {
    .bz-block-description {
        margin-bottom: 32px;
    }
    .title-desctop {
        font-weight: 500;
        font-size: 24px;
        line-height: 22px;
        margin-bottom: 8px;
    }
    .sub-title-desctop {
        font-weight: 500;
        font-size: 17px;
        line-height: 22px;
        color: rgba(255, 255, 255, 0.5);
    }
    .ant-space {
        width: 100%;
        .button-delivery-items-size {
            .ant-btn {
                width: 100%;
                background: url(../../../../../public/buttonDelivery.png) center no-repeat;
                background-size: cover;
                background-blend-mode: color, normal;
                color: #000000;
            }
        }
        .button-delivery-items-size-mobile {
            .ant-btn-primary {
                width: 100%;
            }
        }
    }
    .bz-block-title {
        margin-bottom: 10px;
    }
    .button-delivery {
        margin-top: 34px;
        .ant-space {
            width: 100%;
            .ant-space-item {
                width: 100%;
                .ant-btn {
                    width: 100%;
                }
            }
        }
    }
    .text-items-size {
        text-align: center;
        font-weight: 700;
        font-size: 17px;
        line-height: 120%;
        margin-top: 56px;
    }
    .sub-text-items-size {
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 66px;
    }
}

@media screen and (max-width: 768px) {
    .bl-widget-delivery {
        .bz-block-content {
            margin-top: 0 !important;
            .text-items-size {
                margin-top: 0;
            }
            .title-mobile {
                font-weight: 700;
                font-size: 17px;
            }
            .suttitle-mobile {
                font-weight: 500;
                font-size: 17px;
                color: rgba(255, 255, 255, 0.5);
                margin-bottom: 40px;
            }
            .sub-text-items-size {
                margin-bottom: 40px;
            }
        }
        .button-delivery {
            .ant-space {
                display: grid;
            }
        }
    }
}
