@import "../../../../styles/variables";

.page-lottery {
    background: url("../../../../images/lotterybg.jpg");
    background-repeat: no-repeat;
    background-size: cover !important;
    background-position: top !important;
    background-attachment: fixed;
    .ui-back {
        top: 184px;
    }

    @media screen and (max-width: 768px) {
        .ui-back {
            top: 100px;
            .ant-btn {
                svg {
                    top: -9px;
                }
            }
        }
    }
    @media screen and (max-width: 431px) {
        background: url("../../../../images/lotterybg_mob.jpg");
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        transition: all 1.3s ease-in-out;
        box-sizing: border-box;
        will-change: transform;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000;
        display: block;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-top: 0;
        background-attachment: scroll;
    }
}
