.bl-logo {
    text-align: center;
    svg {
        width: 44px;
    }
    .title-gold {
        font-family: "ALS Alumna";
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 100%;
        letter-spacing: 0.05em;
        background: linear-gradient(141.08deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-repeat: no-repeat;
        background-origin: content-box;
        padding: 1px;
    }
    @media screen and (max-width: 768px) {
        svg {
            width: 38px;
            position: relative;
            top: 12px;
        }
    }
}
