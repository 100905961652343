.page-history {
    .bl-operations-history {
        .bl-title-colapse-panel-payouts {
            margin-bottom: 16px;
        }
    }
}

@media screen and (max-width: 767px) {
    .page-history {
        .bl-operations-history {
            .bl-title-colapse-panel-payouts {
                display: none;
            }
        }
    }
}
