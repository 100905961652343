.bl-super-link {
    border: 1px solid #ffce73;
    background: url("../../../../images/superWidget.png") no-repeat top left;
    background-size: cover;
    .title,
    .subtitle {
        font-weight: 500;
        line-height: 120%;
    }
    .subtitle {
        font-weight: 400;
    }
    .ant-btn {
        width: 100%;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        &.share {
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 4px;
        }
    }
    @media screen and (max-width: 1214px) {
        .bz-block-content > .ant-row {
            row-gap: 8px !important;
        }
        padding-top: 10px;
        .ant-btn.view-numbers {
            margin-top: 6px;
        }
    }
    @media screen and (max-width: 930px) and (min-width: 768px) {
        padding-top: 16px;
        .title {
            font-size: 19px !important;
        }
    }
    @media screen and (max-width: 768px) {
        .bz-block-content > .ant-row {
            row-gap: 20px !important;
        }
        padding-top: 16px;
        .ant-btn.view-numbers {
            margin-top: -4px;
        }
    }
}
