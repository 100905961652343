.bl-isRegistered-phone {
    height: 500px;
    .bl-isRegistered-phone-card {
        .bl-isRegistered-phone-browser {
            display: flex;
            height: 50px;
        }
        .bl-isRegistered-phone-mobile {
            display: none;
            .bl-isRegistered-phone-mobileNumberFormat {
                display: none;
            }
        }
        .field-phone-errorTypography {
            margin-bottom: 25px;
            white-space: pre;
        }
    }
    .bz-input-group-numbers input {
        padding-top: 5px;
        color: #ffeeb4;
        backdrop-filter: blur(6px);
        background: hsla(0, 0%, 100%, 0.2);
        text-align: center;
    }
    input::-webkit-input-placeholder {
        color: #ffeeb4;
        opacity: 0.8;
    }
    input:-moz-placeholder {
        color: #ffeeb4;
        opacity: 0.8;
    }
    input::-moz-placeholder {
        color: #ffeeb4;
        opacity: 0.8;
    }
    input:-ms-input-placeholder {
        color: #ffeeb4;
        opacity: 0.8;
    }
    input::-ms-input-placeholder {
        color: #ffeeb4;
        opacity: 0.8;
    }
    .ant-btn {
        width: 252px;
        margin-top: 10px;
    }
    .ant-input {
        color: #ffeeb4 !important;
    }
    .bz-typography-underline:hover {
        color: #bfbfbf;
    }
}

@media screen and (max-width: 769px) {
    .bl-isRegistered-phone {
        height: 300px;
        .bl-isRegistered-phone-card {
            width: 330px;
            .bl-isRegistered-phone-browser {
                display: none;
            }
            .bl-isRegistered-phone-mobile {
                display: flex;
                margin-bottom: 5px;
            }
            .field-phone-errorTypography {
                white-space: inherit;
            }
            .bz-input-block {
                width: 296px;
            }
        }
    }
}
