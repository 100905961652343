.modal-not-bonuses {
    width: 558px !important;
    position: relative;
    max-width: calc(100% - 16px);
    .ant-modal-content {
        border-radius: 16px;
    }
    .bz-modal-title {
        text-align: center;
        white-space: pre-line;
        font-size: 28px;
        --color: #000000;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        margin-bottom: 20px;
    }
    .bl-not-bonuse {
        .ant-space {
            width: 100%;
            .ant-space-item {
                width: 100%;
            }

            .subtitle {
                line-height: normal;
                @media screen and (min-width: 578px) {
                    white-space: pre-line;
                }
            }
            .list-payment {
                white-space: pre-line;
                line-height: normal;
                img {
                    margin-right: 4px;
                    top: -2px;
                    position: relative;
                }
                a {
                    color: #642878;
                    cursor: pointer;
                }
            }
        }
    }
    .ant-modal-footer {
        margin-top: 0;
        .ant-btn {
            border-radius: 30px;
            width: 100%;
            height: 58px;
            font-size: 18px;
        }
        .btn-payment-card {
            margin-top: 18px !important;
            margin-bottom: 12px !important;
            color: #000;
        }
        .btn-payment-sbp {
            color: #000;
            border: 1px solid #300d55;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                margin-left: 16px;
            }
        }
    }
}
