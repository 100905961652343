//sizes
$max-small-phone-width: 370px;
$max-phone-width: 767px;
$max-tablet-width: 991px;
$min-desktop-width: 1440;  //by figma layout

$btn-standard-width: 277px;

//colors
$primary-color: #5d256f;
$text-primary-color: #ffffff;
$text-secondary-color: #1F1F1F;
$text-pale-color: rgba(255, 255, 255, 0.5);
$text-violet-color: rgba(191, 105, 192, 1);
$text-gray-color: #828282;
$text-error-color: #ED0101;
$bg-opacity-color: rgba(255, 255, 255, 0.1);
$bg-opacity-color2: rgba(255, 255, 255, 0.2);
$bg-violet-opacity-color:rgba(120, 88, 134, 0.5);
$bg-avatar: rgba(152, 118, 164, 1);
$bg-violet: #642878;
$border-violet: #6B337E;
$dot-gray-color: #E5E5E5;
$beige-color: #FEF0B7;
$beige-inactive-color: rgba(254, 240, 183, 0.5);
$bg-gold-color: #FFCE73;
$gold-border-color: #EFCE73;
$bronze-color:#855917;
// hover
$border-hover-color: hsla(0deg, 0%, 100%, 0.3);
$text-hover-color: rgba(255, 255, 255, 0.4);
$icon-hover-color: rgba(255, 255, 255, 0.7);
//gradient
$bronze-gradient: linear-gradient(80.13deg, #CEA86F 25.32%, #FEF0B7 110.1%);
$bronze-banner: radial-gradient(63.41% 141.76% at 104.52% 103.4%, #EBCC9D 0%, #CEA86F 0.01%, #EFCE73 38.54%, #CEA86F 100%);
$silver-gradient: linear-gradient(257.78deg, #838383 -14.84%, #D6D6D6 50.91%, #7A7A7A 111.36%);
$silver-banner: linear-gradient(106.71deg, #9D9C9C 34.71%, #BFBFBF 60.08%, #919191 92.87%);
$gold-gradient: linear-gradient(141.08deg, #EFCE73 25.79%, #FEF0B7 63.66%, #CEA86F 97.07%);
$gold-banner: linear-gradient(109.26deg, #EED99B 43.99%, #FFF4B5 61.18%, #F2E093 71.35%);
$top-gradient: linear-gradient(284.43deg, #333238 -96.16%, #59657B 2.16%, #576176 26.08%, #3D3941 73.91%);
$top-banner: radial-gradient(75.95% 170.75% at 109.62% 116.98%, #333238 0%, #59657B 35.42%, #3D3941 100%);
$violet-gradient: linear-gradient(180.01deg, rgba(255, 255, 255, 0.7) -12.49%, rgba(255, 255, 255, 0) 54.58%), #BF69C0;

$lion-slice-img: image-set(
    url("../../../../../images/lion-slice@1x.webp") 1x,
    url("../../../../../images/lion-slice@1x.png") 1x,
    url("../../../../../images/lion-slice@2x.webp") 2x,
    url("../../../../../images/lion-slice@1x.png") 2x,
    url("../../../../../images/lion-slice@3x.webp") 3x,
    url("../../../../../images/lion-slice@1x.png") 3x);
$lion-slice-webkit-img: -webkit-image-set(
    url("../../../../../images/lion-slice@1x.webp") 1x,
    url("../../../../../images/lion-slice@1x.png") 1x,
    url("../../../../../images/lion-slice@2x.webp") 2x,
    url("../../../../../images/lion-slice@1x.png") 2x,
    url("../../../../../images/lion-slice@3x.webp") 3x,
    url("../../../../../images/lion-slice@1x.png") 3x);

//fonts
$font-family-base: "ALS Hauss", sans-serif;
//titles
@mixin title-hugeFont-normal {
    font-family: ALS Alumna, sans-serif;
    font-size: 79px;
    line-height: 64px;
    font-weight: 400;
}

@mixin title-bigFont-normal {
    font-family: ALS Alumna, sans-serif;
    font-size: 48px;
    line-height: 120%;
    font-weight: 400;
}

@mixin title-standardFont-normal {
    font-family: ALS Alumna, sans-serif;
    font-size: 36px;
    line-height: 36px;
    font-weight: 400;
}

@mixin title-smallFont-normal {
    font-family: ALS Alumna, sans-serif;
    font-size: 32px;
    line-height: 32px;
    font-weight: 400;
}

@mixin title-tinyFont-normal {
    font-family: ALS Alumna, sans-serif;
    font-size: 24px;
    line-height: 24px;
    font-weight: 400;
}

@mixin title-inputFont-normal {
    font-family: ALS Alumna, sans-serif;
    font-size: 18px;
    line-height: 18px;
    font-weight: 400;
}

@mixin title-banner-normal {
    font-family: ALS Alumna, sans-serif;
    font-size: 16px;
    line-height: 14.4px;
    font-weight: 400;
}

// text
@mixin bigFont-medium {
    font-family: 'ALS Hauss';
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
}
@mixin bigFont-normal {
    font-family: 'ALS Hauss';
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
}
@mixin standardFont-bold {
    font-family: ALS Hauss;
    font-size: 17px;
    line-height: 120%;
    font-weight: 700;
}

@mixin standardFont-medium {
    font-family: ALS Hauss;
    font-size: 17px;
    line-height: 120%;
    font-weight: 500;
}

@mixin standardFont-normal {
    font-family: ALS Hauss;
    font-size: 17px;
    line-height: 120%;
    font-weight: 400;
}

@mixin smallFont-normal {
    font-family: 'ALS Hauss';
    font-size: 16px;
    line-height: 120%;
    font-weight: 400;
}

@mixin tinyFont-bold {
    font-family: ALS Hauss;
    font-size: 14px;
    line-height: 19px;
    font-weight: 700;
}

@mixin tinyFont-medium {
    font-family: ALS Hauss;
    font-size: 14px;
    line-height: 19px;
    font-weight: 500;
}

@mixin tinyFont-normal {
    font-family: ALS Hauss;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
}
@mixin numberFont-normal {
    font-family: ALS Hauss;
    font-size: 10px;
    line-height: 140%;
    font-weight: 400;
}

//animations
@keyframes outercirc {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes innercirc {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}
@keyframes innercirc {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

//helpers
@mixin fixed {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
}
@mixin flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}