.bl-operations-history {
    .bl-default-history {
        text-align: center;
        width: 800px;
        margin: auto;
        .ant-btn {
            margin-top: 40px;
            width: 100%;
        }
    }
}
