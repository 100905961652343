.bl-lottery-ng-places {
    display: flex;
    flex-direction: column;
    gap: 22px;
    max-width: 631px;

    @media screen and (max-width: 992px) {
        gap: 36px;
    }

    .bl-places {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        gap: 11px;

        @media screen and (max-width: 992px) {
            gap: 4px;
        }

        &__images {
            margin-bottom: -6px;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: center;

            img {
                height: auto !important;

                @media screen and (max-width: 992px) {
                    width: 100% !important;
                }
            }
        }
    }
}
