.bl-activation-filter-form {
    text-align: center;
    .ch-container {
        @media screen and (max-width: 576px) {
            width: 100vw;
            margin-left: -16px;
            margin-right: -16px;
            overflow: hidden;
            height: 38px;
            .ant-checkbox-group {
                overflow: auto;
                padding: 0 16px 12px;
            }
        }
    }
    .ant-btn {
        &[type="submit"] {
            min-width: 100%;
        }
    }
    .field-number-search {
        .ant-input-search {
            min-width: 100%;
            width: 100%;
            height: 48px;
        }
        .ant-input-affix-wrapper {
            background: transparent;
            padding: 0 16px !important;
            .ant-input {
                color: #fff;
            }
        }
        .ant-input-suffix {
            margin: 1px 0 0;
        }
        .bz-input-block {
            display: flex;
        }
    }
    .ant-row {
        margin: 0;
    }
    .ant-form-item {
        margin: 0;
    }
    .ant-checkbox-group {
        display: flex;
        width: 100%;
        label {
            height: 30px;
            border-radius: 36px;
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            background: rgba(255, 255, 255, 0.1);
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: center;
            align-content: center;
            align-items: center;
            padding: 0 16px;
            white-space: nowrap;

            .ant-checkbox-inner {
                display: none;
            }
            &.ant-checkbox-wrapper-checked {
                box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.25);

                border: none;
                background-image: url("../../../images/btn_long@1x.jpg");
                background-image: -webkit-image-set(
                    url("../../../images/btn_long@1x.webp") 1x,
                    url("../../../images/btn_long@1x.jpg") 1x,
                    url("../../../images/btn_long@2x.webp") 2x,
                    url("../../../images/btn_long@2x.jpg") 2x
                );
                background-image: image-set(
                    url("../../../images/btn_long@1x.webp") 1x,
                    url("../../../images/btn_long@1x.jpg") 1x,
                    url("../../../images/btn_long@2x.webp") 2x,
                    url("../../../images/btn_long@2x.jpg") 2x
                );
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                * {
                    color: black !important;
                }
            }
            @media screen and (max-width: 768px) {
                height: 38px;
                width: 100%;
            }
        }
    }
    .reset {
        display: flex;
        justify-content: center;
        .ant-btn {
            font-weight: 500;
            font-size: 17px;
            line-height: 22px;
            background: transparent;
            color: #fff;
            border: 0;
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            flex-direction: row-reverse;
            flex-wrap: nowrap;
            gap: 8px;
            span {
                opacity: 0.5;
            }
        }
    }
    .ant-collapse {
        background: transparent;
        border: 0;
        .ant-collapse-content-box {
            padding: 0;
        }
        .ant-collapse-item {
            border: 0;
            display: flex;
            align-content: center;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            flex-wrap: nowrap;
            width: 100%;
            .ant-collapse-content {
                padding-top: 8px;
                width: 100%;
                background: transparent;
                border: 0;
                @media screen and (max-width: 768px) {
                    padding-top: 16px;
                }
            }
            .ant-collapse-header {
                display: flex;
                flex-direction: row-reverse;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: center;
                max-width: 106px;
                padding: 0 0 16px 0;
                gap: 8px;
                .ant-collapse-header-text {
                    color: #fff;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 22px;
                }
                .ant-collapse-expand-icon {
                    svg {
                        color: #fff;
                        position: relative;
                        top: 3px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .bl-activation-filter-form {
        .ant-space {
            width: 100%;
        }
        .ant-btn {
            &[type="submit"] {
                min-width: 100%;
                border-radius: 24px;
                font-weight: 700;
                font-size: 17px;
                line-height: 120%;
            }
        }
        .field-number-search {
            .ant-input-affix-wrapper {
                padding: 0 12px !important;
            }
        }
    }
}
