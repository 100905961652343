.bl-ng-timer-item {
    &__data {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 6px;
        margin: 14px 0 14px;

        &__num {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            overflow: hidden;
            background: rgba(255, 255, 255, 0.02);
            backdrop-filter: blur(21px);
            width: 86px;
            height: 87px;
            position: relative;
            top: 4px;
            background-image: radial-gradient(
                    circle at 100% 100%,
                    transparent 9px,
                    #ffeaaf 9px,
                    #ffeaaf 10px,
                    transparent 10px
                ),
                linear-gradient(to right, #ffeaaf, #dfb06d),
                radial-gradient(
                    circle at 0% 100%,
                    transparent 9px,
                    #dfb06d 9px,
                    #dfb06d 10px,
                    transparent 10px
                ),
                linear-gradient(to bottom, #dfb06d, #d17014),
                radial-gradient(
                    circle at 0% 0%,
                    transparent 9px,
                    #d17014 9px,
                    #d17014 10px,
                    transparent 10px
                ),
                linear-gradient(to left, #d17014, #efaf5f),
                radial-gradient(
                    circle at 100% 0%,
                    transparent 9px,
                    #efaf5f 9px,
                    #efaf5f 10px,
                    transparent 10px
                ),
                linear-gradient(to top, #efaf5f, #ffeaaf);
            background-size: 10px 10px, calc(100% - 20px) 1px, 10px 10px, 1px calc(100% - 20px);
            background-position: top left, top center, top right, center right, bottom right,
                bottom center, bottom left, center left;
            background-repeat: no-repeat;
            -webkit-box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.2);

            @media screen and (max-width: 1200px) {
                width: 41px;
                height: 48px;
                border-radius: 8px;
                background-image: radial-gradient(
                        circle at 100% 100%,
                        transparent 7px,
                        #ffeaaf 7px,
                        #ffeaaf 8px,
                        transparent 8px
                    ),
                    linear-gradient(to right, #ffeaaf, #dfb06d),
                    radial-gradient(
                        circle at 0% 100%,
                        transparent 7px,
                        #dfb06d 7px,
                        #dfb06d 8px,
                        transparent 8px
                    ),
                    linear-gradient(to bottom, #dfb06d, #d17014),
                    radial-gradient(
                        circle at 0% 0%,
                        transparent 7px,
                        #d17014 7px,
                        #d17014 8px,
                        transparent 8px
                    ),
                    linear-gradient(to left, #d17014, #efaf5f),
                    radial-gradient(
                        circle at 100% 0%,
                        transparent 7px,
                        #efaf5f 7px,
                        #efaf5f 8px,
                        transparent 8px
                    ),
                    linear-gradient(to top, #efaf5f, #ffeaaf);
                background-size: 8px 8px, calc(100% - 16px) 1px, 8px 8px, 1px calc(100% - 16px);
                background-position: top left, top center, top right, center right, bottom right,
                    bottom center, bottom left, center left;
                background-repeat: no-repeat;
                -webkit-box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.2);
                -moz-box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.2);
                box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.2);
            }
            @media screen and (max-width: 340px) {
                width: 32px;
                height: 39px;
            }

            &__text {
                text-align: center;
                filter: drop-shadow(2px 1px 0px #3d074f);
                font-family: "ALS Alumna";
                font-size: 100px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                background: linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                position: relative;
                top: 4px;

                @media screen and (max-width: 1200px) {
                    font-size: 50px;
                    top: 2px;
                }
                @media screen and (max-width: 340px) {
                    font-size: 32px;
                }
            }
        }
    }

    &__suffix {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        overflow: hidden;
        background: rgba(255, 255, 255, 0.02);
        backdrop-filter: blur(21px);
        width: 182px;
        height: 32px;
        background-image: radial-gradient(
                circle at 100% 100%,
                transparent 8px,
                #ffeaaf 8px,
                #ffeaaf 9px,
                transparent 9px
            ),
            linear-gradient(to right, #ffeaaf, #dfb06d),
            radial-gradient(
                circle at 0% 100%,
                transparent 8px,
                #dfb06d 8px,
                #dfb06d 9px,
                transparent 9px
            ),
            linear-gradient(to bottom, #dfb06d, #d17014),
            radial-gradient(
                circle at 0% 0%,
                transparent 8px,
                #d17014 8px,
                #d17014 9px,
                transparent 9px
            ),
            linear-gradient(to left, #d17014, #efaf5f),
            radial-gradient(
                circle at 100% 0%,
                transparent 8px,
                #efaf5f 8px,
                #efaf5f 9px,
                transparent 9px
            ),
            linear-gradient(to top, #efaf5f, #ffeaaf);
        background-size: 9px 9px, calc(100% - 18px) 1px, 9px 9px, 1px calc(100% - 18px);
        background-position: top left, top center, top right, center right, bottom right,
            bottom center, bottom left, center left;
        background-repeat: no-repeat;
        -webkit-box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.2);

        @media screen and (max-width: 1200px) {
            width: 86px;
            height: 22px;
            border-radius: 4px;
            background-image: radial-gradient(
                    circle at 100% 100%,
                    transparent 3px,
                    #ffeaaf 3px,
                    #ffeaaf 4px,
                    transparent 4px
                ),
                linear-gradient(to right, #ffeaaf, #dfb06d),
                radial-gradient(
                    circle at 0% 100%,
                    transparent 3px,
                    #dfb06d 3px,
                    #dfb06d 4px,
                    transparent 4px
                ),
                linear-gradient(to bottom, #dfb06d, #d17014),
                radial-gradient(
                    circle at 0% 0%,
                    transparent 3px,
                    #d17014 3px,
                    #d17014 4px,
                    transparent 4px
                ),
                linear-gradient(to left, #d17014, #efaf5f),
                radial-gradient(
                    circle at 100% 0%,
                    transparent 3px,
                    #efaf5f 3px,
                    #efaf5f 4px,
                    transparent 4px
                ),
                linear-gradient(to top, #efaf5f, #ffeaaf);
            background-size: 4px 4px, calc(100% - 8px) 1px, 4px 4px, 1px calc(100% - 8px);
            background-position: top left, top center, top right, center right, bottom right,
                bottom center, bottom left, center left;
            background-repeat: no-repeat;
            -webkit-box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.2);
        }
        @media screen and (max-width: 340px) {
            width: 70px;
        }

        &__text {
            text-align: center;
            filter: drop-shadow(2px 1px 0px #3d074f);
            font-family: "ALS Hauss";
            font-size: 26px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            background: linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media screen and (max-width: 1200px) {
                font-size: 12px;
            }
        }
    }
}
