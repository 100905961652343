.ant-modal-mask {
    background: rgba(100, 40, 120, 0.8);
}
.ant-modal-content {
    padding-top: 56px;
    padding-bottom: 56px;
    .ant-btn:disabled,
    .ant-btn:disabled:hover {
        background: #cfc8db;
        color: #fff;
    }
    .ant-btn:not(.ant-btn-text) {
        min-width: 252px;
    }
    .ant-form-item-has-error
        :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
    .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    .ant-input,
    .ant-input-affix-wrapper,
    .ant-picker {
        border: 1px solid #e5e5e5 !important;
        .ant-input {
            border: 0px !important;
        }
    }
    .ant-form-item-control {
        min-width: 434px;
    }
    .text-helper {
        font-weight: 500;
    }
    .subtitle {
        font-weight: 400;
        line-height: 19px;
    }
}
.ant-modal-close {
    .ant-modal-close-icon {
        svg {
            color: #000;
            font-size: 20px;
        }
    }
}

@media screen and (max-width: 768px) {
    .ant-modal-content {
        padding-top: 40px;
        padding-bottom: 40px;
        .ant-form-item-control {
            min-width: 100%;
        }
        .ant-space {
            width: 100%;
            .ant-space-item {
                width: 100%;
            }
        }
        .ant-form-item {
            margin: 0;
        }
        .ant-btn:not(.ant-btn-text) {
            min-width: 100%;
        }
    }
}

@media screen and (max-width: 576px) {
    .ant-modal-content {
        padding: 30px 16px;
    }
}
