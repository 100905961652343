@import "../../../../styles//variables";

.container-loading-icon {
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    height: 100%;
    transition: none;

    &::after {
        position: static;
        content: "";
        display: flex;
        align-items: center;
        justify-content: center;
        width: 84px;
        height: 84px;
        background: url("../../../../images/lions.png") no-repeat center center;
        background-size: auto 100%;
        animation: spin 3000ms infinite linear;
    }
}

@media screen and (max-width: $max-phone-width) {
    .container-loading-icon {
        &::after {
            width: 42px;
            height: 42px;
        }
    }
}