.page-history {
    .bl-operations-history {
        .bl-list-payouts {
            margin-bottom: 16px;
        }
    }
}

@media screen and (min-width: 767px) {
    .page-history {
        .bl-operations-history {
            .bl-list-payouts {
                .mobile-text-payout {
                    display: none;
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .page-history {
        .bl-operations-history {
            .bl-list-payouts {
                border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                margin-bottom: 32px;
                .col-mobile-payouts-sum {
                    top: -20px;
                    .mobile-payouts-sum {
                        text-align: end;
                        .payouts-sum {
                            font-size: 17px !important;
                            font-weight: 700;
                        }
                        .mobile-text-payout{
                            margin-top: 2px;
                        }
                    }
                }
                .date-format-mobile-payouts{
                    margin-top: 4px;
                    font-weight: 500;
                }
            }
        }
    }
}
