.bl-nps-reservation-edit {
    display: flex;
    width: 488px;
    padding: 16px 24px;
    height: 77px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(21px);
    margin: 0 auto 20px;
    background: transparent;
    position: relative;
    &::before {
        content: "";
        background: linear-gradient(
            45deg,
            #fef0b7,
            #eaccb5,
            #fff,
            #cd9ab1,
            #851ca8,
            #851ca8,
            #851ca8,
            #ff00c8,
            #fef0b7
        );
        position: absolute;
        top: -2px;
        left: -2px;
        background-size: 400%;
        z-index: -1;
        filter: blur(5px);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        animation: glowing 20s linear infinite;
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
        border-radius: 12px;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    &::after {
        z-index: -1;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 12px;
        backdrop-filter: blur(12px);
        background: transparentb;
        background: rgb(40 19 49 / 90%);
    }
    @media screen and (max-width: 768px) {
        max-width: 343px;
        width: 100%;
        height: 53px;
        padding: 12px;
        margin-top: 12px;
        margin-bottom: 0;
        svg {
            width: 29px;
        }
    }
    .phones {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        @media screen and (max-width: 768px) {
            img {
                height: 26px;
                width: auto;
            }
        }
        .phone {
            font-family: "ALS Hauss";
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            background: var(
                --Linear,
                linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%)
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            @media screen and (max-width: 768px) {
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
            }
        }
    }
    .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        img {
            cursor: pointer;
            @media screen and (max-width: 768px) {
                height: 23px;
            }
        }
    }
}
