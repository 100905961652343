.bl-menu {
    justify-content: space-between;
    text-align: center;
    .ant-menu-inline .ant-menu-item::after {
        display: none;
    }

    .links {
        display: flex;
        justify-content: center;
    }
    .bl-menu__dealer-service {
        margin-top: 55px;
        width: 280px;
        .bl-menu__dealer-service__dealer,
        .bl-menu__dealer-service__service {
            font-family: ALS Hauss;
            color: #cfc8db;
            cursor: pointer;
            font-size: 17px;
            font-weight: 700;
            text-decoration: none;
            padding-bottom: 8px;
            border-bottom: 1px solid #cccccc;
        }
        .bl-menu__dealer-service__active {
            color: #642878 !important;
            border-bottom-color: #642878 !important;
            border-bottom-width: 3px;
        }
    }
    @media only screen and (max-width: 390px) {
        .sidebar-close {
            position: relative;
            left: 32px;
        }
        .bl-menu__dealer-service {
            left: 25px;
            position: relative;
        }
        &--wrapper {
            position: relative;
            left: -39px;
        }
    }
    @media only screen and (max-width: 300px) {
        .bl-menu__dealer-service {
            left: 3px;
            width: 255px;
        }
        .sidebar-close {
            left: 11px;
        }
    }
    .ant-menu-inline {
        width: auto;
        border: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        grid-gap: 22px;
        margin: 20px 0;

        .ant-menu-item {
            width: auto;
            padding: 0 !important;
            margin: 0;
            height: auto;
            line-height: normal;
        }
        .ant-menu-item:not(:last-child) {
            margin: 0;
        }
        .ant-menu-item-selected,
        .ant-menu-item:active {
            background: transparent !important;
            color: #5f2875;
        }
        .ant-menu-item-selected,
        .ant-menu-item:hover {
            color: #5f2875;
        }
        .ant-menu-title-content {
            color: var(--color-main) !important;
            font-size: 20px;
            text-decoration: none !important;
            padding-bottom: 8px;
            border-bottom: 1px solid #cccccc;
            transition: opacity 0.3s;
        }
    }
}
