.bl-invite-a-user-modal {
  .subtitle {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .bl-invite-a-user-modal {
    .subtitle {
      text-align: left;
      font-weight: 500;
    }
  }
}