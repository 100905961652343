@media screen and (max-width: 768px) {
    .bl-code-confirmation-replenish-bonuses-output {
        .ant-space-vertical {
            gap: 0px !important;
        }
        .bl-duration {
            margin-top: 16px;
        }
        .bl-code-confirmation-form .send-to-phone-message {
            margin-bottom: 20px !important;
        }
        .phone {
            margin-top: 10px !important;
        }
        .text-helper {
            margin-top: 6px !important;
        }
        .bl-code-confirmation-form .error-text.error-show {
            margin: 0px 0 0px !important;
        }
    }
}
