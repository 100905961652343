.bl-support {
    .bz-block {
        width: 138px;
        padding: 5px 5px 5px 10px;
        border-color: #642878;
        .support-download {
            font-style: normal;
            font-weight: 400;
            font-size: 9px;
            line-height: 11px;
            font-family: "ALS Roboto";
        }
        .bz-block-content {
            svg {
                margin-right: 6px;
            }
        }
    }
    .ant-typography {
        color: #fff;
        margin: 0;
    }
    .support {
        font-size: 14px;
        font-weight: normal;
    }
    .support-contacts {
        display: flex;
        align-items: center;
        grid-gap: 9px;

        a {
            appearance: none;
            text-decoration: none;
            color: white;
            display: flex;
            align-items: center;
        }
        .app-store,
        .google-play {
            font-family: "ALS NotoSans";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.02em;
            color: #ffffff;
        }
    }
}
@media screen and (max-width: 1024px) {
    .bl-support {
        .mobile {
            display: grid;
        }
    }
}

@media screen and (max-width: 768px) {
    .bl-support {
        .mobile {
            display: grid;
        }
        .bz-block {
            min-width: 164px;
            min-height: 50px;
            padding: 8px 5px 8px 10px;
        }
        .support-contacts {
            .app-store,
            .google-play {
                font-size: 20px;
            }
        }
    }
}

@media screen and (max-width: 360px) {
    .bl-support {
        .mobile {
            display: grid;
        }
        .bz-block {
            min-width: 138px;
            min-height: 46px;
        }
        .support-contacts {
            .app-store,
            .google-play {
                font-size: 16px;
            }
        }
    }
}
