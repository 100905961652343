.page-dealers {
    .ui-back {
        svg {
            top: 0;
        }
    }

    @media screen and (max-width: 768px) {
        .ui-back{
            .ant-btn{
                svg{
                    top: -9px;
                }
            }
        }
    }
}
