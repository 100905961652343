.bl-copy-button {
  .ant-btn {
    min-width: 311px;
    background: #572b57;
    backdrop-filter: blur(30px);
    overflow: hidden;
    position: relative;
    span {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
    }
  }
}

@media screen and (max-width: 768px) {
  .bl-copy-button {
    .ant-btn {
      min-width: calc(100% - 32px);
    }
  }
}