.mask-modal-clear-metod {
    background: rgba(255, 255, 255, 0.1);
    background-color: rgba(0, 0, 0, 0);
}

.modal-success-clear {
    width: 834px !important;
    backdrop-filter: blur(10px);
    .ant-modal-content {
        background: transparent;
        border: 1px solid rgba(255, 255, 255, 0.5);
        padding: 100px 64px 56px 64px !important;
        .ant-space {
            width: 100%;
            text-align: center;
        }
        .back-arrow {
            color: hsla(0, 0%, 100%, 0.5);
            font-size: 17px;
            font-weight: 400;
            background: transparent;
            display: flex;
            align-items: center;
            position: absolute;
            left: -210px;
            top: -70px;
            display: flex;
            flex-direction: row;
            gap: 10px;
            svg {
                width: 40px;
                margin-right: 8px;
            }
            @media screen and (max-width: 1024px) {
                left: 34%;
                top: -65px;
            }
            @media screen and (max-width: 430px) {
                left: 0;
                top: -59px;
            }
        }
        .ant-modal-close-x {
            display: none;
        }
        .close-btn {
            background: transparent;
            position: absolute;
            top: 14px;
            right: 24px;
            padding: 0;
            min-width: auto;
        }
        .title-success {
            font-family: "ALS Alumna";
            font-size: 46px;
            white-space: pre-line;
            @media screen and (max-width: 430px) {
                font-size: 28px;
            }
        }
        .desc-success {
            font-family: "ALS Hauss";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            color: #ffffff;
            white-space: pre-line;
        }
        .lisr-numers {
            align-items: center;
            .number {
                font-size: 22px;
                font-weight: 700;
                text-align: start;
                @media screen and (max-width: 430px) {
                    font-size: 20px;
                }
            }
            .ant-btn {
                height: 38px;
                min-width: 159px;
                width: 100%;
                @media screen and (max-width: 430px) {
                    margin-top: 8px;
                }
            }
        }
        .modal-success-promised {
            .ant-divider {
                background: rgba(255, 255, 255, 0.5);
                margin: 16px 0;
            }
        }
        @media screen and (max-width: 728px) {
            padding: 45px 16px !important;
        }
        .button-close {
            margin-top: 20px;
            width: 322px;
            height: 48px;
            background-size: 100% 100%;
            font-family: "ALS Hauss";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 100%;
            color: #1f1f1f;
        }
    }
}
