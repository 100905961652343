@import "../../../styles/variables";

.bl-profile-page {
    max-width: 965px;
    margin: auto;
    .bl-personal-manager {
        .bz-block {
            .ant-space {
                justify-content: center;
            }
        }
    }

  .btn-sessions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 8px;
    }
  }
}

.bl-profile-confirm {
    flex-grow: 1;
    max-width: 100%;
}

.confirm-field {
    .ant-input {
        color: $text-primary-color;
    }
    span {
        background: none;
    }
}
