@import "../../../styles/variables";

.skeleton-lottery {
    padding: 0 16px;
    @media screen and (max-width: 992px) {
        max-width: 100%;
        padding: 0;
    }
    .bl-content {
        max-width: 1218px !important;
    }
    .actions {
        margin-top: 30px;
        text-align: center;
        .button {
            width: 252px;
            height: 48px;
            border-radius: 12px;
        }
    }
    .item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        padding: 16px;
        @media screen and (max-width: 992px) {
            padding-inline: 0;
        }
        &:last-child {
            border-bottom: 0;
        }
        .text {
            width: 200px;
            height: 16px;
            @media screen and (max-width: 992px) {
                width: 100px;
                min-width: 100px;
            }
            &.half {
                width: 100px;
                @media screen and (max-width: 992px) {
                    width: 40px;
                    min-width: 40px;
                }
            }
            &.half2 {
                width: 162px;
                @media screen and (max-width: 992px) {
                    width: 70px;
                    min-width: 70px;
                }
            }
        }
        .circle {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            overflow: hidden;
            min-width: 16px;
        }
        .inner {
            display: flex;
            gap: 4px;
            flex-direction: row;
        }
    }
}

.bl-lottery-search-container {
    margin-top: 36px;
    margin-bottom: 34px;
    @media screen and (max-width: 992px) {
        margin: 8px auto 18px;
        max-width: 343px;
    }
    svg {
        @media screen and (max-width: 992px) {
            width: 48px;
            height: 48px;
        }
        @media screen and (max-width: 768px) {
            width: 36px;
            height: 36px;
        }
    }
    .close-container {
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: var(--Linear, linear-gradient(147deg, #642878 9.91%, #25062f 86.34%));
        border-radius: 12px;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background: url("../../../images/search_chancess.png") no-repeat;
            background-size: 100% 100%;
            @media screen and (max-width: 992px) {
                background: url("../../../images/search_chancess.png") no-repeat;
                background-size: 100% 100%;
            }
        }
        @media screen and (max-width: 768px) {
            width: 36px;
            height: 36px;
            border-radius: 6px;
            img {
                width: 20px;
            }
        }
    }
    .text {
        color: var(--White, #fff);
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: "ALS Hauss";
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        @media screen and (max-width: 992px) {
            font-size: 12px;
            line-height: normal;
        }
        @media screen and (max-width: 768px) {
            text-align: left;
        }
    }
    form {
        display: flex;
        gap: 14px;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 21px;
        @media screen and (max-width: 992px) {
            gap: 6px;
            margin-bottom: 8px;
        }
    }
    .ant-form-item {
        margin-bottom: 0;
    }
    .input-container {
        position: relative;
        ::-webkit-input-placeholder {
            color: #fff;
            font-family: "ALS Hauss";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            @media screen and (max-width: 992px) {
                font-size: 12px;
            }
        }
        :-moz-placeholder {
            color: #fff;
            font-family: "ALS Hauss";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            @media screen and (max-width: 992px) {
                font-size: 12px;
            }
        }
        ::-moz-placeholder {
            color: #fff;
            font-family: "ALS Hauss";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            @media screen and (max-width: 992px) {
                font-size: 12px;
            }
        }
        :-ms-input-placeholder {
            color: #fff;
            font-family: "ALS Hauss";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            @media screen and (max-width: 992px) {
                font-size: 12px;
            }
        }
        ::-ms-input-placeholder {
            color: #fff;
            font-family: "ALS Hauss";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            @media screen and (max-width: 992px) {
                font-size: 12px;
            }
        }
        ::placeholder {
            color: #fff;
            font-family: "ALS Hauss";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            @media screen and (max-width: 992px) {
                font-size: 12px;
            }
        }
        &.disabled {
            opacity: 0.5;
            &::before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                margin: auto;
                z-index: 5;
            }
        }
        .boll {
            width: 52px;
            height: 52px;
            border-radius: 50%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            color: var(--White, #fff);
            text-align: center;
            font-family: "ALS Hauss";
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 42px;
            @media screen and (max-width: 992px) {
                width: 20px;
                height: 20px;
                font-size: 10px;
                left: 16px;
            }
            &.b1 {
                border: 1px solid #000;
                background: linear-gradient(
                    136.01deg,
                    #aeaeae 13.99%,
                    #858585 26.98%,
                    #323232 64.1%,
                    #000000 85.26%
                );
            }
            &.b2 {
                border: 1px solid #0072f9;
                background: linear-gradient(
                    136.01deg,
                    #9acfff 13.99%,
                    #43a5ff 26.98%,
                    #0063f9 64.1%,
                    #0072f9 85.26%
                );
            }
            &.b3 {
                border: 1px solid #921c1c;
                background: linear-gradient(
                    138.27deg,
                    #ffdfd7 0.36%,
                    #ce1515 37.39%,
                    #921c1c 79.46%
                );
            }
        }
    }
    .ant-input {
        border-radius: 12px;
        border: 2px solid rgba(255, 255, 255, 0.65);
        background: rgba(255, 255, 255, 0.02);
        backdrop-filter: blur(12px);
        width: 171px;
        height: 80px;
        color: var(--White, #fff);
        font-family: "ALS Hauss";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        max-height: 100%;
        padding-left: 102px;
        @media screen and (max-width: 992px) {
            width: 82px;
            height: 48px;
            padding-left: 44px;
            padding-right: 14px;
            font-size: 12px;
            border-radius: 8px;
            border: 1.5px solid rgba(255, 255, 255, 0.65);
            background: rgba(255, 255, 255, 0.02);
            backdrop-filter: blur(12px);
        }
        @media screen and (max-width: 768px) {
            height: 36px;
        }
    }
}

.custom-overlay-container {
    position: relative;
    width: 100%;
    padding: 6px;
    cursor: pointer;
    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background: url("../../../images/actions_boarder.png") no-repeat;
        background-size: 100% 100%;
    }
    .actions-open {
        img {
            transform: rotate(90deg);
        }
    }
}
.vid {
    margin: auto;
}
.mt-0 {
    margin-top: 38px !important;
}
.mt-6 {
    margin-top: -6px !important;
}
.custom-overlay {
    top: 64px !important;
    left: initial !important;
    right: 0px !important;
    &.over2 {
        .ant-dropdown-menu {
            width: 786px;
        }
    }
    .ant-dropdown-menu {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 56px 64px 40px;
        gap: 24px;
        width: 992px;
        background: rgba(255, 255, 255, 0.01) !important;
        border: 2px solid rgba(255, 255, 255, 0.65);
        border-radius: 12px !important;
        filter: none !important;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            margin: auto;
            background: rgba(255, 255, 255, 0.01) !important;
            backdrop-filter: blur(81px);
        }

        .ant-dropdown-menu-item {
            background: transparent;
            height: 100%;
            width: 100%;
            overflow: hidden;
            font-family: "ALS Hauss";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 17px;
            color: #ffffff;
            white-space: pre-line;
            text-align: left;
            padding: 0;
            cursor: default;
            a {
                text-decoration-line: underline;
                color: #ffffff;
            }
            .award-position {
                font-family: "ALS Hauss";
                font-style: normal;
                font-weight: 500;
                font-size: 17px;
                line-height: 120%;
                color: #ffffff;
            }
            .award-name {
                font-family: "ALS Alumna";
                font-style: normal;
                font-weight: 400;
                font-size: 23px;
                line-height: 100%;
                color: #ffce73;
            }
        }
    }
}

.bl-lottery-page {
    margin: auto;
    margin-top: -20px;
    min-height: 1200px;
    @media screen and (max-width: 992px) {
        min-height: auto;
    }

    .bz-title {
        font-family: "ALS Alumna";
        font-style: normal;
        font-weight: 400;
        font-size: 82px;
        line-height: 120%;
        background: linear-gradient(141.08deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        filter: drop-shadow(2px 2px #3d074f);
        margin-bottom: 65px;
        margin-top: 36px;

        @media screen and (max-width: 768px) {
            text-align: center;
            font-size: 32px;
            line-height: 100%;
            margin-top: 20px;
            white-space: pre-line;
        }
    }
    .bl-lottery-ng-prize__title1 {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 15px;
        .bl-lottery-ng-prize__title__text1 {
            font-size: 170px;
            font-style: normal;
            font-family: "ALS Belbak";
            text-fill-color: transparent;
            -webkit-text-fill-color: #0000;
            animation: shine 4s linear infinite;
            background-clip: text;
            -webkit-background-clip: text;
            background-image: linear-gradient(
                90deg,
                #fff7d8,
                #fef0b7,
                #efce73,
                #f0c78a,
                #efce73,
                #fef0b7,
                #fff7d8
            );
            background-size: 200% auto;
            filter: drop-shadow(6px 2px 0px #3d074f);
            @keyframes shine {
                100% {
                    background-position: 200% center;
                }
            }
            @media screen and (max-width: 431px) {
                font-size: 62px;
            }
        }
        @media screen and (max-width: 431px) {
            top: 7px;
        }
    }
    .text-prizes {
        color: #fff;
        text-align: center;
        font-family: "ALS Hauss";
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
    }

    .lottery-container {
        @media screen and (max-width: 768px) {
            margin-top: 12px;
        }
        .col-lottery-rules-container {
            display: flex;
            align-items: flex-end;
        }
        .lottery-video {
            -moz-border-radius: 12px;
            border-radius: 12px;
            overflow: hidden;
            @media screen and (max-width: 992px) {
                margin: -8px auto 4px;
                max-width: 468px;
            }
            iframe {
                width: 100%;
                max-height: 316px;
            }
        }
        .lottery-timer-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 168px;
            width: 100%;
            picture {
                position: relative;
                left: 100px;
                @media screen and (max-width: 992px) {
                    left: 0;
                }
            }
            .lottery-rules-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
                width: 100%;
                @media screen and (max-width: 992px) {
                    gap: 8px;
                    margin-bottom: 10px;
                }
                .ant-btn,
                .actions-open {
                    padding: 16px 24px;
                    width: 100%;
                    height: 49px;
                    background: -webkit-linear-gradient(
                        141.08deg,
                        #efce73 25.79%,
                        #fef0b7 63.66%,
                        #cea86f 97.07%
                    );
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    filter: drop-shadow(2px 2px #2a1901);
                    backdrop-filter: blur(12px);
                    border-radius: 12px;
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    justify-content: space-between;
                    font-family: "ALS Alumna";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 120%;
                    box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.2);
                    @media screen and (max-width: 768px) {
                        width: 100%;
                        height: 43px;
                        font-size: 18px;
                        line-height: 140%;
                        padding: 16px;
                        span {
                            position: relative;
                            top: 1px;
                        }
                    }
                    &.ant-dropdown-open {
                        svg {
                            transform: rotate(-180deg);
                        }
                    }
                }
                .countdown {
                    width: 100%;
                }
            }
        }
        .lottery-countdown-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 24px;
            margin-top: -12px;
            z-index: -1;
            position: relative;
            @media screen and (max-width: 992px) {
                margin-top: -9px !important;
            }
            @media screen and (max-width: 767px) {
                margin-top: -9px !important;
                &.mt-10 {
                    margin-top: -9px;
                }
            }
            .countdown {
                position: relative;
                top: 8px;
                width: 100%;
                .timer-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 19px 16px;
                    gap: 10px;
                    height: 134px;
                    background: linear-gradient(147.02deg, #642878 9.91%, #25062f 86.34%);
                    backdrop-filter: blur(15px);
                    border-radius: 12px;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    min-width: 482px;
                    @media screen and (max-width: 992px) {
                        max-width: 468px;
                        min-width: 100%;
                    }
                    @media screen and (max-width: 768px) {
                        max-width: 343px;
                        height: 108px;
                        .ant-space {
                            gap: 16px !important;
                            .mr-9 {
                                position: relative;
                                right: -9px;
                            }
                        }
                    }
                    .lottery-timer-text {
                        font-family: "ALS Alumna";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 62px;
                        line-height: 62px;
                        text-align: center;
                        background: linear-gradient(
                            141.08deg,
                            #efce73 25.79%,
                            #fef0b7 63.66%,
                            #cea86f 97.07%
                        );
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                        @media screen and (max-width: 768px) {
                            font-size: 50px;
                            line-height: normal;
                            width: auto !important;
                        }
                    }
                    .tiny {
                        font-family: "ALS Hauss";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        leading-trim: both;
                        text-edge: cap;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        background: linear-gradient(
                            141.08deg,
                            #efce73 25.79%,
                            #fef0b7 63.66%,
                            #cea86f 97.07%
                        );
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                        margin-top: -12px;
                    }
                }
                .lottery-start {
                    font-family: "ALS Hauss";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 120%;
                    text-align: center;
                    color: var(--65, rgba(255, 255, 255, 0.65));
                    margin: 0;
                    white-space: pre;
                    @media screen and (max-width: 992px) {
                        font-size: 15px;
                        font-weight: 400;
                    }
                    @media screen and (max-width: 768px) {
                        font-size: 12px;
                    }
                }
            }
            .summury {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 24px;
                @media screen and (max-width: 992px) {
                    gap: 8px;
                }
                .all-summury {
                    background-image: radial-gradient(
                            circle at 100% 100%,
                            transparent 10px,
                            #ffeaaf 10px,
                            #ffeaaf 12px,
                            transparent 12px
                        ),
                        linear-gradient(to right, #ffeaaf, #816867),
                        radial-gradient(
                            circle at 0% 100%,
                            transparent 10px,
                            #816867 10px,
                            #816867 12px,
                            transparent 12px
                        ),
                        linear-gradient(to bottom, #816867, #2a0b31),
                        radial-gradient(
                            circle at 0% 0%,
                            transparent 10px,
                            #2a0b31 10px,
                            #2a0b31 12px,
                            transparent 12px
                        ),
                        linear-gradient(to left, #2a0b31, #9c8477),
                        radial-gradient(
                            circle at 100% 0%,
                            transparent 10px,
                            #9c8477 10px,
                            #9c8477 12px,
                            transparent 12px
                        ),
                        linear-gradient(to top, #9c8477, #ffeaaf);
                    background-size: 12px 12px, calc(100% - 24px) 2px, 12px 12px,
                        2px calc(100% - 24px);
                    background-position: top left, top center, top right, center right, bottom right,
                        bottom center, bottom left, center left;
                    background-repeat: no-repeat;
                    padding: 2px;
                    .lottery-summary {
                        position: relative;
                        height: 216px;
                        gap: 0;
                        min-width: 222px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 19px 16px;
                        background: var(
                            --Radial,
                            radial-gradient(58.68% 51.2% at 47.81% 49.5%, #642878 0%, #25062f 100%)
                        );
                        backdrop-filter: blur(15px);
                        border-radius: 10px;
                        flex: none;
                        order: 0;
                        flex-grow: 0;
                        @media screen and (max-width: 992px) {
                            min-width: 228px;
                        }
                        @media screen and (max-width: 768px) {
                            min-width: auto;
                            width: 168px;
                            height: 86px;
                            padding-bottom: 28px;
                        }
                        @media screen and (max-width: 340px) {
                            width: 140px;
                            padding: 19px 8px;
                        }
                        .summary-value {
                            height: 126px;
                            font-family: "ALS Alumna";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 142px;
                            line-height: normal;
                            leading-trim: both;
                            text-edge: cap;
                            display: flex;
                            align-items: center;
                            text-align: center;
                            background: -webkit-linear-gradient(
                                141.08deg,
                                #efce73 25.79%,
                                #fef0b7 63.66%,
                                #cea86f 97.07%
                            );
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            filter: drop-shadow(2px 2px #2a1901);
                            @media screen and (max-width: 768px) {
                                font-size: 50px;
                                line-height: normal;
                                padding-top: 11px;
                                height: 55px;
                            }
                        }
                        .summary-title {
                            font-family: "ALS Hauss";
                            font-style: normal;
                            font-weight: 500;
                            font-size: 17px;
                            text-align: center;
                            background: linear-gradient(
                                141.08deg,
                                #efce73 25.79%,
                                #fef0b7 63.66%,
                                #cea86f 97.07%
                            );
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                            text-fill-color: transparent;
                            white-space: pre;
                            height: 34px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            line-height: normal;
                            @media screen and (max-width: 768px) {
                                font-size: 12px;
                                height: 28px;
                            }
                        }
                        .conteiner-summary-title {
                            width: 100%;
                            position: relative;
                            &::before {
                                content: "";
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 0;
                                bottom: 0;
                                margin: auto;
                                background: url("../../../images/actions_boarder.png") no-repeat;
                                background-size: 100% 100%;
                                box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.65);
                            }
                        }
                    }
                }
            }
        }
        .lottery-table-container {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            padding: 24px;
            gap: 24px;
            width: 683px;
            height: 96px;
            background: rgba(0, 0, 0, 0.25);
            backdrop-filter: blur(12px);
            border-radius: 12px;
            margin: 40px auto;
            @media screen and (max-width: 992px) {
                height: auto;
                text-align: center;
                max-width: 468px;
                background: transparent;
                backdrop-filter: blur(0px);
                padding: 16px 0 0;
                margin: 0 auto;
                .ant-row {
                    row-gap: 16px !important;
                    .ant-col {
                        flex: 0 0 100%;
                        max-width: 100%;
                        &.lottery-register {
                            text-align: center;
                            .ant-btn {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            @media screen and (max-width: 768px) {
                max-width: 343px;
            }
            @media screen and (max-width: 360px) {
                max-width: 100%;
            }
            .lottery-text-register {
                font-family: "ALS Hauss";
                font-style: normal;
                font-weight: 500;
                font-size: 17px;
                line-height: 22px;
                color: #ffffff;
                @media screen and (max-width: 768px) {
                    font-size: 14px;
                    line-height: normal;
                }
            }
            .ant-btn {
                font-family: "ALS Hauss";
                font-style: normal;
                font-weight: 700;
                font-size: 17px;
                line-height: 120%;
                color: #1f1f1f;
                background-size: 100% 100%;
                @media screen and (max-width: 768px) {
                    font-size: 14px;
                    line-height: 140%;
                }
            }
        }
        .lottery-result-container {
            margin-top: 40px;
            @media screen and (max-width: 992px) {
                max-width: 468px;
                margin: 16px auto;
            }
            @media screen and (max-width: 430px) {
                .lottery-table {
                    tr {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        .col-activated-code {
                            padding-top: 12px;
                        }
                    }
                }
            }
            .lottery-empty-result {
                &.is-search {
                    .bz-block-content {
                        max-width: 665px;
                        margin: auto;
                        @media screen and (max-width: 768px) {
                            max-width: 343px;
                        }
                        @media screen and (max-width: 360px) {
                            max-width: 100%;
                        }
                    }
                }
                @media screen and (max-width: 992px) {
                    padding: 0;
                    margin-top: 32px;
                }
                .bz-block-content {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 24px;
                    gap: 40px;
                    width: 893.63px;
                    height: 175px;
                    background: rgba(0, 0, 0, 0.25);
                    backdrop-filter: blur(12px);
                    border-radius: 12px;
                    margin: auto;
                    @media screen and (max-width: 992px) {
                        max-width: 468px;
                        height: auto;
                        flex-direction: column;
                        padding: 16px 0;
                        margin: 0 auto;
                        border: 1.5px solid rgba(255, 255, 255, 0.5);
                        background: rgba(0, 0, 0, 0.25);
                        gap: 24px;
                    }
                    @media screen and (max-width: 768px) {
                        max-width: 343px;
                    }
                    @media screen and (max-width: 360px) {
                        max-width: 100%;
                    }
                    picture {
                    }
                    .text-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        .title {
                            font-family: "ALS Hauss";
                            font-style: normal;
                            font-weight: 500;
                            font-size: 24px;
                            line-height: 120%;
                            leading-trim: both;
                            text-edge: cap;
                            color: #ffffff;
                            margin-top: 0;
                            @media screen and (max-width: 992px) {
                                font-size: 18px !important;
                                white-space: pre;
                                margin: auto;
                            }
                            @media screen and (max-width: 768px) {
                                font-size: 17px !important;
                                font-weight: 700;
                            }
                        }
                        .text {
                            margin-bottom: 0;
                            font-family: "ALS Hauss";
                            font-style: normal;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 120%;
                            leading-trim: both;
                            text-edge: cap;
                            color: rgba(255, 255, 255, 0.5);
                            @media screen and (max-width: 992px) {
                                font-size: 16px !important;
                                white-space: pre;
                                margin: auto;
                            }
                            @media screen and (max-width: 768px) {
                                font-size: 14px !important;
                                line-height: 140%;
                            }
                        }
                    }
                }
            }
        }
    }

    .lottery-content {
        padding-top: 30px;
        padding-bottom: 24px;
    }
    .lottery-name {
        margin-bottom: 70px;
        text-align: center;
        @include bigFont-medium;
    }
    .rules-description {
        white-space: pre-line;
        color: $text-primary-color;
    }
    .award-position {
        color: $text-primary-color;
        @include standardFont-medium;
    }
    .award-name {
        @include title-tinyFont-normal;
    }
    .lottery-summary {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .summary-title {
            white-space: normal;
            color: $text-pale-color;
            @include bigFont-normal;
        }

        .summary-value {
            @include title-hugeFont-normal;
        }
    }
    .lottery-start {
        margin-top: 24px;
        margin-bottom: 64px;
        text-align: center;
        white-space: pre-line;
        @include title-tinyFont-normal;
    }
    .lottery-table-wrapper {
        padding-top: 80px;
    }
    .lottery-register {
        text-align: end;
    }
    .lottery-text-register {
        white-space: pre-line;
        @include standardFont-bold;
    }
    .lottery-btn-register {
        min-width: 252px;
    }
    .lottery-empty-result {
        border: none;
        text-align: center;
        .title {
            margin-top: 40px;
            @include bigFont-medium;
        }
        .text {
            margin-bottom: 16px;
            color: $text-pale-color;
            @include standardFont-medium;
        }
    }
    .lottery-table {
        .ant-table-tbody {
            .ant-table-row {
                &:hover {
                    td {
                        &.col-phone {
                            -webkit-text-fill-color: transparent;
                            background: linear-gradient(
                                141.08deg,
                                #efce73 25.79%,
                                #fef0b7 63.66%,
                                #cea86f 97.07%
                            );
                            -webkit-background-clip: text;
                            background-origin: content-box;
                            background-repeat: no-repeat;
                            padding: 16px;

                            &.inActive {
                                color: $text-pale-color;
                                -webkit-text-fill-color: $text-pale-color;
                                background: transparent;
                            }
                        }
                    }
                }
            }
        }
        .col-phone {
            @include bigFont-medium;
            color: $text-primary-color;

            &.inActive {
                color: $text-pale-color;
            }
        }
        .col-activated {
            @include standardFont-normal;
            color: $text-primary-color;
            text-align: end;
        }
        .col-code {
            @include tinyFont-medium;
            color: $text-pale-color;
            text-align: end;
        }
        thead {
            display: none;
        }
        .ant-table-tbody {
            tr {
                td {
                    border-bottom: 1px solid $text-pale-color;
                    @media screen and (max-width: 431px) {
                        border-bottom: none;
                    }
                }
                &:last-child {
                    td {
                        border-bottom: none;
                    }
                }
            }
        }
    }
    .lottery-table-btn {
        margin-top: 35px;
        text-align: center;

        button {
            min-width: 252px;
        }
    }
    .bl-balls {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        &-wrapper {
            display: contents;
        }
        @media screen and (max-width: 414px) {
            &-wrapper {
                display: flex;
                justify-content: flex-end;
                margin-top: 6px;
            }
            justify-content: initial;
            align-items: initial;
            flex-direction: column;
            & > p {
                display: none;
            }
        }
        @media screen and (min-width: 414px) {
            margin-top: 5px;
        }
        .balls-0,
        .balls-1,
        .balls-2 {
            margin-right: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            @include numberFont-normal;
            color: $text-primary-color;
        }
        .balls-0 {
            margin-left: 16px;
            background: linear-gradient(
                136.01deg,
                #aeaeae 13.99%,
                #858585 26.98%,
                #323232 64.1%,
                #000000 85.26%
            );
        }
        .balls-1 {
            background: linear-gradient(
                136.01deg,
                #9acfff 13.99%,
                #43a5ff 26.98%,
                #0063f9 64.1%,
                #0072f9 85.26%
            );
        }
        .balls-2 {
            background: linear-gradient(138.27deg, #ffdfd7 0.36%, #ce1515 37.39%, #921c1c 79.46%);
            margin-right: 0px;
        }
    }

    @media screen and (max-width: $max-phone-width) {
        .lottery-name {
            margin-bottom: 35px;
        }
        .lottery-content {
            padding-bottom: 16px;
        }
        .lottery-collapse {
            justify-content: start;
            .ant-collapse-header {
                justify-content: start;
                padding: 10px 16px;
                .ant-collapse-header-text {
                    @include standardFont-bold;
                }
            }
            .ant-collapse-content-box {
                padding: 16px 16px !important;
            }
        }
        .lottery-video {
            iframe {
                width: 100%;
            }
        }
        .lottery-timer {
            picture {
                img {
                    width: 328px;
                }
            }
        }
        .lottery-timer-timer {
            margin-bottom: 10px;
        }
        .timer-container {
            min-width: 358px;
            min-height: 117px;
            padding: 7px 10px;
            @media screen and (max-width: 370px) {
                min-width: 327px;
            }
        }
        .lottery-start {
            margin-top: 16px;
            margin-bottom: 22px;
            @include standardFont-normal;
        }
        .lottery-summary {
            padding: 24px 5px;
            gap: 8px;
            .summary-title {
                @include tinyFont-medium;
            }
            .summary-value {
                @include title-smallFont-normal;
            }
        }
        .lottery-table-wrapper {
            padding-top: 24px;
        }
        .lottery-register {
            text-align: center;
        }
        .lottery-text-register {
            text-align: center;
            @include tinyFont-medium;
        }
        .lottery-btn-register {
            min-width: 100%;
        }
        .lottery-table {
            .col-phone {
                @include title-tinyFont-normal;
                @media screen and (max-width: 430px) {
                    padding-bottom: 22px !important;
                    padding-top: 12px;
                    &::before {
                        content: "Код розыгрыша:";
                        position: absolute;
                        top: 41px;
                        left: 18px;
                        font-family: ALS Hauss;
                        font-size: 14px;
                        line-height: 19px;
                        font-weight: 500;
                        color: rgba(255, 255, 255, 0.5);
                        -webkit-text-fill-color: rgba(255, 255, 255, 0.5);
                        @media screen and (min-width: 350px) {
                            font-size: 12px;
                        }
                    }
                }
                @media screen and (max-width: 350px) {
                    padding-right: 0px;
                }
            }
            .col-activated {
                @include tinyFont-medium;
            }
            .col-activated-code {
                padding-bottom: 10px;
            }
        }
    }

    @media screen and (max-width: 350px) {
        .lottery-summary {
            .summary-title {
                white-space: pre-line;
            }
        }
    }
}
.bl-lottery-block2-drawer {
    .ant-drawer-mask {
        background: transparent !important;
    }
    .ant-drawer-content-wrapper {
        @media screen and (max-width: 992px) {
            width: 100% !important;
        }
        .ant-drawer-wrapper-body {
            padding: 0 60px;
            @media screen and (max-width: 992px) {
                padding: 0 12px;
            }
            @media screen and (max-width: 370px) {
                padding: 0 8px;
            }

            .ant-drawer-body {
                -ms-overflow-style: none;
                scrollbar-width: none;
                padding: 55px 24px 68px;
                white-space: pre-line;
                @media screen and (max-width: 992px) {
                    padding: 22px 0 40px;
                    overflow-x: hidden;
                }
                .bl-lottery-ng-prize {
                    width: 100%;
                    height: auto;
                    .bl-lottery-ng-prize__title {
                        top: -20px;
                    }
                    @media screen and (max-width: 767px) {
                        display: none;
                    }
                    .bl-lottery-ng-prize__title1 {
                        position: absolute;
                        z-index: 2;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        top: 8px;
                        .bl-lottery-ng-prize__title__text1 {
                            font-size: 96px;
                            line-height: normal;
                            font-style: normal;
                            font-family: "ALS Belbak";
                            text-fill-color: transparent;
                            -webkit-text-fill-color: #0000;
                            animation: shine 4s linear infinite;
                            background-clip: text;
                            -webkit-background-clip: text;
                            background-image: linear-gradient(
                                90deg,
                                #fff7d8,
                                #fef0b7,
                                #efce73,
                                #f0c78a,
                                #efce73,
                                #fef0b7,
                                #fff7d8
                            );
                            background-size: 200% auto;
                            filter: drop-shadow(6px 2px 0px #3d074f);
                            @keyframes shine {
                                100% {
                                    background-position: 200% center;
                                }
                            }
                            @media screen and (max-width: 431px) {
                                font-size: 62px;
                            }
                        }
                        @media screen and (max-width: 431px) {
                            top: 7px;
                        }
                    }
                }
                a {
                    text-decoration-line: underline;
                    color: #ffffff;
                }
                .title-all-prizes {
                    text-align: center;
                    font-family: "ALS Hauss";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%;
                    background: var(
                        --Linear,
                        linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%)
                    );
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            .ant-drawer-body::-webkit-scrollbar {
                display: none;
            }
        }
        .ant-drawer-header {
            border: 0;
            padding: 0;
            .ant-drawer-header-title {
                justify-content: flex-end;
                .ant-drawer-close {
                    padding: 0;
                    margin: 0;
                    position: absolute;
                    top: 55px;
                    right: 46px;
                    z-index: 100;
                    @media screen and (max-width: 992px) {
                        top: 23px;
                        right: 18px;
                    }
                }
            }
        }
        .ant-drawer-content {
            background: rgba(255, 255, 255, 0.05);
            backdrop-filter: blur(50px);
            .actions {
                text-align: center;
                margin-top: 24px;
                @media screen and (max-width: 992px) {
                    margin-top: 12px;
                }
                .ant-btn {
                    border-radius: 12px;
                    background-size: 100% 100%;
                    width: 343px;
                    height: 48px;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    @media screen and (max-width: 992px) {
                        width: 189px;
                        height: 43px;
                    }
                }
            }
        }
    }

    .bl-lot-awards {
        h2 {
            color: var(--White, #fff);
            text-align: center;
            font-family: "ALS Hauss";
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 42px;
            @media screen and (max-width: 992px) {
                font-size: 24px;
            }
            @media screen and (max-width: 360px) {
                font-size: 22px;
                margin-bottom: 32px;
            }
        }
        div,
        p,
        li {
            color: var(--White, #fff);
            font-family: "ALS Hauss";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            @media screen and (max-width: 992px) {
                font-size: 16px;
                line-height: 18px;
            }
            @media screen and (max-width: 360px) {
                font-size: 14px;
                line-height: 16px;
            }
        }
        strong {
            color: var(--White, #fff);
            leading-trim: both;
            text-edge: cap;
            font-family: "ALS Hauss";
            font-size: 24px;
            font-style: normal;
            font-weight: 550;
            line-height: 28px;
            margin: 12px 0 12px;
            @media screen and (max-width: 992px) {
                font-size: 20px;
            }
            @media screen and (max-width: 360px) {
                font-size: 18px;
                line-height: 20px;
            }
        }
        .title {
            text-align: center;
            leading-trim: both;
            text-edge: cap;
            font-family: "ALS Hauss";
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            background: var(
                --Linear,
                linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%)
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 32px;
            @media screen and (max-width: 992px) {
                font-size: 23px;
                text-align: start;
            }
        }
        .row-places {
            justify-content: center;
            gap: 16px;

            .bl-places-one-till-five {
                display: flex;
                justify-content: center;
                flex-direction: column;
                gap: 62px;
                margin-bottom: 16px;

                @media screen and (max-width: 992px) {
                    gap: 40px;
                    margin-bottom: 0;
                }

                &__images {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 16px;

                    @media screen and (max-width: 992px) {
                        gap: 18px;
                    }

                    img {
                        height: auto !important;

                        @media screen and (max-width: 992px) {
                            width: 112px !important;
                        }
                    }
                }

                .places-row {
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    flex-direction: row;
                    gap: 11px;

                    @media screen and (max-width: 992px) {
                        gap: 4px;
                    }
                }
            }
            .bl-places-six-till-end {
                width: 387px;
                margin: 0 auto 32px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                gap: 4px;

                @media screen and (max-width: 992px) {
                    width: 100%;
                }

                .place-item {
                    display: flex;
                    padding: 10px 64px;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                    border-radius: 8px;
                    border-top: 1px solid var(--unnamed, #ffeaaf);
                    border-bottom: 1px solid var(--unnamed, #ffeaaf);
                    background: rgba(255, 255, 255, 0.05);
                    -webkit-box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.2);
                    -moz-box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.2);
                    box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.2);
                    backdrop-filter: blur(50px);
                    width: 387px;
                    margin: auto;

                    @media screen and (max-width: 430px) {
                        width: 100%;
                        max-width: 387px;
                    }

                    @media screen and (max-width: 410px) {
                        padding: 10px 50px;
                    }

                    @media screen and (max-width: 375px) {
                        padding: 10px 44px;
                    }

                    @media screen and (max-width: 360px) {
                        padding: 10px 36px;
                    }

                    &__title {
                        color: var(--Color-8, #fff);
                        font-family: "ALS Hauss";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%;
                    }
                }
            }
        }
    }
}
