.modal-pay-card-or-bonuses {
    .modal-pay-default-description {
        text-align: center;
        font-family: "ALS Hauss";
        font-size: 17px;
        margin-top: 10px;
        margin-bottom: 30px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }
    .modal-pay-default-number {
        vertical-align: text-bottom;
    }
    .button-pay-with-bonuses {
        color: #642878;
        border: 1px solid #642878;
    }
    .ant-modal-footer {
        margin-top: initial;
    }
    .activation-modal--description {
        text-align: center;
    }
    .removement-modal--number {
        text-align: center;
    }
    @media screen and (max-width: 600px) {
        position: relative;
        max-width: calc(100% - 16px);
        .ant-modal-content {
            border-radius: 16px;
        }
    }
    @media screen and (min-width: 400px) and (max-width: 430px) {
        .ant-modal-content {
            padding: 30px 16px;
        }
    }
}
