.autopayment-modal--description-defaultCreate {
    .autopayment-form--description-defaultCreate-space {
        font-family: "ALS Hauss";
        font-style: normal;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        padding: 10px 80px 39px;
        color: #828282;
        span {
            color: #9876a4;
            vertical-align: baseline;
        }
        &-saved-cards {
            font-family: "ALS Hauss";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            padding: 32px 0px 9px;
        }
        @media screen and (max-width: 480px) {
            padding: 10px 0px 39px;
        }
    }
    .field-phone {
        .ant-form-item-control-input {
            max-width: 300px;
            margin-top: 15px;
        }
    }
    .autopayment-form--description-defaultCreate-wrapper {
        .ant-form-item + div {
            margin-bottom: 30px;
            margin-top: 8px;
            cursor: pointer;
            text-align: center;
        }
        .description-no-saved-cards-modal {
            color: black;
            text-align: center;
            margin: 10px 0px 20px;
            &-add {
                color: black;
                text-align: center;
                margin: 8px 0px 30px;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .ant-space-vertical > .ant-space-item > .bz-typography {
            font-family: "ALS Hauss";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
        }
    }
    .autopayment-modal--cancel-btn-defaultCreate {
        display: block;
        margin: 0 auto;
    }
}
