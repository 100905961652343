.page-nps {
    margin-top: -224px;
    background: url("../../../../images/npspagebg3.jpg") no-repeat;
    background-size: cover;
    @media screen and (max-width: 1440px) {
        background: url("../../../../images/npspagebg.jpg") no-repeat;
        background-size: cover;
    }
    @media screen and (max-width: 768px) {
        background: url("../../../../images/npspagebg2.jpg") no-repeat;
        background-size: cover;
        margin-top: -200px;
        .p0 {
            padding: 0 !important;
        }
    }
    @media screen and (max-width: 430px) {
        margin-inline: -1px;
    }
    .bl-content {
        max-width: 1270px;
        @media screen and (max-width: 768px) {
            position: relative;
        }
    }
    .ui-back {
        top: 160px;
        // left: 16px;
        // left: calc(50% - 564px);
        @media screen and (max-width: 1200px) {
            left: 14px;
        }
        @media screen and (max-width: 768px) {
            left: 16px;
            top: -27px;
            svg {
                width: 29px;
            }
        }
    }
    @media screen and (min-width: 1270px) {
        .ant-col-lg-10 {
            max-width: 510px;
        }
        .ant-col-lg-14 {
            min-width: 744px;
        }
    }
}
