.page-book-reservations {
    .bl-title {
        span {
            border: 0;
            outline: none;
            text-align: center;
            font-weight: 400;
            font-size: 79px;
            line-height: 60px;
            background: linear-gradient(141.08deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-repeat: no-repeat;
            background-origin: content-box;
            padding: 1px;
            width: 100%;
            height: 100%;
        }
    }
    .tariff {
        font-weight: 400;
        line-height: 120%;
    }
}

@media screen and (max-width: 768px) {
    .page-book-reservations {
        .bl-title {
            span {
                font-weight: 400;
                font-size: 48px;
                line-height: 120%;
            }
        }
        .tariff {
            font-weight: 500;
            line-height: 120%;
        }
        .ui-back {
            margin-top: -27px;
        }
    }
}

@media screen and (max-width: 374px) {
    .page-book-reservations {
        .bl-title {
            span {
                font-weight: 400;
                font-size: 40px;
                line-height: 120%;
            }
        }
    }
}
