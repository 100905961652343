.bl-info-common {
    .info-packet-subtitle {
        font-family: ALS Alumna, sans-serif;
        font-size: 36px;
        line-height: 36px;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: 8px;
        &-gray,
        &-gray-bezlimit {
            color: rgba(255, 255, 255, 0.5);
            margin-bottom: 0px;
        }
    }
    .info-packet-value {
        font-family: ALS Alumna, sans-serif;
        font-size: 79px;
        line-height: 60px;
        font-weight: 400;
        margin-bottom: 0;
    }
    .info-common-rest {
        display: block;
        font-family: "ALS Hauss";
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 100%;
        background: linear-gradient(141.08deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-repeat: no-repeat;
        background-origin: content-box;
        text-align: center;
        margin-bottom: 0;
    }

    .bl-info-common-row {
        row-gap: 10px;
        width: 100%;
        margin: 0px !important;
    }
    .bl-info-common-wrapper {
        flex-direction: row !important;
        width: 100%;
        > .ant-space-item {
            width: 33.3%;
        }
    }
    .bl-info-common-item {
        width: 33.3%;
    }
    .info-packet-subtitle {
        font-weight: 500;
        font-family: "ALS Hauss";
        font-size: 16px !important;
        line-height: 120%;
        &-gray,
        &-gray-bezlimit {
            margin-top: 8px;
            color: #ffffff;
        }
    }
    .info-packet-value {
        font-family: ALS Alumna, sans-serif;
        font-size: 28px;
        line-height: 28px;
        font-weight: 400;
    }
    .info-packet-sub {
        font-family: "ALS Hauss";
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
    }
}

@media screen and (max-width: 1200px) {
    .bl-info-common {
        .info-packet-subtitle {
            font-size: 14px;
        }
    }
}
