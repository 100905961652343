.ant-form-item {
  margin: 0 0 16px;
}
.ant-input-affix-wrapper, .ant-input-affix-wrapper-has-feedback, 
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
}
.ant-input, 
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input{
  color: #000;
}
.ant-input-affix-wrapper-status-success {
  .ant-form-item-feedback-icon-success {
    display: none;
  }
}
.ant-input-affix-wrapper-status-error {
  .ant-input-password-icon {
    // display: none;
  }
}
.ant-input-affix-wrapper-status-error {
  .ant-form-item-feedback-icon-error {
    svg {
      display: none;
    }
    .anticon-close-circle {
      &::before {
        content: '';
        display: block;
        background: url('../../images/fall.svg') no-repeat;
        width: 16px;
        height: 16px;
      }
    }
  }
}
.bz-icon.ant-input-password-icon {
  width: 22px;
}
.ant-input-suffix>:not(:last-child) {
  margin: 0;
}
.bz-input-block .title {
  color: #828282;
}
.bz-input-group-numbers {
  justify-content: center;
  grid-gap: 6px;
  input {
    background: #CFC8DB;
    font-family: 'ALS Alumna';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    color: #642878;
    width: 37px;
    border-radius: 12px;
    height: 48px;
  }
}
.bz-input-group-numbers-error {
  input {
    border-color: hsla(0,0%,100%,.3);
  }
}
.bz-input-group-numbers-item {
  input {
    &:disabled {
      opacity: .5;
    }
  }
  .error-text{
    &.error-show {
      padding: 0;
    }
    &.error-hide {
      padding: 0;
    }
  }
}
.ant-btn.ant-btn-text{
  color: #000;
  padding: 0;
  height: auto;
  &:active {
    background: transparent;
  }
}

@media screen and (max-width: 768px) {
  .bz-icon.ant-input-password-icon {
    width: 16px;
  }
  .ant-btn {
    font-size: 14px;
  }
}
@media screen and (max-width: 300px) {
  .ant-btn, .ant-input-affix-wrapper  {
    height: 40px;
  }
}
