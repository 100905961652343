.bl-success {
    text-align: center;
    h2 {
        font-weight: 500;
        > * {
            color: #000;
        }
    }
    .text {
        line-height: 19px;
    }
    .subtitle {
        > * {
            color: #000;
        }
    }
    .bl-restore-access-btn-recycle {
        margin-top: 24px;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .bl-success {
        .ant-space-item:nth-child(3) {
            margin-top: -10px;
        }
        h2 {
            font-weight: 700;
        }
    }
}
