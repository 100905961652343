.bl-group-loyalty-shimmer {
    overflow: hidden;
    .group-coll {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        max-width: 930px;
        margin: auto;
        grid-gap: 12px;
        flex-direction: column;
        .group-count {
            .block-1 {
                width: 365px;
                height: 30px;
                border-radius: 8px;
                background: hsla(0, 1%, 1%, 0);
                @media screen and (max-width: 370px) {
                    width: 340px;
                }
            }
            .block-2 {
                width: 340px;
                height: 154px;
                border-radius: 8px;
                background: hsla(0, 1%, 1%, 0);
                @media screen and (max-width: 370px) {
                    width: 320px;
                }
            }
        }
        @media screen and (max-width: 425px) {
            gap: 24px;
            flex-wrap: nowrap;
            overflow: auto;
            justify-content: flex-start;
            padding: 0 15px;
            padding-bottom: 8px;
            margin-bottom: -8px;
        }
    }
}
