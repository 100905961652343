.svgAssetHidden {
    visibility:hidden;
    position:absolute;
    width:0px;
    height:0px;
    overflow:hidden;
}
.bl-delivery-sim {
    .bl-title {
        text-align: center;
        margin-bottom: 60px;
    }
    .title-mobile{
        text-align: center;
        margin-bottom: 38px;
    }
    .bl-back{
        margin-top: 0;
    }
    .tabs-container {
        .ant-tabs-tab {
            &:hover {
                .ant-tabs-tab-btn {
                    color: #fef0b7;
                }
            }
        }
    }
    .ant-tabs-nav{
        .ant-tabs-nav-more{
            display: none;
        }
    }
    .btn-deliveries-support {
        text-align: center;
        margin-top: 24px;
    }
    .bl-default-delivery {
        .text {
            text-align: center;
            .title-default {
                font-weight: 500;
                font-size: 24px;
            }
            .sub-title-default {
                font-weight: 500;
                font-size: 17px;
                color: rgba(255, 255, 255, 0.5);
            }
            .images{
                margin-bottom: 32px;
            }
        }
        .btn-default{
            margin-top: 40px;
            .ant-btn{
                width: 100%;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .bl-delivery-sim {
        .ui-back{
          .ant-btn{
            svg{
              top: -5px;
            }
          }
      }
    }
  }
