.bl-numbers-list-info {
    .lightning {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        opacity: 0;
        transition: none;
    }
    .dealer-id-kanban {
        font-weight: 500;
        line-height: 100%;
        margin-bottom: 31px;
    }
}
