.bl-field-tariff {
  .ant-breadcrumb-separator {
    svg {
      position: relative;
      top: -2px;
    }
  }
  .ant-space, .ant-radio-group {
    width: 100%;
  }
  .ant-radio-group{
    max-width: 785px;

  }
  .header {
    font-weight: 400;
    line-height: 120%;
  }
  label {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 12px;
    padding: 16px 0px 16px 16px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    max-width: 608px;
    margin: auto;
    .ant-radio {
      top: -1px;
    }
    &.ant-radio-wrapper-checked {
      border-color: #EFCE73;
    }
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    >span:not(.ant-radio) {
      width: 100%;
      .ant-col {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &.left {
          justify-content: flex-end;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .bl-field-tariff {
    label {
      align-items: flex-start;
      justify-content: flex-start;
      .ant-radio {
        top: 1px;
      }
      >span:not(.ant-radio) {
        .ant-col {
          &.left {
            justify-content: flex-start;
          }
        }
      }
    }
    .btn-show {
      text-align: center;
      margin-top: 16px;
      .btn-show-more {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        &.hidden {
            display: none;
        }
      }
    }
  }  
}