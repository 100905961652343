.bl-new-password-form {
    text-align: center;
    max-width: 430px;
    .ant-btn {
        width: 252px;
    }
    .ant-space.ant-space-vertical {
        min-width: 100%;
    }
    .ant-form-item-explain-error {
        margin-bottom: 5px;
    }
}

@media screen and (max-width: 768px) {
    .bl-new-password-form {
        max-width: 100%;
        .ant-btn {
            width: 100%;
        }
    }
}
