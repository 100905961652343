@import "../../../../styles/variables";

.bl-widget-nps {
    padding: 0;
    border: 1px solid #ffce73;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    border-radius: 12px;
    @media screen and (max-width: 576px) {
        height: 206px;
    }
    .bz-block-content {
        height: 204px;
        @media screen and (max-width: 576px) {
            height: 188px;
        }
        .vidContain {
            .bl-widget-advertisement-is-ios {
                position: absolute;
                width: 100%;
                height: 100%;
            }
            video {
                width: 100%;
                height: 100%;
                position: absolute;
                object-fit: cover;
                z-index: 0;
            }
        }
        .caption {
            padding: 24px;
            min-height: 204px;
            @media screen and (max-width: 576px) {
                min-height: 160px;
                padding: 14px 18px 16px;
            }
        }
        .img-files {
            position: absolute;
            z-index: 100;
            right: 22px;
            top: 36px;
            @media screen and (max-width: 576px) {
                top: initial;
                bottom: 40px;
            }
        }
    }
    .title {
        color: var(--White, #fff);
        leading-trim: both;
        text-edge: cap;
        font-family: "ALS Hauss";
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        @media screen and (max-width: 1110px) {
            font-size: 30px;
        }
        @media screen and (max-width: 992px) {
            font-size: 36px;
        }
        @media screen and (max-width: 576px) {
            font-size: 34px;
            line-height: 44px;
        }
        @media screen and (max-width: 360px) {
            font-size: 28px;
        }
    }
    .description {
        leading-trim: both;
        text-edge: cap;
        font-family: "ALS Hauss";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        background: var(
            --Linear,
            linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        position: relative;
        top: -5px;
        @media screen and (max-width: 1110px) {
            font-size: 16px;
        }
        @media screen and (max-width: 992px) {
            font-size: 20px;
        }
        @media screen and (max-width: 576px) {
            font-size: 18px;
            line-height: 26px;
            top: -10px;
        }
        @media screen and (max-width: 360px) {
            font-size: 14px;
        }
    }
    .col-btn-linc {
        text-align: start;
        .ant-btn {
            margin-top: 10px;
            max-width: 224px;
            width: 224px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                margin-right: 10px;
            }
            @media screen and (max-width: 576px) {
                width: 152px;
                height: 38px;
                border-radius: 12px;
                font-family: "ALS Hauss";
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                color: #1f1f1f;
            }
        }
    }
}
