.bl-field-sim {
    .ant-form-item {
        margin: 0;
    }

    .multi-field .container {
        gap: 3px;
    }

    .sim-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 23px;

        .sim-text {
            // padding-top: 3px;
            font-family: "ALS Alumna";
            font-weight: 400;
            line-height: 120%;
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: #efce73;
            text-fill-color: #efce73;
            background: transparent;
            color: #efce73;
            -webkit-backdrop-filter: blur(0);
            backdrop-filter: blur(0);
            letter-spacing: 3px;
            position: relative;
            right: -5px;
        }

        .bz-input-group-numbers {
            input {
                &::-moz-selection {
                    background: none;
                }

                &::selection {
                    background: none;
                }
            }
        }
        .no-value {
            position: relative;
            &::before {
                content: "___________";
                position: absolute;
                left: 0px;
                right: 0;
                bottom: 0px;
                margin: auto;
                font-size: 30px;
                font-family: "ALS Alumna";
                font-weight: 400;
                line-height: 120%;
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: #efce73;
                text-fill-color: #efce73;
                background: transparent;
                color: #efce73;
                -webkit-backdrop-filter: blur(0);
                backdrop-filter: blur(0);
                letter-spacing: 4px;
                @media screen and (max-width: 768px) {
                    font-size: 23px;
                }
            }
        }
        @media screen and (max-width: 768px) {
            margin-left: 0;
        }
    }

    .header {
        .title {
            font-weight: 400;
            line-height: 22px;
        }

        .subtitle {
            font-weight: 400;
            line-height: 19px;
        }
    }

    .content {
        background: url("../../../images/sim.png") no-repeat;
        width: 455px;
        height: 280px;
        padding: 22px 30px;

        > .ant-space {
            width: 100%;

            > .ant-space-item {
                &:last-child {
                    width: 100%;

                    .ant-space,
                    .ant-space-item {
                        width: 100%;
                    }
                }
            }
        }

        .binding-space-container {
            .code {
                height: 18px;
                font-size: 146px;
            }

            > .ant-space {
                > .ant-space-item {
                    &:last-child {
                        justify-content: flex-start;
                        text-align: left;
                    }
                }
            }

            .binding {
                font-weight: 400;
                line-height: 17px;
                min-height: 17px;
            }
        }

        .logo-text {
            width: 55px;
            height: 60px;
            font-size: 55px;
        }

        input {
            font-weight: 400;
            font-size: 32px;
            line-height: 120%;
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: #efce73;
            text-fill-color: #efce73;
            border: 0;
            border-radius: 0;
            //   width: 17px;
            height: auto;
            //   text-align: center;
            background: transparent;
            color: #efce73;
            backdrop-filter: blur(0);
            text-align: start;
            padding: 0;
            // width: 267px;
            font-family: "ALS Alumna";
            letter-spacing: 3px;
            border: none;
            outline: inherit;
            width: 100%;
        }

        .bz-input-group-numbers {
            gap: 3px;
        }
    }
}

@media screen and (max-width: 768px) {
    .bl-field-sim {
        .header {
            .title {
                line-height: 120%;
            }

            .subtitle {
                line-height: 140%;
            }
        }

        .content {
            width: 345px;
            height: 220px;
            background-size: 106% 109%;
            margin: 0 0px;
            background-position-x: -10px;
            background-position-y: -6px;
            padding: 20px 16px 8px;

            .binding-space-container {
                .code {
                    font-size: 111px;
                }

                .binding {
                    line-height: 13px;
                    min-height: 13px;
                }
            }

            .logo-text {
                width: 44px;
                height: 48px;
                font-size: 44px;
            }

            input {
                font-size: 26px;
            }

            .bz-input-group-numbers {
                gap: 3px;
            }
        }
    }
}

@media screen and (max-width: 374px) {
    .bl-field-sim {
        > .ant-space-vertical {
            width: 100%;
        }

        .content {
            width: 100%;
            height: 220px;
            background-size: 106% 109%;
            margin: 0 0px;
            background-position-x: -10px;
            background-position-y: -6px;
            padding: 20px 16px 8px;
            border-radius: 10px;

            .bz-input-group-numbers {
                gap: 2px;
            }
        }
    }
}
