.bl-labelMenuRoute {
    .ant-collapse-item > .ant-collapse-header {
        margin-right: 35px;
        padding: 0px;
        position: relative;
        .ant-collapse-header-text .bz-typography {
            font-size: 20px !important;
        }
        .ant-collapse-arrow {
            font-size: 18px;
            right: -35px;
            transform: rotateZ(90deg) !important;
            top: 2px !important;
        }
    }
    .ant-collapse-item-active > .ant-collapse-header {
        .ant-collapse-arrow {
            transform: rotateZ(180deg) !important;
        }
    }
    .ant-collapse-item > .ant-collapse-content > {
        .ant-collapse-content-box {
            padding: 0px;
            .ant-menu {
                grid-gap: 0px;
                margin: 12px 0px 0px 5px;
            }
            .ant-menu-item span {
                font-size: 18px;
                padding-top: 2px;
                padding-bottom: 3px;
                border-bottom: initial;
                color: black;
            }
            ul li:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
    .bl-labelMenuRoute__icon-content {
        img {
            margin-right: 6px;
            position: relative;
            top: -2px;
        }
    }
}

@media screen and (max-width: 450px) {
    .bl-labelMenuRoute .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        font-size: 13px !important;
        top: 4px !important;
    }
}
