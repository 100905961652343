@import "../../../../styles/variables";

.bl-loyalty {
    border-color: $bg-gold-color;

    .subtitle {
        color: $text-pale-color;
        @include tinyFont-medium;
    }

    .loyalty-code {
        @include title-tinyFont-normal;
        margin-top: 2px;
        margin-bottom: 13px;
        &.top {
            color: $text-primary-color;
        }
    }

    .loyalty-status {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .loyalty-status-count {
            color: $text-primary-color;
            @include standardFont-bold;
        }
    }

    .loaylty-progress-line {
        .ant-progress-inner {
            background-color: rgba(255, 255, 255, 0.2);
        }
        .ant-progress-bg {
            background-color: white;
            background-image: url("../../../../images/gold-line@1x.png");
            background-image: -webkit-image-set(
                url("../../../../images/gold-line@1x.webp") 1x,
                url("../../../../images/gold-line@1x.png") 1x,
                url("../../../../images/gold-line@2x.webp") 2x,
                url("../../../../images/gold-line@1x.png") 2x
            );
            background-image: image-set(
                url("../../../../images/gold-line@1x.webp") 1x,
                url("../../../../images/gold-line@1x.png") 1x,
                url("../../../../images/gold-line@2x.webp") 2x,
                url("../../../../images/gold-line@1x.png") 2x
            );
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }

    .activation {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 20px;
        .last-month-total {
            display: flex;
            align-items: center;
            gap: 20px;
            border-left: none;
            border-right: none;
            border-top: none;
            border-bottom: 0.5px solid;
            border-image: linear-gradient(to right, #642878, #cea86f, #fef0b7, #642878) 47% 0%;
            padding-bottom: 10px;
            @media screen and (max-width: 431px) {
                border-bottom: none;
            }
        }
        .bl-last-month {
            display: flex;
            align-items: center;
        }
        @media screen and (max-width: 431px) {
            padding-top: 10px;
        }
    }

    .activation-cnt {
        font-family: "ALS Hauss";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
    }

    .activation-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin-bottom: 6px;
    }

    .activation-col {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        min-width: 50%;
        flex-grow: 1;
        &:first-child {
            padding-right: 4px;
        }
        &:last-child {
            padding-left: 8px;
        }
        @media screen and (max-width: $max-tablet-width) {
            &:first-child {
                padding-right: 16px;
                border-right: 1px solid $bg-gold-color;
            }
            &:last-child {
                padding-left: 16px;
                color: $text-pale-color;
            }
        }

        @media screen and (max-width: $max-phone-width) {
            p {
                color: $text-pale-color;
            }
        }
    }
}
