.bl-lottery-ng-place {
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.02);
    box-shadow: 0px 1px 1px 0px #fef0b7, 0px 1px 1px 0px #fef0b7 inset;
    padding: 6px;
    width: 189px;

    @media screen and (max-width: 992px) {
        width: 112px;
        padding: 4px;
    }

    &.place_1 {
        height: 176px;

        @media screen and (max-width: 992px) {
            height: 101px;
        }
    }

    &.place_2,
    &.place_4 {
        height: 158px;

        @media screen and (max-width: 992px) {
            height: 93px;
        }
    }

    &.place_3,
    &.place_5 {
        height: 143px;

        @media screen and (max-width: 992px) {
            height: 84px;
        }
    }

    &__container {
        fill: rgba(255, 255, 255, 0.05);
        filter: drop-shadow(2px 3px 4px rgba(0, 0, 0, 0.25));
        background: rgba(255, 255, 255, 0.02);
        backdrop-filter: blur(21px);
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        gap: 0;
        height: 100%;
        padding-bottom: 30px;
        -webkit-box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.2);

        @media screen and (max-width: 992px) {
            padding-bottom: 9px;
        }

        &__top {
            display: flex;
            align-items: flex-end;
            gap: 0;
            flex-direction: row;
            margin-bottom: -3px;
            position: relative;
            left: -10px;

            @media screen and (max-width: 992px) {
                left: 0;
            }

            &__place {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 8px;
                position: relative;
                left: -2px;

                @media screen and (max-width: 992px) {
                    margin-bottom: 4px;
                    left: -12px;
                }

                .num {
                    leading-trim: both;
                    text-edge: cap;
                    -webkit-text-stroke-width: 0.30000001192092896;
                    -webkit-text-stroke-color: #000;
                    font-family: "ALS Alumna";
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    background: linear-gradient(
                        141deg,
                        #efce73 25.79%,
                        #fef0b7 63.66%,
                        #cea86f 97.07%
                    );
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    position: relative;
                    top: 6px;

                    &.num_1 {
                        font-size: 132px;
                        line-height: 92px;

                        @media screen and (max-width: 992px) {
                            font-size: 72px;
                            line-height: 50px;
                        }
                    }

                    &.num_2,
                    &.num_4 {
                        font-size: 100px;
                        line-height: 70px;

                        @media screen and (max-width: 992px) {
                            font-size: 62px;
                            line-height: 43px;
                        }
                    }

                    &.num_3,
                    &.num_5 {
                        font-size: 92px;
                        line-height: 64px;

                        @media screen and (max-width: 992px) {
                            font-size: 52px;
                            line-height: 36px;
                        }
                    }
                }
                .text {
                    text-align: center;
                    leading-trim: both;
                    text-edge: cap;
                    font-family: "ALS Hauss";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.2px;
                    background: linear-gradient(
                        141deg,
                        #efce73 25.79%,
                        #fef0b7 63.66%,
                        #cea86f 97.07%
                    );
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    @media screen and (max-width: 992px) {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    &__prize {
        border-radius: 10px;
        overflow: hidden;
        background: rgba(255, 255, 255, 0.02);
        backdrop-filter: blur(21px);
        width: 162px;
        padding: 6px 12px 8px 12px;
        height: 48px;
        margin: -30px auto 0;
        background-image: radial-gradient(
                circle at 100% 100%,
                transparent 9px,
                #ffeaaf 9px,
                #ffeaaf 10px,
                transparent 10px
            ),
            linear-gradient(to right, #ffeaaf, #9b6a96),
            radial-gradient(
                circle at 0% 100%,
                transparent 9px,
                #9b6a96 9px,
                #9b6a96 10px,
                transparent 10px
            ),
            linear-gradient(to bottom, #9b6a96, #78308e),
            radial-gradient(
                circle at 0% 0%,
                transparent 9px,
                #78308e 9px,
                #78308e 10px,
                transparent 10px
            ),
            linear-gradient(to left, #78308e, #b59996),
            radial-gradient(
                circle at 100% 0%,
                transparent 9px,
                #b59996 9px,
                #b59996 10px,
                transparent 10px
            ),
            linear-gradient(to top, #b59996, #ffeaaf);
        background-size: 10px 10px, calc(100% - 20px) 1px, 10px 10px, 1px calc(100% - 20px);
        background-position: top left, top center, top right, center right, bottom right,
            bottom center, bottom left, center left;
        background-repeat: no-repeat;

        @media screen and (max-width: 992px) {
            width: 97px;
            padding: 0;
            height: 25px;
            border-radius: 6px;
            margin: -7px auto 0;
            background-image: radial-gradient(
                    circle at 100% 100%,
                    transparent 5px,
                    #ffeaaf 5px,
                    #ffeaaf 6px,
                    transparent 6px
                ),
                linear-gradient(to right, #ffeaaf, #9b6a96),
                radial-gradient(
                    circle at 0% 100%,
                    transparent 5px,
                    #9b6a96 5px,
                    #9b6a96 6px,
                    transparent 6px
                ),
                linear-gradient(to bottom, #9b6a96, #78308e),
                radial-gradient(
                    circle at 0% 0%,
                    transparent 5px,
                    #78308e 5px,
                    #78308e 6px,
                    transparent 6px
                ),
                linear-gradient(to left, #78308e, #b59996),
                radial-gradient(
                    circle at 100% 0%,
                    transparent 5px,
                    #b59996 5px,
                    #b59996 6px,
                    transparent 6px
                ),
                linear-gradient(to top, #b59996, #ffeaaf);
            background-size: 6px 6px, calc(100% - 12px) 1px, 6px 6px, 1px calc(100% - 12px);
            background-position: top left, top center, top right, center right, bottom right,
                bottom center, bottom left, center left;
            background-repeat: no-repeat;
        }

        &__sum {
            text-align: center;
            filter: drop-shadow(2px 1px 0px #3d074f);
            font-family: "ALS Hauss";
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            background: var(
                --Linear,
                linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%)
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media screen and (max-width: 992px) {
                font-size: 18px;
                line-height: normal;
                position: relative;
                top: 1px;
            }
        }
    }
}
