.bl-reservation-list-info-description {
    .tariff-name-mob {
        font-weight: 500;
        line-height: 120%;
    }
    .bz-typography {
        font-weight: 500;
        line-height: 19px;
    }

    svg {
        font-size: 5px;
        position: relative;
        top: -2px;
    }

    .tariff-name {
        svg {
            position: static;
            top: 0;
        }
    }

    svg.info {
        font-size: 16px;
    }

    ol {
        display: block;

        li {
            display: inline;

            .ant-breadcrumb-link {
                > span {
                    white-space: nowrap;
                }
            }
        }

        nav {
            display: inline;

            ol {
                display: inline;
            }
        }
    }
}
.description {
    .bl-reservation-list-info-description {
        padding-left: 16px;
    }
}

@media screen and (max-width: 768px) {
    .bl-reservation-list-info-description {
        .tariff-name {
            .ant-space-item {
                &:last-child {
                    display: flex;
                }
            }
        }

        .bz-typography,
        .sim-number {
            font-weight: 400;
            font-weight: 500;
            line-height: 18px;
        }
        .sim-number {
            margin-top: -1px;
        }
    }
}
