.modal-error-clear {
    width: 760px !important;
    backdrop-filter: blur(10px);
    .ant-modal-content {
        background: transparent;
        border: 1px solid rgba(255, 255, 255, 0.5);
        padding: 100px 64px 56px 64px !important;
        border-radius: 12px;

        .ant-space {
            width: 100%;
            text-align: center;
        }
        .ant-modal-close {
            display: none;
        }
        .close-btn {
            background: transparent;
            position: absolute;
            top: 14px;
            right: 24px;
            padding: 0;
            min-width: auto;
        }
        .back-arrow {
            color: hsla(0, 0%, 100%, 0.5);
            font-size: 17px;
            font-weight: 400;
            background: transparent;
            display: flex;
            align-items: center;
            position: absolute;
            left: -210px;
            top: -70px;
            display: flex;
            flex-direction: row;
            gap: 10px;
            svg {
                width: 40px;
                margin-right: 8px;
            }
            @media screen and (max-width: 1024px) {
                left: 34%;
                top: -65px;
            }
            @media screen and (max-width: 430px) {
                left: 0;
                top: -59px;
            }
        }
        .button-close {
            width: 100%;
        }
        @media screen and (max-width: 728px) {
            padding: 45px 16px !important;
        }
    }
}
