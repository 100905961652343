@import '../../../../styles/variables';

.bl-payouts-history {
    .payouts-title {
        @include bigFont-medium;
    }
    .payouts-subtitle {
        @include standardFont-medium;
        color: $text-pale-color;
    }
    .payouts-col-title {
        @include tinyFont-medium;
        color: $text-pale-color;
        display: flex;
        justify-content: right;
    }
}