.bl-mb-base {
  .ant-space-item {
    position: relative;
    padding-left: 12px;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 4px;
      height: 26px;
      background: linear-gradient(141.08deg, #EFCE73 25.79%, #FEF0B7 63.66%, #CEA86F 97.07%);
      border-radius: 8px;
    }
  }
}