.bl-duration {
    line-height: 19px;
    .ant-btn.ant-btn-loading {
        .ant-btn-loading-icon {
            &:after {
                width: 24px;
                height: 24px;
            }
        }
    }
    .my-modal-code__descriptionBonuses {
        color: black;
    }
    .my-modal-code__description {
        color: white;
    }
}

@media screen and (max-width: 768px) {
    .bl-duration {
        margin-top: -40px;
        text-align: center;
        .ant-space {
            width: 100%;
        }
    }
}
