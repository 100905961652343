.bl-body-modal {
    .bz-block {
        border: 1px solid #e5e5e5;
        margin: 32px 0 24px 0;
        text-align: start;
        .block-subtitle {
            line-height: 140%;
        }
        .text {
            line-height: 22px;
        }
    }
}
