.bl-saving-bank-cards {
    width: 643px;
    padding: 0 !important;
    margin: 0px auto 0px;
    .bankCards-list--row {
        margin-bottom: 16px;
    }
    .numbers-list--type {
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        svg {
            width: 46px !important;
            height: 30px !important;
            margin-right: 9px;
            background-color: white;
            border-radius: 6px;
        }
    }
    .numbers-list--maskerPan {
        display: flex;
        align-items: center;
        vertical-align: middle;
        margin-right: 168px;
        span {
            margin-top: -3px;
            margin-right: 3px;
        }
    }
    .numbers-list--trash {
        display: flex;
        align-items: flex-end;
        cursor: pointer;
        margin-bottom: 2px;
    }
    .title {
        font-size: 24px;
        text-align: center;
        margin-bottom: 24px;
    }
    .description {
        font-size: 14px;
        margin-bottom: 24px;
        text-align: center;
        &-no-saved-cards {
            margin-bottom: 0px;
            &-two {
                margin-bottom: 24px;
            }
        }
    }
    .title + .description {
        margin-top: -16px;
    }

    @media screen and (min-width: 766px) {
        .ant-btn {
            width: 252px !important;
            margin: 0 auto;
            margin-bottom: 5px;
            display: flex;
            justify-content: center;
            span {
                margin-top: 9px;
            }
        }
    }
}
.bl-removement-bank-cards-modal {
    .bz-modal-title {
        font-weight: 500 !important;
        margin-bottom: 16px;
    }
    .removement-modal--description {
        text-align: center;
    }
}
.bl-modal-bank-cards-saving {
    .ant-modal-footer {
        display: none;
    }
}
@media screen and (max-width: 992px) {
    .bl-saving-bank-cards {
        padding: 0 16px !important;
    }
}
@media screen and (max-width: 710px) {
    .bl-saving-bank-cards {
        width: auto;
        padding: 0 !important;
    }
}
@media screen and (max-width: 585px) {
    .bl-saving-bank-cards {
        .numbers-list--type {
            p {
                width: 90px;
                white-space: normal;
                word-break: break-all;
                line-height: 1;
            }
        }
    }
}
@media screen and (max-width: 550px) {
    .bl-saving-bank-cards {
        .numbers-list--maskerPan {
            margin-right: 100px;
        }
    }
}
@media screen and (max-width: 480px) {
    .bl-saving-bank-cards {
        .numbers-list--maskerPan {
            margin-right: 50px;
        }
    }
}
@media screen and (max-width: 430px) {
    .bl-saving-bank-cards {
        .numbers-list--maskerPan {
            margin-right: 20px;
        }
    }
}
@media screen and (max-width: 370px) {
    .bl-saving-bank-cards {
        .numbers-list--type {
            p {
                width: 65px;
            }
        }
    }
}
@media screen and (max-width: 340px) {
    .bl-saving-bank-cards {
        .numbers-list--maskerPan {
            margin-right: 5px;
        }
    }
}
@media screen and (max-width: 320px) {
    .bankCards-list--row {
        .ant-row-space-between {
            row-gap: 9px !important;
        }
    }
}
@media screen and (max-width: 400px) {
    .bl-saving-bank-cards {
        .numbers-list--type {
            font-size: 12px;
        }
        .numbers-list--maskerPan {
            font-size: 12px;
        }
    }
}
