@import '../../../../styles/variables';

.bl-support-widget {
    .bz-block{
        min-height: 310px;
    }
    .bz-block-title {
        margin-bottom: 1rem;
    }
    .support-content{
        span {
            white-space: normal;
            text-align: start;
        }
        :last-child {
            margin: auto;
        }
    }
    .support-link {
        background: none;
    }
}