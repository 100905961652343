@import '../../../../styles/variables';

.bonuses-progress {
    .progress {
        max-width: 155px;
        min-width: 155px;
    }
}

.bonuses-counts {
    margin-left: 24px;
    display: flex;
    justify-content: flex-start;
    text-align: left;

    .ant-space.ant-space-vertical{
        text-align: left;
    }
}

.bonuses-future-payment{
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .sum {
        @include title-tinyFont-normal;
        margin-right: 20px;
    }
    .label {
        @include tinyFont-medium;
        line-height: 100%;
        color: $text-pale-color;
    }

}

@media screen and (min-width: $max-tablet-width) {
    .bl-mybonuses {
        .bz-block{
            min-height: 214px;
        }
    }
}

@media screen and (max-width: $max-phone-width) {
    .bonuses-progress {
        text-align: center;
        .progress {
            max-width: 200px;
            min-width: 200px;
        }
    }
    .bonuses-counts {
        margin-left: 0;
    }
}

@media screen and (max-width: $max-small-phone-width) {
   .col-bonuses-rate {
        flex: 0 0 100%;
        max-width: 100%;
   }
}