@import "../../../../styles/variables";

.bl-payments-history {
    .payments-title {
        @include bigFont-medium;
    }
    .payments-subtitle {
        @include standardFont-medium;
        color: $text-pale-color;
        a {
            color: rgba(255, 255, 255, 0.5);
            text-decoration: underline;
            position: relative;
            top: -1px;
        }
    }
    .payments-col-title {
        @include tinyFont-medium;
        color: $text-pale-color;
        &.last {
            display: flex;
            justify-content: right;
        }
    }
}
