.bl-edit-email-modal {
    text-align: center;
  }
  
  @media screen and (max-width: 768px) {
    .bl-edit-email-modal {
      text-align: left;
      h2 {
        font-weight: 700;
      }
    }
  }