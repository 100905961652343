@import '../../../../styles/variables';

.bl-register-passport {
    .ant-space-item {
        width: 100%;
    }
    .ant-space-vertical {
        width: 100%;
    }
    .subtitle {
        color: $text-pale-color;
        @include tinyFont-normal;
    }
}

.bl-photo-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.bl-photo-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-right: 16px;
}

.bl-photo-item-title {
    position: absolute;
    top: Calc(50% + 46px);
}

.bl-photo {
    border: 1px solid $border-violet;
    border-radius: 19px;
    width: 190px;
    height: 177px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
        border-width: 2px;
    }

    .photo-input {
        position: absolute;
        top: 0;
        visibility: hidden;
        width: 0;
        height: 0;
    }

    .photo-icon {
        width: 59px;
    }
    .photo-icon-plus {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        background: $bg-opacity-color2;
        border-radius: 50%;

        svg {
            path {
                fill: $text-primary-color;
            }
        }
    }
}

.photo-title {
    @include tinyFont-medium;
    margin-bottom: 7px;
}
.photo-edit {
    @include tinyFont-normal;
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (max-width: $max-phone-width) {
    .bl-photo-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .bl-photo {
        width: 100px;
        height: 100px;
        margin-bottom: 8px;
        margin-right: 16px;
    }
    .bl-photo-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: start;
        margin-right: 0;
    }
    .bl-photo-item-title {
        position: relative;
    }
}