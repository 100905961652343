@import '../../../../styles/variables';

.bl-widget-loyalty {
    min-height: 222px;
    border-color: $bg-gold-color;

    &.without-loyalty {
        min-height: 208px;
        padding: 22px 16px 23px;
        .loyalty-code {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            font-family: "ALS Alumna";
            font-style: normal;
            font-weight: 400;
            font-size: 26px;
            line-height: 100%;
            color: #ffffff;
            img {
                width: 62px;
            }
        }
    }
    .bl-hint_header {
        position: relative;
        top: -17px;
        left: 9px;
        .ant-btn {
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .title-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .hint-icon {
            .bl-hint_header {
                position: relative;
                top: -17px;
                left: 9px;
                .ant-btn {
                    width: 48px;
                    background: transparent;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media screen and (max-width: 768px) {
                        width: 23px;
                    }
                }
            }
        }
        @media screen and (max-width: 431px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .subtitle {
        @include tinyFont-medium;
    }

    .loyalty-code {
        @include title-tinyFont-normal;
        &.top {
            color: $text-primary-color;
        }
        @media screen and (max-width: 431px) {
            font-family: "ALS Alumna";
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 32px */
            letter-spacing: 1.6px;
        }
    }

    .loyalty-status {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .loyalty-status-count {
            color: $text-primary-color;
            @include standardFont-bold;
        }
    }

    .loaylty-progress-line {
        .ant-progress-inner {
            background-color: rgba(255, 255, 255, 0.2);
        }
        .ant-progress-bg {
            background-color: white;
            background-image: url("../../../../images/gold-line@1x.png");
            background-image: -webkit-image-set(
                url("../../../../images/gold-line@1x.webp") 1x,
                url("../../../../images/gold-line@1x.png") 1x,
                url("../../../../images/gold-line@2x.webp") 2x,
                url("../../../../images/gold-line@1x.png") 2x
            );
            background-image: image-set(
                url("../../../../images/gold-line@1x.webp") 1x,
                url("../../../../images/gold-line@1x.png") 1x,
                url("../../../../images/gold-line@2x.webp") 2x,
                url("../../../../images/gold-line@1x.png") 2x
            );
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }

    .activation {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .bl-last-month {
            display: flex;
            align-items: center;
        }
        .last-chaild-col {
            justify-content: flex-end;
        }
    }

    .activation-cnt {
        font-family: "ALS Hauss";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
    }

    .activation-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin-bottom: 16px;
    }

    .activation-row:last-child {
        margin-bottom: 0;
    }

    .activation-col {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        min-width: 50%;
        flex-grow: 1;
        &:first-child {
            padding-right: 4px;
        }
        &:last-child {
            padding-left: 8px;
        }
        @media screen and (max-width: $max-tablet-width) {
            &:first-child {
                padding-right: 16px;
                border-right: 1px solid $bg-gold-color;
            }
            &:last-child {
                padding-left: 16px;
                color: $text-pale-color;
            }
        }

        @media screen and (max-width: $max-phone-width) {
            p {
                color: $text-pale-color;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .bl-widget-loyalty {
        min-height: 162px;
    }
}
