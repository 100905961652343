.bl-hint-modal {
    p {
        margin-bottom: 0rem;
    }
    ul {
        margin-top: 1rem;
    }
    li {
        margin-bottom: 1rem;
        padding: 0px 0px 3px 23px;
        list-style: none;
    }
    img {
        margin: 10px auto;
    }
    iframe {
        width: 100% !important;
        margin: 10px auto;
        border-radius: 16px;
    }
    .faq-title {
        text-align: center;
        margin-bottom: 30px;
        @media screen and (max-width: 430px) {
            word-wrap: initial !important;
            font-size: 30px;
        }
    }
    .cancel {
        margin-top: 15px;
    }
}
.bl-hint-modal-mobile {
    margin-top: 30px;
    margin-bottom: 30px;
    &.isScrolled {
        .ant-modal-close {
            display: none !important;
        }
    }
    .ant-modal-content {
        @media screen and (max-width: 530px) {
            padding: 30px 16px;
        }
    }
}
.ant-modal-root__hint-scrollbar-color {
    .ant-modal-content {
        padding-left: 0;
        padding-right: 0;
        max-height: 90vh !important;
        overflow: hidden;
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-transform: translateZ(-3000px) rotateY(90deg);
        -moz-transform: translateZ(-3000px) rotateY(90deg);
        -ms-transform: translateZ(-3000px) rotateY(90deg);
        transform: translateZ(-3000px) rotateY(90deg);
        opacity: 0;
        -webkit-animation: slit 0.7s forwards ease-out;
        -moz-animation: slit 0.7s forwards ease-out;
        animation: slit 0.7s forwards ease-out;

        .ScrollbarsCustom-Content {
            padding: 0 56px !important;
        }

        .ScrollbarsCustom-Track {
            background: transparent !important;
            width: 3px !important;
            height: calc(100% + 200px) !important;
            top: -156px !important;
            right: 0 !important;
        }

        .ScrollbarsCustom-Thumb {
            background: linear-gradient(
                rgba(239, 206, 115, 0),
                rgb(239 206 73),
                rgba(206, 168, 111, 0)
            ) !important;
            width: 3px !important;
            height: 35vh !important;
        }

        .ScrollbarsCustom-Wrapper {
            width: 100%;
        }

        @media screen and (max-width: 530px) {
            .ScrollbarsCustom-Content {
                padding: 0 16px !important;
            }
        }
        @media screen and (max-width: 500px) {
            position: relative;
        }
    }
    @keyframes slit {
        50% {
            transform: translateZ(-250px) rotateY(89deg);
            opacity: 1;
            animation-timing-function: ease-in;
        }
        100% {
            transform: translateZ(0) rotateY(0deg);
            opacity: 1;
        }
    }
    @-webkit-keyframes slit {
        50% {
            -webkit-transform: translateZ(-250px) rotateY(89deg);
            opacity: 0.5;
            -webkit-animation-timing-function: ease-out;
        }
        100% {
            -webkit-transform: translateZ(0) rotateY(0deg);
            opacity: 1;
        }
    }

    @-moz-keyframes slit {
        50% {
            -moz-transform: translateZ(-250px) rotateY(89deg);
            opacity: 0.5;
            -moz-animation-timing-function: ease-out;
        }
        100% {
            -moz-transform: translateZ(0) rotateY(0deg);
            opacity: 1;
        }
    }
}
