.page {
    padding-top: 200px;
    margin-top: -200px;
    height: 100%;
    min-height: 100vh;
    background-image: url("../images/bg_lady@1x.png");
    background-image: image-set(
        url("../images/bg_lady@1x.webp") 1x,
        url("../images/bg_lady@1x.png") 1x,
        url("../images/bg_lady@2x.webp") 2x,
        url("../images/bg_lady@2x.png") 2x
    );
    background-image: -webkit-image-set(
        url("../images/bg_lady@1x.webp") 1x,
        url("../images/bg_lady@1x.png") 1x,
        url("../images/bg_lady@2x.webp") 2x,
        url("../images/bg_lady@2x.png") 2x
    );
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    backdrop-filter: blur(45px);

    .bl-content {
        padding: 0 16px;
        max-width: 1184px;
        margin: 16px auto;
    }
}

.page.page-index {
    background-image: url("../images/car.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: rgba(100, 40, 120, 0.6);
    display: flex;
    justify-content: center;
    padding: 90px 0 0;
    margin-top: 0;
    max-width: 100%;
    width: 100%;
    min-height: 100%;
    z-index: 1;
    .bl-back {
        margin-top: 4px;
    }
    .linck-smart-captcha {
        a {
            color: #fff;
        }
    }
}

.page-book-reservations {
    background-image: url("../images/bg_book_reservations.jpg");
}

.page.page-dashboard {
    background-image: url("../images/page-dashboard.jpg");
}
.page-database-of-numbers {
    background-image: url("../images/dashboard.jpg");
    background-image: -webkit-image-set(
        url("../images/dashboard.webp") 1x,
        url("../images/dashboard.jpg") 1x,
        url("../images/dashboard.webp") 2x,
        url("../images/dashboard.jpg") 2x
    );
    background-image: image-set(
        url("../images/dashboard.webp") 1x,
        url("../images/dashboard.jpg") 1x,
        url("../images/dashboard.webp") 2x,
        url("../images/dashboard.jpg") 2x
    );
}
.page-database-of-numbers {
    @media screen and (min-width: 992px) {
        padding-top: 140px;
    }
    .bl-content {
        max-width: 1300px;
    }
}

.page.page-clear-my-data {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    .ui-back {
        display: none;
    }
    .bl-logo {
        margin-bottom: 40px;
        .ant-space {
            .ant-space-item:last-child {
                @media screen and (max-width: 768px) {
                    display: none;
                }
            }
        }
    }
    &.is-has-token {
        min-height: 100vh;
        margin-top: -200px;
        padding-top: 200px;
        z-index: 0;
        .bl-logo {
            display: none;
        }
        .ui-back {
            display: block;
        }
    }
}

.page.page-database-of-numbers {
    &.is-safari,
    &.is-android {
        -webkit-backdrop-filter: none !important;
        backdrop-filter: none !important;
    }
}
.is-paralax {
    @media screen and (max-width: 430px) {
        opacity: 0;
        z-index: -50;
        position: absolute;
        top: -9999px;
    }
}
.page.page-news {
    background-image: url("../images/bg-page-news.png");
    background-attachment: fixed;
    @media screen and (max-width: 430px) {
        background-image: url("../images/bg-page-news.png");
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        transition: all 1.3s ease-in-out;
        box-sizing: border-box;
        will-change: transform;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000;
        display: block;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-top: 0;
        background-attachment: scroll;
    }
}
.page.notifications {
    background-image: url("../images/bg-page-notifications.png");
    background-attachment: fixed;
    @media screen and (max-width: 430px) {
        background-image: url("../images/bg-page-notifications-mob.png");
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        transition: all 1.3s ease-in-out;
        box-sizing: border-box;
        will-change: transform;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000;
        display: block;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-top: 0;
        background-attachment: scroll;
    }
}
.page.page-delivery {
    background-image: url("../images/bg_page_delivery.png");
    background-attachment: fixed;
    @media screen and (max-width: 430px) {
        background-image: url("../images/bg_page_delivery_mob.png");
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        transition: all 1.3s ease-in-out;
        box-sizing: border-box;
        will-change: transform;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000;
        display: block;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-top: 0;
        background-attachment: scroll;
    }
}
.page.page-book {
    background-image: url("../images/bg_page_book.jpg");
    background-attachment: fixed;
    @media screen and (max-width: 430px) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        transition: all 1.3s ease-in-out;
        box-sizing: border-box;
        will-change: transform;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000;
        display: block;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-top: 0;
        background-attachment: scroll;
    }

    @media screen and (max-width: 320px) {
        .bz-title {
            font-size: 24px;
        }
    }
    .bl-header {
        padding: 0;
    }
    .bl-content {
        margin-top: 0;
        padding: 0;
        max-width: 1350px;
        @media screen and (max-width: 768px) {
            padding: 0 16px;
        }
    }
}
.page-bonuses {
    @media screen and (max-width: 430px) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        transition: all 1.3s ease-in-out;
        box-sizing: border-box;
        will-change: transform;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000;
        display: block;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-top: 0;
        background-attachment: scroll;
    }
    .bl-header {
        padding: 0;
    }
    .bl-content {
        @media screen and (max-width: 430px) {
            margin-top: 0;
        }
    }
    .bl-hint-mobile {
        margin-top: 0;
    }
    background: url("../images/bg-operation-history.jpg") no-repeat top center;
    background-size: cover;
    background-attachment: fixed;
    .bl-replenish-number-bonuses.without-loyalty {
        .ant-btn {
            height: 99px;
        }
    }
}
.page-history {
    background: url("../images/bg-operation-history.jpg") no-repeat top center;
    background-size: cover;
    background-attachment: fixed;
}
.page-profile {
  background: url("../images/bg-page-profile.png") no-repeat top center;
  background-size: cover;

  .bl-footer {
    margin-top: 50px !important;
  }
}
.page.page-super-link {
    min-height: 3900px !important;
    background: url("../images/colored2.png") no-repeat top center;
    background-size: cover;
    background-position: center 500px;
    overflow: hidden;
    .ui-back {
        margin-top: -2px;
    }
    .ant-layout-footer {
        display: none;
    }
    .bl-sl-bottom {
        .ant-layout-footer {
            display: block;
        }
    }
    @media screen and (max-width: 1440px) {
        // background-position: center 800px;
    }
    @media screen and (max-width: 992px) {
        min-height: 3750px !important;
        .bl-sl-top {
            height: 1000px;
        }
    }
    @media screen and (max-width: 844px) {
        min-height: 3650px !important;
        &.is-safari {
            min-height: 3880px !important;
            .bl-sl-bottom {
                .ant-layout-footer {
                    padding-bottom: 0;
                }
            }
        }
        .bl-sl-top {
            height: 1000px;
        }
    }
    @media screen and (max-width: 768px) {
        min-height: 3700px !important;
        &.is-safari {
            min-height: 3750px !important;
            .bl-sl-bottom {
                .ant-layout-footer {
                    padding-bottom: 0;
                }
            }
        }
    }
    @media screen and (max-width: 576px) {
        min-height: 3750px !important;
        &.is-safari {
            min-height: 3700px !important;
            overflow-x: hidden;
            .bl-sl-bottom {
                .ant-layout-footer {
                    padding-bottom: 0;
                }
            }
        }
        padding-top: 90px;
        margin-top: -90px;
        .bl-sl-top {
            padding-top: 146px;
            height: 970px;
        }
    }
    @media screen and (max-width: 320px) {
        min-height: 3600px !important;
    }
    @media screen and (max-width: 280px) {
        min-height: 3500px !important;
    }
}

@media screen and (max-width: 768px) {
    .page.page-index {
        padding: 18px 0 126px;
    }
    .page-dashboard {
        padding-top: 243px;
    }
}
