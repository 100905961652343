@import '../../../styles/variables';

.field-code {
    .bz-input-group-numbers {
        input {
            &::-moz-selection {
                background: #CFC8DB;
                color: $primary-color;
            }
            &::selection {
                background: #CFC8DB;
                color: $primary-color;
            }
        }
    }
}
