.sidebar-profile {
  z-index: 4;
  height: 100%;
  width: 100%;
  background-size: 100% 100%;
  color: black;
  transition: left 0.3s;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 59px 46px;
  font-family: ALS Alumna, sans-serif;
  max-height: 100vh;
  overflow-y: auto;
  transition: right 0.3s;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(50px);

  &.sidebar.sidebar-close {
    right: calc(0px - max(350px, 100%));
  }

  &.sidebar.sidebar-open {
    right: 0;
  }

  .sidebar-close {
    color: #fff;
    cursor: pointer;
    min-height: 23px;
    max-height: 23px;
  }

  .sidebar-logo {
    min-height: 61px;
    max-height: 61px;
  }

  .ant-menu {
    background: transparent;
  }

  .row-group-btn-close {
    align-items: center;
    justify-content: space-between;
    width: 100%;

    margin-bottom: 70px;

    .col-close {
      text-align: end;
    }

    .ant-btn-default {
      background: transparent;
      display: flex;
      align-items: center;
      color: var(--white-250, rgba(255, 255, 255, 0.5));
      font-family: ALS Hauss;
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;

      svg {
        width: 28px;
        margin-right: 16px;
      }
    }
  }

  .ant-space {
    width: 100%;

    .block-basic {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        color: var(--White, #fff);
        text-align: center;
        font-family: ALS Hauss;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        margin-bottom: 12px;
        @media only screen and (max-width: 425px) {
          font-size: 18px;
        }
      }

      .bz-block {
        max-width: 436px;
        width: 100%;
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.5);
        border: none;
        padding: 0;

        .col-img {
          padding-right: 24px;

          .group-icon-in-type {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            border-radius: 8px;
            background: linear-gradient(
                            335deg,
                            #642878 0.01%,
                            rgba(162, 86, 188, 0.6) 97.62%
            );
          }
        }

        .col-content-block {
          padding: 16px 0;
          font-family: ALS Hauss;
          font-style: normal;
          font-weight: 500;
          line-height: 26px;

          text-align: start;

          .discription-devices {
            color: rgba(255, 255, 255, 0.5);
            font-weight: 400;
          }
        }

        .online {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-family: ALS Hauss;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }
      }

      .bl-mobile-active {
        border-radius: 8px;
        background: rgba(31, 31, 31, 0.75);
        backdrop-filter: blur(21px);

        .col-img {
          padding: 10px;
        }

        .col-content-block {
          padding: 10px 0;

          .discription-devices {
            display: flex;
            align-items: center;

            svg {
              position: relative;
              top: 1px;
            }
          }
        }
      }
    }

    .block-active-sessions {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        color: var(--White, #fff);
        text-align: center;
        font-family: ALS Hauss;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        margin-bottom: 12px;
        @media only screen and (max-width: 425px) {
          font-size: 18px;
        }
      }

      .bz-block {
        max-width: 478px;
        width: 100%;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.08);
        border: none;
        padding: 0;

        .col-img {
          img {
            height: 100%;
          }
        }

        .icon-devicess {
          svg {
            position: relative;
            left: 13px;
            top: 15px;
          }
        img {
                        position: relative;
                        left: 13px;
                        top: 15px;
                    }
                }
                .col-content-block {
                    padding: 16px 0 16px 24px;
                    font-family: ALS Hauss;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px;
                    text-align: start;
                    .discription-devices {
                        color: rgba(255, 255, 255, 0.5);
                        font-weight: 400;
                    }
                }
                .btn-complite {
                    height: 100%;
                    width: 100%;
                    padding: 0;
                    img {
                        height: 100%;
                        width: 100%;
                    }
                }
            }
            .bl-mobile-no-active {
                border-radius: 8px;
                background: rgba(31, 31, 31, 0.5);
                backdrop-filter: blur(21px);
                width: 100%;
                .col-content-block {
                    padding: 10px 0;
                    .discription-devices {
                        display: flex;
                        align-items: center;
                        svg {
                            position: relative;
                            top: 1px;
                        }
                    }
                }
            }
            .bl-active-sessions {
                width: 100%;
                border-radius: 8px;
                background: rgba(31, 31, 31, 0.5);
                backdrop-filter: blur(21px);
                .ant-col {
                    padding-bottom: 8px;
                }
                .conteiner {
                    overflow: hidden;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

          .bl-group-sessins {
            margin-bottom: -8px;
            gap: 6px;
            flex-wrap: nowrap;
            overflow: auto;
            justify-content: flex-start;

            .ant-row {
              flex-wrap: nowrap;
            }

            .btn-complite {
              width: 150px;
              background: red;
              padding: 0 30px;
              display: flex;
              align-items: center;
              color: var(--White, #fff);
              text-align: center;
              font-family: ALS Hauss;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%;
              margin-bottom: 8px;
              border-radius: 1px;
            }

            .icon-devicess {
              padding: 10px;
              display: flex;
              justify-content: center;
              align-items: flex-start;
            }

            .col-content-block {
              .ant-space {
                padding: 10px 0;
              }
            }
          }
        }

        .title-devices,
        .sub-title-devices {
          color: var(--White, #fff);
          font-family: ALS Hauss;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .discription-devices {
          color: var(--white-250, rgba(255, 255, 255, 0.5));
          font-family: ALS Hauss;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          display: flex;
          align-items: center;

          svg {
            position: relative;
            top: 1px;
          }

          @media only screen and (max-width: 375px) {
            font-size: 12px !important;
          }
        }
      }
    }

    .group-btn-close {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .btn-close-group {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .ant-btn-primary {
        max-width: 343px !important;
        width: 100%;
      }

      .ant-btn-text {
        background: transparent;
        color: #fff;
      }
    }
  }

  @media only screen and (max-width: 425px) {
    padding: 59px 16px;
  }

  .list-sessions {
    width: 100%;
  }

  .bl-modal-attention {
    padding: 40px 16px 60px;

    .close-btn-modal {
      text-align: end;
      padding: 0 26px;
      margin-bottom: 27px;
      @media only screen and (max-width: 425px) {
        padding: 0;
      }
    }

    .ant-row {
      text-align: center;

      .title {
        color: var(--White, #fff);
        text-align: center;
        font-family: ALS Alumna;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        @media only screen and (max-width: 425px) {
          font-size: 32px;
        }
      }

      .sub-title {
        color: var(--White, #fff);
        text-align: center;
        font-family: ALS Hauss;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        @media only screen and (max-width: 425px) {
          font-size: 12px;
          white-space: pre-line;
        }
      }

      .ant-btn {
        width: 100%;
        max-width: 343px;
      }

      .ant-btn-text {
        background: transparent;
        margin-top: -12px;
        color: #fff;
      }
    }

    @media only screen and (max-width: 425px) {
      padding: 12px 12px 30px;
    }
  }

  .sidebar-profile-shimmer {
    width: 100%;

    .list-sessions {
      .row-group-btn-close {
        .col-back {
          .btn-skeleton-back {
            width: 250px;
            border-radius: 8px;
          }
        }

        .col-close {
          .btn-skeleton-close {
            width: 45px;
            min-width: 45px;
            border-radius: 8px;
          }
        }
      }

      .block-basic,
      .block-active-sessions {
        .title {
          width: 100%;

          .btn-skeleton-title {
            width: 250px;
            border-radius: 8px;
          }
        }

        .btn-skeleton-active {
          width: 436px;
          height: 105px;
          border-radius: 8px;
          @media only screen and (max-width: 425px) {
            width: 100%;
          }
        }
      }

      .btn-close-group {
        .btn-skeleton {
          width: 343px;
          height: 48px;
          border-radius: 8px;
        }
      }
    }
  }

  @media only screen and (max-width: 430px) {
    background: url("../../../../images/bg-menu-session-mob.png");
  }
}

// }

.ant-layout-sider-children {
  position: relative;
}

.bl-sider-profile {
  right: 0;
  @media only screen and (max-width: 430px) {
    width: 100% !important;
    min-width: 100% !important;
    transition: all 0s;
  }
}

.ant-layout-sider-collapsed.ant-layout-sider {
  right: -750px;
}

.ant-layout-sider-collapsed {
  .sidebar {
    &:before {
      display: none;
    }
  }
}

.sidebar-drop {
  z-index: 3;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0px);
}
