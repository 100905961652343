@import "../../../../../../styles/variables";

.bl-number-selection-collection {
    display: flex;
    flex-direction: column;
    align-items: center;

    .actions-container {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        gap: 8px;
        .ant-btn {
            border-radius: 39px !important;
        }
    }

    .carousel-row {
        width: 100vw;
    }
    .carousel-item-wrapper {
        padding: 0 16px;
    }
    .scrolled-row {
        transition: left 1s ease-in-out;
        position: relative;
        margin-top: 21px;
        padding-left: 30px;
        padding-right: 30px;
        max-width: Calc(100vw - 32px);
        overflow-x: scroll;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }
    .collection-block {
        display: flex;
        flex-direction: column;
        padding: 12px;
        width: 340px;
        min-height: 462px;
        border-radius: 16px;
        border-color: $gold-border-color;
        border-width: 1px;
        background-size: 100% auto;
        background-position: center top;
        background-repeat: no-repeat;
        margin: auto;
        background-size: cover;
        &.item-selected {
            border-width: 2.5px;
        }
        .bz-block-content {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 20px;
        }
    }
    .collection-block-btn {
        @include tinyFont-medium;
        color: $text-secondary-color;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }
    .collection-title {
        -webkit-text-fill-color: #eeda89;
        font-size: 32px;
        line-height: 32px;
        width: min-content;
        margin: 0 auto 8px;
        line-height: 32px;
        min-height: 66px;
    }
    .collection-item-wrapper {
        width: 100%;
    }
    .bl-filter-button {
        position: absolute;
        right: 0;
    }
    .info-desc {
        position: absolute;
        right: 12px;
    }
}

.info-desc-tooltip-container {
    min-width: 343px;
    @media screen and (max-width: 576px) {
        min-width: auto;
    }
    &.ant-tooltip-placement-bottomRight {
        .ant-tooltip-arrow {
            top: 15px;
            .ant-tooltip-arrow-content {
                transform: translateY(11px) rotate(244deg);
                &::before {
                    background: #3d1948;
                    background: linear-gradient(180deg, #3d1948, #3d1948);
                }
            }
        }
    }
    &.ant-tooltip-placement-bottomLeft {
        .ant-tooltip-arrow {
            top: 15px;
            .ant-tooltip-arrow-content {
                transform: translateY(11px) rotate(20deg);
                &::before {
                    background: #3d1948;
                    background: linear-gradient(180deg, #3d1948, #3d1948);
                }
            }
        }
    }
    .ant-tooltip-arrow {
        height: 28px;
        width: 30px;
        .ant-tooltip-arrow-content {
            background: transparent;
            &::before {
                backdrop-filter: blur(50px);
                clip-path: none;
                border: 1px solid rgba(255, 255, 255, 0.5);
                border-radius: 6px;
            }
        }
    }
    .ant-tooltip-inner {
        border-radius: 30px;
        backdrop-filter: blur(50px);
        padding: 24px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        min-width: 100%;

        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;

        background: #3d1948;
        background: linear-gradient(180deg, #3d1948, #653756);
    }
}

.back-collection {
    position: absolute;
    top: 16px;
    &.loading {
        &::after {
            content: "";
            position: absolute;
            width: 28px;
            height: 16px;
            top: 0;
            z-index: 5;
            left: 0;
        }
    }
    img {
        cursor: pointer;
    }
}

@media screen and (max-width: 1919px) {
    .bl-number-selection-collection {
        .scrolled-row {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}

@media screen and (max-width: 769px) {
    .bl-number-selection-collection {
        .scrolled-row {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}

@media screen and (max-width: 449px) {
    .bl-number-selection-collection {
        .ant-carousel {
            .slick-slide {
                img {
                    display: inline;
                }
            }
        }
        .collection-block {
            width: auto;
            margin: 0 4px;
        }
    }
}

@media screen and (max-width: 390px) {
    .bl-number-selection-collection {
        .collection-block {
            padding: 12px 8px;
        }
    }
}

@media screen and (max-width: 374px) {
    .bl-number-selection-collection {
        .collection-block {
            .phone {
                font-size: 22px !important;
            }
        }
    }
}

@media screen and (max-width: 319px) {
    .bl-number-selection-collection {
        .collection-block {
            .phone {
                font-size: 16px !important;
            }
        }
    }
}
