@import "../../../../../styles/variables";

.bl-banner {
    border-color: $bg-gold-color;
    min-height: 142px;
    .ant-space {
        width: 100%;
    }
    &.bronze-banner {
        .banner-title-text {
            color: $bronze-color;
        }
        background-image: url("../../../../../images/lion-slice@1x.png"), $bronze-banner;
        background-image: $lion-slice-webkit-img, $bronze-banner;
        background-image: $lion-slice-img, $bronze-banner;
        background-repeat: no-repeat;
        background-position: left top;
    }
    &.gold-banner {
        .banner-title-text {
            color: $bronze-color;
        }
        background-image: url("../../../../../images/lion-slice@1x.png"), $gold-banner;
        background-image: $lion-slice-webkit-img, $gold-banner;
        background-image: $lion-slice-img, $gold-banner;
        background-repeat: no-repeat;
        background-position: left top;
    }
    &.silver-banner {
        .banner-title-text {
            color: $text-primary-color;
        }
        background-image: url("../../../../../images/lion-slice@1x.png"), $silver-banner;
        background-image: $lion-slice-webkit-img, $silver-banner;
        background-image: $lion-slice-img, $silver-banner;
        background-repeat: no-repeat;
        background-position: left top;
    }
    &.top-banner {
        background-image: url("../../../../../images/lion-slice@1x.png"), $top-banner;
        background-image: $lion-slice-webkit-img, $top-banner;
        background-image: $lion-slice-img, $top-banner;
        background-repeat: no-repeat;
        background-position: left top;
        .banner-title-text {
            color: $text-primary-color;
        }
        .banner-text {
            p {
                color: $text-primary-color;
            }
            span {
                color: $beige-color;
            }
        }
    }

    .banner-title {
        position: relative;
    }
    .banner-title-text {
        @include title-tinyFont-normal;
    }
    .banner-title-picture {
        picture {
            position: absolute;
            left: -5px;
            top: 30px;
            img {
                width: 130px;
            }
        }
    }
    .banner-text {
        text-align: center;
        @include title-banner-normal;
        p {
            color: $text-secondary-color;
        }
        span {
            color: $bg-violet;
        }
        &.last {
            margin-bottom: 24px;
        }
    }

    @media screen and (max-width: 1199px) and (min-width: 992px) {
        .banner-title-text {
            text-align: center;
        }
        .banner-title-picture {
            picture {
                left: -5px;
                top: 0px;
                img {
                    width: 70px;
                }
            }
        }
        .banner-content {
            &.ant-col {
                flex: 0 0 100%;
                max-width: 100%;
            }
            .ant-space-vertical {
                width: 100%;
                gap: 8px !important;
            }
        }
        .banner-title {
            &.ant-col {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .banner-text {
            &.last {
                margin-bottom: 8px;
            }
        }
    }

    @media screen and (max-width: 400px) {
        .banner-title {
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            align-items: center;
            margin-bottom: 12px;
        }
        .banner-title-text {
            text-align: center;
        }
        .banner-title-picture {
            picture {
                position: relative;
                top: 0px;
                left: -12px;
                img {
                    width: 50px;
                }
            }
        }
        .banner-content {
            &.ant-col {
                flex: 0 0 100%;
                max-width: 100%;
            }
            .ant-space-vertical {
                width: 100%;
                gap: 8px !important;
            }
        }
        .banner-title {
            &.ant-col {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .banner-text {
            &.last {
                margin-bottom: 8px;
            }
        }
    }
    @media screen and (max-width: 300px) {
        .banner-text {
            font-size: 15px;
        }
    }
}
