.bl-number-list-info-modal-info-item {
  display: flex;
  flex-direction: column-reverse;
  font-weight: 700;
  .header {
    line-height: 19px;
    font-weight: 400;
  }
}

@media screen and (max-width: 768px) {
  .bl-number-list-info-modal-info-item {
    flex-direction: column;
    font-weight: 500;
    line-height: 140%;
    .header {
      line-height: 140%;
    }
  }
}