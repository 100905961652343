@import "../../../../styles/variables";

.bl-banner-slider {
    .ant-carousel {
        .slick-dots-bottom {
            bottom: 0px;
        }
        .slick-dots {
            margin-bottom: 14px;
        }
        .slick-dots li button {
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background: $dot-gray-color;
        }
        .slick-dots li.slick-active button {
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background: $text-primary-color;
        }

        .slick-arrow {
            z-index: 1;
            width: 35px;
            &:hover {
                color: $beige-color;
            }
        }
        .slick-arrow.slick-prev {
            left: 0px;
            text-align: right;
        }
        .slick-arrow.slick-next {
            right: 0px;
            text-align: left;
        }

        @media screen and (max-width: 1124px) {
            .slick-arrow {
                width: 27px;
            }
        }
    }
}
