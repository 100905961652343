.bl-scroller-wrapper {
    display: block;
    position: relative;
    height: 22px;
    width: 100px;
    .scroller-bg {
        position: absolute;
        top: 0;
        left: 0;
    }
    .scroller-line {
        position: absolute;
        top: 0;
        left: 0;
    }
    @media screen and (max-width: 450px) {
        margin-bottom: 15px;
    }
}
.scroller-isShow {
    display: none;
}
