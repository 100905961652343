.notfav {
    .skeleton-list {
        .list {
            .ant-col-lg-12 {
                max-width: 49%;
            }
        }
    }
}
.skeleton-list {
    margin-top: 12px;
    label {
        font-weight: 500;
        position: relative;
        top: 7px;
    }
    .ant-skeleton {
        overflow: hidden !important;
        border-radius: 4px;
        height: 26px !important;
        .ant-skeleton-button {
            background: linear-gradient(
                93.49deg,
                rgba(255, 255, 255, 0.2) -17.7%,
                rgba(217, 217, 217, 0.05) 49.94%
            );
            width: 100%;
            min-width: 100% !important;
            height: 100% !important;
            line-height: normal !important;
        }
        &.mw134 {
            min-width: 134px !important;
        }
        &.h40 {
            height: 40px !important;
        }
        &.w40 {
            width: 40px;
        }
        &.w26 {
            width: 26px;
        }
        &.w32 {
            width: 32px !important;
        }
        &.w20 {
            width: 20px !important;
        }
        &.w100 {
            width: 100%;
        }
        &.h20 {
            height: 20px !important;
        }
        &.h48 {
            height: 48px !important;
        }
        &.h40 {
            height: 40px !important;
        }
        &.h16 {
            height: 16px !important;
        }
        &.h14 {
            height: 14px !important;
        }
        &.h32 {
            height: 32px !important;
        }
    }
    .rt8 {
        position: relative;
        top: -8px;
    }
    .g8 {
        gap: 8px;
    }
    .f11 {
        flex: 1 1;
    }
    .head {
        margin-bottom: 2px;
    }
    .ant-divider {
        border-color: #fff;
        margin-block: -24px !important;
        padding-block: 20px !important;
        &.dv {
            border-color: rgba(207, 200, 219, 0.2);
            margin: 25px 0 !important;
            padding: 0 !important;
        }
    }
    .flex-left {
        display: flex;
        justify-content: flex-end;
    }
    .flex-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.kanban-list {
        .wrapper {
            padding: 16px;
            border: 1px solid rgba(207, 200, 219, 0.2);
            border-radius: 8px;
            @media screen and (max-width: 768px) {
                padding: 8px;
            }
            label {
                position: static;
            }
        }
        .wrapper-container {
            margin-bottom: 25px;
        }
    }
    &.mobile-list {
        margin-top: 4px;
        .wrapper {
            padding: 8px;
            border: 1px solid rgba(207, 200, 219, 0.2);
            border-radius: 8px;
            label {
                position: static;
            }
        }
        .wrapper-container {
            margin-bottom: 25px;
        }
        .ant-divider {
            margin: -4px 0 0 0 !important;
            padding: 0px 0 16px 0 !important;
        }
        label {
            top: -4px;
        }
        .head {
            margin-bottom: -7px;
        }
    }
}
.kanb {
    .skeleton-list {
        .head {
            margin-bottom: -8px;
        }
    }
}
.notfav {
    &.list {
        @media screen and (min-width: 1200px) {
            .left {
                max-width: 15.666667%;
            }
            .right {
                max-width: 5.16666667%;
            }
        }
    }
}

.page-database-of-numbers {
    background-image: url("../../../../images/number_page_background2.jpg");
    background-attachment: fixed;
    &:has(.isCollection) {
        .bl-footer {
            padding-bottom: 28px;
        }
    }
    .fav {
        .bl-list-number-content {
            margin-top: -71px;
            @media screen and (max-width: 768px) {
                margin-top: -65px;
            }
            @media screen and (max-width: 576px) {
                margin-top: -11px;
            }
        }
    }
    .notfav {
        .bl-list-number-content {
            margin-top: -58px;
            @media screen and (max-width: 576px) {
                margin-top: -22px;
            }
        }
    }
    .list-container {
        position: relative;
        width: 100%;
        &.mobile-grouped-dropdown-open {
            .ant-dropdown {
                &.sort-mobile {
                    ul {
                        opacity: 1 !important;
                    }
                }
            }
        }
        > .ant-space-item {
            &:first-child {
                position: relative;
                z-index: 10;
            }
            > .ant-space {
                width: 100%;
            }
        }
        &.grouped {
            .ant-table-thead > tr > th {
                border: 0;
            }
        }
        .ant-tooltip {
            @media screen and (max-width: 431px) {
                display: none;
            }
        }
    }
    .isCollection {
        row-gap: 77px !important;

        @media screen and (max-width: 991px) {
            row-gap: 12px !important;
            .bl-list-number-content {
                margin-top: 0px;
            }
        }
        @media screen and (max-width: 780px) {
            row-gap: 12px !important;
        }
        @media screen and (max-width: 574px) {
            row-gap: 10px !important;
        }
    }
    .block-notise-mobile {
        position: sticky;
        top: 0px;
        z-index: 50;
        height: 110px;
        margin-top: -16px;
        width: 110%;
        margin-left: -16px;
        background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%),
            linear-gradient(0deg, rgba(100, 40, 120, 0.8) 0%, rgba(100, 40, 120, 0.8) 100%),
            url("../../../../images/number_page_background_mob.jpg");
        background-repeat: no-repeat;
        background-size: cover;
    }
    @media screen and (max-width: 430px) {
        background-image: url("../../../../images/number_page_background2.jpg");

        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        transition: all 1.3s ease-in-out;
        box-sizing: border-box;
        will-change: transform;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000;
        display: block;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-top: 0;
        background-attachment: scroll;
    }
}
.clopy-all {
    min-width: 311px;
    background: #572b57 !important;
}
@media screen and (min-width: 769px) {
    .kanban {
        .ant-table {
            &:not(.ant-table-empty) {
                tbody {
                    tr {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .page-database-of-numbers {
        .ui-back {
            .ant-btn {
                svg {
                    top: -9px;
                }
            }
        }
    }
}
