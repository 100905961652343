.bl-error {
  text-align: center;
  h2 {
    font-weight: 500;
    line-height: 32px;
    >*{
      color: #000;
    }
  }
  .subtitle {
    line-height: 19px;
    >*{
      color: #000;
    }
  }
}

@media screen and (max-width: 768px) {
  .bl-error {
    .ant-space-item:nth-child(3){
      margin-top: -10px;
    }
    h2 {
      line-height: 100%;
    }
  }
  
}