.bl-in-booking {
  .ant-btn, .ant-space {
    width: 100%;
  }
  .bz-block-title {
    margin-bottom: 10px;
  }
  .bz-block {
    .bz-block-content:not(:first-child) {
      margin-top: 32px;
    }
  }
}