.field-upload-container {
    position: relative;
    .ant-btn {
        transition: none;
        &.plus {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            color: #fff;
            background: rgba(255, 255, 255, 0.2);
            .anticon {
                margin: 0;
            }
            &.remove {
                position: absolute;
                z-index: 1;
                right: 16px;
                top: 16px;
                transform: rotate(45deg);
                color: #642878 !important;
                background: rgba(255, 255, 255, 0.8) !important;
            }
            &.ant-btn-loading {
                background: transparent;
                &::before {
                    background: transparent;
                }
                .ant-btn-loading-icon {
                    &::after {
                        width: 48px;
                        height: 48px;
                    }
                }
            }
            &:not(.ant-btn-loading) {
                svg {
                    font-size: 26px;
                    position: relative;
                    top: 2px;
                }
            }
        }
    }
    &.error {
        .field-upload {
            .ant-upload {
                &.ant-upload-select-picture-card {
                    border-color: red;
                }
            }
        }
    }
    .field-upload {
        * {
            transition: none !important;
        }
        margin: 0;
        .preview-container {
            width: 100%;
            height: 100%;
            position: relative;
            .remove {
                .ant-btn-loading-icon {
                    display: none;
                }
            }
            .anticon {
                margin: 0;
            }
            .ant-image {
                width: 100%;
                height: 100%;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .ant-upload-list-picture-card-container {
            display: none;
        }
        .ant-upload {
            align-items: flex-start !important;
            .ant-upload {
                padding: 88px 16px 16px;
            }
            &.ant-upload-select-picture-card {
                background: transparent;
                border: 1px dashed rgba(255, 255, 255, 0.5);
                border-radius: 19px;
                cursor: pointer;
                width: 296px;
                height: 273px;
                margin: 0;
                text-align: center;
                transition: border-color 0.3s;
                vertical-align: top;
            }
        }
        &.preview {
            .ant-upload {
                .ant-upload {
                    padding: 58px 32px;
                }
            }
        }
        .title {
            font-weight: 400;
            line-height: 19px;
        }
    }
}

@media screen and (max-width: 768px) {
    .field-upload-container {
        .field-upload {
            &.preview {
                .ant-upload {
                    .ant-upload {
                        padding: 32px 24px;
                    }
                }
            }
            .title {
                font-weight: 500;
            }
        }
    }
}
@media screen and (max-width: 670px) {
    .bl-field-passport-upload {
        .ant-space {
            display: flex;
        }
    }
    .field-upload-container {
        .field-upload {
            .ant-upload {
                .ant-upload {
                    padding: 85px 3px 43px;
                }
                &.ant-upload-select-picture-card {
                    width: 100%;
                    height: 250px;
                }
            }
        }
    }
}
@media screen and (max-width: 445px) {
    .field-upload-container {
        .field-upload {
            .ant-upload {
                .ant-upload {
                    padding: 43px 36px 34px;
                }
                &.ant-upload-select-picture-card {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
@media screen and (max-width: 420px) {
    .field-upload-container {
        .field-upload {
            .ant-upload {
                .ant-upload {
                    padding: 20px 36px 34px;
                }
                &.ant-upload-select-picture-card {
                    width: 160px;
                    height: 150px;
                }
            }
        }
    }
}
@media screen and (max-width: 370px) {
    .field-upload-container {
        .field-upload {
            .ant-upload {
                .ant-upload {
                    padding: 20px 3px 34px;
                }
                &.ant-upload-select-picture-card {
                    width: 156px;
                    height: 150px;
                    .bz-typography {
                        font-size: 12px !important;
                        width: 79px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 300px) {
    .bl-field-passport-upload {
        .ant-space-align-center {
            gap: 6px !important;
        }
    }
    .field-upload-container {
        .field-upload {
            .ant-upload {
                &.ant-upload-select-picture-card {
                    width: 105px;
                    height: 150px;
                    .bz-typography {
                        font-size: 11px !important;
                    }
                }
            }
        }
    }
}
