.bl-lottery-widget {
    .ant-carousel {
        .slick-dots {
            bottom: 25px;
            margin-right: 5%;
            padding-left: 0;
            justify-content: flex-end;
            @media screen and (max-width: 820px) {
                bottom: 20px;
            }
            li {
                width: 15px;
                button {
                    border-radius: 50%;
                    height: 12px;
                    width: 12px;
                    background: linear-gradient(
                        141deg,
                        #efce73 25.79%,
                        #fef0b7 63.66%,
                        #cea86f 97.07%
                    );
                    &:focus {
                        opacity: 0.3;
                    }
                    &:hover {
                        opacity: 0.3;
                    }
                }
            }
            .slick-active {
                button {
                    opacity: 1 !important;
                }
            }
        }
    }
}
