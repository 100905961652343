@import "../../../styles/variables";

.bl-modal-qrcode {
    .qrcode-wrapper {
        max-width: 340px;
        margin: auto;
    }
    .qrcode-title {
        @include bigFont-medium;
        color: $text-secondary-color;
        white-space: pre-line;
        margin-bottom: 16px;

        span {
            color: $text-violet-color;
            display: contents;
        }
    }
    .qrcode-text {
        color: var(--gray-550, rgba(0, 0, 0, 0.5));
        font-size: 18px;
    }
    .qrcode-text-bottom {
        color: var(--gray-1, #1f1f1f);
        line-height: 140%;
    }
    .qrcode-img {
        max-width: 100%;
        width: 340px;
        height: 340px;
    }

    //shimmer
    .shimmer {
        pointer-events: none;
        color: transparent;
        background: linear-gradient(
            90deg,
            hsla(0, 0%, 75%, 0.35) 25%,
            hsla(0, 0%, 51%, 0.4) 37%,
            hsla(0, 0%, 75%, 0.35) 63%
        );
        background-size: 400% 100%;
        animation-name: skeleton-loading;
        animation-duration: 1.4s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        border-radius: 4px;
        height: 20px;
        margin-bottom: 8px;

        span {
            color: transparent;
        }
    }

    @keyframes skeleton-loading {
        0% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0 50%;
        }
    }
}
