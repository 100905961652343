.bl-header {
    z-index: 1;
    height: auto;
    background: transparent;
    padding: 50px 50px 0;
    margin-bottom: 50px;
    position: relative;
    .bl-burger {
        .ant-btn {
            background: transparent;
            border: 0;
            color: #fff;
        }
    }
    .ant-row {
        align-items: center;
    }

    .btn-default.ant-btn {
        cursor: pointer;
        height: 30px;
        width: 30px;
        background: transparent;
        border: 0;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ffffff;
        border-radius: 8px;

        svg {
            fill: none;
            width: 16px;
            height: 16px;
        }
    }
    .btn-default.ant-btn :hover {
        color: rgba(255, 255, 255, 0.7);
    }
}
.title-gold-dashboard-page {
    position: absolute;
    font-family: "ALS Alumna";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 100%;
    text-align: center;
    background: linear-gradient(141.08deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    top: -43px;
    left: calc(47% - 123px);
}
@media screen and (min-width: 768px) {
    .bl-header {
        .btn-default.ant-btn {
            height: 40px;
            width: 40px;

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .bl-header {
        padding: 0 16px;
        margin: 0;
        .bl-burger {
            .ant-btn {
                position: relative;
                top: 8px;
            }
        }
        .title-gold {
            display: none;
        }
    }
}
@media screen and (max-width: 580px) {
    .bl-header {
        .ant-row {
            justify-content: space-between;
        }
    }
}
