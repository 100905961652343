.bl-numbers-list-header {
  position: relative;
  z-index: 1;
  .ant-space {
    -ms-flex-align: flex-end;
    align-items: flex-end;
  }
}

@media screen and (max-width: 768px) {
  .bl-numbers-list-header {
    >.ant-space {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}