.bl-output-bonuses-form
    .bl-radio-button-group
    .ant-form-item-control-input-content
    .ant-radio-group
    .ant-radio-button-wrapper-disabled {
    background: url("../../../images/gold-opacity.png") center center no-repeat,
        url("../../../images/gold-texture.png") center center no-repeat !important;
    background-size: 100% !important;
    background-blend-mode: color, normal !important;
    transition: none !important;
    white-space: nowrap !important;
    color: #000000 !important;
}
