.ant-dropdown-placement-topRight {
    .ant-dropdown-menu {
        background: url("../../../../../../../../images/tooltip.png") no-repeat center;
        background-size: cover;
        min-height: 96px;
        min-width: 195px;
        border-radius: 0;
        box-shadow: none;
        .ant-dropdown-menu-item {
            padding-left: 10px;
            padding-right: 10px;
            text-align: start;
            &:hover {
                background: transparent;
            }
            .ant-dropdown-menu-title-content {
                .delete-filter {
                    margin-bottom: 15px;
                }
            }
        }
    }
    .bz-typography {
        background: transparent;
        font-size: 14px;
        font-weight: 400;
        &:hover {
            color: rgba(255, 255, 255, 0.5);
        }
        svg {
            margin-right: 8px;
        }
    }
}
.ant-dropdown-placement-bottomRight {
    .ant-dropdown-menu {
        background: url("../../../../../../../../images/tooltip_reverse.png") no-repeat center;
        background-size: cover;
        min-height: 96px;
        min-width: 195px;
        border-radius: 0;
        box-shadow: none;
        filter: grayscale(50%);
        .ant-dropdown-menu-item {
            padding-left: 10px;
            padding-right: 10px;
            text-align: start;
            padding-top: 7px;
            &:hover {
                background: transparent;
            }
            .ant-dropdown-menu-title-content {
                .change-name {
                    padding-top: 25px;
                }
                .delete-filter {
                    margin-bottom: -7px;
                }
            }
        }
    }
    .bz-typography {
        background: transparent;
        font-size: 14px;
        font-weight: 400;
        &:hover {
            color: rgba(255, 255, 255, 0.5);
        }
        svg {
            margin-right: 8px;
        }
    }
}
