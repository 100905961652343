#usedesk-messenger {
    color: #000;
}

@media screen and (max-width: 768px) {
    .page-profile {
        .ui-back {
            svg {
                top: -9px;
            }
        }
    }
}
