@import "../../../../../styles/variables";

.payments-history {
    margin-bottom: 16px;

    .total-sum-sum-ikon {
        display: flex;
        justify-content: right;
    }
    .bl-nps {
        .nps-description {
            background: var(
                --Linear,
                linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%)
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: "ALS Hauss";
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            img {
                margin-right: 4px;
                @media screen and (max-width: 767px) {
                    width: 20px;
                }
            }
            @media screen and (max-width: 767px) {
                position: absolute;
                margin-left: -16px !important;
                border-top: 1px solid transparent;
                border-image: linear-gradient(0.25turn, #642878, #cea86f, #642878);
                border-image-slice: 1;
                width: 100%;
                max-width: 125px;
                padding-top: 1px;
                margin-top: 1px;
            }
        }
    }
}

.loaylty-progress-line {
    &.active {
        .ant-progress-bg {
            background-color: white;
            background-image: url("../../../../../images/gold-line@1x.png");
            background-image: -webkit-image-set(
                url("../../../../../images/gold-line@1x.webp") 1x,
                url("../../../../../images/gold-line@1x.png") 1x,
                url("../../../../../images/gold-line@2x.webp") 2x,
                url("../../../../../images/gold-line@1x.png") 2x
            );
            background-image: image-set(
                url("../../../../../images/gold-line@1x.webp") 1x,
                url("../../../../../images/gold-line@1x.png") 1x,
                url("../../../../../images/gold-line@2x.webp") 2x,
                url("../../../../../images/gold-line@1x.png") 2x
            );
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }
    .ant-progress-inner {
        background-color: rgba(255, 255, 255, 0.2);
    }
    .ant-progress-bg {
        background-color: white;
        background-image: url("../../../../../images/gold-line@1x.png");
        background-image: -webkit-image-set(
            url("../../../../../images/gold-line@1x.webp") 1x,
            url("../../../../../images/gold-line@1x.png") 1x,
            url("../../../../../images/gold-line@2x.webp") 2x,
            url("../../../../../images/gold-line@1x.png") 2x
        );
        background-image: image-set(
            url("../../../../../images/gold-line@1x.webp") 1x,
            url("../../../../../images/gold-line@1x.png") 1x,
            url("../../../../../images/gold-line@2x.webp") 2x,
            url("../../../../../images/gold-line@1x.png") 2x
        );
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}

@media screen and (min-width: $max-phone-width) {
    .payments-history {
        .mobile-text-payments {
            display: none;
        }
    }
}

@media screen and (max-width: $max-tablet-width) {
    .payments-history {
        .list-total-sum,
        .total-sum-sum {
            font-size: 17px !important;
            line-height: 100%;
            font-weight: 700;
        }
    }
}

@media screen and (max-width: $max-phone-width) {
    .payments-history {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        padding-bottom: 24px;
        margin-bottom: 24px;
        .mobile-loyalty {
            text-align: end;
        }
        .mobile-total-sum {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
        .total-sum-sum-ikon {
            text-align: end;
        }
        .list-total-sum {
            font-size: 17px !important;
            line-height: 100%;
            margin-bottom: 4px;
            font-weight: 700;
        }
        .mobile-payments-sum {
            text-align: start;
        }
        .date-format-mobile-payments {
            margin-top: 4px;
            font-weight: 500;
        }
        .col-mobile-status-payments {
            display: flex;
            align-items: center;
            .bz-typography {
                margin-left: 4px;
            }
            img {
                width: 14px;
                height: 14px;
            }
        }
        .date-format-mobile {
            display: flex;
            justify-content: center;
            margin-bottom: 24px;
            .date-format-mobile-text {
                background: rgba(255, 255, 255, 0.16);
                border-radius: 16px;
                font-weight: 500;
                line-height: 100%;
                color: rgba(255, 255, 255, 0.5);
                min-width: 127px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 10px;
            }
        }
    }
}
