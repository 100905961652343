.bl-column-title {
  .ant-checkbox-wrapper {
    position: relative;
    z-index: 2;
    > span:last-child {
      position: absolute;
      left: 18px;
      white-space: nowrap;
      z-index: 1;
      top: 4px;
    }
  } 
  .title {
    font-weight: 700;
    line-height: 120%;
  }
  &.absolute {
    position: absolute;
    left: 0;
    top: -18px;
  }
}