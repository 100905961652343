.bl-reservation-list-info-actions-tooltip-container {
    position: relative;
    > div {
        top: 12px !important;
        margin-top: 10px;
    }
}
.bl-reservation-list-info-actions {
    @media screen and (max-width: 768px) {
        display: flex;
    }
    .simpng {
        width: 24px;
        height: 24px;
    }
    svg {
        font-size: 24px;
    }

    .ant-btn {
        background: transparent;
        width: auto;
        display: flex;
        align-content: center;
        align-items: center;

        svg {
            font-size: 24px;
        }

        &.copy {
            position: relative;

            path {
                stroke: #fff;
            }

            span {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                margin: auto;
            }
        }
    }
}

.toltip-reservation {
    transition: none;
    top: initial !important;
    bottom: 68px !important;
    z-index: 1;
    position: relative;
    @media screen and (max-width: 768px) {
        top: -67px !important;
        &.is-android {
            .ant-tooltip-arrow {
                top: 12px;
            }
        }
    }
    .ant-tooltip-arrow {
        transition: none;
        top: 13px;
        .ant-tooltip-arrow-content {
            transition: none;
            --antd-arrow-background-color: linear-gradient(
                to right bottom,
                rgba(94, 0, 94, 1),
                rgba(94, 0, 94, 1)
            );
            overflow: hidden;
            border: 1px solid rgba(255, 255, 255, 0.6);
            // backdrop-filter: blur(20px);
            border-radius: 0;
            &::before {
                top: -13px;
            }
        }
    }
    .ant-tooltip-inner {
        background: rgb(94 0 94);
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 8px;
        position: relative;
        transition: none;
        white-space: nowrap;
        &::after {
            content: "";
            transition: none;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -1px;
            height: 1px;
            background: rgb(94 0 94);
            margin: auto;
            width: 13px;
        }
    }
}
.toltip-reservation-hover {
    transition: none;
    top: initial !important;
    bottom: 68px !important;
    z-index: 1;
    position: relative;
    @media screen and (max-width: 768px) {
        top: -67px !important;
        &.is-android {
            .ant-tooltip-arrow {
                top: 12px;
            }
        }
    }
    .ant-tooltip-arrow {
        transition: none;
        top: 13px;
        .ant-tooltip-arrow-content {
            transition: none;
            --antd-arrow-background-color: linear-gradient(
                to right bottom,
                rgba(94, 0, 94, 1),
                rgba(94, 0, 94, 1)
            );
            overflow: hidden;
            border: 1px solid rgba(255, 255, 255, 0.6);
            border-radius: 0;
            &::before {
                top: -13px;
            }
        }
    }
    .ant-tooltip-inner {
        background: rgb(94 0 94);
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 8px;
        position: relative;
        transition: none;
        white-space: nowrap;
        &::after {
            content: "";
            transition: none;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -1px;
            height: 1px;
            background: rgb(94 0 94);
            margin: auto;
            width: 13px;
        }
    }
}
