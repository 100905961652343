.bl-description-list-delivery {
    .btn-repeat-delivery {
        margin-top: 32px;
        .ant-btn {
            width: 251px;
            height: 36px;
            font-size: 14px;
        }
    }

    .spase-list-description {
        width: 100%;
        .ant-space-item {
            .List:last-child {
                border-bottom: none !important;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .bl-description-list-delivery {
        .spase-list-description {
            border-bottom: none;
            padding: 0;
        }
        .List {
            .ant-divider {
                display: none;
            }
        }
    }
}
