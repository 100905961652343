@import '../../../../styles/variables';

.bl-confirm-email {
    height: 100%;
    .ant-space-item {
        width: 100%;
    }
    .bz-block-content{
        height: 100%;
        .ant-space-vertical {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
    .subtitle {
        color: $text-pale-color;
        @include tinyFont-normal;
    }
    .confirm-field-read-only{
        color: $text-primary-color;
        background: none;
        border: 1px solid hsla(0,0%,100%,.5);
        border-radius: 8px;
    }
}
