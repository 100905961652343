.bl-mobile-active {
  background: rgba(31, 31, 31, 0.5);
  -webkit-backdrop-filter: blur(21px);
  backdrop-filter: blur(21px);
  padding: 16px 0px 16px;

  border: none;

  .col-content-block {
    text-align: start;
  }

  .discription-devices {
    color: var(--white-250, rgba(255, 255, 255, 0.5));
    font-family: ALS Hauss;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    display: flex;
    align-items: center;
  }
}
