.error-not-found {
    padding: 128px 16px 0;

    font-family: "ALS Hauss";
    font-style: normal;
    font-weight: normal;
    font-size: 17px;

    @media screen and (max-height: 700px) {
        padding-top: 64px;
    }

    .title {
        color: rgba(255, 255, 255, 0.5);
        font-size: 17px;
    }

    .description {
        color: #fff;
        font-size: 17px;

        a {
            font-size: 17px;
            line-height: 20.4px;
            vertical-align: baseline;
            text-decoration: underline;
        }
    }

    .error-code {
        font-family: "ALS Alumna";
        font-size: 107px;
        color: rgba(206, 168, 111, 1);
    }
}
.bl-page-title {
    min-height: 100vh !important;
}
