.bl-numbers-list-info-description {
  .bz-typography {
    font-weight: 500;
    line-height: 19px;
  }

  .nowrap {
    white-space: nowrap;
  }

  svg {
    font-size: 5px;
    position: relative;
    top: -2px;
  }

  .tariff-name {
    svg {
      position: static;
      top: 0;
    }
  }

  svg.info {
    font-size: 16px;
  }

  ol {
    display: block;

    li {
      display: inline;
    }

    nav {
      display: inline;

      ol {
        display: inline;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .bl-numbers-list-info-description {
    .tariff-name {
      display: flex;
      .ant-space-item {
        &:last-child {
          display: flex;
        }
      }
    }

    .bz-typography {
      font-weight: 400;
    }
    svg{
      display: none;
    }
    .ant-breadcrumb-separator{
      margin: 0;
    }
  }
}