.bl-book-a-number-form {
  .ant-space-item {
    &:first-child {
      display: none;
    }
    .ant-btn {
      &[type="submit"] {
        min-width: 364px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .bl-book-a-number-form {
    .ant-space-item {
      .ant-btn {
        &[type="submit"] {
          min-width: 100%;
        }
      }
    }
  }
}