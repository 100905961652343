.bl-block1-1 {
    padding: 24px 18px 42px;
    max-width: 359px;
    background: url("../../../../../images/npsbg6.png") no-repeat;
    background-size: 100% auto;
    color: var(--White, #fff);
    font-family: "ALS Hauss";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    margin: -28px auto 0;
    overflow: hidden;
    backdrop-filter: blur(21px);
    position: relative;
    border-radius: 44px 44px 0px 0px;
    @media screen and (max-width: 430px) {
        max-width: calc(100% - 16px);
        border-radius: 51px 51px 0px 0px;
    }
    @media screen and (max-width: 414px) {
        border-radius: 49px 49px 0px 0px;
    }
    @media screen and (max-width: 390px) {
        border-radius: 46px 46px 0px 0px;
    }
    @media screen and (max-width: 375px) {
        border-radius: 44px 44px 0px 0px;
    }
    @media screen and (max-width: 360px) {
        border-radius: 42px 42px 0px 0px;
    }
}
.bl-block1 {
    max-width: 1200px;
    border-radius: 62px 62px 0px 0px;
    border-top: 1px solid var(--Linear, #fef0b7);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0px 0px 1px 0px #21045c;
    backdrop-filter: blur(21px);
    padding: 42px 62px;
    margin: auto;
    @media screen and (max-width: 768px) {
        padding: 24px 18px 42px;
        max-width: 343px;
        background: url("../../../../../images/npsbg6.png") no-repeat;
        background-size: 100% auto;
        box-shadow: none;
        border-radius: 0;
        border: 0;
    }
    @media screen and (max-width: 430px) {
        max-width: calc(100% - 32px);
    }

    .bottom {
        color: var(--White, #fff);
        font-family: "ALS Hauss";
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @media screen and (max-width: 1200px) {
            font-size: 24px;
        }
        @media screen and (max-width: 992px) {
            font-size: 20px;
        }
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
    .top {
        display: flex;
        gap: 72px;
        padding-bottom: 32px;
        border-style: solid;
        border-bottom-width: 1px;
        border-image: linear-gradient(
                90deg,
                rgba(134, 29, 169, 1) 0%,
                rgba(250, 233, 182, 1) 49%,
                rgba(134, 29, 169, 1) 100%
            )
            100% 0 100% 0 / 0px 0 1px 0 stretch;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        margin-bottom: 24px;
        @media screen and (max-width: 768px) {
            gap: 12px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0;
            padding: 0;
            border: 0;
            img {
                width: 120px;
            }
        }
        .inner {
            display: flex;
            gap: 24px;
            flex-direction: column;
            justify-content: center;
            @media screen and (max-width: 992px) {
                margin-bottom: 12px;
            }
            @media screen and (max-width: 768px) {
                gap: 2px;
                margin: 0;
            }
            .desc {
                color: #fff;
                leading-trim: both;
                text-edge: cap;
                font-family: "ALS Hauss";
                font-size: 32px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                &.mt16 {
                    margin-top: -16px;
                    @media screen and (max-width: 768px) {
                        margin: 0;
                    }
                }
                @media screen and (max-width: 1200px) {
                    font-size: 26px;
                }
                @media screen and (max-width: 992px) {
                    font-size: 20px;
                }
                @media screen and (max-width: 768px) {
                    text-align: center;
                    font-size: 18px;
                    line-height: 28px;
                }
                @media screen and (max-width: 360px) {
                    font-size: 16px;
                    line-height: 26px;
                }
            }
            .title {
                leading-trim: both;
                text-edge: cap;
                -webkit-text-stroke-width: 0.5;
                -webkit-text-stroke-color: var(--Beige, #fef0b7);
                font-family: "ALS Alumna";
                font-size: 70px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
                background: var(
                    --Linear,
                    linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%)
                );
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-left: -4px;
                @media screen and (max-width: 1200px) {
                    font-size: 48px;
                }
                @media screen and (max-width: 992px) {
                    font-size: 42px;
                }
                @media screen and (max-width: 768px) {
                    text-align: center;
                    font-size: 26px;
                    font-weight: 550;
                    line-height: 28px;
                    text-transform: none;
                    font-family: "ALS Hauss";
                }
            }
        }
    }
}
