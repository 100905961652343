.bl-ng-timer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    gap: 12px;

    &__dots {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 9px;
        overflow: hidden;
        background: rgba(255, 255, 255, 0.02);
        backdrop-filter: blur(21px);
        width: 26px;
        height: 87px;
        position: relative;
        top: 4px;
        background-image: radial-gradient(
                circle at 100% 100%,
                transparent 8px,
                #ffeaaf 8px,
                #ffeaaf 9px,
                transparent 9px
            ),
            linear-gradient(to right, #ffeaaf, #dfb06d),
            radial-gradient(
                circle at 0% 100%,
                transparent 8px,
                #dfb06d 8px,
                #dfb06d 9px,
                transparent 9px
            ),
            linear-gradient(to bottom, #dfb06d, #d17014),
            radial-gradient(
                circle at 0% 0%,
                transparent 8px,
                #d17014 8px,
                #d17014 9px,
                transparent 9px
            ),
            linear-gradient(to left, #d17014, #efaf5f),
            radial-gradient(
                circle at 100% 0%,
                transparent 8px,
                #efaf5f 8px,
                #efaf5f 9px,
                transparent 9px
            ),
            linear-gradient(to top, #efaf5f, #ffeaaf);
        background-size: 9px 9px, calc(100% - 18px) 1px, 9px 9px, 1px calc(100% - 18px);
        background-position: top left, top center, top right, center right, bottom right,
            bottom center, bottom left, center left;
        background-repeat: no-repeat;
        -webkit-box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 4px 4px 2px 0px rgba(0, 0, 0, 0.2);

        @media screen and (max-width: 1200px) {
            width: 17px;
            height: 48px;
            border-radius: 4px;
            background-image: radial-gradient(
                    circle at 100% 100%,
                    transparent 3px,
                    #ffeaaf 3px,
                    #ffeaaf 4px,
                    transparent 4px
                ),
                linear-gradient(to right, #ffeaaf, #dfb06d),
                radial-gradient(
                    circle at 0% 100%,
                    transparent 3px,
                    #dfb06d 3px,
                    #dfb06d 4px,
                    transparent 4px
                ),
                linear-gradient(to bottom, #dfb06d, #d17014),
                radial-gradient(
                    circle at 0% 0%,
                    transparent 3px,
                    #d17014 3px,
                    #d17014 4px,
                    transparent 4px
                ),
                linear-gradient(to left, #d17014, #efaf5f),
                radial-gradient(
                    circle at 100% 0%,
                    transparent 3px,
                    #efaf5f 3px,
                    #efaf5f 4px,
                    transparent 4px
                ),
                linear-gradient(to top, #efaf5f, #ffeaaf);
            background-size: 4px 4px, calc(100% - 8px) 1px, 4px 4px, 1px calc(100% - 8px);
            background-position: top left, top center, top right, center right, bottom right,
                bottom center, bottom left, center left;
            background-repeat: no-repeat;
        }
        @media screen and (max-width: 340px) {
            height: 39px;
        }

        &__text {
            text-align: center;
            filter: drop-shadow(2px 1px 0px #3d074f);
            font-family: "ALS Alumna";
            font-size: 100px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            background: linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media screen and (max-width: 1200px) {
                font-size: 50px;
            }
            @media screen and (max-width: 340px) {
                font-size: 32px;
            }
        }
    }
}
