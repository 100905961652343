@import "../../../styles/variables";

.bl-modal-sbp {
    .sbp-wrapper {
        max-width: 340px;
        margin: auto;
        text-align: center;
    }
    .sbp-image {
        width: 200px;
        height: 200px;

        &.ring-shimmer {
            -webkit-animation: outercirc 3000ms linear infinite;
            -moz-animation: outercirc 3000ms linear infinite;
            animation: outercirc 3000ms linear infinite;
        }
    }
    .sbp-title {
        @include standardFont-bold;
        color: $text-secondary-color;
    }
    .sbp-subtitle {
        @include standardFont-medium;
        color: $text-secondary-color;
        white-space: pre-line;

        span{
            color: $text-violet-color;
            display: contents;
            font-weight: 700;
        }
    }
    .sbp-text {
        @include standardFont-normal;
        color: #642878;
    }
    .spb-comment {
        @include tinyFont-normal;
        color: $text-secondary-color;
        white-space: pre-line;

    }

    //shimmer
    .shimmer {
        pointer-events: none;
        color: transparent;
        background: linear-gradient(90deg,hsla(0,0%,75%,.35) 25%,hsla(0,0%,51%,.4) 37%,hsla(0,0%,75%,.35) 63%);
        background-size: 400% 100%;
        animation-name: skeleton-loading;
        animation-duration: 1.4s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        border-radius: 4px;
        height: 20px;
        margin-bottom: 8px;

        span {
            color: transparent;
        }
    }
    
    @keyframes skeleton-loading {
        0% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0 50%;
        }
    }

}