.bl-code-confirmation-form {
    .ant-form-item-explain {
        display: none;
    }
    .code-container {
        position: relative;
        .ant-btn {
            display: none;
            &.ant-btn-loading {
                height: 48px;
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                z-index: 1;
                background: transparent;
                opacity: 1;
                &::before {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .bl-code-confirmation-form {
        margin-top: 55px;
        .error-text.error-show,
        .send-to-phone-message {
            margin: 24px 0 56px !important;
        }
    }
}
