.bl-scroller-buttons {
    display: flex;
    margin: 0 auto;

    .current-item,
    .total {
        font-family: "ALS Hauss";
        font-style: normal;
        font-size: 24px;
        line-height: 120%;
        white-space: nowrap;
        display: flex;
        align-items: center;
    }
    .current-item {
        font-weight: 700;
        margin-left: 21px;
    }
    .total {
        font-weight: 400;
        margin-right: 21px;
    }
    // for safari
    @media not all and (min-resolution: 0.001dpcm) {
        .current-item,
        .total {
            -webkit-text-fill-color: #fef0b7;
            background: none;
            color: #fef0b7;
        }
    }
    @media screen and (min-width: 991px) {
        margin-top: 0px;
    }
    @media screen and (max-width: 991px) {
        margin-top: 12px;
    }
}
#left-button,
#right-button {
    cursor: pointer;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin: 0px auto;
    svg {
        scale: 1.3;
    }
    button {
        width: 42px;
        height: 42px;
        background: #ffffff00;
        border-radius: 50%;
        border: 1px solid #fef0b7;
        svg {
            color: #fef0b7;
        }
        &:hover {
            background: url(~@bezlimit/bezlimit-ui/dist/images/gold-opacity.png) center center
                    no-repeat,
                url(~@bezlimit/bezlimit-ui/dist/images/gold-texture.png) center center no-repeat;
            background-size: auto 100%;
            background-blend-mode: color, normal;
            svg {
                color: #1f1f1f;
            }
        }
        &:disabled {
            &:hover {
                background: #767676;
            }
        }
    }
}
