#autopayment-form--description-defaultCreate_card_id {
    display: block;
    .bankCards-list--row {
        color: #000000;
        margin-top: 16px;
        .ant-row-space-between {
            align-items: center !important;
            line-height: 1 !important;
        }
        .numbers-list--type {
            font-size: 14px;
            font-weight: 500;
            display: flex;
            align-items: center;
            @media screen and (max-width: 369px) {
                font-size: 12px !important;
            }
            svg {
                width: 46px !important;
                height: 30px !important;
                margin-right: 9px;
                background-color: white;
                border-radius: 5px;
            }
            span {
                margin-top: -3px;
                margin-right: 3px;
                margin-left: 3px;
            }
            p {
                @media screen and (max-width: 369px) {
                    width: 65px;
                    font-size: 14px;
                    text-align: start;
                }
            }
        }
    }
}
