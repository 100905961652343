@import "../../../styles/variables";

.bl-modal-bank-list {
    .ant-modal-content {
         width: 459px;
         height: 787px;
         padding: 56px 42px;
         background-image: none;
         margin: auto;
    }
    .bank-title {
        color: $text-secondary-color;
        margin-bottom: 16px;
        @include bigFont-medium;
    }
    .bank-list-select {
        width: 100%;
        min-width: 375px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 8px;

        .ant-select-selection-search {
            color: #828282;
        }
        .ant-select-selection-item {
            color: #1F1F1F;
        }
        .ant-select-selector {
            .ant-select-selection-placeholder {
                position: relative;
                top: 9px;
            }
        }
        .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
            border: none !important;
        }
    }
    .bank-list-holder {
        width: 375px;
        height: 456px;
        background-color: white;
    }
    .bank-list-btn {
        margin-top: 36px;
        font-weight: 500;
    }
    .bank-cancel-btn {
        color: #1f1f1f;
        font-weight: 500;
        &.ant-btn:active {
            background: none;
            color: #642878;
        }
        &:hover {
            color: #642878;
        }

    }
}
.bank-list-dropdown {
    box-shadow: none;
    min-width: 375px !important;
    width: 375px !important;
    margin-left: -4px;

    .bank-list-option {
        border-bottom: 1px solid #E5E5E5;
        padding-top: 13px;
        padding-bottom: 13px;

        &.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
            background-color: unset;

            .ant-radio-inner:after {
                opacity: 1;
                transform: scale(.5);
                transition: all .3s cubic-bezier(.78,.14,.15,.86);
            }

        }
    }

    .bank-list-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .bank-list-icon {
        display: contents;
        img{
            margin-right: 10px;
        }
    }

    .bank-list-divider {
        margin: 0;
    }
}

@media screen and (max-width: 490px) {
    .bl-modal-bank-list {
        .ant-modal-content {
            width: Calc(100vw - 32px);
            height: 677px;
            margin: auto;
            padding: 40px 16px;
        }
        .bank-title {
            margin-bottom: 8px;
        }
        .bank-list-select {
            width: Calc(100vw - 64px);
            min-width: Calc(100vw - 64px);
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 8px;
        }
        .bank-list-holder {
            width: Calc(100vw - 64px);
            height: 376px;
            background-color: white;
        }
    }

    .bank-list-dropdown {
        box-shadow: none;
        min-width: Calc(100vw - 64px) !important;
        width: Calc(100vw - 64px) !important;
        margin-left: 0px;

        .bank-list-option {
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }
}

@media screen and (max-height: 600px) {
    .bl-modal-bank-list{
        .bank-list-holder {
            height: 188px;
        }
    }
}