@import "../../../styles/variables";

.bl-support-faq {
    p {
        margin-bottom: 1rem;
    }
    li {
        margin-bottom: 1rem;
        background: url("../../../images/star.svg") no-repeat left center;
        padding: 0px 0px 3px 23px;
        list-style: none;
    }
    img {
        width: 100%;
        margin: 30px auto;
        border-radius: 16px;
    }
    .faq-title {
        text-align: center;
        color: $text-secondary-color;
        @include title-standardFont-normal;
        @media screen and (max-width: 430px) {
            word-wrap: initial !important;
            font-size: 30px;
        }
    }
}

.modal-carousel-container {
    .slick-arrow {
        color: $text-primary-color;
        min-width: 162px;
        width: min-content;
        height: 57px;
        @include title-tinyFont-normal;
        &::before {
            display: none;
        }
        &:hover {
            color: $beige-color;
        }
    }
    .slick-arrow.slick-prev {
        left: calc(100% / 2 - 600px);
        position: fixed;
        top: 50%;
    }
    .slick-arrow.slick-next {
        right: calc(100% / 2 - 600px);
        position: fixed;
        top: 50%;
    }

    @media screen and (max-width: 1356px) {
        .slick-arrow.slick-prev {
            left: calc(50% - 510px) !important;
            position: fixed;
            top: 50%;
        }
        .slick-arrow.slick-next {
            left: calc(50% + 350px) !important;
            position: fixed;
            top: 50%;
        }
    }

    @media screen and (max-width: 1024px) {
        .slick-arrow.slick-prev {
            left: calc(50% - 450px) !important;
            position: fixed;
            top: 50%;
        }
        .slick-arrow.slick-next {
            left: calc(50% + 290px) !important;
            position: fixed;
            top: 50%;
        }
    }

    @media screen and (max-width: $max-tablet-width) {
        .slick-arrow {
            display: none !important;
        }
    }
}
