.bl-block3 {
    border-radius: 42px 42px 0px 0px;
    box-shadow: -1px 0px 1px 0px #20014a;
    backdrop-filter: blur(21px);
    padding: 32px 32px 16px 32px;
    background: url("../../../../../images/npsbg3.png") no-repeat;
    background-size: 100% auto;
    @media screen and (max-width: 768px) {
        max-width: 375px;
        background: url("../../../../../images/npsbg6.png") no-repeat;
        background-size: 100% auto;
        margin: -40px auto 0;
        backdrop-filter: blur(21px);
        position: relative;
        border-radius: 46px 46px 0px 0px;
        box-shadow: none;
        border: 0;
        padding: 24px 17px 62px 17px;
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 1px;
        }
        &::before {
            left: 0;
            background: #faeab7;
        }
        &::after {
            right: 0;
            background: #861da9;
        }
    }
    @media screen and (max-width: 430px) {
        max-width: 100%;
        border-radius: 53px 53px 0px 0px;
    }
    @media screen and (max-width: 414px) {
        border-radius: 51px 51px 0px 0px;
    }
    @media screen and (max-width: 390px) {
        border-radius: 48px 48px 0px 0px;
    }
    @media screen and (max-width: 375px) {
        border-radius: 46px 46px 0px 0px;
    }
    @media screen and (max-width: 375px) {
        border-radius: 44px 44px 0px 0px;
    }
    .title {
        color: var(--White, #fff);
        leading-trim: both;
        text-edge: cap;
        font-family: "ALS Hauss";
        font-size: 26px;
        font-style: normal;
        font-weight: 550;
        line-height: 28px;
        @media screen and (max-width: 768px) {
            text-align: center;
            font-size: 22px;
            margin-bottom: 0;
        }
    }
    .list {
        &.mb24 {
            margin-bottom: 8px;
        }
        .subtitle {
            font-family: "ALS Hauss";
            font-size: 18px;
            font-style: normal;
            font-weight: 550;
            line-height: 20px;
            background: var(
                --Linear,
                linear-gradient(141deg, #efce73 25.79%, #fef0b7 63.66%, #cea86f 97.07%)
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 16px;
            @media screen and (max-width: 768px) {
                font-size: 14px;
                font-style: normal;
                font-weight: 550;
                line-height: 20px;
            }
        }
        .items {
            .item {
                &:not(:last-child) {
                    border-style: solid;
                    border-bottom-width: 1px;
                    border-image: linear-gradient(
                            90deg,
                            rgb(250, 233, 182) 0%,
                            rgb(172, 95, 173) 49%,
                            rgb(134, 29, 169) 100%
                        )
                        100% 0 100% 0 / 0px 0 1px 0 stretch;
                    border-top: 0;
                    border-left: 0;
                    border-right: 0;
                    @media screen and (max-width: 768px) {
                        border-image: linear-gradient(
                                90deg,
                                rgba(250, 234, 183, 1) 0%,
                                rgba(174, 99, 173, 1) 49%,
                                rgba(134, 29, 169, 1) 100%
                            )
                            100% 0 100% 0 / 0px 0 1px 0 stretch;
                    }
                }
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 24px;
                padding: 16px 10px;
                @media screen and (max-width: 768px) {
                    padding: 16px 12px;
                }
                ul {
                    padding-left: 10px;
                    margin-bottom: 0;
                    li {
                        color: var(--White, #fff);
                        leading-trim: both;
                        text-edge: cap;
                        font-family: "ALS Hauss";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 20px;
                        b {
                            display: contents;
                        }
                        &:not(:last-child) {
                            margin-bottom: 10px;
                        }
                        @media screen and (max-width: 768px) {
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }
                .left {
                    color: #fff;
                    leading-trim: both;
                    text-edge: cap;
                    font-family: "ALS Hauss";
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 720;
                    line-height: 24px;
                    @media screen and (max-width: 768px) {
                        font-size: 26px;
                    }
                }
                .right {
                    color: var(--White, #fff);
                    leading-trim: both;
                    text-edge: cap;
                    font-family: "ALS Hauss";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 24px;
                    @media screen and (max-width: 768px) {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
}
