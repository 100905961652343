@import "../../../styles/variables";

.field-adress {
    &.ant-select {
        width: 100%;
        .ant-select-selector {
            background-color: transparent;
            border: 1px solid hsla(0, 0%, 100%, 0.5);
            border-radius: 8px;
            box-shadow: none !important;
            color: #fff;
            font-size: 14px;
            height: 48px;
            line-height: 14px;
            max-height: 48px;
            padding-left: 16px;
            padding-right: 16px;

            .ant-select-selection-search-input {
                height: 100%;
            }
        }

        &:hover,
        &.ant-select-focused {
            .ant-select-selector {
                border-color: rgb(207, 200, 219) !important;
            }
        }
    }
    .ant-select-clear {
        background: none;
        color: $text-pale-color;
    }
}

.field-address-wrapper {
    position: relative;

    .title {
        position: absolute;
        display: flex;
        align-items: center;
        left: 17px;
        top: 0;
        margin-top: 0;
        height: 48px;
        pointer-events: none;
        transition: 0.2s;
        font-size: 14px;
        color: #828282;

        &.filled,
        &.focused {
            height: 12px;
            margin-top: 6px;
            font-size: 12px;
        }
    }

    .ant-select-focused.with-title,
    .ant-select.with-title.filled {
        input {
            padding-bottom: 11px !important;
            padding-top: calc(48px - 11px - 14px - 2px) !important;
        }
    }
    @media screen and (max-width: 768px) {
        .ant-select.with-title.filled {
            input {
                max-width: 88%;
            }
        }
    }
}

.bz-spinner {
    .ant-spin-dot {
        animation: spin 3000ms infinite linear;
    }
}
