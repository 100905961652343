@import "../../../../../styles/variables";

.bl-modal-info-tariff {
    margin-top: 385px;
    padding: 0 25px;
    .bl-tariff-available-title {
        @include title-bigFont-normal;
        text-align: center;
        padding: 20px 30px;
        text-decoration: underline solid #bfbfbf 1px;
        text-underline-offset: 15px;
        border-radius: 20px;
        transition: 0.9s;

        &:hover {
            background: url(~@bezlimit/bezlimit-ui/dist/images/gold-opacity.png) center center
                    no-repeat,
                url(~@bezlimit/bezlimit-ui/dist/images/gold-texture.png) center center no-repeat;
            background-size: cover;
            background-blend-mode: color, normal;
            color: $text-secondary-color;
            border-color: transparent;
            border-radius: 12px;
            box-shadow: none;
            cursor: pointer;
            -webkit-transform: scale(1.1);
            -ms-transform: scale(1.1);
            transform: scale(1.1);
        }
    }
}

@media screen and (max-width: 976px) {
    .bl-modal-info-tariff {
        margin-top: 155px;
    }
}
@media screen and (max-width: 770px) {
    .bl-modal-info-tariff {
        margin-top: 220px;
    }
}
@media screen and (max-width: 479px) {
    .bl-modal-info-tariff {
        margin-top: 190px;
        .bl-tariff-available-title {
            font-size: 24px;
            text-underline-offset: 6px;
            border: none;
            box-shadow: none;
        }
    }
}
