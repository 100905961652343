.bl-politics {
    &-support {
        cursor: pointer;
    }
}

@media screen and (max-width: 768px) {
    .bl-politics {
        text-align: center;
    }
}
